import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Paginations = (props) => {
  return (
    <>
      {props.data.from ? (
        <Pagination
          aria-label="Page navigation example"
          size="sm"
          listClassName="paginationSm justify-content-center"
        >
          {props.data.links &&
            props.data.links.map((item, i) => (
              <PaginationItem
                active={item.active}
                disabled={item.url == null || item.active}
                key={i}
              >
                <PaginationLink
                  className="mx-1"
                  onClick={(e) => props.paginate(item.url)}
                >
                  {item.label.includes("Next") ? (
                    <i className="fa fa-forward"></i>
                  ) : item.label.includes("Previous") ? (
                    <i className="fa fa-backward"></i>
                  ) : (
                    item.label
                  )}
                </PaginationLink>
              </PaginationItem>
            ))}
        </Pagination>
      ) : (
        ""
      )}
    </>
  );
};
export default Paginations;
