import React, { useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const AllAssestClient = (props) => {
    const [displayAssets, setDisplayAssets] = useState(props.data);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");

    const deleteAsset = (Id) => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets/${Id}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setDisplayAssets(null);
                setdynamic_description(res.data.message);
            });
    };

    if (displayAssets === null) {
        return "";
    } else
        return (
            <>
                <tr>
                    <td>
                        <Link to={`/assets/${displayAssets.id}`}>
                            <img src={displayAssets.main_image} height="59px"></img>
                        </Link>
                    </td>
                    <td>
                        <Link to={`/assets/${displayAssets.id}`}>
                            {displayAssets.reference}
                        </Link>
                    </td>
                    <td>
                        <Link to={`/assets/${displayAssets.id}`}>{displayAssets.name}</Link>
                    </td>
                    <td>
                        <Link to={`/assets/${displayAssets.id}`}>
                            {displayAssets.identifier}
                        </Link>
                    </td>
                    <td>
                        <Link to={`/assets/${displayAssets.id}`}>
                            {displayAssets.serial_number}
                        </Link>
                    </td>
                    <td>
                        <Link to={`/assets/${displayAssets.id}`}>
                            {displayAssets.client_name}
                        </Link>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <Button
                            color="danger"
                            onClick={() => {
                                setconfirm_alert(true);
                            }}
                        >
                            {props.t("Delete")}
                        </Button>
                    </td>
                </tr>
                {confirm_alert ? (
                    <SweetAlert
                        title={props.t("Are you sure?")}
                        cancelBtnText={props.t("Cancel")}
                        confirmBtnText={props.t("Yes, delete it!")}
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            deleteAsset(displayAssets.id);
                            setconfirm_alert(false);
                            setsuccess_dlg(true);
                            setdynamic_title("Deleted");
                            setdynamic_description("The asset has been deleted.");
                        }}
                        onCancel={() => setconfirm_alert(false)}
                    ></SweetAlert>
                ) : null}

                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t(dynamic_title)}
                        onConfirm={() => {
                            setsuccess_dlg(false);
                        }}
                    >
                        {props.t(dynamic_description)}
                    </SweetAlert>
                ) : null}
            </>
        );
};

export default withTranslation()(AllAssestClient);
AllAssestClient.propTypes = {
    t: PropTypes.any,
};