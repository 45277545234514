import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

let selectedRoleId = [];
const AllTechnicalServices = (props) => {
  const [displayTechnical, setDisplayTechnical] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const deleteUser = (Id) => {
    setdynamic_title("");
    setdynamic_description("");
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/technical-incidents/${Id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setdynamic_title("Deleted");
          setdynamic_description("The incident has been deleted.");
          setDisplayTechnical(null);
        } else {
          setdynamic_title("Failed");
          setdynamic_description(res.data.message);
        }
        setsuccess_dlg(true);
      });
    // .then(function (res) {
    //   setDisplayTechnical(null)
    //   setdynamic_description(res.data.message)
    // })
  };
  useEffect(() => {
    selectedRoleId = [];
  }, []);

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      props.setSelectedIds(selectedRoleId);
    } else {
      selectedRoleId = props.selectedIds;
      var array = selectedRoleId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
      props.setSelectedIds(selectedRoleId);
    }
  };
  return (
    <>
      {displayTechnical !== null ? (
        <tr>
          <td>
            <input
              onChange={(e) => getSelectedId(displayTechnical.id, e)}
              name="chk"
              value={displayTechnical.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>
            <Link to={`/technical-services/${displayTechnical.id}`}>
              {displayTechnical.reference}
              {displayTechnical.reference_number}
            </Link>
          </td>
          <td>
            <Link to={`/technical-services/${displayTechnical.id}`}>
              {displayTechnical.client_name}
            </Link>
          </td>
          <td>
            <Link to={`/technical-services/${displayTechnical.id}`}>
              {displayTechnical.description}
            </Link>
          </td>
          <td>
            <Link to={`/technical-services/${displayTechnical.id}`}>
              {props.t(
                displayTechnical.status == "pending"
                  ? "Pending"
                  : displayTechnical.status
              )}
            </Link>
          </td>
          <td>
            <Link to={`/technical-services/${displayTechnical.id}`}>
              {displayTechnical.assigned_to_name}
            </Link>
          </td>
          <td>
            <Link to={`/technical-services/${displayTechnical.id}`}>
              {getCurrentUser().name}
            </Link>
          </td>
          <td>
            <Link to={`/technical-services/${displayTechnical.id}`}>
              {displayTechnical.date}
            </Link>
          </td>
          <td>
            {getPermission().incidents.incidents.delete.is_checked === 1 ? (
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                {props.t("Delete")}
              </Button>
            ) : (
              ""
            )}
          </td>
        </tr>
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnText={props.t("Yes, delete it!")}
          warning
          showCancel
          
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteUser(displayTechnical.id);
            setconfirm_alert(false);
            // setsuccess_dlg(true)
            // setdynamic_title('Deleted')
            // setdynamic_description('El Documento fue eliminado.')
          }}
          onCancel={() => setconfirm_alert(false)}
        ></SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success={dynamic_title == "Deleted" ? true : false}
          error={dynamic_title == "Failed" ? true : false}
          title={props.t(dynamic_title)}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {props.t(dynamic_description)}
        </SweetAlert>
      ) : null}
    </>
  );
};
export default withTranslation()(AllTechnicalServices);
AllTechnicalServices.propTypes = {
  t: PropTypes.any,
};
