import usFlag from "../assets/images/flags/us.jpg";
import spain from "../assets/images/flags/spain.jpg";
import globe from "../assets/images/flags/globe.png";
import esRound from "../assets/images/flags/es-round.png";
import enRound from "../assets/images/flags/en-round.png";

const languages = {
  sp: {
    label: "ES",
    flag: esRound,
  },
  en: {
    label: "EN",
    flag: enRound,
  },
};

export default languages;
