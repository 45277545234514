/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Table, Card, CardBody, Row, Col } from "reactstrap";
import { getCurrentUser } from "../../helpers/Utils";
import MultiType from "../AllCharts/chartjs/barchart";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
let fromDate = "";
let category = "";
let selectedStock = "";

const StockValuation = (props) => {
    const history = useHistory();
    const [stock, setStock] = useState([]);
    //   const [salesInvoicing, setSalesInvoicing] = useState([]);
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [stockHistory, setStockHistory] = useState([]);
    const [allCategories, setAllCategories] = useState([]);

    const productCategories = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product_categories`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllCategories(res.data.product_categories);
            });
    };

    const overviewGraph = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/stock-valuation`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setStock(res.data.data.stock_valuation);
                }
                setLoader(false);
            });
    };
    const getHistory = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/stock-valuation-history`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setStockHistory(res.data.data);
                }
            });
    };
    const getExport = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/stock-valuation-history?export=download`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.location.href = res.data.url;
                }
            });
    };
    useEffect(() => {
        overviewGraph();
        getHistory();
    }, []);

    const getProducts = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products?type=history`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.products);
                } else {
                    setError(res.data.message);
                }
            });
    };
    const searchData = () => {
        setStock([]);
        setLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/stock-valuation?date=${fromDate}&category=${category}&stock=${selectedStock}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setStock(res.data.data.stock_valuation);
                }
                setLoader(false);
            });
        setStockHistory([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/stock-valuation-history?date=${fromDate}&category=${category}&stock=${selectedStock}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setStockHistory(res.data.data);
                }
            });
    };
    const getDate = (e) => {
        if (e.target.name == "start_date") {
            fromDate = e.target.value;
            searchData();
        }
    };

    const getStock = (e) => {
        selectedStock = e.target.value;
        searchData();
    };
    const getCategory = (e) => {
        category = e.target.value;
        searchData();
    };
    const getEntryType = ($referenceType, $link = "no") => {
        if ($referenceType == "PRO" || $referenceType == "pro") {
            if ($link == "no") {
                return "Product";
            }
            return "/product/";
        }
        if ($referenceType == "SER" || $referenceType == "ser") {
            if ($link == "no") {
                return "Service";
            }
            return "/service/";
        }
    };
    useEffect(() => {
        productCategories();
    }, []);
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title> Reports-StockValuation| Clouget</title>
                </MetaTags>
                <Container>
                    <div className="page-title-box">
                        <AvForm>
                            <Row>
                                <Col md={2}>
                                    <AvField
                                        onChange={(e) => getDate(e)}
                                        dateformat="MMMM d, yyyy"
                                        type="date"
                                        name="start_date"
                                        autoComplete="off"
                                        className="is-untouched is-pristine av-valid form-control"
                                    />
                                </Col>
                                <Col md={2}>
                                    <AvField
                                        type="select"
                                        name="stocks"
                                        onChange={(e) => getStock(e)}
                                    >
                                        <option value="">{props.t("Select Stock type")}</option>
                                        <option value="real_stock">{props.t("Real Stock")}</option>
                                        <option value="virtual_stock">
                                            {props.t("Virtual Stock")}
                                        </option>
                                    </AvField>
                                </Col>
                                <Col md={2}>
                                    <AvField
                                        type="select"
                                        name="product_category_id"
                                        onChange={(e) => getCategory(e)}
                                    >
                                        <option value="">{props.t("Product Category")}</option>
                                        {allCategories &&
                                            allCategories.map((item, i) => (
                                                <option key={i} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </AvField>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </Container>
                <Container>
                    <div className="page-title-box">
                        <h6 className="page-title">{props.t("Stock Valuation")}</h6>
                    </div>
                    <Card>
                        <CardBody>
                            {loader ? <Loader /> : <MultiType data={stock} />}
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Col md={2}>
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => getExport()}
                                    >
                                        {props.t("Export")}
                                    </button>
                                </div>
                            </Col>
                            <div className="table-responsive">
                                <Table striped className="align-middle">
                                    <thead>
                                        <tr>
                                            <th>{props.t("Reference")}</th>
                                            <th>{props.t("Name")}</th>
                                            <th>{props.t("Stock")}</th>
                                            <th>{props.t("Sales Stock Value($)")}</th>
                                            <th>{props.t("Purchase Stock Value($)")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stockHistory &&
                                            stockHistory.map((item, i) => (
                                                <tr
                                                    key={i}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                        history.push(
                                                            getEntryType(item.referenceType, "yes") + item.id
                                                        )
                                                    }
                                                >
                                                    <td>{item.reference}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.stock}</td>
                                                    <td>{item.sales_stock_value}</td>
                                                    <td>{item.purchase_stock_value}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(StockValuation);
StockValuation.propTypes = {
    t: PropTypes.any,
};
