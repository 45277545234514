import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import {
    getCurrentUser,
    getPermission,
    getFilterDate,
    setFilterDate,
} from "../../helpers/Utils";
const axios = require("axios").default;
import AllTechnicalServices from "../../components/TechnicalServices/technical-incident-list";
import Loader from "../../components/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBContainer,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import MultipleChoice from "../../components/filters/multiChoice";
import DateFilter from "../../components/filters/dateFilter";
import InputFilter from "../../components/filters/searchFilter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";

const TechnicalCustom = (props) => {
    const history = useHistory();
    const [ref_filter, setRef_filter] = useState(false);
    const [cliName_filter, setCliName_filter] = useState(false);
    const [createdby_filter, setCreatedBy_filter] = useState(false);
    const [amount_filter, setAmount_filter] = useState(false);
    const [statusWI_filter, setstatusWI_filter] = useState(false);
    const [date_filter, setdate_filter] = useState(false);
    const [technicalData, setTechnicalData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [timer, setTimer] = useState(null);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            history.push("/create-new-teachnical");
        }
    };
    const getSearchData = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getTechnicalData(e);
        }, 1000);
        setTimer(newTimer);
    };

    const getTechnicalData = (e) => {
        setTechnicalData([]);
        if (e !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/technical-incidents?search=${e}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setTechnicalData(res.data.clients);
                    } else {
                        setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/technical-incidents`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setTechnicalData(res.data.clients);
                    } else {
                        setError(res.data.message);
                    }
                });
        }
    };

    const getSearchedList = (e, type) => {
        setError("");
        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "client_name") {
            localStorage.setItem("clientName", e);
        }
        if (type == "created_by_name") {
            localStorage.setItem("createdBy", e);
        }
        if (type == "statusWI") {
            localStorage.setItem("statusWI", e);
        }
        if (type == "date") {
            setFilterDate(e);
        }
        if (type == "amount") {
            localStorage.setItem("amount", e);
        }
        setTechnicalData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incidents?&status=${localStorage.getItem("statusWI") !== null
                    ? localStorage.getItem("statusWI")
                    : ""
                }&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&client_name=${localStorage.getItem("clientName") !== null
                    ? localStorage.getItem("clientName")
                    : ""
                }&created_by_name=${localStorage.getItem("createdBy") !== null
                    ? localStorage.getItem("createdBy")
                    : ""
                }&amount=${localStorage.getItem("amount") !== null
                    ? localStorage.getItem("amount")
                    : ""
                }&start_date=${getFilterDate() && getFilterDate().from !== null
                    ? getFilterDate().from
                    : ""
                }&end_date=${getFilterDate() && getFilterDate().to !== null
                    ? getFilterDate().to
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setTechnicalData(res.data.clients);
                    setRef_filter(false);
                    setCliName_filter(false);
                    setdate_filter(false);
                    setCreatedBy_filter(false);
                    setAmount_filter(false);
                    setstatusWI_filter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };

    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedIds.push(parseInt(ele[i].value));
                }
            }
        } else {
            var elem = document.getElementsByName("chk");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedIds;
                    var index = array.indexOf(parseInt(elem[j].value));
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };

    const deleteMultiple = () => {
        let ids = selectedIds.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incidents/batch-delete`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    getTechnicalData();
                    setdynamic_description(res.data.message);
                }
            });
    };

    const getExport = () => {
        let ids = selectedIds?.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incidents-export`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.url;
                }
            });
    };

    const getDuplicate = () => {
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incidents/duplicate_incident`,
                {
                    id: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    history.push(`/technical-services/${res.data.data.id}`);
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };

    const getGenerate = (type) => {
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/generates`,
                {
                    id: ids,
                    from_type: "Incident",
                    to_type: type,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    if (type == "Normal Invoice") {
                        history.push(
                            `/invoicing-service/${res.data.data.id}?generate=${res.data.data.generated_from}`
                        );
                    } else {
                        history.push(
                            `/technical-service/${res.data.data.id}?generate=${res.data.data.generated_from}`
                        );
                    }
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };

    useEffect(() => {
        getTechnicalData();
    }, []);

    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <div className="page-content">
                <MetaTags>
                    <title>Technical Services | Clouget</title>
                </MetaTags>
                <Container fluid className="mt-3">
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h6 className="page-title">{props.t("Incidents")}</h6>
                                </Col>
                                <Col md={6}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getSearchData(e.target.value)}
                                            ></input>
                                        </div>
                                        {getPermission().incidents.incidents.create.is_checked ===
                                            1 ? (
                                            <>
                                                <div className="btn-group me-2">
                                                    <MDBContainer>
                                                        <MDBDropdown>
                                                            <MDBDropdownToggle className="btn btn-primary">
                                                                {props.t("More")}
                                                            </MDBDropdownToggle>
                                                            <MDBDropdownMenu>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getDuplicate()}
                                                                    >
                                                                        {props.t("Duplicate")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => getExport()}
                                                                    >
                                                                        {props.t("Export")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        {props.t("Generate")}
                                                                    </span>
                                                                    <ul className="dropdown-menu dropdown-submenu">
                                                                        <MDBDropdownItem>
                                                                            <span
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() =>
                                                                                    getGenerate("Work Estimate")
                                                                                }
                                                                            >
                                                                                {props.t("Estimate")}
                                                                            </span>
                                                                        </MDBDropdownItem>
                                                                        <MDBDropdownItem>
                                                                            <span
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() =>
                                                                                    getGenerate("Work Order")
                                                                                }
                                                                            >
                                                                                {props.t("Work Order")}
                                                                            </span>
                                                                        </MDBDropdownItem>
                                                                        <MDBDropdownItem>
                                                                            <span
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() =>
                                                                                    getGenerate("Work Delivery Note")
                                                                                }
                                                                            >
                                                                                {props.t("Delivery Notes")}
                                                                            </span>
                                                                        </MDBDropdownItem>
                                                                        <MDBDropdownItem>
                                                                            <span
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() =>
                                                                                    getGenerate("Normal Invoice")
                                                                                }
                                                                            >
                                                                                {props.t("Invoice")}
                                                                            </span>
                                                                        </MDBDropdownItem>
                                                                    </ul>
                                                                </MDBDropdownItem>
                                                            </MDBDropdownMenu>
                                                        </MDBDropdown>
                                                    </MDBContainer>
                                                </div>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => createNew()}
                                                >
                                                    {props.t("Create New")}
                                                </button>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        onClick={(e) => selectAllCheck(e.target)}
                                                        className="p-0 d-inline-block"
                                                        type="checkbox"
                                                    />
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Reference")}
                                                        {ref_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setRef_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("reference") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            ref_filter={ref_filter}
                                                            setRef_filter={setRef_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Client")}
                                                        {cliName_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setCliName_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("clientName") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setCliName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setCliName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            cliName_filter={cliName_filter}
                                                            setCliName_filter={setCliName_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{props.t("Description")}</th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Status")}
                                                        {statusWI_filter ? (
                                                            <>
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setstatusWI_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("statusWI") == "" ? (
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setstatusWI_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setstatusWI_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <MultipleChoice
                                                            statusWI_filter={statusWI_filter}
                                                            setstatusWI_filter={setstatusWI_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{props.t("Assigned to")}</th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Created by")}
                                                        {createdby_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setCreatedBy_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("createdBy") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setCreatedBy_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setCreatedBy_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            createdby_filter={createdby_filter}
                                                            setCreatedBy_filter={setCreatedBy_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Date")}
                                                        {date_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setdate_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {getFilterDate() &&
                                                                    getFilterDate().from == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setdate_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setdate_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <DateFilter
                                                            date_filter={date_filter}
                                                            setdate_filter={setdate_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {technicalData &&
                                                technicalData.map((item, i) => (
                                                    <AllTechnicalServices
                                                        data={item}
                                                        key={i}
                                                        selectedIds={selectedIds}
                                                        setSelectedIds={setSelectedIds}
                                                    />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {loader}
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(TechnicalCustom);
TechnicalCustom.propTypes = {
    t: PropTypes.any,
};
