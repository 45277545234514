/* eslint-disable use-isnan */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Table,
    Card,
    Spinner,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Modal,
    Input,
} from "reactstrap";
import classnames from "classnames";
import {
    getCurrentUser,
    getPermission,
    getFilterDate,
    setFilterDate,
} from "../../../helpers/Utils";
const axios = require("axios").default;
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uploadImg from "../../../assets/images/def-img.svg";
import Loader from "../../../components/Loader";
import InvoiceAttachment from "../../../components/Attachments/invoice-attachment";
import ClientAddress from "../../../components/ClientAddresses";
import ProductTechEstimates from "../../../components/Product/estimates";
import NewPayment from "../../../components/PaymentOption/payment_option";
import PayTermModal from "../../../components/paymentTerm";
import DeliveryOptionModal from "../../../components/DeliveryOption/modal";
import ClientList from "../../../components/ClientList";
import AssetsNew from "../../../components/Assests/assests-new";
import { Link } from "react-router-dom";
import AssignUserList from "../../../components/AssignUserList";
import AllReceipts2 from "./edit-recipt-list";
import ClientCustomDropdown from "../../../components/ClientDropdown";
import {
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBContainer,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import DownloadOption from "../../Invoicing/download";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import SendEmail from "../../sendEmail/index";
import fileSaver from "file-saver";
import DateFilter from "../../../components/filters/dateFilter";
import InputFilter from "../../../components/filters/searchFilter";
import MultipleChoice from "../../../components/filters/multiChoice";
import SendAttachment from "../../SendAttachments/index";
import InvoicingService from "../invoices-create";
import PrintOption from "../../MorePrintOption";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import moment from "moment";
import CreateTrialModal from "../../plans/trialModal";
let totalAmount;
let subTotal;
let subTotalShow;
let dValue;
let totalBasePrice;
let totalDisc;
let totalTax;
let otherTax;
let searchedClient;

const InvoicingServiceEdit = (props) => {
    const { id } = useParams();
    const [userId] = useState(parseInt(id, 10));
    const [ref_filter, setRef_filter] = useState(false);
    const [payTermModal, setPayTermModal] = useState(false);
    const [payTermLoader, setPayTermLoader] = useState(false);
    const [payTerm, setPayTerm] = useState("");
    const [payOption_filter, setPayOption_filter] = useState(false);
    const [paidBy_filter, setPaidBy_filter] = useState(false);
    const [amount_filter2, setAmount_filter2] = useState(false);
    const [dateExp_filter, setdateExp_filter] = useState(false);
    const [datePay_filter, setdatePay_filter] = useState(false);
    const [clients, setClients] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [isOpenAddress, setIsOpenAddress] = useState(false);
    const [isOpenAddress2, setIsOpenAddress2] = useState(false);
    const [clientNameData, setClientNameData] = useState("");
    const [refType, setRefType] = useState("");
    const [bank, setBank] = useState(false);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [clientAddress, setClientAddress] = useState("");
    const [saleData, setSaleData] = useState("");
    const [document, setDocument] = useState("");
    const [fileDocument, setFileDocument] = useState("");
    const [data, setData] = useState([]);
    const [attachDocument, setAttachDocument] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [downloadError, setdownloadError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [image, setImage] = useState("");
    const [selectedCate, setSelectedCate] = useState("");
    const [clientFull, setClientFull] = useState("");
    const [startDate, setStartDate] = useState("");
    const [emailDate, setEmailDate] = useState("");
    const [sentDate, setSentDate] = useState("");
    const [scheduleSentDate, setScheduleSentDate] = useState("");
    const [client2, setClient2] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [referenceType, setReferenceType] = useState("pro");
    const [assetId, setAssetId] = useState("");
    const [assetData, setAssetData] = useState("");
    const [mod, setMod] = useState(false);
    const [mods, setMods] = useState(false);
    const [deliveryOptionModal, setDeliveryOptionModal] = useState(false);
    const [isOpenModalNew, setIsOpenModalNew] = useState(false);
    const [assetModal, setassetModal] = useState(false);
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [paymentData, setPaymentData] = useState([]);
    const [deliveryOptionLoader, setDeliveryOptionLoader] = useState(false);
    const [deliveryOption, setDeliveryOption] = useState([]);
    const [selectClientModal, setSelectClientModal] = useState(false);
    const [discountValue, setDiscountValue] = useState(0);
    const [selectClientModal2, setSelectClientModal2] = useState(false);
    const [selectClientId, setSelectClientId] = useState("");
    const [selectedCates, setSelectedCates] = useState("");
    const [paymentOption, setPaymentOption] = useState("");
    const [selectClientId2, setSelectClientId2] = useState("");
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignedUserId, setAssignedUserId] = useState("");
    const [assignedUserName, setAssignedUserName] = useState("");
    const [assignUsers, setAssignUsers] = useState([]);
    const [attachedReceipts, setAttachedReceipts] = useState([]);
    const [receiptError, setReceiptError] = useState("");
    const [clientSelect, setClientSelect] = useState("");
    const [clientLoader, setClientLoader] = useState(false);
    const [mainFormDisabled, setMainFormDisabled] = useState(false);
    const [allBanks, setAllBanks] = useState([]);
    const [clientData, setClientData] = useState("");
    const [delAddress, setDelAddress] = useState("");
    const [assignedModal1, setAssignedModal1] = useState(false);
    const [assignedModal2, setAssignedModal2] = useState(false);
    const [assignedUserName1, setAssignedUserName1] = useState("");
    const [assignedUserId1, setAssignedUserId1] = useState("");
    const [agentUserName, setAgentUserName] = useState("");
    const [agentUserId, setAgentUserId] = useState("");
    const [timer, setTimer] = useState(null);
    //   const [assignUsers1, setAssignUsers1] = useState([]);
    const [allRates, setAllRates] = useState([]);
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");
    const [refNum, setRefNum] = useState("");
    const [fields, setFields] = useState({
        formValues: [],
    });

    const [paymentTerm, setPaymentTerm] = useState("");
    const [invoice, setInvoice] = useState(data.data && data.data);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [isDownloadModal, setIsDownloadModal] = useState(false);
    const history = useHistory();
    const [format, setFormat] = useState("");
    const [template1, setTemplate1] = useState("");
    const [priceTax, setPriceTax] = useState("");
    const [mailingFormat, setMailingFormat] = useState("");
    const [productService, setProductService] = useState("");
    const [defaultConfig, setDefaultConfig] = useState("");
    const [link, setLink] = useState("");
    const [email, setEmail] = useState(getCurrentUser().email);
    const [email2, setEmail2] = useState("");
    const [isSendModal, setIsSendModal] = useState(false);
    const [templateData, setTemplateData] = useState([]);
    const [selectedType, setSelectedType] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [isAttachOpenModal, setIsAttachOpenModal] = useState(false);
    const [attach, setAttach] = useState("");
    const [error2, setError2] = useState("");
    const [attachEmail, setAttachEmail] = useState("");
    const [attachEmail2, setAttachEmail2] = useState("");
    const [attachEmail3, setAttachEmail3] = useState("");
    const [emailMessage2, setEmailMessage2] = useState("");
    const [desc, setDesc] = useState("");
    const [paidStatus, setPaidStatus] = useState(false);
    const [error3, setError3] = useState("");
    const [isInvoicingService, setIsInvoicingService] = useState(false);
    const [generatedFrom, setGeneratedFrom] = useState("");
    const [error4, setError4] = useState("");
    const [isPrintModal, setIsPrintModal] = useState(false);
    const [link1, setLink1] = useState("");
    const [messageData, setMessageData] = useState("");
    const [rateValue, setRateValue] = useState("");
    const [signedBox, setSignedBox] = useState("");
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [isFormDisable, setFormDisable] = useState(false);
    const [paginationData, setPaginationData] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmModal2, setConfirmModal2] = useState(false);
    const [clientEmail, setClientEmail] = useState("");
    const [emails, setEmails] = useState("");
    const [defaultDeliveyOption, setDefaultDeliveryOption] = useState({});

    let params = new URLSearchParams(window.location.search);
    const [generate] = useState(params.get("generate"));

    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            editInv();
        }
    };

    const getClients = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getClientData(e);
        }, 1000);
        setTimer(newTimer);
    };

    const createAttachment = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("invoice_id", userId);
        formData.append("document", document);
        formData.append("description", desc);
        // formData.append("bank_account", value.bank_account);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoice-attachments`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                setDesc("");
                if (res.data.status) {
                    setError("");
                    AllAttach();
                    setSuccess(res.data.message);
                    setMods(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const authorizeVeronica = (a) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/veronica/${saleData.reference_type === "Refund Invoice"
                    ? "create-and-authorize-credit-note"
                    : "create-and-authorize-invoice"
                }/${userId}?email=${a ? clientEmail : null}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setSuccess("Invoice Authorized Successfully!");
                    getSaleData();
                } else {
                    if (res.data.data !== undefined) {
                        setError(res.data.data?.detalleError);
                    } else {
                        setError(res.data.message);
                    }
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const cancelVeronica = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/veronica/cancel-invoice/${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setSuccess("Invoice Canceled Successfully!");
                    getSaleData();
                } else {
                    if (res.data.data !== undefined) {
                        setError(res.data.data?.detalleError);
                    } else {
                        setError(res.data.message);
                    }
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const notifyVeronica = (a) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/veronica/invoice-notify/${userId}?emails=${a ? emails : null}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    getSaleData();
                } else {
                    if (res.data.result !== undefined) {
                        setError(res.data.result.messages[0]);
                    } else {
                        setError(res.data.message);
                    }
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const downloadVeronica = (type, redirect) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/veronica/generate-invoice-pdf/${userId}?type=${type}&logo=1`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                    // responseType: "blob",
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    var url = res.data.data?.urlReporte;
                    window.open(url, "_blank");
                    // if (res.data) {
                    //   var blob = new Blob([res.data], {
                    //     type: "application/pdf",
                    //   });
                    //   if (redirect){
                    //     var url = window.URL.createObjectURL(blob);
                    //     window.open(url, "_blank");
                    //   } else {
                    //     fileSaver.saveAs(blob, `INVOICE-${id}.${type}`);
                    //     setSuccess("Downloaded Successfully!");
                    //   }
                    //   setError("");
                } else {
                    if (res.data.result !== undefined) {
                        setError(res.data.result.messages[0]);
                    } else {
                        setError(res.data.message);
                    }
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const bulkPay = () => {
        let ids = selectedIds.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoice-receipts-bulk-pay`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    receiptsAll();
                    setSelectedIds([]);
                    //   setdynamic_description(res.data.message);
                }
            });
    };

    const DownloadReceipts = () => {
        if (selectedIds.length > 1) {
            setdownloadError(
                "This action can only be carried out on a single document"
            );
            setTimeout(() => {
                setdownloadError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setdownloadError("Please select document");
            setTimeout(() => {
                setdownloadError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoice-receipts-download`,
                // formData,
                {
                    ids: ids,
                },

                {
                    headers: {
                        // "Content-type": "multipart/form-data",
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                    responseType: "arraybuffer",
                }
            )
            .then(function (res) {
                if (res.data) {
                    var blob = new Blob([res.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    fileSaver.saveAs(blob, "receipt.pdf");
                }
                receiptsAll();
            });
    };
    const AllAttach = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoice-attachments`,
                {
                    headers: {
                        // "content-type": "application/json",
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAttachDocument(res.data.invoice_attachments);
            });
    };

    const receiptsAll = () => {
        setAttachedReceipts([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoice-receipts?invoice_id=${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setReceiptError(res.data.message);
                    setAttachedReceipts(res.data.invoice_receipts);
                } else {
                    setReceiptError(res.data.message);
                }
            });
    };

    const getSearchedList = (e, type) => {
        setError("");
        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "paid_by") {
            localStorage.setItem("paidBy", e);
        }
        if (type == "dateExp") {
            setFilterDate(e);
        }
        if (type == "datePay") {
            localStorage.setItem("datePay", JSON.stringify(e));
        }
        if (type == "amount2") {
            localStorage.setItem("amount2", e);
        }
        if (type == "payOption") {
            localStorage.setItem("payOption", e);
        }
        setAttachedReceipts([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoice-receipts?invoice_id=${userId}&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&paid_by=${localStorage.getItem("paidBy") !== null
                    ? localStorage.getItem("paidBy")
                    : ""
                }&amount=${localStorage.getItem("amount2") !== null
                    ? localStorage.getItem("amount2")
                    : ""
                }&payment_option=${localStorage.getItem("payOption") !== null
                    ? localStorage.getItem("payOption")
                    : ""
                }&exp_start_date=${getFilterDate() && getFilterDate().from !== null
                    ? getFilterDate().from
                    : ""
                }&exp_end_date=${getFilterDate() && getFilterDate().to !== null
                    ? getFilterDate().to
                    : ""
                }&payment_start_date=${JSON.parse(localStorage.getItem("datePay")).from !== null
                    ? JSON.parse(localStorage.getItem("datePay")).from
                    : ""
                }&payment_end_date=${JSON.parse(localStorage.getItem("datePay")).to !== null
                    ? JSON.parse(localStorage.getItem("datePay")).to
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setAttachedReceipts(res.data.invoice_receipts);
                    setRef_filter(false);
                    setPaidBy_filter(false);
                    setdateExp_filter(false);
                    setdatePay_filter(false);
                    setAmount_filter2(false);
                    setPayOption_filter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };

    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk2");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedIds.push(parseInt(ele[i].value));
                }
            }
        } else {
            var elem = document.getElementsByName("chk2");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedIds;
                    var index = array.indexOf(parseInt(elem[j].value));
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };
    const NewpaymentShow = () => {
        setPaymentLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setPaymentData(res.data.PaymentOptions);
                setPaymentLoader(false);
            });
    };

    const getDeliveryOption = () => {
        setDeliveryOptionLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/delivery-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setDeliveryOption(res.data.delivery_options);
                res.data.delivery_options?.map((item) => {
                    if (item.by_default === "1") {
                        setDefaultDeliveryOption({ value: item.id, label: item.name });
                    }
                });
                setDeliveryOptionLoader(false);
            });
    };
    const neWInvoice = () => {
        if (saleData.reference_type === "Refund Invoice") {
            history.push(`/refund-invoice-create/`);
        } else {
            history.push(`/invoicing-service/`);
        }
    };
    const backPage = () => {
        if (saleData.reference_type === "Refund Invoice") {
            history.push(`/refund-invoices/`);
        } else {
            history.push(`/invoicing/`);
        }
    };
    const getClientData = (e) => {
        setClientLoader(true);
        setAllClients([]);
        let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
        )}/get-all-clients`;
        if (e !== undefined) {
            searchedClient = e;
            url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
            )}/get-all-clients?search=${searchedClient}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.status) {
                    setAllClients(res.data.clients.data);
                    setPaginationData(res.data.clients);
                    let index = res.data.clients.data.findIndex(
                        (x) => x.id == saleData && saleData.client_id
                    );
                    if (index != -1) {
                        setClientNameData(res.data.clients.data[index].name);
                        setClientData(res.data.clients.data[index].address);
                        setDelAddress(res.data.clients.data[index].address);
                        setClients(res.data.clients.data[index]);
                        //   getSingleClientsAddress(res.data.clients.data[index].id);
                        AssetsData(res.data.clients.data[index].id);
                    }
                } else {
                    setAllClients([]);
                    setError(res.data.message);
                }
                setClientLoader(false);
                setTimeout(() => {
                    setError("");
                    //   setSuccess("");
                }, 2000);
            });
    };
    const editInv = () => {
        if (saleData.status === "paid") {
            setError3(
                "Unable to edit/delete the invoice because it contains paid receipts. Access the Receipts for this invoice and ensure that all of them are set as unpaid. This will allow you to edit/delete the invoice."
            );
            setTimeout(() => {
                setError3("");
            }, 4000);
        } else {
            setIsEditable(true);
        }
    };
    const getAllRates = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/rates`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAllRates(res.data.rates);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        AllAttach();
        getAllRates();
        getClientData();
        NewpaymentShow();
        getDeliveryOption();
    }, []);
    const getAllBanks = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/bank_accounts`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    //   setAllBanks(res.invoice.bank_accounts);
                    setAllBanks(res.data.bank_accounts);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        history.listen((location) => {
            if (location.pathname.replace(/\D/g, "")) {
                getSaleData(location.pathname.replace(/\D/g, ""));
            }
        });
        getAllBanks();
    }, []);

    const getSaleData = (id) => {
        let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
        )}/invoices/${userId}`;
        if (id !== undefined) {
            url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
            )}/invoices/${id}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.status) {
                    setClients(res.data.data);
                    setRefNum(res.data.data.reference_number);
                    setSaleData(res.data.data);
                    setPaymentOption(res.data.data.payment_option);
                    setPayTerm(res.data.data.payment_term);
                    getTemplate(res.data.data.reference_type);
                    setImgPreview(res.data.data.signature);
                    setPaidStatus(res.data.data.set_as_paid === 1 ? true : false);
                    const momentDate = moment(res.data.data.date, "DD-MM-YYYY");
                    setStartDate(momentDate.toDate());
                    if (res.data.data.email_sent_date !== null) {
                        const momentDate1 = moment(
                            res.data.data.email_sent_date,
                            "DD-MM-YYYY"
                        );
                        setEmailDate(momentDate1.toDate());
                    }
                    if (res.data.data.sent_date !== null) {
                        const momentDate2 = moment(res.data.data.sent_date, "DD-MM-YYYY");
                        setSentDate(momentDate2.toDate());
                    }
                    setSelectClientId(res.data.data.client_id);
                    setClientNameData(res.data.data.client_name);
                    setRefType(res.data.data.reference_type);
                    setClientData(res.data.data.inv_address);
                    setDelAddress(res.data.data.del_address);
                    setAssetId(res.data.data.asset_id);
                    setSelectedCates(res.data.data.payment_option);
                    setFields({
                        formValues: res.data.data.items?.map((option) => ({
                            reference: option.reference,
                            reference_id: option.reference_id,
                            reference_number: option.product_reference_number,
                            name: option.name,
                            description: option.description,
                            base_price: option.base_price,
                            quantity: option.quantity,
                            discount: option.discount,
                            amount: option.amount_with_out_vat,
                            vat: option.vat,
                            tax: option.tax,
                            subtotal: option.subtotal,
                            discAmount: option.discAmount !== null ? option.discAmount : 0,
                            taxAmount: option.taxAmount,
                            otherTaxAmount: option.otherTaxAmount,
                            showPopup: option?.showPopup,
                            isUpdate: option?.isUpdate,
                        })),
                    });

                    receiptsAll();
                    setScheduleSentDate(res.data.data.schedule_delivery_date);
                    setDiscountValue(res.data.data.meta_discount);
                    setAssignedUserId1(res.data.data.created_by);
                    setAssignedUserName1(res.data.data.created_by_name);
                    setAgentUserId(res.data.data.agent_id);
                    setAgentUserName(res.data.data.agent_name);
                    if (res.data.data.payment_option === "1") {
                        setBank(true);
                    }
                    AssetsData(res.data.data.client_id);
                    singleClientdata(res.data.data.client_id);
                    getReferences(res.data.data.reference_type);
                    getDefaultPdf(res.data.data.reference_type);
                }
            });
    };
    useEffect(() => {
        getSaleData();
    }, []);

    const productCreateSubmit = (e, value) => {
        if (isFormDisable) {
        } else {
            setLoading(true);
            let setPaid = "1";
            if (e.target.set_as_paid.checked) {
                setPaid = "1";
            } else {
                setPaid = "0";
            }
            const formData = new FormData();
            formData.append("reference", value.reference);
            // formData.append("reference_number", refNum);
            formData.append("reference_number", e.target.reference_number.value);
            formData.append("client_id", selectClientId);
            formData.append(
                "status",
                e.target.status.value !== null ? e.target.status.value : ""
            );
            formData.append("title", value.title !== null ? value.title : "");
            formData.append(
                "payment_term",
                value.payment_term !== null ? value.payment_term : ""
            );
            formData.append("date", e.target.date.value);
            formData.append("rate", value.rate !== null ? value.rate : "");
            formData.append("name", value.name !== null ? value.name : "");
            formData.append("set_as_paid", setPaid);
            formData.append("sent_date", e.target.sent_date.value);
            formData.append("delivery_option", e.target.delivery_option.value);
            formData.append(
                "inv_address",
                value.inv_address !== null ? value.inv_address : ""
            );
            formData.append(
                "private_comments",
                e.target.private_comments.value !== null
                    ? e.target.private_comments.value
                    : ""
            );
            formData.append(
                "del_address",
                value.del_address !== null ? value.del_address : ""
            );
            // formData.append("assets", value.assets);
            formData.append("created_by", assignedUserId1);
            formData.append("agent_id", agentUserId);
            formData.append("email_sent_date", e.target.email_sent_date.value);
            formData.append(
                "currency",
                value.currency !== null ? value.currency : ""
            );
            formData.append("currency_rate", e.target.currency_rate.value);
            formData.append(
                "comments",
                value.comments !== null ? value.comments : ""
            );
            formData.append(
                "private_comments",
                e.target.private_comments.value !== null
                    ? e.target.private_comments.value
                    : ""
            );
            formData.append(
                "addendum",
                value.addendum !== null ? value.addendum : ""
            );
            formData.append("tin", value.tin);
            formData.append("signature", image);
            // formData.append("asset_id", assetId);
            formData.append("asset_id", value.assets !== null ? value.assets : "");
            formData.append("meta_discount", e.target.meta_discount.value);
            formData.append("item", JSON.stringify(fields.formValues));
            formData.append("payment_option", e.target.payment_option.value);

            axios
                .post(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/invoices/${saleData.id}?_method=PUT`,
                    formData,
                    {
                        headers: {
                            "Content-type": "multipart/form-data",
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoading(false);
                    if (res.data.status) {
                        setError("");
                        setSuccess(res.data.message);
                        window.location.reload();
                        setIsEditable(false);
                    } else {
                        setError(res.data.message);
                        setSuccess("");
                        if (res.data.message === "Ced/Ruc number is required") {
                            setCustomActiveTab("5");
                        }
                    }
                    setTimeout(() => {
                        setError("");
                        setSuccess("");
                    }, 4000);
                });
        }
    };

    const getReferences = (type) => {
        setAllRef([]);
        if (type == "Normal Invoice") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/references?type=Normal Invoice`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        setAllRef(res.data.reference);
                        res.data.reference?.map((item) => {
                            if (item.by_default === "1") {
                                setDefaultRef(item.prefix);
                            }
                        });
                    } else {
                        //   setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/references?type=Refund Invoice`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        setAllRef(res.data.reference);
                        res.data.reference?.map((item) => {
                            if (item.by_default === "1") {
                                setDefaultRef(item.prefix);
                            }
                        });
                    } else {
                        //   setError(res.data.message);
                    }
                });
        }
    };

    const getType = (e) => {
        setReferenceType(e.target.value);
        setRefNum("");
    };

    const filePreview = (e) => {
        setDocument(e.target.files[0]);
        setFileDocument(URL.createObjectURL(e.target.files[0]));
    };

    const [fieldSection, setFieldSection] = useState({
        formValues: [{ message: "", cast: "" }],
    });
    const [sec, setSec] = useState(false);

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const handleChange = (i, e) => {
        let formValues = fields.formValues;
        formValues[i][e.target.name] = e.target.value;
        setFields({ formValues });
    };

    let optionPayment = [];
    if (paymentData !== undefined) {
        {
            paymentData &&
                paymentData.map((payment) => {
                    optionPayment.push({
                        value: payment.id,
                        label: payment.name,
                    });
                });
        }
    }
    function handleSelectCategoryAll(selectedcate) {
        setSelectedCates(selectedcate.value);
        if (selectedcate.value === 1) {
            setBank(true);
        } else {
            setBank(false);
        }
    }
    const addFormFields = () => {
        setFields({
            formValues: [
                ...fields.formValues,
                {
                    reference: "",
                    reference_id: "",
                    reference_number: "",
                    name: "",
                    description: "",
                    base_price: "",
                    quantity: "1",
                    discount: "",
                    amount: "0",
                    vat: "0",
                    tax: "0",
                    subtotal: "0",
                    discAmount: "0",
                    taxAmount: "0",
                    otherTaxAmount: "0",
                    showPopup: "0",
                    isUpdate: "0",
                },
            ],
        });
    };

    const removeFormFields = (i) => {
        let formValues = fields.formValues;
        formValues.splice(i, 1);
        setFields({ formValues });
    };

    const addFormFieldSection = () => {
        setSec(true);
        setFieldSection({
            formValues: [...fieldSection.formValues, { message: "", cast: "" }],
        });
    };

    totalAmount = fields.formValues.reduce(
        (totalAmount, currentValue) =>
            (totalAmount = totalAmount + parseFloat(currentValue.amount)),
        0
    );

    subTotal = fields.formValues.reduce(
        (subTotal, currentValue) =>
            (subTotal = subTotal + parseFloat(currentValue.subtotal)),
        0
    );
    subTotalShow = subTotal - (subTotal * discountValue) / 100;
    dValue = (subTotal - subTotalShow).toFixed(2);

    totalBasePrice = fields.formValues.reduce(
        (totalBasePrice, currentValue) =>
            (totalBasePrice = totalBasePrice + parseFloat(currentValue.amount)),
        0
    );
    totalDisc = fields.formValues.reduce(
        (totalDisc, currentValue) =>
            (totalDisc = totalDisc + parseFloat(currentValue.discAmount)),
        0
    );

    totalTax = fields.formValues.reduce(
        (totalTax, currentValue) =>
            (totalTax = totalTax + parseFloat(currentValue.taxAmount)),
        0
    );
    otherTax = fields.formValues.reduce(
        (otherTax, currentValue) =>
            (otherTax = otherTax + parseFloat(currentValue.otherTaxAmount)),
        0
    );
    const metaDiscount = (e) => {
        if (e == undefined) {
            subTotal = fields.formValues.reduce(
                (subTotal, currentValue) =>
                    (subTotal = subTotal + parseFloat(currentValue.subtotal)),
                0
            );
            subTotalShow = subTotal - (subTotal * discountValue) / 100;
            dValue = (subTotal - subTotalShow).toFixed(2);
        } else {
            setDiscountValue(e);
            subTotalShow = subTotal - (subTotal * e) / 100;
            dValue = (subTotal - subTotalShow).toFixed(2);
        }
    };

    const removeFormFieldSection = (i) => {
        let formValues = fieldSection.formValues;
        formValues.splice(i, 1);
        setFieldSection({ formValues });
    };

    const handleChangeSection = (i, e) => {
        let formValues = fieldSection.formValues;
        formValues[i][e.target.name] = e.target.value;
        setFieldSection({ formValues });
    };

    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };
    const AssetsData = (e) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets?client_id=${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssetData(res.data.client_assets);
                }
            });
    };

    const singleClientdata = (e) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setClients(res.data.client_address);
            });
    };

    let optionAssets = [];
    if (assetData !== undefined) {
        {
            assetData &&
                assetData.map((asset) => {
                    optionAssets.push({
                        value: asset.id,
                        label: asset.name,
                    });
                });
        }
    }

    function handleAssets(selectedcate) {
        setAssetId(selectedcate.value);
    }

    const getProducts = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.products);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const clientDetail = (e) => {
        setSelectClientId(e.id);
        setClientNameData(e.legal_name);
        setClientData(e.address);
        setDelAddress(e.address);
        setClients(e);
        //   getSingleClientsAddress(e.id);
        AssetsData(e.id);
        setPaymentOption(e.payment_option_id);
        setRateValue(e.rate);
    };

    const clientDetail2 = (e) => {
        setSelectClientId2(e);
        let index = allClients.findIndex((x) => x.id == e);
        if (index != -1) {
            setClient2(allClients[index].name);
        }
    };

    const productCategories = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product_categories`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllCategories(res.data.product_categories);
            });
    };

    // Product Categories/Set Categories
    let optionCategory = [];
    if (allCategories !== undefined) {
        {
            allCategories &&
                allCategories.map((category) => {
                    optionCategory.push({
                        value: category.id,
                        label: category.name,
                    });
                });
        }
    }
    function handleSelectCategory(selectedcate) {
        setSelectedCate(selectedcate.value);
    }

    const openNew = (e) => {
        if (e.target.value == "new") {
            setMod(true);
        }
    };

    const getallClients = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-all-clients`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllClients(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const paginateData = (link) => {
        setPaginationData("");
        setAllClients([]);
        setClientLoader(true);
        let url = `${link}&search=${searchedClient ? searchedClient : ""}`;
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setClientLoader(false);
                if (res.data.status) {
                    setError("");
                    setAllClients(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                    //   setAssignUsers1(res.data.users);
                }
            });
    };
    const getAllPayment = () => {
        setPaymentData([]);
        setPayTermLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-terms`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setPaymentTerm(res.data.payment_terms);
                    setPayTermLoader(false);
                } else {
                    setError(res.data.message);
                }
            });
    };
    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };
    const getAssignedUser1 = (id, name) => {
        setAssignedUserId1(id);
        setAssignedUserName1(name);
    };
    const getAgentUser = (id, name) => {
        setAgentUserId(id);
        setAgentUserName(name);
    };

    const deleteInvoice = (e) => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoices/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setdynamic_description(res.data.message);
                    history.push("/invoicing");
                }
            });
    };

    const getDuplicate = () => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoices/duplicate_invoice`,
                {
                    id: userId,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    window.location.href = `/invoicing-service/${res.data.data.id}`;
                }
            });
    };

    const getprint = () => {
        const formData = new FormData();
        formData.append("format", "valued");
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    openInNewTab(res.data.url);
                }
            });
    };

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };
    const getTemplate = (type) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/my_templates?type=${type}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setTemplateData(res.data.templates);
                } else {
                    setError(res.data.message);
                }
            });
    };
    const getDownloadPDF = () => {
        setLink("");
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setEmail([res.data?.client_email]);
                    setIsDownloadModal(true);
                }
            });
    };
    const getDownloadPDF2 = () => {
        // setLink("");
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);
        formData.append("download", "1");

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                    responseType: "arraybuffer",
                }
            )
            .then(function (res) {
                if (res.data) {
                    var blob = new Blob([res.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    if (saleData.reference_type == "Normal Invoice") {
                        fileSaver.saveAs(blob, `INVOICE${refNum}${clientNameData}.pdf`);
                    } else {
                        fileSaver.saveAs(
                            blob,
                            `REFUNDINVOICE${refNum}${clientNameData}.pdf`
                        );
                    }
                }
                setIsDownloadModal(false);
            });
    };

    const getSendEmail = () => {
        setLink("");
        setIsSendModal(true);
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template_id", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setEmail([res.data?.client_email]);
                }
            });
    };
    const getSendEmail2 = () => {
        // setLink("");
        const formData = new FormData();
        formData.append("send_to", email);
        formData.append("cc", email2);
        formData.append("body", messageData);
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink(res.data.url);
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                    setIsSendModal(false);
                }, 3000);
            });
    };
    const getGenerate = (type) => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/generates`,
                {
                    id: userId,
                    from_type: saleData.reference_type,
                    to_type: type,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    {
                        history.push(`/invoicing-service/${res.data.data.id}`);
                        // setTimeout(() => {
                        //   window.location.reload();
                        // }, 500);
                    }
                    setGeneratedFrom(res.data.data.generated_from);
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };
    const getSendAttachment = () => {
        if (selectedIds.length < 1) {
            setError2("Please select document");
            setTimeout(() => {
                setError2("");
            }, 4000);
            return false;
        }
        setIsAttachOpenModal(true);
    };
    const getSendAttachment2 = () => {
        const formData = new FormData();
        formData.append("send_to", attachEmail);
        formData.append("cc", attachEmail2);
        formData.append("bcc", attachEmail3);
        formData.append("body", emailMessage2);
        formData.append("ids", selectedIds);
        formData.append("type", "invoice_attachments");
        formData.append("name", selectedName);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-attachments-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 3000);
            });
    };
    const getDefaultPdf = (type) => {
        // setDefaultSend([]);
        if (type == "Normal Invoice") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/get-default-pdf?type=Normal Invoice`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        // setDefaultSend(res.data.data);
                        setFormat(res.data.data.format);
                        setMailingFormat(res.data.data.mailing_format);
                        setPriceTax(res.data.data.price_after_tax);
                        setProductService(res.data.data.include_main_image);
                    } else {
                        //   setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/get-default-pdf?type=Refund Invoice`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    // setLoader("");
                    if (res.data.status) {
                        setError("");
                        // setDefaultSend(res.data.data);
                        setFormat(res.data.data.format);
                        setMailingFormat(res.data.data.mailing_format);
                        setPriceTax(res.data.data.price_after_tax);
                        setProductService(res.data.data.include_main_image);
                    } else {
                        //   setError(res.data.message);
                    }
                });
        }
    };
    const getExport = () => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients/export-receipt`,
                {
                    ids: userId,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.location.href = res.data.url;
                }
            });
    };
    const getPrintOption = () => {
        setLink1("");
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLink1(res.data.url);
                    setIsPrintModal(true);
                }
            });
    };
    const getPrintOption2 = () => {
        const formData = new FormData();
        formData.append("format", format);
        formData.append("template1", template1);
        formData.append("prices_tax", priceTax ? "1" : "0");
        formData.append("mailing_format", mailingFormat ? "1" : "0");
        formData.append("product_service", productService ? "1" : "0");
        formData.append("default_config", defaultConfig ? "1" : "0");
        formData.append("disable_signed", signedBox ? "1" : "0");
        formData.append("id", userId);
        formData.append("type", saleData.reference_type);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    openInNewTab(res.data.url);
                }
            });
    };
    const getMessage = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-message`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    const originalString =
                        res.data.settings.email_configuration_client_emails_message;
                    setMessageData(originalString.replace(/\n/g, "<br />"));
                } else {
                    setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        productCategories();
        getAssignUser();
        getAllPayment();
        getProducts();
        getallClients();
        getMessage();
    }, []);

    return (
        <React.Fragment>
            <SendAttachment
                isAttachOpenModal={isAttachOpenModal}
                setIsAttachOpenModal={setIsAttachOpenModal}
                attach={attach}
                setAttach={setAttach}
                setAttachEmail={setAttachEmail}
                setAttachEmail2={setAttachEmail2}
                setAttachEmail3={setAttachEmail3}
                getSendAttachment2={getSendAttachment2}
                attachDocument={attachDocument}
                emailMessage2={emailMessage2}
                setEmailMessage2={setEmailMessage2}
                success={success}
                error={error}
                selectedName={selectedName}
            />
            {confirmModal ? (
                <SweetAlert
                    // title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Send")}
                    // warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        notifyVeronica(true);
                        setConfirmModal(false);
                    }}
                    onCancel={() => setConfirmModal(false)}
                >
                    <label>{props.t("Enter emails to notify")}</label>
                    <textarea
                        className="form-control"
                        type="textarea"
                        name="emails"
                        onChange={(e) => setEmails(e.target.value)}
                    />
                    <small>{props.t("Write multiple emails with ',' in between.")}</small>
                </SweetAlert>
            ) : null}
            {confirmModal2 ? (
                <SweetAlert
                    // title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Submit")}
                    // warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        authorizeVeronica(true);
                        setConfirmModal2(false);
                    }}
                    onCancel={() => setConfirmModal2(false)}
                >
                    <label>{props.t("Enter client email")}</label>
                    <input
                        className="form-control"
                        type="email"
                        name="email"
                        onChange={(e) => setClientEmail(e.target.value)}
                    />
                    <small>{props.t("")}</small>
                </SweetAlert>
            ) : null}
            {isDownloadModal ? (
                <DownloadOption
                    isDownloadModal={isDownloadModal}
                    setIsDownloadModal={setIsDownloadModal}
                    link={link}
                    setLink={setLink}
                    type={saleData.reference_type}
                    id={userId}
                    refNum={refNum}
                    setFormat={setFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getDownloadPDF={getDownloadPDF}
                    getDownloadPDF2={getDownloadPDF2}
                    templateData={templateData}
                    setSignedBox={setSignedBox}
                />
            ) : (
                ""
            )}
            {isSendModal ? (
                <SendEmail
                    isOpenModal={isSendModal}
                    setIsOpenModal={setIsSendModal}
                    link={link}
                    // setLink={setLink}
                    //   id={ids}
                    setEmail2={setEmail2}
                    setEmail={setEmail}
                    setFormat={setFormat}
                    setTemplate1={setTemplate1}
                    setPriceTax={setPriceTax}
                    setMailingFormat={setMailingFormat}
                    setProductService={setProductService}
                    setDefaultConfig={setDefaultConfig}
                    getSendEmail={getSendEmail}
                    getSendEmail2={getSendEmail2}
                    success={success}
                    error={error}
                    email={email}
                    email2={email2}
                    // emailMessage={emailMessage}
                    type={saleData.reference_type}
                    // setEmailMessage={setEmailMessage}
                    messageData={messageData}
                    setMessageData={setMessageData}
                    format={format}
                    mailingFormat={mailingFormat}
                    priceTax={priceTax}
                    productService={productService}
                    setSignedBox={setSignedBox}
                    id={userId}
                />
            ) : (
                ""
            )}
            <PrintOption
                isPrintModal={isPrintModal}
                setIsPrintModal={setIsPrintModal}
                link={link1}
                setLink={setLink1}
                type={saleData.reference_type}
                id={userId}
                refNum={refNum}
                setFormat={setFormat}
                setTemplate1={setTemplate1}
                setPriceTax={setPriceTax}
                setMailingFormat={setMailingFormat}
                setProductService={setProductService}
                setDefaultConfig={setDefaultConfig}
                getPrintOption={getPrintOption}
                getPrintOption2={getPrintOption2}
                templateData={templateData}
                setSignedBox={setSignedBox}
            />
            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Yes, delete it!")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        deleteInvoice(userId);
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        setdynamic_title("Deleted");
                        setdynamic_description("El Documento fue eliminado.");
                    }}
                    onCancel={() => setconfirm_alert(false)}
                ></SweetAlert>
            ) : null}
            <div className="page-content">
                <MetaTags>
                    <title>Edit Invoice | Clouget</title>
                </MetaTags>
                <Modal size="lg" isOpen={assetModal} centered={true}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            {props.t("New Assets")}
                        </h5>
                        <button
                            type="button"
                            className="btn btn-primary text-md btn-sm"
                            onClick={() => setassetModal(false)}
                            //   onClick={() => props.setassetModal(false)
                            aria-hidden="true"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <AssetsNew
                            clientData={clients}
                            modalData={assetModal}
                            setassetModal={setassetModal}
                            AssetsData={AssetsData}
                        />
                    </div>
                </Modal>
                {isOpenModalNew ? (
                    <NewPayment
                        setIsOpenModal={setIsOpenModalNew}
                        NewpaymentShow={NewpaymentShow}
                        setPaymentOption={setPaymentOption}
                    />
                ) : (
                    ""
                )}
                {payTermModal ? (
                    <PayTermModal
                        setPayTerm={setPayTerm}
                        getAllPayment={getAllPayment}
                        setPayTermModal={setPayTermModal}
                    />
                ) : (
                    ""
                )}
                {deliveryOptionModal ? (
                    <DeliveryOptionModal
                        setDeliveryOptionModal={setDeliveryOptionModal}
                        getDeliveryOption={getDeliveryOption}
                    />
                ) : (
                    ""
                )}
                {selectClientModal ? (
                    <ClientList
                        setSelectClientModal={setSelectClientModal}
                        setSelectClientId={setSelectClientId}
                        clients={allClients}
                        clientDetail={clientDetail}
                        getClients={getClients}
                    />
                ) : (
                    ""
                )}
                {selectClientModal2 ? (
                    <ClientList
                        setSelectClientModal={setSelectClientModal2}
                        setSelectClientId={setSelectClientId2}
                        clients={allClients}
                        clientDetail={clientDetail2}
                    />
                ) : (
                    ""
                )}
                {assignedModal ? (
                    <AssignUserList
                        setAssignedModal={setAssignedModal}
                        getAssignedUser={getAssignedUser}
                        data={assignUsers}
                    />
                ) : (
                    ""
                )}
                {isOpenAddress ? (
                    <ClientAddress
                        setIsOpenModal={setIsOpenAddress}
                        setClientNameData={setClientNameData}
                        setClientData={setClientData}
                        clients={clients}
                    />
                ) : (
                    ""
                )}
                {isOpenAddress2 ? (
                    <ClientAddress
                        setIsOpenModal={setIsOpenAddress2}
                        setClientNameData={setClientNameData}
                        setDelAddress={setDelAddress}
                        clients={clients}
                    />
                ) : (
                    ""
                )}
                {isInvoicingService ? (
                    <InvoicingService
                        isInvoicingService={isInvoicingService}
                        setIsInvoicingService={setIsInvoicingService}
                    />
                ) : (
                    ""
                )}
                <CreateTrialModal
                    isTrialModal={isTrialModal}
                    setIsTrialModal={setIsTrialModal}
                    nonActive={nonActive}
                />
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                productCreateSubmit(e, v);
                            }}
                            disabled={mainFormDisabled ? true : false}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">
                                            {" "}
                                            {saleData.generated_from == "" ? (
                                                getCurrentUser().rimpe_regime_type === "popular" ? (
                                                    `${props.t("Edit Sales Note")}`
                                                ) : (
                                                    `${props.t("Edit Invoice")}`
                                                )
                                            ) : saleData.generated_from ? (
                                                <>
                                                    {props.t(saleData.generated_from)}{" "}
                                                    {saleData.generated_id}
                                                </>
                                            ) : getCurrentUser().rimpe_regime_type === "popular" ? (
                                                `${props.t("Edit Sales Note")}`
                                            ) : (
                                                `${props.t("Edit Invoice")}`
                                            )}
                                        </h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            <span
                                                className="btn btn-primary"
                                                onClick={() => backPage()}
                                            >
                                                {props.t("Back")}
                                            </span>
                                            <span
                                                className="btn btn-primary ms-2"
                                                onClick={() => neWInvoice()}
                                            >
                                                {props.t("Add")}
                                            </span>
                                            <div className="btn-group me-2">
                                                <MDBContainer>
                                                    <MDBDropdown>
                                                        <MDBDropdownToggle
                                                            className="btn btn-primary"
                                                            type="button"
                                                        >
                                                            {props.t("More")}
                                                        </MDBDropdownToggle>
                                                        <MDBDropdownMenu>
                                                            {/* <MDBDropdownItem>
                                <span
                                  className="nav-item dropdown-item"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => downloadVeronica("xml", false)}
                                >
                                  {props.t("Download XML")}
                                </span>
                              </MDBDropdownItem> */}
                                                            {saleData.veronica_status === "pending" ||
                                                                saleData.veronica_status === "rejected" ? (
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() =>
                                                                            saleData?.client_email
                                                                                ? authorizeVeronica(false)
                                                                                : setConfirmModal2(true)
                                                                        }
                                                                    >
                                                                        {props.t("Authorize")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                            ) : (
                                                                <>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="nav-item dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => cancelVeronica()}
                                                                        >
                                                                            {props.t("Cancel")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="nav-item dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() =>
                                                                                downloadVeronica("pdf-ticket", true)
                                                                            }
                                                                        >
                                                                            {props.t("Print TICKET")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="nav-item dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() =>
                                                                                downloadVeronica("pdf-factura", false)
                                                                            }
                                                                        >
                                                                            {props.t("Print RIDE")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="nav-item dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => notifyVeronica(false)}
                                                                        >
                                                                            {props.t("Notify")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="nav-item dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => setConfirmModal(true)}
                                                                        >
                                                                            {props.t("Notify to")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                </>
                                                            )}
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => getDownloadPDF()}
                                                                >
                                                                    {props.t("Download as pdf")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => getprint()}
                                                                >
                                                                    {props.t("Print")}
                                                                </span>
                                                                <ul className="dropdown-menu dropdown-submenu">
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => getprint()}
                                                                        >
                                                                            {props.t("Default View")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem>
                                                                        <span
                                                                            className="dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => getPrintOption()}
                                                                        >
                                                                            {props.t("More options")}
                                                                        </span>
                                                                    </MDBDropdownItem>
                                                                </ul>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setIsSendModal(true)}
                                                                >
                                                                    {props.t("Send")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => getDuplicate()}
                                                                >
                                                                    {props.t("Duplicate")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            {getCurrentUser().role_name == "admin" ||
                                                                getCurrentUser().role_name == "Admin" ? (
                                                                <MDBDropdownItem>
                                                                    <span
                                                                        className="nav-item dropdown-item"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setconfirm_alert(true);
                                                                        }}
                                                                    >
                                                                        {props.t("Delete")}
                                                                    </span>
                                                                </MDBDropdownItem>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <MDBDropdownItem>
                                                                <span
                                                                    className="nav-item dropdown-item"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => getExport()}
                                                                >
                                                                    {props.t("Export")}
                                                                </span>
                                                            </MDBDropdownItem>
                                                            <MDBDropdownItem>
                                                                {saleData.reference_type ===
                                                                    "Normal Invoice" ? (
                                                                    <>
                                                                        <span
                                                                            className="nav-item dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {props.t("Generate")}
                                                                        </span>
                                                                        <ul className="dropdown-menu dropdown-submenu">
                                                                            <MDBDropdownItem>
                                                                                <span
                                                                                    className="dropdown-item"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() =>
                                                                                        getGenerate("Refund Invoice")
                                                                                    }
                                                                                >
                                                                                    {props.t("Refund Invoice")}
                                                                                </span>
                                                                            </MDBDropdownItem>
                                                                        </ul>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </MDBDropdownItem>
                                                        </MDBDropdownMenu>
                                                    </MDBDropdown>
                                                </MDBContainer>
                                            </div>
                                            {isEditable ? (
                                                <>
                                                    {loading ? (
                                                        <button
                                                            className="btn btn-primary  disabled"
                                                            type="button"
                                                            disabled
                                                        >
                                                            {props.t("Loading")}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-primary "
                                                            type="submit"
                                                            disabled={isFormDisable}
                                                        >
                                                            {props.t("Update")}
                                                        </button>
                                                    )}
                                                    <span
                                                        onClick={() => setIsEditable(false)}
                                                        className="btn btn-primary  ms-2"
                                                    >
                                                        {props.t("Cancel")}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {getPermission().invoices.invoices.edit.is_checked ===
                                                        1 ? (
                                                        <span
                                                            onClick={() => createNew()}
                                                            className="btn btn-primary "
                                                        >
                                                            {props.t("Edit")}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? (
                                <Alert color="success">{props.t(success)}</Alert>
                            ) : null}
                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                            {error3 ? <Alert color="danger">{props.t(error3)}</Alert> : null}
                            {error4 ? <Alert color="danger">{props.t(error4)}</Alert> : null}
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label>{props.t("Reference")}:</label>
                                                <div className="d-flex">
                                                    <AvField
                                                        style={{
                                                            width: "100px",
                                                        }}
                                                        type="select"
                                                        value={
                                                            saleData.reference !== null
                                                                ? saleData.reference
                                                                : ""
                                                        }
                                                        name="reference"
                                                        onChange={(e) => getType(e)}
                                                        disabled={isEditable ? false : true}
                                                    >
                                                        {allRef &&
                                                            allRef.map((item, i) => (
                                                                <option value={item.prefix} key={i}>
                                                                    {item.prefix}
                                                                </option>
                                                            ))}
                                                        {/* {saleData && saleData.reference == "INV" ? (
                              <option value="INV">INV</option>
                            ) : (
                              <option value="RCT">RCT</option>
                            )} */}
                                                    </AvField>
                                                    <div className="w-100 ps-3">
                                                        <Input
                                                            className="w-100 quantity"
                                                            type="number"
                                                            //   value={saleData && saleData.reference_number}
                                                            step="1"
                                                            min="1"
                                                            value={refNum !== null ? refNum : ""}
                                                            name="reference_number"
                                                            //   placeholder={
                                                            //     referenceType === "pro" ? "Automatic" : ""
                                                            //   }
                                                            disabled={isEditable ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 required">
                                                <label>{props.t("Client")}</label>
                                                <div className="d-flex">
                                                    <div className="pe-2 w-100 client_custom-dropdown">
                                                        <ClientCustomDropdown
                                                            getClients={getClients}
                                                            data={allClients}
                                                            getClientData={getClientData}
                                                            clientLoader={clientLoader}
                                                            setMainFormDisabled={setMainFormDisabled}
                                                            setClientSelect={setClientSelect}
                                                            setClientAddress={setClientAddress}
                                                            setClientFull={setClientFull}
                                                            clientNameData={clientNameData}
                                                            setClientNameData={setClientNameData}
                                                            clientDetail={clientDetail}
                                                            getAssests={AssetsData}
                                                            isEditable={isEditable}
                                                            paginationData={paginationData}
                                                            paginateData={paginateData}
                                                        />
                                                    </div>
                                                    <div className="pe-2">
                                                        <button
                                                            className="btn btn-light"
                                                            onClick={
                                                                isEditable
                                                                    ? () => {
                                                                        setSelectClientModal(true),
                                                                            getClientData();
                                                                    }
                                                                    : () => ""
                                                            }
                                                            type="button"
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </div>
                                                    <Link
                                                        className={`btn btn-light ${selectClientId == "" ? "disabled" : ""
                                                            }`}
                                                        to={`/client/${selectClientId}`}
                                                        target="_blank"
                                                    >
                                                        <i className="fas fa-eye" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Payment Option")} </label>
                                                <div className="d-flex justify-content-between">
                                                    {paymentLoader ? (
                                                        <div className="pe-2">
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                            ></Spinner>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div className="w-100 pe-2">
                                                        <AvField
                                                            readOnly={isEditable ? false : true}
                                                            type="select"
                                                            name="payment_option"
                                                            onChange={(e) =>
                                                                handleSelectCategoryAll(e.target.value)
                                                            }
                                                            // isDisabled={paymentLoader ? true : false}
                                                            value={
                                                                paymentOption !== null ? paymentOption : ""
                                                            }
                                                        //   value={saleData && saleData.payment_option}
                                                        >
                                                            <option value="" hidden>
                                                                {props.t("Select")}
                                                            </option>
                                                            {paymentData &&
                                                                paymentData.map((item, i) => (
                                                                    <option
                                                                        value={item.id}
                                                                        key={i}
                                                                    // label={item.name}
                                                                    >
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </AvField>
                                                    </div>
                                                    <button
                                                        className="btn btn-primary waves-effect waves-light"
                                                        onClick={() => setIsOpenModalNew(true)}
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                        }}
                                                        type="button"
                                                        disabled={isEditable ? false : true}
                                                    >
                                                        + {props.t("New")}
                                                    </button>
                                                </div>
                                            </div>

                                            {bank ? (
                                                <div className="mb-3">
                                                    <AvField
                                                        label={props.t("Bank Account")}
                                                        readOnly={isEditable ? false : true}
                                                        type="select"
                                                        name="bank_account"
                                                        value={
                                                            saleData.bank_account !== null
                                                                ? saleData.bank_account
                                                                : ""
                                                        }
                                                    >
                                                        <option value="no_account">
                                                            {props.t("Without bank account")}
                                                        </option>
                                                        {allBanks &&
                                                            allBanks.map((bank, i) => (
                                                                <option value={bank.id} key={i}>
                                                                    {bank.name}
                                                                </option>
                                                            ))}
                                                    </AvField>
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Title")}
                                                    type="text"
                                                    name="title"
                                                    value={saleData.title !== null ? saleData.title : ""}
                                                    readOnly={isEditable ? false : true}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Payment Term")}</label>
                                                <div className="d-flex justify-content-between">
                                                    {payTermLoader ? (
                                                        <div className="pe-2">
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                            ></Spinner>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div className="w-100 pe-2">
                                                        <AvField
                                                            type="select"
                                                            name="payment_term"
                                                            onChange={(e) => setPayTerm(e.target.value)}
                                                            value={payTerm}
                                                        >
                                                            <option value="" hidden>
                                                                {" "}
                                                                {props.t("Select")}
                                                            </option>
                                                            {paymentTerm &&
                                                                paymentTerm.map((item) => (
                                                                    <option value={item.id} key={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </AvField>
                                                    </div>
                                                    <button
                                                        className="btn btn-primary waves-effect waves-light"
                                                        onClick={() => setPayTermModal(true)}
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                        }}
                                                        type="button"
                                                    >
                                                        + {props.t("New")}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Set as paid")}
                                                    name="set_as_paid"
                                                    type="checkbox"
                                                    onChange={() => setPaidStatus(!paidStatus)}
                                                    checked={saleData.set_as_paid === 1 ? true : false}
                                                    disabled={isEditable ? false : true}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label>{props.t("Date")}</label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(e) => setStartDate(e)}
                                                    dateFormat="dd/MM/yyyy"
                                                    //   minDate={new Date()}
                                                    name="date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                    disabled={isEditable ? false : true}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                {saleData.veronica_status === "authorized" ? (
                                                    <span className="badge bg-success float-end">
                                                        {props.t("AUTHORIZED")}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                                <AvField
                                                    label={props.t("Status")}
                                                    type="select"
                                                    name="status"
                                                    value={
                                                        paidStatus ? "paid" : saleData && saleData.status
                                                    }
                                                    //   value={saleData && saleData.status}
                                                    disabled={isEditable ? false : true}
                                                >
                                                    <option value="unpaid">{props.t("Unpaid")}</option>
                                                    <option value="draft">{props.t("Draft")}</option>
                                                    <option value="partially paid" disabled>
                                                        {props.t("Partially Paid")}
                                                    </option>
                                                    <option value="paid" disabled>
                                                        {props.t("Paid")}
                                                    </option>
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Created by")}:</label>

                                                <span
                                                    onClick={() => setAssignedModal1(true)}
                                                    className="form-control"
                                                    style={{
                                                        minHeight: "35px",
                                                        backgroundColor: isEditable ? "" : "#f1f1f1",
                                                        pointerEvents: isEditable ? "" : "none",
                                                    }}
                                                >
                                                    {assignedUserName1}
                                                </span>
                                                {/* </input> */}
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Agent")}</label>
                                                <span
                                                    onClick={() => setAssignedModal2(true)}
                                                    className="form-control"
                                                    style={{
                                                        minHeight: "35px",
                                                        backgroundColor: isEditable ? "" : "#f1f1f1",
                                                        pointerEvents: isEditable ? "" : "none",
                                                    }}
                                                >
                                                    {agentUserName}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Rate")}
                                                    type="select"
                                                    name="rate"
                                                    // value={saleData && saleData.rate}
                                                    value={rateValue !== "" ? rateValue : saleData.rate}
                                                    disabled={isEditable ? false : true}
                                                >
                                                    {allRates?.map((item, i) => (
                                                        <option value={item.name} key={i}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </AvField>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Items")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("More Information")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "3",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("3");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Receipts")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "4",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("4");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Attachments")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "5",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("5");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Signature")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "6",
                                                })} `}
                                                onClick={() => {
                                                    toggleCustom("6");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Related")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="p-3">
                                        <TabPane tabId="1" className="table-responsive">
                                            <Row>
                                                <Col md={3} className="p-0">
                                                    <div className="button-section">
                                                        <button
                                                            className="btn btn-primary"
                                                            type="button"
                                                            onClick={() => addFormFields()}
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            + &nbsp; {props.t("New Item")}
                                                        </button>
                                                    </div>
                                                </Col>

                                                <Col md={9}></Col>
                                            </Row>
                                            <Row>
                                                <Col className="p-0">
                                                    <div className="table-responsive m-height250">
                                                        <table className="table prod_data" width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>{props.t("Reference")}</th>
                                                                    <th>{props.t("Name")}</th>
                                                                    <th>{props.t("Description")}</th>
                                                                    <th>{props.t("Base Price ($)")}</th>
                                                                    <th>{props.t("Quantity")}</th>
                                                                    <th>{props.t("Disc. %")}</th>
                                                                    <th>{props.t("Subtotal ($)")}</th>
                                                                    <th>{props.t("Tax")} %</th>
                                                                    <th>{props.t("Other Tax")}</th>
                                                                    <th>{props.t("Delete")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {fields.formValues &&
                                                                    fields.formValues.map((element, i) => (
                                                                        <ProductTechEstimates
                                                                            i={i}
                                                                            key={i}
                                                                            data={element}
                                                                            fields={fields}
                                                                            clientId={selectClientId}
                                                                            setFields={setFields}
                                                                            discountValue={discountValue}
                                                                            metaDiscount={metaDiscount}
                                                                            setMainFormDisabled={setMainFormDisabled}
                                                                            isEditable={isEditable}
                                                                        />
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <Row className="row pt-3 pb-2">
                                                        <Col>
                                                            <div
                                                                style={{
                                                                    borderBottom: "1px solid #ced4da",
                                                                }}
                                                            ></div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="">
                                                        <div className="d-flex justify-content-end pe-5 ms-2">
                                                            <div>
                                                                <label className="me-2">
                                                                    {props.t("Disc. %")}:
                                                                </label>
                                                                <input
                                                                    style={{
                                                                        width: "70px",
                                                                    }}
                                                                    className="input-style"
                                                                    min="0"
                                                                    type="number"
                                                                    name="meta_discount"
                                                                    onChange={(e) => {
                                                                        setDiscountValue(e.target.value),
                                                                            metaDiscount(e.target.value);
                                                                    }}
                                                                    value={(
                                                                        (totalDisc / (totalBasePrice + totalDisc)) *
                                                                        100
                                                                    ).toFixed(2)}
                                                                    disabled
                                                                ></input>
                                                            </div>
                                                            <div>
                                                                {" "}
                                                                <input
                                                                    style={{
                                                                        width: "70px",
                                                                    }}
                                                                    disabled
                                                                    value={totalDisc.toFixed(2)}
                                                                    className="ms-1 input-style"
                                                                    name="d_value"
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="sub-total-div mt-5">
                                                        <div className="sub-total">
                                                            <h6>{props.t("Subtotal ($)")}:</h6>
                                                            <p>{totalBasePrice.toFixed(2)}</p>
                                                        </div>
                                                        {totalDisc !== 0 || totalDisc !== NaN ? (
                                                            <div className="sub-total">
                                                                <h6>{props.t("Discount (%)")}:</h6>
                                                                <p>{totalDisc.toFixed(2)}</p>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="sub-total">
                                                            <h6>{props.t("IVA ($)")}:</h6>
                                                            <p>{totalTax.toFixed(2)}</p>
                                                        </div>
                                                        {otherTax !== 0 ? (
                                                            <div className="sub-total">
                                                                <h6>{props.t("IR ($)")}:</h6>
                                                                <p>{otherTax.toFixed(2)}</p>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="sub-total2">
                                                            <h6>{props.t("Total ($)")}:</h6>
                                                            <p>
                                                                {subTotalShow
                                                                    ? subTotalShow.toFixed(2)
                                                                    : subTotal.toFixed(2)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <label>{props.t("Assets")}:</label>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="w-100 pe-2">
                                                                <AvField
                                                                    type="select"
                                                                    name="assets"
                                                                    value={
                                                                        saleData.asset_id !== null
                                                                            ? saleData.asset_id
                                                                            : ""
                                                                    }
                                                                    disabled={isEditable ? false : true}
                                                                >
                                                                    <option value="" hidden>
                                                                        {props.t("Select")}
                                                                    </option>
                                                                    {assetData &&
                                                                        assetData.map((item, i) => (
                                                                            <option value={item.id} key={i}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                </AvField>
                                                            </div>
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                onClick={() => setassetModal(true)}
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                                type="button"
                                                                disabled={isEditable ? false : true}
                                                            >
                                                                + {props.t("New")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <div>
                                                    <AvField
                                                        label={props.t("Filter by delivery address")}
                                                        type="checkbox"
                                                        name="chk"
                                                        disabled={isEditable ? false : true}
                                                    />
                                                </div>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <label className="me-2">
                                                        {props.t("Sent date")}:
                                                    </label>
                                                    <DatePicker
                                                        selected={sentDate}
                                                        onChange={(e) => setSentDate(e)}
                                                        disabled={isEditable ? false : true}
                                                        dateFormat="dd/MM/yyyy"
                                                        // value={saleData && saleData.sent_date}
                                                        autoComplete="off"
                                                        // minDate={new Date()}
                                                        name="sent_date"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <label>{props.t("Delivery Options")}</label>
                                                    <div className="d-flex justify-content-between">
                                                        {deliveryOptionLoader ? (
                                                            <div className="pe-2">
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                ></Spinner>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="w-100 pe-2">
                                                            <AvField
                                                                type="select"
                                                                name="delivery_option"
                                                                value={
                                                                    saleData.delivery_option !== null
                                                                        ? saleData.delivery_option
                                                                        : ""
                                                                }
                                                                disabled={isEditable ? false : true}
                                                            >
                                                                {deliveryOption &&
                                                                    deliveryOption.map((item, i) => (
                                                                        <option value={item.id} key={i}>
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                            </AvField>
                                                        </div>
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light"
                                                            onClick={() => setDeliveryOptionModal(true)}
                                                            style={{
                                                                whiteSpace: "nowrap",
                                                            }}
                                                            type="button"
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            + {props.t("New")}
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <AvField
                                                        type="text"
                                                        name="inv_address"
                                                        label="Inv. Address:"
                                                        value={clientData !== null ? clientData : ""}
                                                        onClick={() => setIsOpenAddress(true)}
                                                        disabled={isEditable ? false : true}
                                                    />
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <AvField
                                                        type="text"
                                                        name="del_address"
                                                        label="Del. Address:"
                                                        value={delAddress !== null ? delAddress : ""}
                                                        onClick={() => setIsOpenAddress2(true)}
                                                        disabled={isEditable ? false : true}
                                                    />
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <label className="me-2">
                                                        {props.t("Email Sent date")}:
                                                    </label>
                                                    <DatePicker
                                                        selected={emailDate}
                                                        onChange={(e) => setEmailDate(e)}
                                                        disabled={isEditable ? false : true}
                                                        dateFormat="dd/MM/yyyy"
                                                        autoComplete="off"
                                                        // minDate={new Date()}
                                                        // value={saleData && saleData.email_sent_date}
                                                        name="email_sent_date"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3"></Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <AvField
                                                        type="select"
                                                        name="currency"
                                                        value={
                                                            saleData.currency !== null
                                                                ? saleData.currency
                                                                : ""
                                                        }
                                                        label={props.t("Currency")}
                                                        disabled={isEditable ? false : true}
                                                    >
                                                        <option value="inr">INR</option>
                                                        <option value="usd">USD</option>
                                                        <option value="gbp">GBP</option>
                                                    </AvField>
                                                </Col>
                                                <Col lg={6} md={6} className="mt-3">
                                                    <AvField
                                                        type="text"
                                                        name="currency_rate"
                                                        label={props.t("Currency Rate")}
                                                        value={
                                                            saleData.currency_rate !== null
                                                                ? saleData.currency_rate
                                                                : ""
                                                        }
                                                        disabled={isEditable ? false : true}
                                                    // value={saleData && saleData.currency_rate}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3 mt-3">
                                                        <AvField
                                                            label={props.t("Comments")}
                                                            type="textarea"
                                                            name="comments"
                                                            value={
                                                                saleData.comments !== null
                                                                    ? saleData.comments
                                                                    : ""
                                                            }
                                                            disabled={isEditable ? false : true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Private Comments")}
                                                            type="textarea"
                                                            name="private_comments"
                                                            value={
                                                                saleData.private_comments !== null
                                                                    ? saleData.private_comments
                                                                    : ""
                                                            }
                                                            disabled={isEditable ? false : true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="mt-3">
                                                    <AvField
                                                        label={props.t("Addendum")}
                                                        type="textarea"
                                                        name="addendum"
                                                        value={
                                                            saleData.addendum !== null
                                                                ? saleData.addendum
                                                                : ""
                                                        }
                                                        disabled={isEditable ? false : true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <div>
                                                        <AvField
                                                            label={props.t("Created from")}
                                                            type="text"
                                                            name="created_from"
                                                            value={props.t("Web")}
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane className="content-hight" tabId="3">
                                            <Row>
                                                <Col md={3} className="p-0">
                                                    <div className="button-section">
                                                        <button
                                                            className="btn btn-secondary me-3"
                                                            type="button"
                                                            onClick={() => bulkPay()}
                                                        //   disabled={isEditable ? false : true}
                                                        >
                                                            {props.t("Pay")}
                                                        </button>
                                                        <button
                                                            className="btn btn-secondary"
                                                            type="button"
                                                            onClick={() => DownloadReceipts()}
                                                        //   disabled={isEditable ? false : true}
                                                        >
                                                            {props.t("Download")}
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col md={9}></Col>
                                            </Row>
                                            <Row>
                                                {/* {receiptError ? (
                          <Alert color="danger">{receiptError}</Alert>
                        ) : (
                          ""
                        )} */}
                                                {downloadError ? (
                                                    <Alert color="danger">{downloadError}</Alert>
                                                ) : (
                                                    ""
                                                )}
                                                <div className="table-responsive">
                                                    <Table striped className="align-middle mt-3 ">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>{props.t("Reference")}</th>
                                                                <th>Concept</th>
                                                                <th>
                                                                    <div
                                                                        className="position-relative"
                                                                        style={{ whiteSpace: "nowrap" }}
                                                                    >
                                                                        {props.t("Paid by")}
                                                                        {paidBy_filter ? (
                                                                            <>
                                                                                <span
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        color: "orange",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        setPaidBy_filter(false)
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-filter ms-2"></i>
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {localStorage.getItem("paidBy") ==
                                                                                    "" ? (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() =>
                                                                                            setPaidBy_filter(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "orange",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            setPaidBy_filter(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <InputFilter
                                                                            paidBy_filter={paidBy_filter}
                                                                            setPaidBy_filter={setPaidBy_filter}
                                                                            getSearchedList={getSearchedList}
                                                                        />
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div
                                                                        className="position-relative"
                                                                        style={{ whiteSpace: "nowrap" }}
                                                                    >
                                                                        {props.t("Expiration Date")}
                                                                        {dateExp_filter ? (
                                                                            <>
                                                                                <span
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        color: "orange",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        setdateExp_filter(false)
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-filter ms-2"></i>
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {getFilterDate() &&
                                                                                    getFilterDate().from == "" ? (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() =>
                                                                                            setdateExp_filter(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "orange",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            setdateExp_filter(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <DateFilter
                                                                            dateExp_filter={dateExp_filter}
                                                                            setdateExp_filter={setdateExp_filter}
                                                                            getSearchedList={getSearchedList}
                                                                        />
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div
                                                                        className="position-relative"
                                                                        style={{ whiteSpace: "nowrap" }}
                                                                    >
                                                                        {props.t("Payment Date")}
                                                                        {datePay_filter ? (
                                                                            <>
                                                                                <span
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        color: "orange",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        setdatePay_filter(false)
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-filter ms-2"></i>
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {JSON.parse(
                                                                                    localStorage.getItem("datePay")
                                                                                ) &&
                                                                                    JSON.parse(
                                                                                        localStorage.getItem("datePay")
                                                                                    ).from == "" ? (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() =>
                                                                                            setdatePay_filter(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "orange",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            setdatePay_filter(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <DateFilter
                                                                            datePay_filter={datePay_filter}
                                                                            setdatePay_filter={setdatePay_filter}
                                                                            getSearchedList={getSearchedList}
                                                                        />
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div
                                                                        className="position-relative"
                                                                        style={{ whiteSpace: "nowrap" }}
                                                                    >
                                                                        {props.t("Payment Option")}
                                                                        {payOption_filter ? (
                                                                            <>
                                                                                <span
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        color: "orange",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        setPayOption_filter(false)
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-filter ms-2"></i>
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {localStorage.getItem("payOption") ==
                                                                                    "" ? (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() =>
                                                                                            setPayOption_filter(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "orange",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            setPayOption_filter(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <MultipleChoice
                                                                            payOption_filter={payOption_filter}
                                                                            setPayOption_filter={setPayOption_filter}
                                                                            data={paymentData}
                                                                            getSearchedList={getSearchedList}
                                                                        />
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div
                                                                        className="position-relative"
                                                                        style={{ whiteSpace: "nowrap" }}
                                                                    >
                                                                        {props.t("Amount")}
                                                                        {amount_filter2 ? (
                                                                            <>
                                                                                <span
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        color: "orange",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        setAmount_filter2(false)
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-filter ms-2"></i>
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {localStorage.getItem("amount2") ==
                                                                                    "" ? (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() =>
                                                                                            setAmount_filter2(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className=""
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color: "orange",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            setAmount_filter2(true)
                                                                                        }
                                                                                    >
                                                                                        <i className="fa fa-filter ms-2"></i>
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <InputFilter
                                                                            amount_filter2={amount_filter2}
                                                                            setAmount_filter2={setAmount_filter2}
                                                                            getSearchedList={getSearchedList}
                                                                        />
                                                                    </div>
                                                                </th>
                                                                <th>{props.t("Status")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {attachedReceipts &&
                                                                attachedReceipts.map((item, i) => (
                                                                    <AllReceipts2
                                                                        data={item}
                                                                        key={i}
                                                                        setLoading={setLoading}
                                                                        getSaleData={getSaleData}
                                                                        getAllReceipts={receiptsAll}
                                                                        selectedIds={selectedIds}
                                                                        setSelectedIds={setSelectedIds}
                                                                        setSelectedType={setSelectedType}
                                                                        selectedType={selectedType}
                                                                        NewpaymentShow={NewpaymentShow}
                                                                        paymentData={paymentData}
                                                                        assignUsers={assignUsers}
                                                                        isFormDisable={isFormDisable}
                                                                        setFormDisable={setFormDisable}
                                                                    //   downloadError={downloadError}
                                                                    />
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <TabPane tabId="attachments">
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="attach_btn">
                                                            <div
                                                                className="float-end d-flex btn btn-primary ms-2"
                                                                onClick={() => setMods(true)}
                                                            >
                                                                {props.t("New")}
                                                            </div>
                                                            <div
                                                                className="float-end d-flex btn btn-primary"
                                                                onClick={() => getSendAttachment()}
                                                            >
                                                                {props.t("Send via email")}
                                                            </div>
                                                            {error2 ? (
                                                                <Alert color="danger">{props.t(error2)}</Alert>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <Table striped className="align-middle mt-5">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>{props.t("Name")}</th>
                                                                        <th>{props.t("Description")}</th>
                                                                        <th>{props.t("Upload Date")}</th>
                                                                        <th>{props.t("Activity")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {attachDocument &&
                                                                        attachDocument.map((item) => (
                                                                            <InvoiceAttachment
                                                                                data={item}
                                                                                key={item.id}
                                                                                selectedIds={selectedIds}
                                                                                setSelectedIds={setSelectedIds}
                                                                                selectedName={selectedName}
                                                                                setSelectedName={setSelectedName}
                                                                            />
                                                                        ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <Modal size="md" isOpen={mods} centered={true}>
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title mt-0"
                                                        id="myLargeModalLabel"
                                                    >
                                                        {props.t("New Document")}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="cross__btn btn btn-primary waves-effect waves-light"
                                                        onClick={() => setMods(false)}
                                                        aria-hidden="true"
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                                <form>
                                                    <Card>
                                                        <CardBody>
                                                            <div className="modal-body p-0">
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="mb-3">
                                                                            <label>{props.t("Document")}</label>
                                                                            <input
                                                                                type="file"
                                                                                className="ms-3"
                                                                                name="document"
                                                                                onChange={filePreview}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={12} md={12} className="mt-3">
                                                                        <div className="mb-3">
                                                                            <AvField
                                                                                label={props.t("Description")}
                                                                                type="textarea"
                                                                                name="description"
                                                                                onChange={(e) =>
                                                                                    setDesc(e.target.value)
                                                                                }
                                                                            // value={saleData && saleData.description}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <div className="text-end">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        type="button"
                                                                        onClick={() => createAttachment()}
                                                                    >
                                                                        {props.t("Submit")}
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-primary ms-2"
                                                                        type="button"
                                                                        onClick={() => setMods(false)}
                                                                    >
                                                                        {props.t("Cancel")}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </form>
                                            </Modal>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Name")}
                                                            type="text"
                                                            name="name"
                                                            value={
                                                                saleData.name !== null ? saleData.name : ""
                                                            }
                                                            disabled={isEditable ? false : true}
                                                        />
                                                    </div>
                                                    <div className="mb-3 required">
                                                        <AvField
                                                            label={props.t("Ced/Ruc")}
                                                            type="number"
                                                            onKeyPress={(e) => {
                                                                if (e.target.value.length == 13)
                                                                    e.preventDefault();
                                                            }}
                                                            name="tin"
                                                            value={
                                                                clients?.tin !== null
                                                                    ? clients?.tin
                                                                    : saleData?.tin
                                                            }
                                                            disabled={isEditable ? false : true}
                                                            required="required"
                                                        />
                                                    </div>
                                                    <div className="company_img img-flex">
                                                        <label>{props.t("Main image")}</label>
                                                        <input
                                                            className="hidden d-none"
                                                            onChange={(e) => setComImg(e)}
                                                            disabled={isEditable ? false : true}
                                                            type="file"
                                                            id="comImg"
                                                            defaultValue={saleData && saleData.imgPreview}
                                                        />
                                                        <label className="company_img-box" htmlFor="comImg">
                                                            <img src={imgPreview} />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                    {assignedModal1 ? (
                        <AssignUserList
                            setAssignedModal={setAssignedModal1}
                            getAssignedUser={getAssignedUser1}
                            data={assignUsers}
                        />
                    ) : (
                        ""
                    )}

                    {assignedModal2 ? (
                        <AssignUserList
                            setAssignedModal={setAssignedModal2}
                            getAssignedUser={getAgentUser}
                            data={assignUsers}
                        />
                    ) : (
                        ""
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(InvoicingServiceEdit);
InvoicingServiceEdit.propTypes = {
    t: PropTypes.any,
};
