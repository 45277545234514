import React, { useState, useEffect } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvRadio,
    AvCheckboxGroup,
    AvCheckbox,
} from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let defaultValues;

function BankModal(props) {
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [bank, setBank] = useState("");
    const [swift, setSwift] = useState(false);

    const getSingleBank = () => {
        setLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/bank_accounts/${props.bankId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setBank(res.data.bank_account);
                    if (
                        res.data.bank_account.format == "ahorros" ||
                        res.data.bank_account.format == "corriente"
                    ) {
                        setSwift(true);
                    } else {
                        setSwift(false);
                    }
                    if (res.data.bank_account.format == "swift/aba") {
                        setSwift(true);
                    } else {
                        setSwift(false);
                    }
                    setError("");
                    defaultValues = {
                        is_default: res.data.bank_account.is_default,
                    };
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 500);
                setLoader(false);
            });
    };

    const submitBankAccount = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/bank_accounts${props.bankId ? `/${props.bankId}?_method=PUT` : ""}`,
                {
                    format: value.format,
                    account: value.account,
                    account_holder: value.account_holder,
                    swift_aba: value.swift_aba,
                    ced_ruc: value.ced_ruc,
                    name: value.name,
                    is_default: value.is_default,
                    description: value.description,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    if (props.bankId) {
                        props.setBank(res.data.bank_account);
                    } else {
                        props.getAllBanks();
                    }
                    props.setIsOpenModal(false);
                    setError("");
                    setSuccess("");
                }, 500);
            });
    };

    useEffect(() => {
        if (props.bankId !== undefined) {
            getSingleBank();
        }
    }, []);

    return (
        <Modal size="md" isOpen={props.isOpenModal} centered={true}>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    submitBankAccount(e, v);
                }}
                model={defaultValues}
                style={{
                    minHeight: "350px",
                }}
            >
                {loader ? (
                    ""
                ) : (
                    <>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.bankId ? bank.name : `${props.t("New Bank Account")}`}
                            </h5>
                            <button
                                type="button"
                                className="btn btn-primary text-md btn-sm"
                                onClick={() => props.setIsOpenModal(false)}
                                aria-hidden="true"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {success ? (
                                <div className="mb-3">
                                    <Alert color="success">{props.t(success)}</Alert>
                                </div>
                            ) : null}

                            {error ? (
                                <div className="mb-3">
                                    <Alert color="danger">{props.t(error)}</Alert>
                                </div>
                            ) : null}
                            <div className="mb-3">
                                <label>{props.t("Account type")}</label>
                                <AvRadioGroup
                                    name="format"
                                    className="radiobutton"
                                    value={bank?.format}
                                >
                                    <AvRadio
                                        label={props.t("AHORROS")}
                                        value="ahorros"
                                        onClick={() => setSwift(false)}
                                    />
                                    <AvRadio
                                        label={props.t("CORRIENTE")}
                                        value="corriente"
                                        onClick={() => setSwift(false)}
                                    />
                                    {/* <AvRadio
                    label="Swift/ABA"
                    value="swift/aba"
                    onClick={() => setSwift(true)}
                  /> */}
                                </AvRadioGroup>
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("Bank Name")}
                                    type="text"
                                    name="name"
                                    value={bank?.name}
                                />
                            </div>
                            <div className="mb-3 required">
                                <AvField
                                    label={props.t("Account number")}
                                    type="text"
                                    name="account"
                                    value={bank?.account}
                                    required="required"
                                />
                            </div>
                            <div className="mb-3 required">
                                <AvField
                                    label={props.t("Account Holder Name")}
                                    type="text"
                                    name="account_holder"
                                    value={bank?.account_holder}
                                    required="required"
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("ABA/SWIFT")}
                                    type="text"
                                    name="swift_aba"
                                    value={bank?.swift_aba}
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("CED/RUC")}
                                    type="number"
                                    onKeyPress={(e) => {
                                        if (e.target.value.length == 13) e.preventDefault();
                                    }}
                                    name="ced_ruc"
                                    value={bank?.ced_ruc}
                                />
                            </div>
                            <div className="mb-1">
                                {/* <label>By default</label> */}
                                <AvCheckboxGroup name="is_default">
                                    <AvCheckbox label={props.t("By Default")} value="1" />
                                </AvCheckboxGroup>
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("Description")}
                                    type="textarea"
                                    name="description"
                                    value={bank?.description}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-end">
                                {loading ? (
                                    <button
                                        className="btn btn-primary disabled"
                                        type="button"
                                        disabled
                                    >
                                        Loading
                                    </button>
                                ) : (
                                    <button className="btn btn-primary" type="submit">
                                        {props.bankId
                                            ? `${props.t("Update")}`
                                            : `${props.t("Submit")}`}
                                    </button>
                                )}
                                <button
                                    className="btn btn-primary ms-2"
                                    type="button"
                                    onClick={() => props.setIsOpenModal(false)}
                                >
                                    {props.t("Cancel")}
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </AvForm>
        </Modal>
    );
}
export default withTranslation()(BankModal);
BankModal.propTypes = {
    t: PropTypes.any,
};
