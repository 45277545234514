/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import CreateProductModal from "../../../components/Product/createProductModal/index";
import ServiceModalPP from "../../../components/Product/serrvicePopup/index";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const NameCustomDropdown = (props) => {
  const [createNew, setCreateNew] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [serviceModal, setServiceModal] = useState(false);
  const [productModal, setProductModal] = useState(false);

  const selectOption = (item) => {
    props.selectProduct2(item, props.remove);
    setSelectedName(item.name);
    // props.setMainFormDisabled(false);
  };
  // const getName = (e) => {
  //   setProductNameData(e.target.value);
  // };

  const selectOption2 = (item) => {
    props.selectProduct2(item, props.remove);
    setSelectedName(item.name);
    // props.setMainFormDisabled(false);
  };
  return (
    <>
      {productModal ?
        <CreateProductModal
          productModal={productModal}
          setProductModal={setProductModal}
          getProduct={props.getProduct}
          setMainFormDisabled={props.setMainFormDisabled}
          selectOption={selectOption}
          name={selectedName}
        />
        :
        ""
      }
      {serviceModal ?
        <ServiceModalPP
          serviceModal={serviceModal}
          setServiceModal={setServiceModal}
          getServices={props.getServices}
          setMainFormDisabled={props.setMainFormDisabled}
          selectOption2={selectOption2}
          name={selectedName}
        />
        :
        ""
      }
      <UncontrolledDropdown className="dropdown-menu-right mr-2">
        <DropdownToggle className="w-100 text-left p-0 border-0">
          <div className="position-relative">
            <AvField
              //   className="w-100"
              type="text"
              name="item_name"
              placeholder={props.t("Select")}
              autoComplete="off"
              value={
                props.productNameData !== undefined
                  ? props.productNameData
                  : selectedName
              }
              onChange={(e) => {
                props.getProductData(e.target.value),
                  props.removeFields(e.target.value, props.remove),
                  setSelectedName(e.target.value)
              }}
              disabled={
                props.isEditable !== undefined
                  ? props.isEditable
                    ? false
                    : true
                  : false
              }
            />
            {/* {props.clientLoader ? (
              <div className="clientLoader">
                <Spinner size="sm" />
              </div>
            ) : (
              ""
            )} */}
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="w-100 p-0"
          style={{
            transform: "translate3d(0px, 35px, 0px)",
            overflow: "auto",
          }}
        >
          <DropdownItem
            onClick={() => { setProductModal(true), props.setMainFormDisabled(true) }}
            className="text-dark px-3"
          >
            {props.t("Add Product")}
          </DropdownItem>
          <DropdownItem
            onClick={() => { setServiceModal(true), props.setMainFormDisabled(true) }}
            className="text-dark px-3"
          >
            {props.t("Add Service")}
          </DropdownItem>
          {props.data &&
            props.data.map((item, i) => (
              <DropdownItem
                key={i}
                className="text-dark px-3"
                style={{
                  lineHeight: "15px",
                }}
                onClick={() => {
                  setSelectedName(""), selectOption(item);
                }}
              >
                {/* <small className="text-uppercase d-block">
                  {item.reference}
                  {item.reference_number}
                </small> */}
                {item.name}
              </DropdownItem>
            ))}
          {props.data2 &&
            props.data2.map((item, i) => (
              <DropdownItem
                key={i}
                className="text-dark px-3"
                style={{
                  lineHeight: "15px",
                }}
                onClick={() => {
                  setSelectedName(""), selectOption2(item);
                }}
              >
                {/* <small className="text-uppercase d-block">
                  {item.reference}
                  {item.reference_number}
                </small> */}
                {item.name}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};
export default withTranslation()(NameCustomDropdown);
NameCustomDropdown.propTypes = {
  t: PropTypes.any,
};
