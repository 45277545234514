/* eslint-disable no-empty */
import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../../components/Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

let totalpercentage;

const CreatePaymentTerm = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);
    const [err2, setErr2] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loader, setLoader] = useState("");
    const [paymentTerms, setPaymentTerms] = useState({
        fields: [{ percentage: "100", days: "0" }],
    });

    const addOtherFields = () => {
        setPaymentTerms({
            fields: [...paymentTerms.fields, { percentage: "0", days: "0" }],
        });
    };

    const removeOtherFields = (i) => {
        setLoader(<Loader />);
        let fields = paymentTerms.fields;
        setPaymentTerms({ fields: [] });
        fields.splice(i, 1);
        setTimeout(() => {
            setPaymentTerms({ fields });
            setLoader("");
        }, 500);
    };

    const handleOtherFields = (i, e) => {
        let fields = paymentTerms.fields;
        fields[i][e.target.name] = e.target.value;
        setPaymentTerms({ fields });
        totalpercentage = paymentTerms.fields.reduce(
            (totalpercentage, currentValue) =>
                (totalpercentage = totalpercentage + parseInt(currentValue.percentage)),
            0
        );
        if (totalpercentage != 100) {
            setErr(true);
            setErr2(false);
        } else {
            setErr(false);
        }
    };

    const submitPaymentTerm = (e, value) => {
        let valid = "1";
        paymentTerms.fields?.map((item) => {
            if (item.percentage == "0") {
                setErr2(true);
                valid = "0";
            }
        });
        if (valid == "0") {
        } else {
            setErr2(false);
            setLoading(true);
            axios
                .post(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/payment-terms`,
                    {
                        name: value.name,
                        description: value.description,
                        terms: paymentTerms.fields,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setSuccess(res.data.message);
                        setLoading(false);
                        history.push(`/company?main=4&sub=2`);
                    } else {
                        setError(res.data.message);
                        setSuccess("");
                        setLoading(false);
                    }
                });
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>New payment term | Clouget</title>
                </MetaTags>
                <Container fluid className="">
                    <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                            submitPaymentTerm(e, v);
                        }}
                    >
                        <div className="company_list">
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">
                                            {props.t("New payment term")}
                                        </h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-primary "
                                                    type="submit"
                                                    disabled={err ? true : false}
                                                >
                                                    {props.t("Save")}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Card>
                                <CardBody>
                                    {success ? <Alert color="success">{props.t(success)}</Alert> : null}
                                    {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                    {loader ? (
                                        loader
                                    ) : (
                                        <>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Name")}
                                                    type="text"
                                                    name="name"
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Description")}
                                                    type="textarea"
                                                    name="description"
                                                />
                                            </div>
                                            <div className="mb-3 mt-2">
                                                <div className="">
                                                    <button
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={() => addOtherFields()}
                                                    >
                                                        <i className="fa fa-plus me-2"></i>
                                                        {props.t("New Item")}
                                                    </button>
                                                </div>
                                                {err ? (
                                                    <span className="text-danger">
                                                        {props.t(
                                                            "The total percentage amount must add to 100%"
                                                        )}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}{" "}
                                                <br />
                                                {err2 ? (
                                                    <span className="text-danger">
                                                        {props.t("You cannot insert a 0% percentage.")}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="table-responsive mb-3">
                                                <table style={{ width: "100%" }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>{props.t("Percentage")}(%)</th>
                                                            <th>{props.t("Days")}</th>
                                                            <th className="text-end">{props.t("Delete")}</th>
                                                        </tr>
                                                        {paymentTerms.fields?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <AvField
                                                                        name="percentage"
                                                                        type="number"
                                                                        onChange={(e) =>
                                                                            handleOtherFields(index, e)
                                                                        }
                                                                        value={item.percentage}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <AvField
                                                                        name="days"
                                                                        type="number"
                                                                        onChange={(e) =>
                                                                            handleOtherFields(index, e)
                                                                        }
                                                                        value={item.days}
                                                                    />
                                                                </td>
                                                                <td className="text-end">
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        onClick={() => removeOtherFields(index)}
                                                                    >
                                                                        &times;
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </AvForm>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(CreatePaymentTerm);
CreatePaymentTerm.propTypes = {
    t: PropTypes.any,
};
