import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Alert, Spinner } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCurrentUser } from "../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const EditDepositWithrawModal = (props) => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [assignedUserName, setAssignedUserName] = useState(
        getCurrentUser().email
    );
    const [paymentData, setPaymentData] = useState("");
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [selectedCates, setSelectedCates] = useState("");
    const [bank, setBank] = useState(false);
    const [assignedUserId, setAssignedUserId] = useState(getCurrentUser().id);

    const createSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("concept", e.target.concept.value);
        formData.append("date", e.target.date.value);
        // formData.append("type", "deposit");
        // formData.append("client_id", props.id);
        formData.append("amount", e.target.amount.value);
        formData.append("paid_by", assignedUserId);
        formData.append("payment_option", selectedCates);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/deposit/${props.isTransaction.id}?_method=PUT`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    window.location.reload();
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    props.setIsOpenEditModal(false);
                    props.getBalance();
                    setError("");
                    setSuccess("");
                }, 2000);
            });
    };
    const NewpaymentShow = () => {
        setPaymentLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setPaymentData(res.data.PaymentOptions);
                setPaymentLoader(false);
            });
    };
    function handleSelectCategoryAll(selectedcate) {
        setSelectedCates(selectedcate);
        if (selectedcate === "1") {
            setBank(true);
        } else {
            setBank(false);
        }
    }
    useEffect(() => {
        NewpaymentShow();
    }, []);
    return (
        <>
            <Modal size="lg" isOpen={props.isOpenEditModal} centered={true}>
                {success ? <Alert color="success">{props.t(success)}</Alert> : null}
                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        createSubmit(e, v);
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            {props.t("Edit")}
                        </h5>
                        <button
                            type="button"
                            className="btn btn-primary text-md btn-sm"
                            onClick={() => props.setIsOpenEditModal(false)}
                            aria-hidden="true"
                        >
                            &times;
                        </button>
                    </div>

                    <Row>
                        <Col>
                            <div className="modal-body">
                                <div>
                                    <AvField
                                        label={props.t("Concept")}
                                        type="text"
                                        name="concept"
                                        value={props.isTransaction && props.isTransaction.concept}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>{props.t("Date")}</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(e) => setStartDate(e)}
                                        dateFormat="dd/MM/yyyy"
                                        name="date"
                                        type="date"
                                        autoComplete="off"
                                        className="is-untouched is-pristine av-valid form-control"
                                        value={props.isTransaction.date}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>{props.t("Payment Option")} </label>
                                    <div className="d-flex justify-content-between">
                                        {paymentLoader ? (
                                            <div className="pe-2">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="w-100 pe-2">
                                            <AvField
                                                type="select"
                                                name="payment_option"
                                                onChange={(e) =>
                                                    handleSelectCategoryAll(e.target.value)
                                                }
                                                isDisabled={paymentLoader ? true : false}
                                                value={props.isTransaction.payment_option_name}
                                            >
                                                {paymentData &&
                                                    paymentData.map((item, i) => (
                                                        <option
                                                            value={item.id}
                                                            key={i}
                                                        // label={item.name}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    ))}
                                            </AvField>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label>{props.t("Amount")}:</label>
                                    <input
                                        type="number"
                                        name="amount"
                                        className="form-control"
                                        value={props.isTransaction.amount}
                                        disabled
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>{props.t("Paid by")}:</label>
                                    <span
                                        className="form-control "
                                        value={props.isTransaction.paid_by_name}
                                        disabled
                                    >
                                        {assignedUserName}
                                    </span>
                                </div>

                                <div className="text-end">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => props.setIsOpenEditModal(false)}
                                    >
                                        {props.t("Cancel")}
                                    </button>
                                    <button className="btn btn-primary ms-2" type="submit">
                                        {props.t("Update")}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </AvForm>
            </Modal>
        </>
    );
};
export default withTranslation()(EditDepositWithrawModal);
EditDepositWithrawModal.propTypes = {
    t: PropTypes.any,
};
