import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
import axios from "axios";
import ReferenceModal from "./reference-modal";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../../plans/trialModal";
const ReferenceList = (props) => {
  const [reference, setReference] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [reference_modal, setreference_modal] = useState(false);
  const [nonActive, setNonActive] = useState(
    JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !== 1 &&
      JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id == null
      ? true
      : false
  );
  const [isTrialModal, setIsTrialModal] = useState(false);
  const createNew = () => {
    if (nonActive) {
      setIsTrialModal(true);
    } else {
      setreference_modal(true);
    }
  };
  const deleteReference = (e) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references/${e} `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function () {
        setReference("");
      });
  };

  if (reference === "") {
    return "";
  } else
    return (
      <>
        {reference_modal ? (
          <ReferenceModal
            reference_modal={reference_modal}
            setreference_modal={setreference_modal}
            reference={reference}
            setReference={setReference}
          />
        ) : null}
        <CreateTrialModal
          isTrialModal={isTrialModal}
          setIsTrialModal={setIsTrialModal}
          nonActive={nonActive}
        />
        <tr key={props.key}>
          <td>
            <span
              onClick={
                getPermission()?.references.references.edit.is_checked === 1
                  ? () => createNew(true)
                  : ""
              }
            >
              {props.t(reference.name)}
            </span>
          </td>
          <td>
            <span
              onClick={
                getPermission()?.references.references.edit.is_checked === 1
                  ? () => createNew(true)
                  : ""
              }
            >
              {props.t(reference.title)}
            </span>
          </td>
          <td>
            <span
              onClick={
                getPermission()?.references.references.edit.is_checked === 1
                  ? () => createNew(true)
                  : ""
              }
            >
              {props.t(reference.type)}
            </span>
          </td>
          <td>
            <span
              onClick={
                getPermission()?.references.references.edit.is_checked === 1
                  ? () => createNew(true)
                  : ""
              }
            >
              {reference.prefix}
            </span>
          </td>
          <td>
            <span
              onClick={
                getPermission()?.references.references.edit.is_checked === 1
                  ? () => createNew(true)
                  : ""
              }
            >
              {reference.last_reference}
            </span>
          </td>
          <td>
            <span
              onClick={
                getPermission()?.references.references.edit.is_checked === 1
                  ? () => createNew(true)
                  : ""
              }
            >
              {reference.number_of_digit}
            </span>
          </td>
          <td>
            <span
              onClick={
                getPermission()?.references.references.edit.is_checked === 1
                  ? () => createNew(true)
                  : ""
              }
            >
              {reference.by_default == "1" ? (
                <i className="fa fa-check" />
              ) : (
                <i className="fa fa-times" />
              )}
            </span>
          </td>
          <td>
            {getPermission()?.references.references.delete.is_checked === 1 ? (
              <a
                className="text-danger ms-2"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                <i className="fas fa-trash-alt me-2" />
                {props.t("Delete")}
              </a>
            ) : (
              ""
            )}
            {confirm_alert ? (
              <SweetAlert
                title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
                warning
                showCancel
                
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false);
                  setsuccess_dlg(true);
                  deleteReference(reference.id);
                  setdynamic_title("Deleted");
                  setdynamic_description("Your file has been deleted.");
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won&apos;t be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
      </>
    );
};
export default withTranslation()(ReferenceList);
ReferenceList.propTypes = {
  t: PropTypes.any,
};
