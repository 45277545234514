import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";
const axios = require("axios").default;
import { Row, Col, Alert, Card } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";
import { withTranslation } from "react-i18next";
// import images
import logoSm from "../../assets/images/logo-sm.png";
import loginbg from "../../assets/images/login-bg.jpg";

const ForgetPasswordPage = (props) => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState("");

    // handleValidSubmit
    const handleValidSubmit = (event, values) => {
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_APIKEY}/forget-password`, {
                email: values.email,
                company_name: values.company_name,
            })
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
            })
            .catch(function (error) {
                setLoading(false);
                setError(error.response.data.message);
                setSuccess("");
            });
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Forget Password | Clouget</title>
            </MetaTags>
            <div
                className="accountbg"
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(${loginbg})`,
                }}
            ></div>
            <div className="wrapper-page account-page-full">
                <Card className="shadow-none">
                    <div className="card-block">
                        <div className="account-box">
                            <div className="card-box shadow-none p-5">
                                <div className="text-center mt-2">
                                    <a className="logo logo-admin">
                                        <img src={logoSm} height="45" alt="logo" />
                                    </a>
                                </div>
                                <h4 className="font-size-18 mt-4 text-center">
                                    {props.t("Forget Password")}
                                </h4>
                                <p className="text-muted text-center">
                                    {props.t(
                                        "Enter your Email and instructions will be sent to you!"
                                    )}
                                </p>

                                <AvForm
                                    className="form-horizontal mt-4"
                                    onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                >
                                    <div className="mt-5">
                                        {success ? (
                                            <Alert color="success">{props.t(success)}</Alert>
                                        ) : null}

                                        {error ? (
                                            <Alert color="danger">{props.t(error)}</Alert>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="email"
                                            label={props.t("Email")}
                                            className="form-control"
                                            placeholder={props.t("Enter email")}
                                            type="email"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label>{props.t("Company Name")}</label>
                                        <AvField
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            type="text"
                                            placeholder={props.t("Enter Company Name")}
                                            className="form-control"
                                            name="company_name"
                                            required
                                        />
                                    </div>
                                    <Row className="mb-3">
                                        <Col className="text-end">
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary " type="submit">
                                                    {props.t("Reset")}
                                                </button>
                                            )}
                                        </Col>
                                    </Row>
                                </AvForm>
                                <div className="mt-5 text-center">
                                    <p>
                                        {props.t("Remember It ?")}
                                        <Link to="/login" className="fw-medium text-primary">
                                            {" "}
                                            {props.t("Sign In here")}
                                        </Link>{" "}
                                    </p>
                                    <p>© {new Date().getFullYear()} Clouget.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </React.Fragment>
    );
};

ForgetPasswordPage.propTypes = {
    forgetError: PropTypes.any,
    forgetSuccessMsg: PropTypes.any,
    history: PropTypes.object,
    userForgetPassword: PropTypes.func,
    t: PropTypes.any,
};

const mapStatetoProps = (state) => {
    const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
    return { forgetError, forgetSuccessMsg };
};

export default withRouter(
    connect(mapStatetoProps, { userForgetPassword })(
        withTranslation()(ForgetPasswordPage)
    )
);
