import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import BankModal from "./bank-modal";
import { Container, Alert, Col, Row, CardBody, Card, Table } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import SingleList from "./single-list";
import Loader from "../../components/Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";
import { Link } from "react-router-dom";

function AllBankAccounts(props) {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [error, setError] = useState("");
    const [allBanks, setAllBanks] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsOpenModal(true);
        }
    };
    const getAllBanks = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/bank_accounts`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setAllBanks(res.data.bank_accounts);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getAllBanks();
    }, []);

    return (
        <React.Fragment>
            {/* <div className="page-content"> */}
            {isOpenModal ? (
                <BankModal
                    isOpenModal={true}
                    setIsOpenModal={setIsOpenModal}
                    getAllBanks={getAllBanks}
                />
            ) : (
                ""
            )}
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <MetaTags>
                <title>All Companies | Clouget</title>
            </MetaTags>
            {/* <Container fluid> */}
            <div className="page-title-box">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h6 className="page-title">{props.t("Bank Accounts")}</h6>
                    </Col>
                    {getPermission().bank_accounts.bank_accounts.create.is_checked ===
                        1 ? (
                        <Col md={6}>
                            <div className="float-end d-none d-md-block">
                                <button
                                    type="button"
                                    onClick={() => createNew()}
                                    className="btn btn-primary ms-2"
                                    data-toggle="modal"
                                    data-target=".bs-example-modal-lg"
                                >
                                    {props.t("New")}
                                </button>
                            </div>
                            {/* <Link to="/company" className="btn btn-primary float-end">
                    {props.t("Back")}
                  </Link> */}
                        </Col>
                    ) : (
                        ""
                    )}
                </Row>
            </div>
            <Card>
                <CardBody>
                    <div className="table-responsive">
                        <Table striped className="align-middle">
                            <thead>
                                <tr>
                                    {/* <th><input type="checkbox" /></th> */}
                                    <th>{props.t("Name")}</th>
                                    <th>{props.t("Description")}</th>
                                    <th>{props.t("Account")}</th>
                                    <th>{props.t("ABA/SWIFT")}</th>
                                    <th className="text-center">{props.t("By Default")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {allBanks.map((bank, i) => (
                                    <SingleList key={i} data={bank} />
                                ))}
                            </tbody>
                        </Table>
                        {loader}
                        {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                        {/* <Pagination */}
                        {/*   aria-label="Page navigation example" */}
                        {/*   listClassName="justify-content-end" */}
                        {/* > */}
                        {/*   <PaginationItem disabled> */}
                        {/*     <PaginationLink href="#" tabIndex="-1"> */}
                        {/*       Previous */}
                        {/*     </PaginationLink> */}
                        {/*   </PaginationItem> */}
                        {/*   <PaginationItem> */}
                        {/*     <PaginationLink href="#">1</PaginationLink> */}
                        {/*   </PaginationItem> */}
                        {/*   <PaginationItem> */}
                        {/*     <PaginationLink href="#">2</PaginationLink> */}
                        {/*   </PaginationItem> */}
                        {/*   <PaginationItem> */}
                        {/*     <PaginationLink href="#">3</PaginationLink> */}
                        {/*   </PaginationItem> */}
                        {/*   <PaginationItem> */}
                        {/*     <PaginationLink href="#">Next</PaginationLink> */}
                        {/*   </PaginationItem> */}
                        {/* </Pagination> */}
                    </div>
                </CardBody>
            </Card>
            {/* </Container> */}
            {/* </div> */}
        </React.Fragment>
    );
}
export default withTranslation()(AllBankAccounts);
AllBankAccounts.propTypes = {
    t: PropTypes.any,
};
