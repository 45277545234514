import React, { useState, useEffect, useRef } from "react";
import { Modal, Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PrintOption = (props) => {
  const [change, setChange] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      props.getPrintOption();
    }
  }, [change]);
  return (
    <div>
      <Modal size="xl" isOpen={props.isPrintModal} centered={true}>
        <AvForm className="form-horizontal">
          <div>
            <Row>
              <Col md={6} className="p-5 pt-3">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  {props.t("Preview")} {props.t(props.type)}
                </h5>
                <div className="mb-3 mt-3">
                  <label>{props.t("Reference")}:{props.refNum}</label>
                </div>
                <div>
                  <h5>{props.t("PDF Options")}</h5>
                  <div className="mb-3">
                    <AvField
                      label={props.t("Format")}
                      type="select"
                      name="format"
                      onChange={(e) => {
                        props.setFormat(e.target.value), setChange(!change);
                      }}
                    >
                      {props.type == "Normal Invoice" ||
                        props.type == "Refund Invoice" ||
                        props.type == "Purchase Invoice" ? (
                        <>
                          <option value="valued">{props.t("Valued")}</option>
                          <option value="ticket">{props.t("Ticket")}</option>
                        </>
                      ) : (
                        <>
                          <option value="valued">{props.t("Valued")}</option>
                          <option value="Without_values">{props.t("Without values")}</option>
                          <option value="before_tax">{props.t("Before tax")}</option>
                          <option value="Pro_forma">{props.t("Pro Forma")}</option>
                          <option value="without_totals">{props.t("Without totals")}</option>
                          <option value="ticket">{props.t("Ticket")}</option>
                        </>
                      )}
                    </AvField>
                  </div>

                  <div className="mb-3">
                    {" "}
                    <AvField
                      label={props.t("Template")}
                      type="select"
                      name="template_id"
                      onClick={(e) => {
                        props.setTemplate1(e.target.value), setChange(!change);
                      }}
                    >
                      <option>{props.t("Document type template")}</option>
                      {props.templateData?.map((item, i) => (
                        <option value={item.id} key={i}>
                          {props.t(item.name)}
                        </option>
                      ))}
                    </AvField>
                  </div>

                  <AvField
                    label={props.t("Prices after tax")}
                    name="prices_tax"
                    type="checkbox"
                    onChange={(e) => {
                      props.setPriceTax(e.target.checked), setChange(!change);
                    }}
                  />

                  <AvField
                    label={props.t("Mailing format")}
                    name="mailing_format"
                    type="checkbox"
                    onChange={(e) => {
                      props.setMailingFormat(e.target.checked),
                        setChange(!change);
                    }}
                  />

                  <AvField
                    label={props.t("Include the main image of the products/services")}
                    name="product_service"
                    type="checkbox"
                    onChange={(e) => {
                      props.setProductService(e.target.checked),
                        setChange(!change);
                    }}
                  />

                  <AvField
                    label={props.t("Save this as the default configuration")}
                    name="default_config"
                    type="checkbox"
                    onChange={(e) => {
                      props.setDefaultConfig(e.target.checked),
                        setChange(!change);
                    }}
                  />
                  <AvField
                    label={props.t("Disable signed box")}
                    name="disable_signed"
                    type="checkbox"
                    onChange={(e) => {
                      props.setSignedBox(e.target.checked), setChange(!change);
                    }}
                  />
                </div>
                <div className="float-end d-none d-md-block ">
                  <button
                    className="btn btn-primary me-2"
                    type="button"
                    onClick={() => props.setIsPrintModal(false)}
                  >
                    {props.t("Cancel")}
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => props.getPrintOption2()}
                  >
                    {props.t("Preview")}
                  </button>
                </div>
              </Col>
              <Col md={6}>
                <iframe
                  src={props.link}
                  style={{ width: "100%", height: "100%" }}
                ></iframe>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
    </div>
  );
};

export default withTranslation()(PrintOption);
PrintOption.propTypes = {
  t: PropTypes.any,
};