import React, { useState } from "react";
import RateModal from "./rate-modal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import { withTranslation } from "react-i18next";
import CreateTrialModal from "../../pages/plans/trialModal";
import PropTypes from "prop-types";
function SingleList(props) {
  const [rate, setRate] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [nonActive, setNonActive] = useState(
    JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
      1 &&
      JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
        null
      ? true
      : false
  );
  const [isTrialModal, setIsTrialModal] = useState(false);
  const editRole = () => {
    if (nonActive) {
      setIsTrialModal(true);
    } else {
      setIsOpen(true);
      setIsOpenModal(true);
    }
  };
  const deleteRate = () => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/rates/${rate.id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setRate(null);
        setdynamic_description(res.data.message);
      });
  };
  if (rate === null) {
    return "";
  } else
    return (
      <>
        {isOpen ? (
          <RateModal
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            rateId={rate.id}
            setRate={setRate}
            setIsOpen={setIsOpen}
          />
        ) : null}
        <CreateTrialModal
          isTrialModal={isTrialModal}
          setIsTrialModal={setIsTrialModal}
          nonActive={nonActive}
        />
        <tr>
          <td
            role="button"
            onClick={
              getPermission().price_rates.price_rates.edit.is_checked === 1
                ? () => editRole()
                : ""
            }
          >
            {rate.name}
          </td>
          <td
            role="button"
            onClick={
              getPermission().price_rates.price_rates.edit.is_checked === 1
                ? () => editRole()
                : ""
            }
          >
            {rate.description}
          </td>
          {getPermission().price_rates.price_rates.delete.is_checked === 1 ? (
            <td className="text-end">
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                {props.t("Delete")}
              </Button>
            </td>
          ) : (
            ""
          )}
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteRate(rate.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("El Documento fue eliminado.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
}
export default withTranslation()(SingleList);
SingleList.propTypes = {
  t: PropTypes.any,
};
