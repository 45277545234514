import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
function MultiType(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    const labels = [""];
    setData({
      labels,
      datasets: props.data,
    });
  }, []);

  return (
    <Bar
      data={data}
      options={{
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      }}
    />
  );
}

export default MultiType;
