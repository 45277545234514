import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Alert, Card, CardBody, Col, Container, Row, Input } from "reactstrap";
import uploadImg from "../../assets/images/def-img.svg";
import templateOne from "../../assets/images/d1.png";
import templateTwo from "../../assets/images/d2.png";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CreateTemplate = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [image, setImage] = useState("");
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [simple_color, setsimple_color] = useState(0);
    const [color, setcolor] = useState("");
    const [design, setDesign] = useState("original");
    const [fields, setFields] = useState([]);

    const onDrag = (c1) => {
        setcolor(c1);
    };

    const newUserSubmit = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", value.title);
        formData.append("document_type", value.document_type);
        formData.append("font", value.font);
        formData.append("watermark", image);
        formData.append("color", color);
        formData.append("is_default", value.is_default);
        formData.append("hide_company_information", value.hide_company_information);
        formData.append("hide_assets_information", value.hide_assets_information);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/my_templates`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    const getFields = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/template-fields`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                setFields(res.data.data);
            });
    };

    useEffect(() => {
        getFields();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>New Template | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                newUserSubmit(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{props.t("New Template")}</h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            <Link
                                                type="button"
                                                to="/company"
                                                className="btn btn-primary"
                                            >
                                                {props.t("Back")}
                                            </Link>
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary ms-2 " type="submit">
                                                    {props.t("Submit")}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Name")}
                                                    type="text"
                                                    name="title"
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 ">
                                                <AvField
                                                    type="select"
                                                    name="document_type"
                                                    label={props.t("Document Type")}
                                                >
                                                    <option>{props.t("Select a type...")}</option>
                                                    <option value="Sales Estimate">
                                                        {props.t("Sales Estimate")}
                                                    </option>
                                                    <option value="Sales Order">
                                                        {props.t("Sales Order")}
                                                    </option>
                                                    <option value="Sales Delivery Note">
                                                        {props.t("Sales Delivery Note")}
                                                    </option>
                                                    <option value="Work Estimate">
                                                        {props.t("Work Estimate")}
                                                    </option>
                                                    <option value="Work Order">
                                                        {props.t("Work Order")}
                                                    </option>
                                                    <option value="Work Delivery Note">
                                                        {props.t("Work Delivery Note")}
                                                    </option>
                                                    <option value="Normal Invoice">
                                                        {props.t("Normal Invoice")}
                                                    </option>
                                                    <option value="Refund Invoice">
                                                        {props.t("Refund Invoice")}
                                                    </option>
                                                    <option value="Purchase Order">
                                                        Purchase {props.t("Order")}
                                                    </option>
                                                    <option value="Purchase Delivery Note">
                                                        {props.t("Purchase Delivery Note")}
                                                    </option>
                                                    <option value="Purchase Invoice">
                                                        {props.t("Purchase Invoice")}
                                                    </option>
                                                </AvField>
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    type="select"
                                                    name="font"
                                                    label={props.t("Font")}
                                                    defaultValue="Helvetica"
                                                    required="required"
                                                >
                                                    {/* <option>{props.t("Select a type...")}</option> */}
                                                    <option value="DejaVu Sans">DejaVu Sans</option>
                                                    <option value="Arial">Arial</option>
                                                    <option value="Arial Black">Arial Black</option>
                                                    <option value="Bookman">Bookman</option>
                                                    <option value="Comic Sans MS">Comic Sans MS</option>
                                                    <option value="Courier">Courier</option>
                                                    <option value="Courier New">Courier New</option>
                                                    <option value="DejaVu Sans">DejaVu Sans</option>
                                                    <option value="DejaVu Sans Mono">
                                                        DejaVu Sans Mono
                                                    </option>
                                                    <option value="DejaVu Serif">DejaVu Serif</option>
                                                    <option value="Garamond">Garamond</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Helvetica">Helvetica</option>
                                                    <option value="Impact">Impact</option>
                                                    <option value="Lucida Sans Unicode">
                                                        Lucida Sans Unicode
                                                    </option>
                                                    <option value="Palatino">Palatino</option>
                                                    <option value="Roboto">Roboto</option>
                                                    <option value="Tahoma">Tahoma</option>
                                                    <option value="Times">Times</option>
                                                    <option value="Times New Roman">
                                                        Times New Roman
                                                    </option>
                                                    <option value="Trebuchet MS">Trebuchet MS</option>
                                                    <option value="Verdana">Verdana</option>
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <div className="company_img text-start">
                                                    <label>{props.t("Watermark")}</label>
                                                    <br />
                                                    <input
                                                        className="hidden d-none"
                                                        onChange={(e) => setComImg(e)}
                                                        type="file"
                                                        id="comImg"
                                                    />
                                                    <div className="d-inline-block">
                                                        <label className="company_img-box" htmlFor="comImg">
                                                            <img src={imgPreview} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Corporate Color")}</label>
                                                <Input
                                                    type="text"
                                                    name="corporate_color"
                                                    className="colorpicker-default"
                                                    value={color}
                                                    onClick={() => {
                                                        setsimple_color(!simple_color);
                                                    }}
                                                    readOnly
                                                />
                                                {simple_color ? (
                                                    <ColorPicker
                                                        saturationHeight={100}
                                                        saturationWidth={100}
                                                        value={color}
                                                        onDrag={onDrag}
                                                    />
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                {/* <AvCheckboxGroup name="is_default"> */}
                                                {/*   <AvCheckbox label="Set as default" value="1" /> */}
                                                {/* </AvCheckboxGroup> */}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="template_design">
                                                <label>{props.t("Select a design")}</label>
                                                <Row>
                                                    <Col>
                                                        <label className="radio-img">
                                                            <input
                                                                className="d-none"
                                                                type="radio"
                                                                name="design"
                                                                onChange={(e) => setDesign(e.target.value)}
                                                                value="original"
                                                                defaultChecked
                                                            />
                                                            <span className="tem_title">
                                                                {props.t("original")}
                                                            </span>
                                                            <img className="mw-100" src={templateOne} />
                                                        </label>
                                                    </Col>
                                                    <Col>
                                                        <label className="radio-img">
                                                            <input
                                                                className="d-none"
                                                                type="radio"
                                                                name="design"
                                                                onChange={(e) => setDesign(e.target.value)}
                                                                value="visual"
                                                            />
                                                            <span className="tem_title">
                                                                {props.t("visual")}
                                                            </span>
                                                            <img className="mw-100" src={templateTwo} />
                                                        </label>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(CreateTemplate);
CreateTemplate.propTypes = {
    t: PropTypes.any,
};
