import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Container, Alert, Col, Row, CardBody, Card, Table } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import SingleList from "./single-list";
import Loader from "../../components/Loader";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CreateTrialModal from "../plans/trialModal";
import { useHistory } from "react-router-dom";

function AllRoles(props) {
    const history = useHistory();
    const [error, setError] = useState("");
    const [allRoles, setAllRoles] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            history.push("/new-role");
        }
    };
    const getAllRoles = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/roles`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setAllRoles(res.data.roles);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    const duplicateRole = (e) => {
        setLoader(<Loader />);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/duplicate-role`,
                {
                    role_id: e,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                getAllRoles();
            });
    };

    useEffect(() => {
        getAllRoles();
    }, []);

    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            {/* <div className="page-content">
        <MetaTags>
          <title>Roles | Clouget</title>
        </MetaTags>
        <Container fluid> */}
            <div className="page-title-box">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h6 className="page-title">{props.t("Roles")}</h6>
                    </Col>
                    <Col md={6}>
                        <div className="float-end d-none d-md-block">
                            {getPermission().roles.roles.create.is_checked === 1 ? (
                                <button
                                    className="btn btn-primary ms-2"
                                    onClick={() => createNew()}
                                >
                                    {props.t("New")}
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                        {/* <Link to="/company" className="btn btn-primary float-end">
              {props.t("Back")}
            </Link> */}
                    </Col>
                </Row>
            </div>
            <Card>
                <CardBody>
                    <div className="mdb-datatable">
                        <Table striped className="align-middle">
                            <thead>
                                <tr>
                                    <th>{props.t("Role")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {loader === "" ? (
                                    allRoles &&
                                    allRoles.map((bank, i) => (
                                        <SingleList
                                            data={bank}
                                            key={i}
                                            duplicateRole={duplicateRole}
                                        />
                                    ))
                                ) : (
                                    <tr>
                                        <td className="text-center">{loader}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                    </div>
                </CardBody>
            </Card>
            {/* </Container>
      </div> */}
        </React.Fragment>
    );
}
export default withTranslation()(AllRoles);
AllRoles.propTypes = {
    t: PropTypes.any,
};
