import React, { useState } from "react";
const axios = require("axios").default;
import SweetAlert from "react-bootstrap-sweetalert";
import { getCurrentUser } from "../../../helpers/Utils";
import BankModal from "./bankModal";

function SingleList(props) {
  const [bank, setBank] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isBankOpenModal, setIsBankOpenModal] = useState(false);
  const delAddress = (e) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/supplier-bank-account/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setBank(null);
        setdynamic_description(res.data.message);
      });
  };
  if (bank === null) {
    return "";
  } else
    return (
      <>
        {isBankOpenModal ? (
          <BankModal
            isBankOpenModal={isBankOpenModal}
            setIsBankOpenModal={setIsBankOpenModal}
            setBank={setBank}
            bankId={bank.id}
            id={bank.supplier_id}
          />
        ) : (
          ""
        )}
        <tr>
          <td onClick={() => setIsBankOpenModal(true)}>
            {bank.bank_account_name}
          </td>
          <td onClick={() => setIsBankOpenModal(true)}>{bank.description}</td>
          <td onClick={() => setIsBankOpenModal(true)}>{bank.bank_account}</td>
          <td onClick={() => setIsBankOpenModal(true)}>{bank.bic_swift}</td>
          <td onClick={() => setIsBankOpenModal(true)}>
            {bank.is_default == "1" ? (
              <i className="fa fa-check" />
            ) : (
              <i className="fa fa-times" />
            )}
          </td>
          <td>
            <button
              type="button-input"
              className="btn btn-danger"
              onClick={() => setconfirm_alert(true)}
            >
              Delete
            </button>
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title="Are you sure?"
            confirmBtnText="Yes, delete it!"
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              delAddress(bank.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("File has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
      </>
    );
}
export default SingleList;
