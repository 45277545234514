import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  Nav,
  Alert,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Modal,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import Switch from "react-switch";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import Wharehouse from "./Wharehouse";
import Loader from "../../components/Loader";
import ProductAttachment from "../../components/Attachments/product-attachment";
import ProductRates from "../../components/Product/Rates/rate";
import ProductSalePrice from "../../components/Product/sale-price";
import ProductPurchasePrice from "../../components/Product/special-purchase-price";
import SendAttachment from "../SendAttachments/index";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CreateTrialModal from "../plans/trialModal";
let varBasePrice = 0;
let varPurchasePrice = 0;

const SingleProduct = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [productId] = useState(parseInt(id, 10));
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(<Loader />);
  const [error, setError] = useState("");
  const [discError, setDiscError] = useState("");
  const [historyError, setHistoryError] = useState("");
  const [success, setSuccess] = useState("");
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [customActiveTabOne, setCustomActiveTabOne] = useState("1");
  const [image, setImage] = useState("");
  const [imgPreview, setImgPreview] = useState(uploadImg);
  const [activeMargin, setActiveMargin] = useState(true);
  const [switch1, setSwitch1] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [product, setProduct] = useState("");
  const [referenceType, setReferenceType] = useState("");
  const [isOpenModalAttach, setIsOpenModalAttach] = useState(false);
  const [document, setDocument] = useState("");
  const [fileDocument, setFileDocument] = useState("");
  const [attachDocument, setAttachDocument] = useState([]);
  const [proHistory, setProHistory] = useState([]);
  const [minPrice, setMinPrice] = useState(0.0);
  const [disc, setDisc] = useState(0.0);
  const [basePrice, setBasePrice] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [purchaseMargin, setPurchaseMargin] = useState("");
  const [salesMargin, setSalesMargin] = useState("");
  const [dataTax, setDataTax] = useState([]);
  const [defaultTax, setDefaultTax] = useState("");
  const [refNum, setRefNum] = useState("");
  const [allRef, setAllRef] = useState([]);
  const [defaultRef, setDefaultRef] = useState("");
  const [isAttachOpenModal, setIsAttachOpenModal] = useState(false);
  const [attach, setAttach] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [error2, setError2] = useState("");
  const [attachEmail, setAttachEmail] = useState("");
  const [attachEmail2, setAttachEmail2] = useState("");
  const [attachEmail3, setAttachEmail3] = useState("");
  const [emailMessage2, setEmailMessage2] = useState("");
  const [nonActive, setNonActive] = useState(
    JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
      1 &&
      JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
        null
      ? true
      : false
  );
  const [isTrialModal, setIsTrialModal] = useState(false);
  const [mainFormDisabled, setMainFormDisabled] = useState(false);
  const createNew = () => {
    if (nonActive) {
      setIsTrialModal(true);
    } else {
      setIsEditable(true);
    }
  };
  const getType = (e) => {
    setReferenceType(e.target.value);
    setRefNum("");
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const toggleCustomInner = (tab) => {
    if (customActiveTabOne !== tab) {
      setCustomActiveTabOne(tab);
    }
  };

  const getSingleProduct = () => {
    setProduct([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/products/${productId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setProduct(res.data.product);
        setRefNum(res.data.product.reference_number);
        setMinPrice(res.data.product.minimum_price);
        setDisc(res.data.product.discount);
        setBasePrice(res.data.product.price);
        setPurchasePrice(res.data.product.purchase_price);
        setPurchaseMargin(res.data.product.purchase_margin);
        setSalesMargin(res.data.product.sales_margin);
        setImgPreview(res.data.product.image);
        if (res.data.product.manage_stock === "1") {
          setSwitch1(true);
        } else if (res.data.product.manage_stock === "0") {
          setSwitch1(false);
        }
        setLoader("");
        getHistory(res.data.product.id, res.data.product.reference);
      });
  };

  const productCreateSubmit = (e, value) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", value.name);
    formData.append("price", basePrice);
    formData.append("reference", value.reference);
    formData.append("reference_number", value.reference_number);
    formData.append("purchase_price", purchasePrice);
    formData.append("barcode", value.barcode);
    formData.append("image", image);
    formData.append("product_category_id", value.product_category_id);
    // formData.append("is_active", value.is_active ? "1" : "0");
    formData.append(
      "description",
      value.description !== null ? value.description : ""
    );
    formData.append(
      "private_comments",
      e.target.private_comments.value !== null
        ? e.target.private_comments.value
        : ""
    );
    formData.append("created_from", value.created_from);
    formData.append("active_margin", value.active_margin);
    formData.append("purchase_margin", purchaseMargin);
    formData.append("sales_margin", salesMargin);
    formData.append("discount", value.discount);
    formData.append("minimum_price", value.minimum_price);
    formData.append("tax", value.tax);
    formData.append("is_promotional", value.is_promotional ? "1" : "0");
    formData.append("manage_stock", switch1 ? "1" : "0");
    formData.append("images", "");
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/products/${productId}?_method=PUT`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          // location.reload();
          setSuccess(res.data.message);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    );
  };

  const getReferences = () => {
    setAllRef([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references?type=Product`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoader("");
        if (res.data.status) {
          setError("");
          setAllRef(res.data.reference);
          res.data.reference?.map((item) => {
            if (item.by_default === "1") {
              setDefaultRef(item.prefix);
            }
          });
        } else {
          //   setError(res.data.message);
        }
      });
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    );
  };
  console.log("Hi", switch1);
  const productCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/product_categories`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllCategories(res.data.product_categories);
      });
  };

  const getIdMargin = (e) => {
    if (e.target.checked) {
      setActiveMargin(false);
    } else {
      setActiveMargin(true);
    }
  };

  const setComImg = (e) => {
    setImage(e.target.files[0]);
    setImgPreview(URL.createObjectURL(e.target.files[0]));
  };

  const getTaxes = () => {
    setDataTax([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/cosumption-taxes`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDataTax(res.data.taxes);
          res.data.taxes.taxes?.map((item) => {
            if (item.by_default === "1") {
              setDefaultTax(item.tax);
            }
          });
        }
      });
  };

  useEffect(() => {
    getSingleProduct();
    productCategories();
    getTaxes();
    getReferences();
  }, []);

  const createAttachment = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("document", document);
    formData.append("description", e.target.description.value);
    formData.append("product_id", productId);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/product-attachments`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          AllAttach();
          setIsOpenModalAttach(false);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  const AllAttach = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/product-attachments?product_id=${productId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        setAttachDocument(res.data.product_attachments);
        setIsOpenModalAttach(false);
      });
  };
  useEffect(() => {
    AllAttach();
  }, []);

  const filePreview = (e) => {
    setDocument(e.target.files[0]);
    setFileDocument(URL.createObjectURL(e.target.files[0]));
  };

  const getHistory = (id, ref) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/history?id=${id}&reference=${ref}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setProHistory(res.data.data);
        } else {
          setHistoryError(res.data.message);
        }
      });
  };

  const calcDiscount = (e) => {
    const bPrice = parseFloat(basePrice);
    const mPrice = parseFloat(minPrice);
    if (bPrice > 0) {
      const discAmount = bPrice - (bPrice * e) / 100;
      const discount = bPrice - discAmount;
      if (discount > mPrice) {
        setDiscError("The discount cannot exceed the minimum price.");
      } else {
        setDisc(e);
        setDiscError("");
      }
    } else {
      setDiscError("First add the base price.");
    }
    setTimeout(() => {
      setDiscError("");
    }, 5000);
  };

  const marginCalc = (e) => {
    varBasePrice = basePrice;
    varPurchasePrice = purchasePrice;
    if (e.name === "price") {
      setBasePrice(e.value);
      varBasePrice = e.value;
    }
    if (e.name === "purchase_price") {
      setPurchasePrice(e.value);
      varPurchasePrice = e.value;
    }
    let pMargin = ((varBasePrice - varPurchasePrice) / varPurchasePrice) * 100;
    let sMargin = ((varBasePrice - varPurchasePrice) / varBasePrice) * 100;
    if (
      !isNaN(pMargin) ||
      pMargin == Number.POSITIVE_INFINITY ||
      pMargin == Number.NEGATIVE_INFINITY
    ) {
      setPurchaseMargin(pMargin.toFixed(2));
    }
    if (!isNaN(sMargin)) {
      setSalesMargin(sMargin.toFixed(2));
    }
  };

  const getSalesMargin = (e) => {
    let SM = e;
    let SP = parseFloat(purchasePrice) / (1 - SM / 100);
    let NP = SP - parseFloat(purchasePrice);
    let PM = (NP / parseFloat(purchasePrice)) * 100;
    setPurchaseMargin(PM.toFixed(2));
    setSalesMargin(SM);
    setBasePrice(SP.toFixed(2));
  };

  const getPurchaseMargin = (e) => {
    let PM = e;
    let NP = (PM * parseFloat(purchasePrice)) / 100;
    let SP = parseFloat(NP) + parseFloat(purchasePrice);
    let SM = (NP / SP) * 100;
    setPurchaseMargin(PM);
    setSalesMargin(SM.toFixed(2));
    setBasePrice(SP.toFixed(2));
  };

  const getSendAttachment = () => {
    // if (selectedIds.length < 1) {
    //   setError2("Please select document");
    //   setTimeout(() => {
    //     setError2("");
    //   }, 4000);
    //   return false;
    // }
    setIsAttachOpenModal(true);
  };
  const getSendAttachment2 = () => {
    const formData = new FormData();
    formData.append("send_to", attachEmail);
    formData.append("cc", attachEmail2);
    formData.append("bcc", attachEmail3);
    formData.append("body", emailMessage2);
    formData.append("ids", selectedIds);
    formData.append("name", selectedName);
    formData.append("type", "product_attachments");
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/send-attachments-email`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setSuccess(res.data.message);
          setError("");
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 3000);
      });
  };
  const getEntryType = ($reference, $link = "no") => {
    if ($reference == "SE" || $reference == "se") {
      if ($link == "no") {
        return "Sales Estimate";
      }
      return "/sales/";
    }
    if ($reference == "SO" || $reference == "so") {
      if ($link == "no") {
        return "Sales Order";
      }
      return "/sales/";
    }
    if ($reference == "SDN" || $reference == "sdn") {
      if ($link == "no") {
        return "Sales Delivery Note";
      }
      return "/sales/";
    }
    if ($reference == "WE" || $reference == "we") {
      if ($link == "no") {
        return "Work Estimates";
      }
      return "/technical-service/";
    }
    if ($reference == "WO" || $reference == "wo") {
      if ($link == "no") {
        return "Work Order";
      }
      return "/technical-service/";
    }

    if ($reference == "WDN" || $reference == "wdn") {
      if ($link == "no") {
        return "Work Delivery Note";
      }
      return "/technical-service/";
    }

    if ($reference == "INV" || $reference == "inv") {
      if ($link == "no") {
        return "Normal Invoice";
      }
      return "/invoicing-service/";
    }

    if ($reference == "RET" || $reference == "ret" || $reference == "RCT") {
      if ($link == "no") {
        return "Refund Invoice";
      }
      return "/invoicing-service/";
    }

    if ($reference == "PDN" || $reference == "pdn") {
      if ($link == "no") {
        return "Purchase Delivery Note";
      }
      return "/purchase-delivery-Notes/";
    }

    if ($reference == "PO" || $reference == "po") {
      if ($link == "no") {
        return "Purchase Order";
      }
      return "/purchase-order/";
    }

    if ($reference == "PINV" || $reference == "pinv") {
      if ($link == "no") {
        return "Purchase Invoice";
      }
      return "/purchase-invoice/";
    }
  };
  return (
    <React.Fragment>
      {isAttachOpenModal ? (
        <SendAttachment
          isAttachOpenModal={isAttachOpenModal}
          setIsAttachOpenModal={setIsAttachOpenModal}
          attach={attach}
          setAttach={setAttach}
          setAttachEmail={setAttachEmail}
          setAttachEmail2={setAttachEmail2}
          setAttachEmail3={setAttachEmail3}
          getSendAttachment2={getSendAttachment2}
          attachDocument={attachDocument}
          emailMessage2={emailMessage2}
          setEmailMessage2={setEmailMessage2}
          success={success}
          error={error}
          selectedName={selectedName}
        />
      ) : (
        ""
      )}
      <CreateTrialModal
        isTrialModal={isTrialModal}
        setIsTrialModal={setIsTrialModal}
        nonActive={nonActive}
      />
      <div className="page-content">
        <MetaTags>
          <title>{product && product.name} | Clouget</title>
        </MetaTags>
        <Container fluid>
          <div className="create_company">
            {loader !== "" ? (
              loader
            ) : (
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  productCreateSubmit(e, v);
                }}
                disabled={mainFormDisabled ? true : false}
              >
                <div className="page-title-box">
                  <Row className="align-items-center">
                    <Col md={6}>
                      <h6 className="page-title">{product && product.name}</h6>
                    </Col>
                    <Col md={6}>
                      {getPermission().products.products.edit.is_checked ===
                      1 ? (
                        <div className="float-end d-flex">
                          <Link
                            type="button"
                            to="/products"
                            className="btn btn-primary"
                          >
                            {props.t("Back")}
                          </Link>
                          {isEditable ? (
                            <>
                              {loading ? (
                                <button
                                  className="btn btn-primary ms-2 disabled"
                                  type="button"
                                  disabled
                                >
                                  {props.t("Loading")}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary ms-2"
                                  type="submit"
                                >
                                  {props.t("Update")}
                                </button>
                              )}
                              <span
                                onClick={() => setIsEditable(false)}
                                className="btn btn-primary  ms-2"
                              >
                                {props.t("Cancel")}
                              </span>
                            </>
                          ) : (
                            <span
                              onClick={() => createNew()}
                              className="btn btn-primary ms-2"
                            >
                              {props.t("Edit")}
                            </span>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                {success ? (
                  <Alert color="success">{props.t(success)}</Alert>
                ) : null}

                {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={5}>
                        <div className="mb-3">
                          <label>{props.t("Reference")}</label>
                          <div className="d-flex">
                            <AvField
                              style={{
                                width: "100px",
                              }}
                              type="select"
                              name="reference"
                              //   value="PRO"
                              value={
                                product.reference !== null
                                  ? product.reference
                                  : ""
                              }
                              onChange={(e) => getType(e)}
                              disabled={isEditable ? false : true}
                            >
                              {allRef &&
                                allRef.map((item, i) => (
                                  <option value={item.prefix} key={i}>
                                    {item.prefix}
                                  </option>
                                ))}
                              <option value="manual">
                                {props.t("MANUAL/MANUAL")}
                              </option>
                            </AvField>
                            <div className="w-100 ps-3">
                              <AvField
                                className="w-100"
                                type="number"
                                value={refNum !== null ? refNum : ""}
                                // value={product.reference_number}
                                name="reference_number"
                                // placeholder={
                                //   referenceType === "PRO" ? "Automatic" : ""
                                // }
                                disabled={isEditable ? false : true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 required">
                          <AvField
                            readOnly={isEditable ? false : true}
                            label={props.t("Name")}
                            type="text"
                            name="name"
                            value={product.name !== null ? product.name : ""}
                            required="required"
                          />
                        </div>
                        <div className="mb-3 required">
                          <AvField
                            readOnly={isEditable ? false : true}
                            label={props.t("Base Sales Price ($)")}
                            type="number"
                            name="price"
                            value={basePrice !== null ? basePrice : ""}
                            onChange={(e) => marginCalc(e.target)}
                            required="required"
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            readOnly={isEditable ? false : true}
                            label={props.t("Barcode")}
                            type="text"
                            name="barcode"
                            value={
                              product.barcode !== null ? product.barcode : ""
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            type="select"
                            name="product_category_id"
                            label={props.t("Product Category")}
                            value={
                              product.product_category_id !== null
                                ? product.product_category_id
                                : ""
                            }
                            disabled={isEditable ? "" : true}
                          >
                            {allCategories &&
                              allCategories.map((item, i) => (
                                <option key={i} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </AvField>
                        </div>
                      </Col>
                      {switch1 ? (
                        <Col md={3}>
                          <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Real Stock")}
                              type="number"
                              name="stock"
                              value={
                                product.stock !== null ? product.stock : ""
                              }
                              disabled
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Virtual Stock")}
                              type="number"
                              name="virtual_stock"
                              value={
                                product.virtual_stock !== null
                                  ? product.virtual_stock
                                  : ""
                              }
                              disabled
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              readOnly={isEditable ? false : true}
                              label={props.t("Minimum Stock")}
                              type="number"
                              name="minimum_stock"
                              value={
                                product.minimum_stock !== null
                                  ? product.minimum_stock
                                  : ""
                              }
                              disabled
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col md={4}>
                        <div className="company_img">
                          <label>{props.t("Main image")}</label>
                          <input
                            className="hidden d-none"
                            onChange={(e) => setComImg(e)}
                            type="file"
                            accept="/image*"
                            id="comImg"
                          />
                          <label
                            className="company_img-box"
                            htmlFor={isEditable ? "comImg" : ""}
                          >
                            <img src={imgPreview} />
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            {props.t("General")}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            {props.t("Commercial")}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          className={`${classnames({
                            active: customActiveTab === "3",
                          })}`}
                          onClick={() => {
                            toggleCustom("3");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            {props.t("Rates")}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          disabled={
                            getCurrentUser().id == product.created_by
                              ? false
                              : true
                          }
                          style={{
                            cursor: "pointer",
                          }}
                          className={`${classnames({
                            active: customActiveTab === "4",
                          })}`}
                          onClick={() => {
                            toggleCustom("4");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            {props.t("Stock")}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          className={`${classnames({
                            active: customActiveTab === "5",
                          })}`}
                          onClick={() => {
                            toggleCustom("5");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            {props.t("Images")}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          className={`${classnames({
                            active: customActiveTab === "6",
                          })}`}
                          onClick={() => {
                            toggleCustom("6");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            {props.t("Attachments")}
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          className={`${classnames({
                            active: customActiveTab === "7",
                          })}`}
                          onClick={() => {
                            toggleCustom("7");
                          }}
                        >
                          <span className="d-none d-sm-block">
                            {props.t("History")}
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={customActiveTab} className="p-3">
                      <TabPane tabId="1">
                        <Row>
                          <Col lg="12">
                            {/* <div className="mb-1">
                                                            <AvField
                                                                name="is_active"
                                                                label={props.t("Hidden")}
                                                                checked={
                                                                    product.is_active == "1" ? true : false
                                                                }
                                                                type="checkbox"
                                                                disabled={isEditable ? false : true}
                                                            />
                                                        </div> */}
                            <div className="mb-3">
                              <AvField
                                readOnly={isEditable ? false : true}
                                label={props.t("Description")}
                                type="textarea"
                                name="description"
                                value={
                                  product.description !== null
                                    ? product.description
                                    : ""
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                readOnly={isEditable ? false : true}
                                label={props.t("Private Comments")}
                                type="textarea"
                                name="private_comments"
                                value={
                                  product.private_comments !== null
                                    ? product.private_comments
                                    : ""
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                readOnly={isEditable ? false : true}
                                label={props.t("Created from")}
                                type="text"
                                name="created_from"
                                value={
                                  product.created_from !== null
                                    ? product.created_from
                                    : ""
                                }
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <AvField
                                readOnly={isEditable ? false : true}
                                label={props.t("Purchase Price ($)")}
                                type="number"
                                name="purchase_price"
                                value={
                                  purchasePrice !== null ? purchasePrice : ""
                                }
                                onChange={(e) => marginCalc(e.target)}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                label={props.t("Active Margin")}
                                type="checkbox"
                                name="active_margin"
                                onChange={(e) => getIdMargin(e)}
                                disabled={isEditable ? false : true}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                readOnly={isEditable ? false : true}
                                label={props.t("Purchase Margin (%)")}
                                type="number"
                                name="purchase_margin"
                                onChange={(e) =>
                                  getPurchaseMargin(e.target.value)
                                }
                                value={
                                  purchaseMargin == "NaN"
                                    ? "0.00"
                                    : purchaseMargin == "" ||
                                      purchaseMargin == "Infinity"
                                    ? "--"
                                    : purchaseMargin
                                }
                                disabled={activeMargin ? true : false}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                readOnly={isEditable ? false : true}
                                label={props.t("Minimum Price ($)")}
                                type="number"
                                name="minimum_price"
                                onChange={(e) => setMinPrice(e.target.value)}
                                value={minPrice}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                className="form-control"
                                type="select"
                                name="tax"
                                label={props.t("Tax")}
                                disabled={isEditable ? false : true}
                                value={product.tax !== null ? product.tax : "0"}
                              >
                                {dataTax?.map((item, i) => (
                                  <option value={item.tax} key={i}>
                                    {dataTax.primary_name} {item.tax}%
                                  </option>
                                ))}
                                <option value="0">
                                  {props.t("Do not apply")}
                                </option>
                              </AvField>
                            </div>
                            <div className="mb-3">
                              <AvField
                                label={props.t("Promotional")}
                                name="is_promotional"
                                type="checkbox"
                                defaultChecked={
                                  product.is_promotional &&
                                  product.is_promotional == "1"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <AvField
                                readOnly={isEditable ? false : true}
                                label={props.t("Base Sales Price ($)")}
                                type="number"
                                name="price"
                                value={basePrice !== null ? basePrice : ""}
                                onChange={(e) => marginCalc(e.target)}
                                disabled={activeMargin ? false : true}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                readOnly={isEditable ? false : true}
                                label={props.t("Sales Margin (%)")}
                                type="number"
                                name="sales_margin"
                                value={
                                  basePrice == ""
                                    ? "--"
                                    : salesMargin == "NaN"
                                    ? "0.00"
                                    : salesMargin == "" ||
                                      salesMargin == "Infinity"
                                    ? "--"
                                    : salesMargin
                                }
                                onChange={(e) => getSalesMargin(e.target.value)}
                                disabled={activeMargin ? true : false}
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                readOnly={isEditable ? false : true}
                                label={props.t("Discount (%)")}
                                type="number"
                                name="discount"
                                // value={
                                //     product.discount !== null
                                //         ? product.discount
                                //         : ""
                                // }
                                onChange={(e) => calcDiscount(e.target.value)}
                                value={disc}
                              />
                              {discError ? (
                                <Alert color="danger">
                                  {props.t(discError)}
                                </Alert>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                              }}
                              className={`${classnames({
                                active: customActiveTabOne === "1",
                              })}`}
                              onClick={() => {
                                toggleCustomInner("1");
                              }}
                            >
                              {props.t("Rates")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                              }}
                              className={`${classnames({
                                active: customActiveTabOne === "2",
                              })}`}
                              onClick={() => {
                                toggleCustomInner("2");
                              }}
                            >
                              {props.t("Special Sales Price")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                              }}
                              className={`${classnames({
                                active: customActiveTabOne === "3",
                              })}`}
                              onClick={() => {
                                toggleCustomInner("3");
                              }}
                            >
                              {props.t("Special Purchase Price")}
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent
                          activeTab={customActiveTabOne}
                          className="py-3"
                        >
                          <TabPane tabId="1">
                            <ProductRates product={product} type="product" />
                          </TabPane>
                          <TabPane tabId="2">
                            <ProductSalePrice
                              product={product}
                              type="product"
                            />
                          </TabPane>
                          <TabPane tabId="3">
                            <ProductPurchasePrice
                              product={product}
                              type="product"
                            />
                          </TabPane>
                        </TabContent>
                      </TabPane>
                      <TabPane tabId="4">
                        <div className="mb-1">
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#626ed4"
                            onChange={() => {
                              setSwitch1(!switch1);
                            }}
                            checked={switch1}
                            disabled={isEditable ? false : true}
                          />
                        </div>
                        {product !== ""
                          ? switch1 && (
                              <Wharehouse
                                product={product}
                                getSingleProduct={getSingleProduct}
                                setSwitch1={setSwitch1}
                                setMainFormDisabled={setMainFormDisabled}
                                // setProduct={setProduct}
                              />
                            )
                          : ""}
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col md={12}>
                            <div className="attach_btn">
                              <div
                                className="float-end d-flex btn btn-primary ms-2"
                                onClick={() => setIsOpenModalAttach(true)}
                              >
                                {props.t("New")}
                              </div>
                              <button
                                type="button"
                                className="float-end d-flex btn btn-primary"
                                onClick={() => getSendAttachment()}
                              >
                                {props.t("Send via email")}
                              </button>

                              <Table striped className="align-middle mt-5">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>{props.t("Name")}</th>
                                    <th>{props.t("Description")}</th>
                                    <th>{props.t("Upload Date")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {attachDocument &&
                                    attachDocument.map((item, i) => (
                                      <ProductAttachment
                                        key={i}
                                        data={item}
                                        type="product"
                                        Attached={item}
                                        selectedIds={selectedIds}
                                        setSelectedIds={setSelectedIds}
                                        selectedName={selectedName}
                                        setSelectedName={setSelectedName}
                                      />
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col lg="3">
                            <div className="form-inline company_img img-flex">
                              <input
                                className="hidden d-none"
                                onChange={(e) => setComImg(e)}
                                disabled={isEditable ? false : true}
                                type="file"
                                id="comImg"
                                name="image"
                              />
                              <label
                                className="company_img-box"
                                htmlFor={isEditable ? "comImg" : ""}
                              >
                                {product && product.image ? (
                                  <img src={imgPreview} />
                                ) : (
                                  <img src={imgPreview} />
                                )}
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="7">
                        <div className="table-responsive">
                          <Table striped className="align-middle mt-5">
                            <thead>
                              <tr>
                                <th>{props.t("Reference")}</th>
                                <th>{props.t("Client")}</th>
                                <th>{props.t("Title")}</th>
                                <th>{props.t("Created by")}</th>
                                <th>{props.t("Status")}</th>
                                <th>{props.t("Type")}</th>
                                <th>{props.t("Date")}</th>
                                <th>{props.t("Amount")}</th>
                                <th>{props.t("Unit Price")}</th>
                                <th>{props.t("Quantity")}</th>
                                <th>{props.t("Prod. Total")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {proHistory &&
                                proHistory.map((item, i) => (
                                  <tr
                                    key={i}
                                    onClick={() =>
                                      history.push(
                                        getEntryType(item.reference, "yes") +
                                          item.id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td>
                                      {item.reference}
                                      {item.reference_number}
                                    </td>
                                    <td>{item.client}</td>
                                    <td>{item.title}</td>
                                    <td>{getCurrentUser().email}</td>
                                    <td>
                                      {props.t(
                                        item.status == "pending"
                                          ? "Pending"
                                          : item.status == "pending invoice"
                                          ? "Pending Invoice"
                                          : item.status == "unpaid"
                                          ? "Unpaid"
                                          : item.status == "paid"
                                          ? "Paid"
                                          : item.status == "closed"
                                          ? "Closed"
                                          : item.status == "refused"
                                          ? "Refused"
                                          : item.status == " resolved"
                                          ? "Resolved"
                                          : item.status == "accepted"
                                          ? "Accepted"
                                          : item.status == "in_progress"
                                          ? "In Progress"
                                          : item.status
                                      )}
                                    </td>
                                    <td>{props.t(item.type)}</td>
                                    <td>{item.date}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.unit_price}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.product_total}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                        {historyError ? (
                          <Alert color="danger">{props.t(historyError)}</Alert>
                        ) : (
                          ""
                        )}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </AvForm>
            )}
          </div>
          <Modal size="lg" isOpen={isOpenModalAttach} centered={true}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                {props.t("New Document")}
              </h5>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => createAttachment(e)}>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>{props.t("Document")}</label>
                      <input
                        type="file"
                        className="ms-3"
                        name="document"
                        onChange={filePreview}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>{props.t("Description")}</label>
                      <textarea
                        name="description"
                        className="form-control"
                      ></textarea>
                    </div>
                  </Col>
                </Row>
                <div className="modal-footer">
                  <div className="text-end">
                    {loading ? (
                      <button className="btn btn-primary" disabled>
                        {props.t("Loading")}
                      </button>
                    ) : (
                      <button className="btn btn-primary" type="submit">
                        {props.t("Submit")}
                      </button>
                    )}
                    <button
                      className="btn btn-primary ms-2"
                      type="button"
                      onClick={() => setIsOpenModalAttach(false)}
                    >
                      {props.t("Cancel")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(SingleProduct);
SingleProduct.propTypes = {
  t: PropTypes.any,
};
