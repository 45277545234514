import React, { useState, useEffect } from "react";
import CreatePaymentOption from "./CreatePaymentOption";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../../plans/trialModal";
let selectedRoleId = [];

function SinglePaymentlist(props) {
  const [payment, setPayment] = useState(props.data && props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [nonActive, setNonActive] = useState(
    JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !== 1 &&
      JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id == null
      ? true
      : false
  );
  const [isTrialModal, setIsTrialModal] = useState(false);
  const createNew = () => {
    if (nonActive) {
      setIsTrialModal(true);
    } else {
      setIsOpenModal(true);
    }
  };
  const deletePayment = (e) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-options/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setPayment(null);
        setdynamic_description(res.data.message);
      });
  };
  useEffect(() => {
    selectedRoleId = [];
  }, []);
  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      data.setSelectedIds(selectedRoleId);
    } else {
      selectedRoleId = data.selectedIds;
      var array = selectedRoleId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
      data.setSelectedIds(selectedRoleId);
    }
  };
  if (payment === null) {
    return "";
  } else
    return (
      <>
        {isOpenModal ? (
          <CreatePaymentOption
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            payment={payment}
            setPayment={setPayment}
          />
        ) : null}
        <CreateTrialModal
          isTrialModal={isTrialModal}
          setIsTrialModal={setIsTrialModal}
          nonActive={nonActive}
        />
        <tr>
          <td>
            <input
              onChange={(e) => getSelectedId(payment.id, e)}
              name="chk"
              value={payment.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td
            role="button"
            onClick={
              getPermission()?.payment_options.payment_options.edit
                .is_checked === 1
                ? () => createNew()
                : ""
            }
          >
            {props.t(payment.name)}
          </td>
          <td
            role="button"
            onClick={
              getPermission()?.payment_options.payment_options.edit
                .is_checked === 1
                ? () => createNew()
                : ""
            }
          >
            {props.t(payment.description)}
          </td>
          <td
            role="button"
            onClick={
              getPermission()?.payment_options.payment_options.edit
                .is_checked === 1
                ? () => createNew()
                : ""
            }
          >
            {payment.by_default == "1" ? (
              <i className="fa fa-check"></i>
            ) : (
              <i className="fa fa-times"></i>
            )}
          </td>
          <td className="text-end">
            {getPermission()?.payment_options.payment_options.delete
              .is_checked === 1 ? (
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                {props.t("Delete")}
              </Button>
            ) : (
              ""
            )}
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deletePayment(payment.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("El Documento fue eliminado.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}
        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
}
export default withTranslation()(SinglePaymentlist);
SinglePaymentlist.propTypes = {
  t: PropTypes.any,
};
