import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Alert,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Table,
} from "reactstrap";
import { getCurrentUser } from "../../helpers/Utils";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const axios = require("axios").default;

let clientName = "";
const CreateContact = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [allRoles, setAllRoles] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [clientId, setClientId] = useState("");
    const [phone, setPhone] = useState("");

    const createContact = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", value.name);
        formData.append("phone", phone);
        formData.append("email", value.email);
        formData.append("client_id", clientId);
        formData.append("fax", value.fax);
        formData.append("position", value.position);
        formData.append("comments", value.comments);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-contacts`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const getUserRoles = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/roles`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllRoles(res.data.roles);
            });
    };

    // Role /Set Role
    let optionRole = [];
    if (allRoles !== undefined) {
        allRoles.map((item) => {
            optionRole.push({
                value: item.id,
                label: item.name,
            });
        });
    }

    const getPassword = () => {
        var generator = require("generate-password");
        generator.generate({
            length: 10,
            numbers: true,
        });
    };
    const getCountry = (e) => {
        setPhone(e);
    };
    useEffect(() => {
        getUserRoles();
        getPassword();
    }, []);

    const getContactTemplate = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setData(res.data.clients);
                } else {
                    setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getContactTemplate();
    }, []);

    const selectClient = (e, item) => {
        clientName = item;
        setClientId(item.id);
        setIsOpenModal(false);
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Create new contact</title>
                </MetaTags>
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                createContact(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{props.t("New Contact")}</h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-none d-md-block">
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary " type="submit">
                                                    {props.t("Save")}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={6}>
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Name")}
                                                type="text"
                                                name="name"
                                            />
                                            <div className="mb-3">
                                                <label>{props.t("Phone")}</label>
                                                <PhoneInput
                                                    placeholder={props.t("Enter phone number")}
                                                    value={phone}
                                                    onChange={(e) => getCountry(e)}
                                                    name="phone"
                                                    inputStyle={{ width: "100%" }}
                                                />
                                            </div>
                                            {/* <AvField
                        className="mb-3"
                        label="Phone"
                        type="text"
                        name="phone"
                      /> */}

                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Email")}
                                                    type="email"
                                                    name="email"
                                                    required="required"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Client")}
                                                type="text"
                                                name="client_name"
                                                value={clientName.legal_name}
                                                onClick={() => setIsOpenModal(true)}
                                            />
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Fax")}
                                                type="text"
                                                name="fax"
                                            />
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Position")}
                                                type="text"
                                                name="position"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Comments")}
                                                type="textarea"
                                                name="comments"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Created from")}
                                                type="text"
                                                name="created_from"
                                                value={props.t("WEB")}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                    <Modal size="lg" isOpen={isOpenModal} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("Clients")}
                            </h5>
                        </div>
                        <div className="modal-body p-0">
                            <Card>
                                <CardBody>
                                    <div className="overflow-auto w-100">
                                        <Table striped className="align-middle">
                                            <thead>
                                                <tr>
                                                    <th>{props.t("Reference")}</th>
                                                    <th>{props.t("Legal Name")}</th>
                                                    <th>{props.t("Name")}</th>
                                                    <th>{props.t("Ced/Ruc")}</th>
                                                    <th>{props.t("Phone")}</th>
                                                    <th>{props.t("Email")}</th>
                                                    <th>{props.t("Address")}</th>
                                                    <th>{props.t("Activity")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data &&
                                                    data.map((item) => (
                                                        <tr
                                                            onClick={(e) => selectClient(e, item)}
                                                            key={item.id}
                                                        >
                                                            <td>{item.reference}</td>
                                                            <td>{item.legal_name}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.tin}</td>
                                                            <td>{item.phone_1}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.address}</td>
                                                            <td>{getCurrentUser().email}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="modal-footer">
                            <div className="text-end">
                                <button className="btn btn-primary" type="button">
                                    Submit
                                </button>
                                <button
                                    className="btn btn-primary ms-2"
                                    type="button"
                                    onClick={() => setIsOpenModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(CreateContact);
CreateContact.propTypes = {
    t: PropTypes.any,
};
