/* eslint-disable no-undef */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;

const ListPurchaseInvoiceSummary = (props) => {
  // const [displayInvoices, setDisplayInvoices] = useState(props.data);
  const [displayPurchaseDelivery, setDisplayPurchaseDelivery] = useState(
    props.data
  );
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const delUser = (e) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/purchase-tables/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setDisplayInvoices(null);
        setDisplayPurchaseDelivery(null);
        setdynamic_description(res.data.message);
      });
  };

  if (displayPurchaseDelivery === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td role="button" className="py-1">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.reference}
              {displayPurchaseDelivery.reference_number}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.purchase_document_ref}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.date}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.reference_number}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.supplier_name}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.tin}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.subtotal}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.vat}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.amount_vat}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.disc}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.amount}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.income_tax}
            </Link>
          </td>
          <td role="button">
            <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
              {displayPurchaseDelivery.amount_income_tax}
            </Link>
          </td>
          {/* <td>
            <button
              type="button-input"
              className="btn btn-danger"
              onClick={() => setconfirm_alert(true)}
            >
              Delete
            </button>
          </td> */}
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title="Are you sure?"
            confirmBtnText="Yes, delete it!"
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              delUser(displayPurchaseDelivery.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("The purchase invoice has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
      </>
    );
};

export default ListPurchaseInvoiceSummary;
