import React from "react";
import { Bar } from "react-chartjs-2";

function BarChart(props) {
  let data = {
    labels: props.data.labels,
    datasets: props.data.data,
  };

  return <Bar data={data} />;
}

export default BarChart;
