/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
import { Card, Col, Row, Modal, Table } from "reactstrap";
import countryList from "react-select-country-list";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ClientWorkAddress = (props) => {
  const [clientAdd, setClientAdd] = useState(props.clients);
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
  const [allAddress, setAllAddress] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const createOtherAddress = (e, value) => {
    const formData = new FormData();
    formData.append("client_id", props.clients.id);
    formData.append("address", value.address);
    formData.append("state", value.state);
    formData.append("city", value.city);
    formData.append("zip_code", value.zip_code);
    formData.append("country", value.country);
    formData.append("type", value.type);
    formData.append("extra_information", value.extra_information);
    formData.append("description", value.description);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-addresses`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
          setIsOpenModalAdd(false);
        }, 4000);
      });
  };

  const modalOpen = () => {
    setIsOpenModalAdd(true);
    // props.setIsOpenModal(false);
  };

  const getSingleClientsAddress = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-addresses?client_id=${clientAdd.id}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllAddress(res.data.client_addresses);
      });
  };

  useEffect(() => {
    getSingleClientsAddress();
  }, []);

  const seclectedAddress = () => { };
  const defaultAddress = (e) => {
    props.setWorkData(clientAdd.address);
    props.setIsOpenModal(false);
  };

  const selectClient = (e, item) => {
    // clientAddress = item;
    if (props.setClientNameData !== undefined) {
      props.setClientNameData(item.name);
    }
    if (props.setWorkData !== undefined) {
      props.setWorkData(item.address);
    }
    // props.setClientNameData(item.name);
    // props.setWorkData(item.address);
    props.setIsOpenModal(false);
  };

  return (
    <>
      <Modal size="lg" isOpen="true" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
                  {props.t("Address Selection")}
          </h5>
        </div>
        <div className="modal-body">
          <Card>
            <div className="overflow-auto w-100">
              <div className="float-end d-none d-md-block">
                <div className="btn btn-primary" onClick={() => modalOpen()}>
                  {props.t("Create New")}
                </div>
              </div>
              <Table striped className="align-middle">
                <thead>
                  <tr>
                    <th>{props.t("Address")}</th>
                    <th>{props.t("City/Town")}</th>
                    <th>{props.t("State")}</th>
                    <th>{props.t("Zip/Postal Code")}</th>
                    <th>{props.t("Country")}</th>
                    {/* <th>{props.t("Type")}</th> */}
                    <th>{props.t("Extra Info")}</th>
                    <th>{props.t("Description")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr onClick={(e) => defaultAddress(e, clientAdd)}>
                    <td>{clientAdd.address}</td>
                    <td>{clientAdd.city}</td>
                    <td>{clientAdd.state}</td>
                    <td>{clientAdd.zip_code}</td>
                    <td>{clientAdd.country}</td>
                    <td>{clientAdd.extra_information}</td>
                    <td>{clientAdd.description}</td>
                    <td></td>
                  </tr>
                  {allAddress &&
                    allAddress.map((item, i) => (
                      <tr key={i} onClick={(e) => selectClient(e, item)}>
                        <td>{item.address}</td>
                        <td>{item.city}</td>
                        <td>{item.state}</td>
                        <td>{item.zip_code}</td>
                        <td>{item.country}</td>
                        <td>{item.extra_information}</td>
                        <td>{item.description}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </div>
        <div className="modal-footer">
          <div className="text-end">
            <button
              className="btn btn-primary ms-2"
              type="button"
              onClick={() => props.setIsOpenModal(false)}
            >
              {props.t("Cancel")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal size="lg" isOpen={isOpenModalAdd} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {props.t("Other Addresses")}
          </h5>
        </div>
        <div className="modal-body">
          <AvForm
            onValidSubmit={(e, v) => {
              createOtherAddress(e, v);
            }}
          >
            <Row>
              <Col lg={6}>
                <AvField
                  className="mb-3"
                  label={props.t("Address")}
                  type="text"
                  name="address"
                />
                <AvField
                  className="mb-3"
                  label={props.t("State/Province")}
                  type="text"
                  name="state"
                />
                <div className="mb-3">
                  <AvField type="select" name="country" label={props.t("Country")}>
                    {countryList().data.map((item) => (
                      <option value={item.value} key={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </AvField>
                </div>
                <AvField
                  className="mb-3"
                  label={props.t("Type")}
                  type="select"
                  name="type"
                >
                  <option value="invoicing">Invoicing</option>
                  <option value="delivery">Delivery/Work</option>
                  <option value="other">Other</option>
                </AvField>
              </Col>
              <Col lg={6}>
                <AvField
                  className="mb-3"
                  label={props.t("City/town")}
                  type="text"
                  name="city"
                />
                <AvField
                  className="mb-3"
                  label={props.t("Zip code")}
                  type="text"
                  name="zip_code"
                />
              </Col>
              <Col lg={12}>
                <div className="mb-3 mt-3">
                  <AvField
                    label={props.t("Extra Info.")}
                    type="text"
                    name="extra_information"
                  />
                </div>
                <div className="mb-3 mt-3">
                  <AvField
                    label={props.t("Description")}
                    type="textarea"
                    name="description"
                  />
                </div>
              </Col>
            </Row>
            <div className="modal-footer">
              <div className="text-end">
                <button className="btn btn-primary" type="submit">
                  {props.t("Submit")}
                </button>
                <button
                  className="btn btn-primary ms-2"
                  type="button"
                  onClick={() => setIsOpenModalAdd(false)}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </div>
          </AvForm>
        </div>
      </Modal>
    </>
  );
}
export default withTranslation()(ClientWorkAddress);
ClientWorkAddress.propTypes = {
  t: PropTypes.any,
};