import React, { useState, useEffect } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function RateModal(props) {
    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [rate, setRate] = useState("");
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState("");
    const [clientName, setClientName] = useState("");
    const [productId, setProductId] = useState(props.product && props.product.id);
    const [productName, setProductName] = useState(
        props.product && props.product.name
    );
    const [purchasePrice, setPurchasePrice] = useState(
        props.product && props.product.purchase_price
    );
    const [purchaseMargin, setPurchaseMargin] = useState(
        props.product && props.product.purchase_margin
    );
    const [salesPrice, setSalesPrice] = useState(
        props.product && props.product.price
    );
    const [salesMargin, setSalesMargin] = useState(
        props.product && props.product.sales_margin
    );
    const [discount, setDiscount] = useState(
        props.product && props.product.discount
    );
    const [specialPrice, setSpecialPrice] = useState(
        props.product && props.product.price
    );

    const getSpecialPrice = (e) => {
        let SP = e;
        let NP = SP - parseFloat(purchasePrice);
        let PM = (NP / parseFloat(purchasePrice)) * 100;
        let SM = (NP / SP) * 100;
        let Disc = 100 * ((parseFloat(salesPrice) - SP) / parseFloat(salesPrice));
        setPurchaseMargin(PM.toFixed(2));
        setSalesMargin(SM.toFixed(2));
        setDiscount(Disc.toFixed(2));
    };

    const getDiscount = (e) => {
        let Disc = e;
        let SP = parseFloat(salesPrice) - (parseFloat(salesPrice) * Disc) / 100;
        let NP = SP - parseFloat(purchasePrice);
        let PM = (NP / parseFloat(purchasePrice)) * 100;
        let SM = (NP / SP) * 100;
        setPurchaseMargin(PM.toFixed(2));
        setSalesMargin(SM.toFixed(2));
        setSpecialPrice(SP.toFixed(2));
    };

    const getSalesMargin = (e) => {
        let SM = e;
        let SP = parseFloat(purchasePrice) / (1 - SM / 100);
        let NP = SP - parseFloat(purchasePrice);
        let PM = (NP / parseFloat(purchasePrice)) * 100;
        let Disc = 100 * ((parseFloat(salesPrice) - SP) / parseFloat(salesPrice));
        setPurchaseMargin(PM.toFixed(2));
        setDiscount(Disc.toFixed(2));
        setSpecialPrice(SP.toFixed(2));
    };
    const getPurchaseMargin = (e) => {
        let PM = e;
        let NP = (PM * parseFloat(purchasePrice)) / 100;
        let SP = parseFloat(NP) + parseFloat(purchasePrice);
        let SM = (NP / SP) * 100;
        let Disc = 100 * ((parseFloat(salesPrice) - SP) / parseFloat(salesPrice));
        setSalesMargin(SM.toFixed(2));
        setDiscount(Disc.toFixed(2));
        setSpecialPrice(SP.toFixed(2));
    };

    const getSingleRate = () => {
        if (props.type == "service") {
            setLoader(true);
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/service-special-prices/${props.rateId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setRate(res.data.service_special_price.rate);
                        setError("");
                        setPurchaseMargin(res.data.service_special_price.purchase_margin);
                        setSalesMargin(res.data.service_special_price.sales_margin);
                        setDiscount(res.data.service_special_price.discount);
                        setSpecialPrice(res.data.service_special_price.special_price);
                        setSelectedClient(res.data.service_special_price.client_id);
                        setProductName(res.data.service_special_price.service_name);
                        setPurchasePrice(res.data.service_special_price.purchase_price);
                        setSalesPrice(res.data.service_special_price.sales_price);
                        setClientName(res.data.service_special_price.client_name);
                        setProductId(res.data.service_special_price.service_id);
                    } else {
                        setError(res.data.message);
                    }
                    setLoader(false);
                });
        } else {
            setLoader(true);
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/client-special-prices/${props.rateId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setRate(res.data.client_special_price.rate);
                        setError("");
                        setPurchaseMargin(res.data.client_special_price.purchase_margin);
                        setSalesMargin(res.data.client_special_price.sales_margin);
                        setDiscount(res.data.client_special_price.discount);
                        setSpecialPrice(res.data.client_special_price.special_price);
                        setSelectedClient(res.data.client_special_price.client_id);
                        setProductName(res.data.client_special_price.product_name);
                        setPurchasePrice(res.data.client_special_price.purchase_price);
                        setSalesPrice(res.data.client_special_price.sales_price);
                        setClientName(res.data.client_special_price.client_name);
                        setProductId(res.data.client_special_price.product_id);
                    } else {
                        setError(res.data.message);
                    }
                    setLoader(false);
                });
        }
    };

    const getAllClients = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setClients(res.data.clients);
                }
            });
    };

    let allClientsOption = [];
    if (clients.length) {
        {
            clients.map((item) => {
                allClientsOption.push({
                    value: item.id,
                    label: item.legal_name,
                });
            });
        }
    }

    function getSelectedClient(client) {
        setSelectedClient(client.value);
    }

    const submitRate = (e, value) => {
        if (props.type == "service") {
            setLoading(true);
            axios
                .post(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/service-special-prices${props.rateId ? `/${props.rateId}?_method=PUT` : ""
                    }`,
                    {
                        client_id: selectedClient,
                        service_id: productId,
                        purchase_price: purchasePrice,
                        purchase_margin: value.purchase_margin,
                        sales_price: props.product && props.product.price,
                        sales_margin: value.sales_margin,
                        discount: value.discount,
                        special_price: value.special_price,
                        type: "sale_price",
                        product_type: "SER",
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoading(false);
                    if (res.data.status) {
                        setError("");
                        setSuccess(res.data.message);
                        if (props.rateId) {
                            props.setRate(res.data.service_special_price);
                            props.setIsOpen(false);
                        } else {
                            props.getAllRates();
                        }
                        props.setIsOpenModal(false);
                    } else {
                        setError(res.data.message);
                        setSuccess("");
                    }
                });
        } else {
            setLoading(true);
            axios
                .post(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/client-special-prices${props.rateId ? `/${props.rateId}?_method=PUT` : ""
                    }`,
                    {
                        client_id: selectedClient,
                        product_id: productId,
                        purchase_price: purchasePrice,
                        purchase_margin: value.purchase_margin,
                        sales_price: props.product && props.product.price,
                        sales_margin: value.sales_margin,
                        discount: value.discount,
                        special_price: value.special_price,
                        type: "sale_price",
                        product_type: "PRO",
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoading(false);
                    if (res.data.status) {
                        setError("");
                        setSuccess(res.data.message);
                        if (props.rateId) {
                            props.setRate(res.data.client_special_price);
                            // props.setIsOpen(false);
                        } else {
                            props.getAllRates();
                        }
                        props.setIsOpenModal(false);
                        window.location.reload();
                    } else {
                        setError(res.data.message);
                        setSuccess("");
                    }
                });
        }
    };

    useEffect(() => {
        getAllClients();
        if (props.rateId !== undefined) {
            getSingleRate();
        }
    }, []);

    return (
        <Modal size="md" isOpen="true" centered={true}>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => submitRate(e, v)}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.rateId
                            ? `${props.t("Edit Sales Special Price")}`
                            : `${props.t("New Special Sales Price")}`}
                    </h5>
                    <button
                        type="button"
                        className="btn btn-primary text-md btn-sm"
                        onClick={() => props.setIsOpenModal(false)}
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {success ? (
                        <div className="mb-3">
                            <Alert color="success">{props.t(success)}</Alert>
                        </div>
                    ) : null}
                    {error ? (
                        <div className="mb-3">
                            <Alert color="danger">{props.t(error)}</Alert>
                        </div>
                    ) : null}
                    {loader ? (
                        "Loading..."
                    ) : (
                        <>
                            <div className="mb-3">
                                {props.rateId ? (
                                    <AvField
                                        label={props.t("Client")}
                                        type="text"
                                        name="client_name"
                                        value={clientName}
                                        disabled
                                    />
                                ) : (
                                    <>
                                        <label>{props.t("Client")}</label>
                                        <Select
                                            options={allClientsOption}
                                            onChange={getSelectedClient}
                                            name="client_category"
                                        />
                                    </>
                                )}
                            </div>{" "}
                            <div className="mb-3">
                                <AvField
                                    label={
                                        props.type == "service"
                                            ? `${props.t("Service")}`
                                            : `${props.t("Product")}`
                                    }
                                    type="text"
                                    name="product"
                                    value={productName}
                                    disabled
                                />
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group mb-3">
                                        <AvField
                                            label={props.t("Purchase Price ($)")}
                                            type="number"
                                            name="purchase_price"
                                            value={purchasePrice}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <AvField
                                            label={props.t("Purchase Margin (%)")}
                                            type="number"
                                            name="purchase_margin"
                                            value={
                                                purchaseMargin == "NaN"
                                                    ? "0.00"
                                                    : purchaseMargin == "" || purchaseMargin == "Infinity"
                                                        ? "0.00"
                                                        : purchaseMargin
                                            }
                                            onChange={(e) => {
                                                getPurchaseMargin(e.target.value),
                                                    setPurchaseMargin(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-3 required">
                                        <AvField
                                            label={props.t("Discount (%)")}
                                            type="number"
                                            name="discount"
                                            max={100}
                                            value={specialPrice < salesPrice ? discount : "0.00"}
                                            onChange={(e) => {
                                                getDiscount(e.target.value),
                                                    setDiscount(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-3">
                                        <AvField
                                            label={props.t("Sales Price ($)")}
                                            type="number"
                                            name="sales_price"
                                            value={salesPrice}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <AvField
                                            label={props.t("Sales Margin (%)")}
                                            type="number"
                                            name="sales_margin"
                                            value={
                                                salesMargin == "NaN"
                                                    ? "0.00"
                                                    : salesMargin == "" ||
                                                        salesMargin == "-Infinity" ||
                                                        salesMargin == null
                                                        ? "0.00"
                                                        : salesMargin
                                            }
                                            onChange={(e) => {
                                                getSalesMargin(e.target.value),
                                                    setSalesMargin(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <AvField
                                            label={props.t("Special Price ($)")}
                                            type="number"
                                            name="special_price"
                                            value={specialPrice == "NaN" ? "0.00" : specialPrice}
                                            onChange={(e) => {
                                                getSpecialPrice(e.target.value),
                                                    setSpecialPrice(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="modal-footer">
                    <div className="text-end">
                        {loading ? (
                            <button
                                className="btn btn-primary disabled"
                                type="button"
                                disabled
                            >
                                {props.t("Loading")}
                            </button>
                        ) : (
                            <button className="btn btn-primary" type="submit">
                                {props.rateId ? props.t("Update") : props.t("Submit")}
                            </button>
                        )}
                        <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => props.setIsOpenModal(false)}
                        >
                            {props.t("Cancel")}
                        </button>
                    </div>
                </div>
            </AvForm>
        </Modal>
    );
}
export default withTranslation()(RateModal);
RateModal.propTypes = {
    t: PropTypes.any,
};
