import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import uploadImg from "../../assets/images/def-img.svg";

function SingleList(data) {
  const [user, setUser] = useState(data.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const deleteUser = (Id) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/users/${Id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setUser(null);
        setdynamic_description(res.data.message);
      });
  };

  if (user === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td>
            <Link style={{ color: " inherit" }} to={`/user/${user.id}`}>
              {data.i + 1}
            </Link>
          </td>
          <td role="button" className="py-1">
            <Link style={{ color: " inherit" }} to={`/user/${user.id}`}>
              <img
                height="40"
                width="40"
                style={{ objectFit: "cover" }}
                src={user.image ? user.image : uploadImg}
              />
            </Link>
          </td>
          <td role="button" className="py-1">
            <Link style={{ color: " inherit" }} to={`/user/${user.id}`}>
              {user.name}
            </Link>
          </td>
          <td role="button" className="py-1">
            <Link style={{ color: " inherit" }} to={`/user/${user.id}`}>
              {user.surname}
            </Link>
          </td>
          <td role="button" className="py-1">
            <Link style={{ color: " inherit" }} to={`/user/${user.id}`}>
              {user.email}
            </Link>
          </td>
          <td role="button" className="py-1">
            <Link style={{ color: " inherit" }} to={`/user/${user.id}`}>
              {user.phone_1}
            </Link>
          </td>
          <td role="button" className="py-1">
            <Link style={{ color: " inherit" }} to={`/user/${user.id}`}>
              {user.role}
            </Link>
          </td>
          {/* <td role="button" className="py-1">
            <Link     style={{ color: " inherit" }} to={`/user/${user.id}`}>
              <span className="badge rounded-pill bg-success">Yes</span>
            </Link>
          </td> */}
          <td className="text-end">
            {getPermission().users.users.delete.is_checked === 1 ? (
              getCurrentUser().email === user.email ? (
                ""
              ) : (
                <Button
                  color="danger"
                  onClick={() => {
                    setconfirm_alert(true);
                  }}
                >
                  Delete
                </Button>
              )
            ) : (
              ""
            )}
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title="Are you sure?"
            confirmBtnText="Yes, delete it!"
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteUser(user.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("El Documento fue eliminado.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
      </>
    );
}

export default SingleList;
