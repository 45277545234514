import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import user1 from "../../../assets/images/users/user-4.jpg";
import {
  getCurrentUser,
  getCompanyLogo,
  setCurrentUser,
  setCurrentCompany,
  setPermission,
  setCompanyLogo,
  getProfileImg,
  setProfileImg,
} from "../../../helpers/Utils";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);

  const logOut = () => {
    setCurrentCompany("");
    setPermission("");
    setCurrentUser("");
    setCompanyLogo("");
    setProfileImg("");
    window.location.href = "/login";
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
        style={{ whiteSpace: "nowrap" }}
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {getCurrentUser().name}{" "}
          <img
            className="rounded-circle header-profile-user"
            src={getProfileImg() && getProfileImg() ? getProfileImg() : user1}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to={`${getCurrentUser().role === 1 || getCurrentUser().role === 2 ? "/company" : "#"}`}
            className="dropdown-item ms-1"
          >
            <span>{props.t("Company")}</span>
          </Link>
          <Link to="/membership" className="dropdown-item ms-1">
            {props.t("My Membership")}
          </Link>
          <Link to="/plans" className="dropdown-item ms-1">
            {props.t("Plans")}
          </Link>
          <Link onClick={() => logOut()} to="/login" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
