import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import {
    convertFromHTML,
    ContentState,
    EditorState,
    convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    getCurrentUser,
    getPermission,
    setCurrentUser,
} from "../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import DefaultPdfByOptions from "./list";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";
import { Link } from "react-router-dom";

const EmailConfigurationPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(<Loader />);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [isEditable, setIsEditable] = useState(false);
    const [email, setEmail] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const [signatureMessage, setSignatureMessage] = useState("");
    const [testLoader, setTestLoader] = useState(false);
    const [sendCopy, setSendCopy] = useState("");
    const [replyTo, setReplyTo] = useState("");
    const [receiptTo, setReceiptTo] = useState("");
    const [data, setData] = useState([]);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [formData, setFormData] = useState({});

    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };

    const sendData = (e) => {
        setSendCopy(e.target.value);
    };

    const replyData = (e) => {
        setReplyTo(e.target.value);
    };
    const receiptData = (e) => {
        setReceiptTo(e.target.value);
    };

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const getEmailConfiguration = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-settings`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                setEmail(res.data.settings);
                setFormData(res.data.settings);
                const html =
                    props.t(res.data.settings.email_configuration_client_emails_message);
                const blocksFromHTML = convertFromHTML(html);
                const content = ContentState.createFromBlockArray(blocksFromHTML);
                setEmailMessage(EditorState.createWithContent(content));

                const html2 = props.t(res.data.settings.email_configuration_signature);
                const blocksFromHTML2 = convertFromHTML(html2);
                const content2 = ContentState.createFromBlockArray(blocksFromHTML2);
                setSignatureMessage(EditorState.createWithContent(content2));
            });
    };

    const getData = (e) => {
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const updateSettings = () => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/update-settings`,
                {
                    email_configuration_send_as: formData?.email_configuration_send_as ? formData?.email_configuration_send_as : "",
                    email_configuration_sender_name:
                        formData?.email_configuration_sender_name ? formData?.email_configuration_sender_name : "",
                    email_configuration_send_copy_to:
                        formData?.email_configuration_send_copy_to ? formData?.email_configuration_send_copy_to : "",
                    email_configuration_email_connect:
                        formData?.email_configuration_email_connect ? formData?.email_configuration_email_connect : "",
                    email_configuration_reply_to: formData?.email_configuration_reply_to ? formData?.email_configuration_reply_to : "",
                    email_configuration_send_read_receipts_to:
                        formData?.email_configuration_send_read_receipts_to ? formData?.email_configuration_send_read_receipts_to : "",
                    email_configuration_client_emails_subject:
                        formData?.email_configuration_client_emails_subject ? formData?.email_configuration_client_emails_subject : "",
                    email_configuration_client_emails_message: draftToHtml(
                        convertToRaw(emailMessage.getCurrentContent())
                    ),
                    email_configuration_signature: draftToHtml(
                        convertToRaw(signatureMessage.getCurrentContent())
                    ),
                    smtp_email_address: formData?.smtp_email_address ? formData?.smtp_email_address : getCurrentUser().smtp_email_address && getCurrentUser().smtp_email_address,
                    smtp_server: formData?.smtp_server ? formData?.smtp_server : getCurrentUser().smtp_server && getCurrentUser().smtp_server,
                    smtp_security_protocol: formData?.smtp_security_protocol ? formData?.smtp_security_protocol : getCurrentUser().smtp_security_protocol && getCurrentUser().smtp_security_protocol,
                    smtp_password: formData?.smtp_password ? formData?.smtp_password : getCurrentUser().smtp_password && getCurrentUser().smtp_password,
                    smtp_port: formData?.smtp_port ? formData?.smtp_port : getCurrentUser().smtp_port &&
                        getCurrentUser().smtp_port,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setCurrentUser(res.data.user);
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const getDefaultPdfByOptions = () => {
        setData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/default-pdf`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.default_pdf_option);
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getDefaultPdfByOptions();
    }, []);

    const sendTestEmail = () => {
        setTestLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-test-email?send_to=${sendCopy}&send_receipt_to=${receiptTo}&reply_to=${replyTo}`,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setTestLoader(false);
                // alert(res.data.message);
                setSuccess(res.data.message);
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const onEditorEmailMessage = (emailMessage) => {
        setEmailMessage(emailMessage);
    };

    const onEditorSignatureMessage = (signatureMessage) => {
        setSignatureMessage(signatureMessage);
    };

    useEffect(() => {
        getEmailConfiguration();
    }, []);

    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <AvForm
                id="email-configuration"
                className="form-horizontal"
            // onValidSubmit={(e, v) => {updateSettings(e, v);}}
            >
                <div className="page-title-box">
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h6 className="page-title">{props.t("Email Configuration")}</h6>
                        </Col>
                        <Col md={6}>
                            <div className="float-end d-flex">
                                {/* <Link to="/company" className="btn btn-primary float-end">
                        {props.t("Back")}
                      </Link> */}
                                {testLoader ? (
                                    <button className="btn btn-primary me-2 disabled" disabled>
                                        {props.t("Loading")}...
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary me-2 ms-2"
                                        onClick={() => sendTestEmail()}
                                    >
                                        {props.t("Send Test Email")}
                                    </button>
                                )}
                                {getPermission().services.services.edit.is_checked === 1 ? (
                                    isEditable ? (
                                        <>
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary " type="button" onClick={() => updateSettings()}>
                                                    {props.t("Update")}
                                                </button>
                                            )}
                                            <span
                                                onClick={() => setIsEditable(false)}
                                                className="btn btn-primary  ms-2"
                                            >
                                                {props.t("Cancel")}
                                            </span>
                                        </>
                                    ) : (
                                        <span
                                            onClick={() => createNew()}
                                            className="btn btn-primary "
                                        >
                                            {props.t("Edit")}
                                        </span>
                                    )
                                ) : (
                                    ""
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
                {loader}
                {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                <Card>
                    <CardBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customActiveTab === "1",
                                    })}
                                    onClick={() => {
                                        toggleCustom("1");
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        {props.t("Email Configuration")}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customActiveTab === "2",
                                    })}
                                    onClick={() => {
                                        toggleCustom("2");
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        {props.t("Client Email")}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames({
                                        active: customActiveTab === "3",
                                    })}`}
                                    onClick={() => {
                                        toggleCustom("3");
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        {props.t("Signature")}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames({
                                        active: customActiveTab === "4",
                                    })}`}
                                    onClick={() => {
                                        toggleCustom("4");
                                    }}
                                >
                                    <span className="d-none d-sm-block">
                                        {props.t("Default PDF Send Options")}
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={customActiveTab} className="p-3">
                            <TabPane tabId="1">
                                <Row>
                                    <Col md="12">
                                        <label>{props.t("Here you can select")}:</label>
                                        <ul>
                                            <li>
                                                {props.t(
                                                    "From which email address you want your business emails to be sent from."
                                                )}
                                            </li>
                                            <li>
                                                {props.t(
                                                    "Which address you want replies to be sent to."
                                                )}
                                            </li>
                                            <li>
                                                {props.t(
                                                    "Which address you want a copy to be sent to."
                                                )}
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("Send as")}
                                                type="text"
                                                name="email_configuration_send_as"
                                                onChange={(e) => getData(e)}
                                                value={
                                                    email.email_configuration_send_as !== null
                                                        ? email.email_configuration_send_as
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("Sender Name")}
                                                type="text"
                                                name="email_configuration_sender_name"
                                                onChange={(e) => getData(e)}
                                                value={
                                                    email.email_configuration_sender_name !== null
                                                        ? email.email_configuration_sender_name
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("Send Copy to")}
                                                type="text"
                                                onChange={(e) => { sendData(e), getData(e) }}
                                                name="email_configuration_send_copy_to"
                                                value={
                                                    email.email_configuration_send_copy_to !== null
                                                        ? email.email_configuration_send_copy_to
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("Email Connect")}
                                                type="text"
                                                name="email_configuration_email_connect"
                                                onChange={(e) => getData(e)}
                                                value={
                                                    email.email_configuration_email_connect !== null
                                                        ? email.email_configuration_email_connect
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("Reply to")}
                                                type="text"
                                                onChange={(e) => { replyData(e), getData(e) }}
                                                name="email_configuration_reply_to"
                                                value={
                                                    email.email_configuration_reply_to !== null
                                                        ? email.email_configuration_reply_to
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("Send read receipts to")}
                                                type="text"
                                                onChange={(e) => { receiptData(e), getData(e) }}
                                                name="email_configuration_send_read_receipts_to"
                                                value={
                                                    email.email_configuration_send_read_receipts_to !==
                                                        null
                                                        ? email.email_configuration_send_read_receipts_to
                                                        : ""
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <p>
                                    {props.t(
                                        "Authorize Billing System if you use Gmail or enter the SMTP settings of your mail server:"
                                    )}
                                </p>
                                <Row>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("Email address")}
                                                type="email"
                                                name="smtp_email_address"
                                                onChange={(e) => getData(e)}
                                                value={
                                                    getCurrentUser().smtp_email_address &&
                                                    getCurrentUser().smtp_email_address
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("SMTP Server")}
                                                type="text"
                                                name="smtp_server"
                                                onChange={(e) => getData(e)}
                                                value={
                                                    getCurrentUser().smtp_server &&
                                                    getCurrentUser().smtp_server
                                                }
                                            />
                                        </div>
                                        {/* <div className="mb-3">
                      <AvField
                        readOnly={isEditable ? "" : true}
                        label={props.t("Security Protocol")}
                        type="text"
                        name="smtp_security_protocol"
                        onChange={(e) => getData(e)}
                        value={
                          getCurrentUser().smtp_security_protocol &&
                          getCurrentUser().smtp_security_protocol
                        }
                      />
                    </div> */}
                                        <div className="mb-3">
                                            <AvField
                                                type="select"
                                                name="smtp_security_protocol"
                                                value={
                                                    getCurrentUser().smtp_security_protocol &&
                                                    getCurrentUser().smtp_security_protocol
                                                }
                                                onChange={(e) => getData(e)}
                                                label={props.t("Security Protocol")}
                                                disabled={isEditable ? "" : true}
                                            >
                                                <option value="">{props.t("None")}</option>
                                                <option value="STARTTLS">STARTTLS</option>
                                                <option value="TLS">TLS</option>
                                                <option value="SSL">SSL</option>
                                            </AvField>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("Email password")}
                                                type="password"
                                                name="smtp_password"
                                                onChange={(e) => getData(e)}
                                                value={
                                                    getCurrentUser().smtp_password &&
                                                    getCurrentUser().smtp_password
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("SMTP Port")}
                                                type="number"
                                                name="smtp_port"
                                                onChange={(e) => getData(e)}
                                                value={
                                                    getCurrentUser().smtp_port &&
                                                    getCurrentUser().smtp_port
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <p>
                                    {props.t(
                                        "These are the configuration settings for the following email service providers:"
                                    )}
                                </p>
                                <Row>
                                    <Col lg="6">
                                        <p>
                                            <b
                                                style={{
                                                    fontWeight: "900",
                                                }}
                                            >
                                                {props.t("HOTMAIL")}
                                            </b>{" "}
                                            <br />
                                            <b
                                                style={{
                                                    fontWeight: "900",
                                                }}
                                            >
                                                SMTP Server: smtp.live.com
                                            </b>{" "}
                                            <br />
                                            Security Protocol (any option is valid): <br />
                                            STARTTLS - SMTP Ports: 25 and 587 <br />
                                            TLS - SMTP Ports: 25, 465 and 587
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <p>
                                            <b
                                                style={{
                                                    fontWeight: "900",
                                                }}
                                            >
                                                {props.t("YAHOO")}
                                            </b>{" "}
                                            <br />
                                            <b
                                                style={{
                                                    fontWeight: "900",
                                                }}
                                            >
                                                SMTP Server: smtp.mail.yahoo.com
                                            </b>{" "}
                                            <br />
                                            Security Protocol (any option is valid): <br />
                                            STARTTLS - SMTP Ports: 25 and 587 <br />
                                            TLS - SMTP Ports: 25, 465 and 587 <br />
                                            SSL - SMTP Ports: 465 <br />
                                        </p>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <AvField
                                                readOnly={isEditable ? "" : true}
                                                label={props.t("Subject")}
                                                type="text"
                                                name="email_configuration_client_emails_subject"
                                                onChange={(e) => getData(e)}
                                                value={
                                                    email.email_configuration_client_emails_subject !==
                                                        null
                                                        ? email.email_configuration_client_emails_subject
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label>{props.t("Message")}</label>
                                            <Editor
                                                className="honey"
                                                editorState={emailMessage}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorEmailMessage}
                                                readOnly={isEditable ? "" : true}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <label>{props.t("Customize your emails")}</label>
                                        <p>
                                            {props.t(
                                                "You can customize your emails using our default tags. When you send an email, these tags will be replaced with the appropriate information."
                                            )}
                                        </p>

                                        <p>
                                            {props.t(
                                                "For example, if you insert @DOCUMENTTYPE@, this information will be replaced in the email using the name for the document type you are sending. `Please find your @DOCUMENTTYPE@ attached` will be replaced by `Please find your Invoice attached` if the document you are sending is an invoice."
                                            )}
                                        </p>

                                        <p>{props.t("Our tags are:")}</p>
                                        <p>
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@MYCOMPANY@")
                                                }
                                            >
                                                @MYCOMPANY@:
                                            </b>{" "}
                                            {props.t("The name of your company or business")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@MYLOGO@")
                                                }
                                            >
                                                @MYLOGO@:
                                            </b>{" "}
                                            {props.t("The logo of your company or business")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@USERNAME@")
                                                }
                                            >
                                                @USERNAME@:
                                            </b>{" "}
                                            {props.t("The name of the user that is sending the email")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@USEREMAIL@")
                                                }
                                            >
                                                @USEREMAIL@:
                                            </b>{" "}
                                            {props.t("The email of the user that is sending the email")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@USERPHONE@")
                                                }
                                            >
                                                @USERPHONE@:
                                            </b>{" "}
                                            {props.t("The phone of the user that is sending the email")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@USERPOSITION@")
                                                }
                                            >
                                                @USERPOSITION@:
                                            </b>{" "}
                                            {props.t("The position of the user that is sending the email")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@DOCUMENTTYPE@")
                                                }
                                            >
                                                {props.t("@DOCUMENTTYPE@")}:
                                            </b>{" "}
                                            {props.t("The document type you are sending")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@DOCUMENTREFERENCE@")
                                                }
                                            >
                                                @DOCUMENTREFERENCE@:
                                            </b>{" "}
                                            {props.t("The reference of document you are sending")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@DOCUMENTTITLE@")
                                                }
                                            >
                                                @DOCUMENTTITLE@:
                                            </b>{" "}
                                            {props.t("The title of document you are sending")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@DOCUMENTURL@")
                                                }
                                            >
                                                @DOCUMENTURL@:
                                            </b>{" "}
                                            {props.t("The document URL you are sending")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@CLIENTNAME@")
                                                }
                                            >
                                                @CLIENTNAME@:
                                            </b>{" "}
                                            {props.t("The legal name of the client that appears in the document")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText(
                                                        "@CLIENTCOMMERCIALNAME@"
                                                    )
                                                }
                                            >
                                                @CLIENTCOMMERCIALNAME@:
                                            </b>{" "}
                                            {props.t("The name of the client that appears in the document")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@RATINGWIDGET@")
                                                }
                                            >
                                                @RATINGWIDGET@:
                                            </b>{" "}
                                            {props.t("Rating widget")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@MYFRIENDLINK@")
                                                }
                                            >
                                                @MYFRIENDLINK@:
                                            </b>{" "}
                                            {props.t("URL of your friend link")}
                                            <br />
                                        </p>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Editor
                                                className="honey"
                                                editorState={signatureMessage}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorSignatureMessage}
                                                readOnly={isEditable ? "" : true}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <label>{props.t("Customize your emails")}</label>
                                        <p>
                                            {props.t(
                                                "You can customize your emails using our default tags. When you send an email, these tags will be replaced with the appropriate information."
                                            )}
                                        </p>

                                        <p>
                                            {props.t(
                                                "For example, if you insert @DOCUMENTTYPE@, this information will be replaced in the email using the name for the document type you are sending. `Please find your @DOCUMENTTYPE@ attached` will be replaced by `Please find your Invoice attached` if the document you are sending is an invoice."
                                            )}
                                        </p>

                                        <p>{props.t("Our tags are:")}</p>
                                        <p>
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@MYCOMPANY@")
                                                }
                                            >
                                                @MYCOMPANY@:
                                            </b>{" "}
                                            {props.t("The name of your company or business")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@MYLOGO@")
                                                }
                                            >
                                                @MYLOGO@:
                                            </b>{" "}
                                            {props.t("The logo of your company or business")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@USERNAME@")
                                                }
                                            >
                                                @USERNAME@:
                                            </b>{" "}
                                            {props.t("The name of the user that is sending the email")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@USEREMAIL@")
                                                }
                                            >
                                                @USEREMAIL@:
                                            </b>{" "}
                                            {props.t("The email of the user that is sending the email")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@USERPHONE@")
                                                }
                                            >
                                                @USERPHONE@:
                                            </b>{" "}
                                            {props.t("The phone of the user that is sending the email")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@USERPOSITION@")
                                                }
                                            >
                                                @USERPOSITION@:
                                            </b>{" "}
                                            {props.t("The position of the user that is sending the email")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@DOCUMENTTYPE@")
                                                }
                                            >
                                                {props.t("@DOCUMENTTYPE@")}:
                                            </b>{" "}
                                            {props.t("The document type you are sending")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@DOCUMENTREFERENCE@")
                                                }
                                            >
                                                @DOCUMENTREFERENCE@:
                                            </b>{" "}
                                            {props.t("The reference of document you are sending")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@DOCUMENTTITLE@")
                                                }
                                            >
                                                @DOCUMENTTITLE@:
                                            </b>{" "}
                                            {props.t("The title of document you are sending")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@DOCUMENTURL@")
                                                }
                                            >
                                                @DOCUMENTURL@:
                                            </b>{" "}
                                            {props.t("The document URL you are sending")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@CLIENTNAME@")
                                                }
                                            >
                                                @CLIENTNAME@:
                                            </b>{" "}
                                            {props.t("The legal name of the client that appears in the document")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText(
                                                        "@CLIENTCOMMERCIALNAME@"
                                                    )
                                                }
                                            >
                                                @CLIENTCOMMERCIALNAME@:
                                            </b>{" "}
                                            {props.t("The name of the client that appears in the document")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@RATINGWIDGET@")
                                                }
                                            >
                                                @RATINGWIDGET@:
                                            </b>{" "}
                                            {props.t("Rating widget")}
                                            <br />
                                            <b
                                                className="text-primary"
                                                onClick={() =>
                                                    navigator.clipboard.writeText("@MYFRIENDLINK@")
                                                }
                                            >
                                                @MYFRIENDLINK@:
                                            </b>{" "}
                                            {props.t("URL of your friend link")}
                                            <br />
                                        </p>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4">
                                <div className="table-responsive">
                                    <Table striped className="align-middle mt-5">
                                        <thead>
                                            <tr>
                                                <th>{props.t("Type")}</th>
                                                <th>{props.t("Format")}</th>
                                                <th>{props.t("Price after tax")}</th>
                                                <th>{props.t("Mailing Format")}</th>
                                                <th>{props.t("Include the main image")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.map((item, i) => (
                                                    <DefaultPdfByOptions data={item} key={i} />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </AvForm>
        </React.Fragment>
    );
};
export default withTranslation()(EmailConfigurationPage);
EmailConfigurationPage.propTypes = {
    t: PropTypes.any,
};
