/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef*/

import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Table,
    Modal,
    Input,
    Spinner,
} from "reactstrap";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import countryList from "react-select-country-list";
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
import SpecialPrice from "../SpecialPrice";
const axios = require("axios").default;
import CreateAssestsSection from "../../../components/Assests/assests-new";
import AllAttachment from "../../../components/Attachments/client-assets-attachment";
import ClientCategory from "../../Category/client/client-category";
import ListContact from "../lists/contectList";
import ListAddress from "../lists/otherAddressList";
import Loader from "../../../components/Loader";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import NewPayment from "../../../components/PaymentOption/payment_option";
import SendAttachment from "../../SendAttachments";
import UnpaidPurchaseInvoice from "./unpaidPurchaseInvoice";
import DepositTransactionModal from "../../../pages/Clients/DepositTransactionModal";
import WithdrawModal from "../../../pages/Clients/withdrawModal";
// import TransactionList from "../../../pages/Clients/TransactionList";
import BankModal from "../edit/bankModal";
import SingleList from "../edit/accountList";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import CreateTrialModal from "../../plans/trialModal";
let cc = require("currency-codes");

const SingleSupplier = (props) => {
    const { id } = useParams();
    const [userId] = useState(parseInt(id, 10));
    const [loading, setLoading] = useState(false);
    const [contactLoading, setContactLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("general");
    const [referenceType, setReferenceType] = useState("SUP");
    const [client, setClient] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [contactsAll, setContactsAll] = useState([]);
    const [isOpenModals, setIsOpenModals] = useState(false);
    const [document, setDocument] = useState("");
    const [fileDocument, setFileDocument] = useState("");
    const [attachDocument, setAttachDocument] = useState([]);
    const [selectedCate, setSelectedCate] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [isOpenAddress, setIsOpenAddress] = useState(false);
    const [isOpenContact, setIsOpenContact] = useState(false);
    const [allAddress, setAllAddress] = useState([]);
    const [contactLoader, setContactLoader] = useState(false);
    const [addressLoader, setAddressLoader] = useState(false);
    const [swift, setSwift] = useState(false);
    const [related, setRelated] = useState([]);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [phone2, setPhone2] = useState("");
    const [phone, setPhone] = useState("");
    const [balance, setBalance] = useState("");
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [paymentData, setPaymentData] = useState([]);
    const [isOpenModalNew, setIsOpenModalNew] = useState(false);
    const [paymentTerm, setPaymentTerm] = useState([]);
    const [mods, setMods] = useState(false);
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");
    const [refNum, setRefNum] = useState("");
    const [isAttachOpenModal, setIsAttachOpenModal] = useState(false);
    const [attach, setAttach] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [error2, setError2] = useState("");
    const [attachEmail, setAttachEmail] = useState("");
    const [attachEmail2, setAttachEmail2] = useState("");
    const [attachEmail3, setAttachEmail3] = useState("");
    const [emailMessage2, setEmailMessage2] = useState("");
    const [customActiveTabOne, setCustomActiveTabOne] = useState("1");
    const [isDepositOpenModal, setIsDepositOpenModal] = useState(false);
    const [isWithdrawOpenModal, setIsWithdrawOpenModal] = useState(false);
    const [unpaidData, setUnpaidData] = useState([]);
    const [transData, setTransData] = useState([]);
    const [error3, setError3] = useState("");
    const [isBankOpenModal, setIsBankOpenModal] = useState(false);
    const [allBanks, setAllBanks] = useState([]);
    const [BankId, setBankId] = useState([]);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };
    const toggleCustomInner = (tab) => {
        if (customActiveTabOne !== tab) {
            setCustomActiveTabOne(tab);
        }
    };

    const getSingleClient = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/suppliers/${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setClient(res.data.Supplier_address);
                setRefNum(res.data.Supplier_address.reference_number);
                setPhone(res.data.Supplier_address.phone_1);
                setPhone2(res.data.Supplier_address.phone_2);
                // setSelectedCates(res.data.Supplier_address.payment_option_id);
                if (res.data.Supplier_address.bank_account_format === "swift/aba") {
                    setSwift(true);
                } else {
                    setSwift(true);
                }
                if (
                    res.data.Supplier_address.bank_account_format === "ahorros" ||
                    res.data.Supplier_address.bank_account_format === "corriente"
                ) {
                    setSwift(true);
                } else {
                    setSwift(true);
                }
            });
    };

    const updateClient = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("reference", value.reference);
        formData.append("reference_number", value.reference_number);
        formData.append(
            "legal_name",
            value.legal_name !== null ? value.legal_name : ""
        );
        formData.append("tin", value.tin !== null ? value.tin : "");
        formData.append("phone_1", phone !== null ? phone : "");
        formData.append("address", value.address !== null ? value.address : "");
        formData.append("state", value.state !== null ? value.state : "");
        formData.append("country", value.country !== null ? value.country : "");
        formData.append("name", value.name !== null ? value.name : "");
        formData.append("email", value.email !== null ? value.email : "");
        formData.append("city", value.city !== null ? value.city : "");
        formData.append("zip_code", value.zip_code !== null ? value.zip_code : "");
        formData.append("fax", value.fax !== null ? value.fax : "");
        formData.append("website", value.website !== null ? value.website : "");
        formData.append("comments", value.comments !== null ? value.comments : "");
        formData.append(
            "created_from",
            value.created_from !== null ? value.created_from : ""
        );
        formData.append("phone_2", phone2 !== null ? phone2 : "");
        formData.append(
            "payment_date",
            value.payment_date !== null ? value.payment_date : ""
        );
        formData.append("discount", value.discount !== null ? value.discount : "");
        formData.append("rate", value.rate !== null ? value.rate : "");
        formData.append("currency", value.currency !== null ? value.currency : "");
        // formData.append("subject_to_vat", value.subject_to_vat);
        formData.append("Agent", value.Agent);
        formData.append(
            "bank_account_format",
            value.bank_account_format !== null ? value.bank_account_format : ""
        );
        formData.append(
            "bank_account_account",
            value.bank_account_account !== null ? value.bank_account_account : ""
        );
        formData.append(
            "bank_account_bic",
            value.bank_account_bic !== null ? value.bank_account_bic : ""
        );
        formData.append(
            "bank_account_name",
            value.bank_account_name !== null ? value.bank_account_name : ""
        );
        formData.append(
            "bank_account_description",
            value.bank_account_description !== null
                ? value.bank_account_description
                : ""
        );
        formData.append(
            "payment_terms_id",
            value.payment_terms_id !== null ? value.payment_terms_id : ""
        );
        formData.append("payment_adjustment", value.payment_adjustment);
        formData.append("supplier_category", e.target.supplier_category.value);
        formData.append("tin", value.tin !== null ? value.tin : "");
        formData.append(
            "payment_option_id",
            value.payment_option_id !== null ? value.payment_option_id : ""
        );
        formData.append(
            "swift_aba",
            value.swift_aba !== null ? value.swift_aba : ""
        );
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/suppliers/${userId}?_method=PUT`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setIsEditable(false);
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };
    const getCountry = (e) => {
        setPhone(e);
    };
    const getCountry2 = (e) => {
        setPhone2(e);
    };
    const getType = (e) => {
        setReferenceType(e.target.value);
        setRefNum("");
    };

    const openNew = (e) => {
        if (e.target.value == "new") {
            setModal(true);
        }
    };

    const getAllPayment = () => {
        setPaymentData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-terms`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    setPaymentTerm(res.data.payment_terms);
                }
            });
    };
    const createAttachment = (e, value) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("document", document);
        formData.append("description", e.target.description.value);
        formData.append("type", "attachments");
        formData.append("supplier_id", userId);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-attachments`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    AllAttach();
                    setSuccess(res.data.message);
                    setIsOpenModals(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };
    const newpaymentShow = () => {
        setPaymentLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setPaymentData(res.data.PaymentOptions);
                setPaymentLoader(false);
            });
    };
    const AllAttach = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-attachments?supplier_id=${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAttachDocument(res.data.supplier_attachments);
            });
    };

    const RelatedAll = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/related?supplier_id=${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setRelated(res.data.data);
            });
    };
    const getBalance = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-balance?supplier_id=${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setBalance(res.data.data);
            });
    };
    const getRelated = (type, id) => {
        // if (res.data.data.reference_type == "Purchase Order") {
        //   // history.push(`/purchase-order/${id}`);
        // } else if (res.data.data.reference_type == "Purchase Delivery Note") {
        //   // history.push(`/purchase-delivery-Notes/${id}`);
        // } else {
        //   // history.push(`/purchase-invoice/${id}`);
        // }
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Supplier`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                    res.data.reference?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultRef(item.prefix);
                        }
                    });
                } else {
                    //   setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getBalance();
        AllAttach();
        getReferences();
        getSingleClient();
        RelatedAll();
        newpaymentShow();
        getAllPayment();
        // getUnpaidInvoices()
    }, []);
    const getUnpaidInvoices = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/unpaid-invoices?supplier_id=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setUnpaidData(res.data.data);
                }
            });
    };
    //   let optionPayment = [];
    //   if (paymentData !== undefined) {
    //     {
    //       paymentData &&
    //         paymentData.map((payment) => {
    //           optionPayment.push({
    //             value: payment.id,
    //             label: payment.name,
    //           });
    //         });
    //     }
    //   }
    //   const getContactAll = () => {
    //     axios
    //       .get(
    //         `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
    //           "companyId"
    //         )}/supplier-contacts`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${getCurrentUser().token}`,
    //           },
    //         }
    //       )
    //       .then(function (res) {
    //         setContactsAll(res.data.supplier_contacts);
    //       });
    //   };
    //   useEffect(() => {
    //     getContactAll();
    //   }, []);

    //   const selectClient = (e, item) => {
    //     clientAddress = item;
    //     if(setClientNameData !== undefined) {
    //       setClientNameData(item.name);
    //     }
    //     if(props.setClientData !== undefined) {
    //         props.setClientData(item.address);
    //     }
    //     if(props.setDelAddress !== undefined) {
    //       props.setDelAddress(item.address);
    //     }
    //     props.setIsOpenModal(false)
    //   };

    const filePreview = (e) => {
        setDocument(e.target.files[0]);
        setFileDocument(URL.createObjectURL(e.target.files[0]));
    };

    let optionCategory = [];
    if (allCategories !== undefined) {
        {
            allCategories &&
                allCategories.map((category) => {
                    optionCategory.push({
                        value: category.id,
                        label: category.name,
                    });
                });
        }
    }

    function handleSelectCategory(selectedcate) {
        setSelectedCate(selectedcate.value);
    }

    const CategoriesClient = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-categories?type=supplier`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllCategories(res.data.client_categories);
            });
    };

    useEffect(() => {
        CategoriesClient();
    }, []);

    const getSingleClientsAddress = () => {
        setAddressLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-addresses?supplier_id=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllAddress(res.data.supplier_addresses);
                setAddressLoader(false);
            });
    };

    useEffect(() => {
        getSingleClientsAddress();
    }, []);

    const getSingleContact = () => {
        setContactLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-contacts?supplier_id=${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setContactsAll(res.data.supplier_contacts);
                setContactLoader(false);
            });
    };

    useEffect(() => {
        getSingleContact();
    }, []);

    const createOtherAddress = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("supplier_id", userId);
        formData.append("address", value.address);
        formData.append("state", value.state);
        formData.append("city", value.city);
        formData.append("zip_code", value.zip_code);
        formData.append("country", value.country);
        formData.append("type", value.type);
        formData.append("extra_information", value.extra_information);
        formData.append("description", value.description);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-addresses`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    setIsOpenAddress(false);
                    setLoading(false);
                    getSingleClientsAddress();
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };
    const createContact = (e, value) => {
        setContactLoading(true);
        const formData = new FormData();
        formData.append("name", value.name);
        formData.append("phone", value.phone);
        formData.append("email", value.email);
        formData.append("supplier_id", userId);
        formData.append("fax", value.fax);
        formData.append("position", value.position);
        formData.append("comments", value.comments);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-contacts`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setContactLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    setIsOpenContact(false);
                    getSingleContact();
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };
    const getSendAttachment = () => {
        if (selectedIds.length < 1) {
            setError2("Please select document");
            setTimeout(() => {
                setError2("");
            }, 4000);
            return false;
        }
        setIsAttachOpenModal(true);
    };
    const getSendAttachment2 = () => {
        const formData = new FormData();
        formData.append("send_to", attachEmail);
        formData.append("cc", attachEmail2);
        formData.append("bcc", attachEmail3);
        formData.append("body", emailMessage2);
        formData.append("ids", selectedIds);
        formData.append("type", "supplier_attachments");
        formData.append("name", selectedName);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-attachments-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 3000);
            });
    };

    const getExportInvoice = () => {
        if (selectedIds.length > 1) {
            setError3("This action can only be carried out on a single document");
            setTimeout(() => {
                setError3("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError3("Please select document");
            setTimeout(() => {
                setError3("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/suppliers/export-receipt`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.url;
                }
            });
    };
    const getExportTrans = () => {
        if (selectedIds.length > 1) {
            setError3("This action can only be carried out on a single document");
            setTimeout(() => {
                setError3("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError3("Please select document");
            setTimeout(() => {
                setError3("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/deposit-export`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.file_url;
                }
            });
    };

    const getCreateDeposit = () => {
        // setContactLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/deposit?supplierId=${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setTransData(res.data.data);
                // setContactLoader(false);
            });
    };
    const getAllBanks2 = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-bank-account?supplier_id=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAllBanks(res.data.clients);
                    setError("");
                } else {
                    //   setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getAllBanks2();
    }, []);
    return (
        <React.Fragment>
            {isDepositOpenModal ? (
                <DepositTransactionModal
                    isDepositOpenModal={isDepositOpenModal}
                    setIsDepositOpenModal={setIsDepositOpenModal}
                    id={userId}
                    getCreateDeposit={getCreateDeposit}
                />
            ) : (
                ""
            )}
            {isWithdrawOpenModal ? (
                <WithdrawModal
                    isWithdrawOpenModal={isWithdrawOpenModal}
                    setIsWithdrawOpenModal={setIsWithdrawOpenModal}
                    id={userId}
                    getCreateDeposit={getCreateDeposit}
                />
            ) : (
                ""
            )}

            <SendAttachment
                isAttachOpenModal={isAttachOpenModal}
                setIsAttachOpenModal={setIsAttachOpenModal}
                attach={attach}
                setAttach={setAttach}
                setAttachEmail={setAttachEmail}
                setAttachEmail2={setAttachEmail2}
                setAttachEmail3={setAttachEmail3}
                getSendAttachment2={getSendAttachment2}
                attachDocument={attachDocument}
                emailMessage2={emailMessage2}
                setEmailMessage2={setEmailMessage2}
                success={success}
                error={error}
                selectedName={selectedName}
            />
            {isOpenModalNew ? (
                <NewPayment
                    setIsOpenModal={setIsOpenModalNew}
                    NewpaymentShow={newpaymentShow}
                />
            ) : (
                ""
            )}
            {isBankOpenModal ? (
                <BankModal
                    isBankOpenModal={isBankOpenModal}
                    setIsBankOpenModal={setIsBankOpenModal}
                    getAllBanks2={getAllBanks2}
                    id={userId}
                />
            ) : (
                ""
            )}
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <div className="page-content">
                <MetaTags>
                    <title>Edit Supplier | Clouget</title>
                </MetaTags>
                {modal ? (
                    <ClientCategory
                        setIsOpenModal={setModal}
                        CategoriesClient={CategoriesClient}
                    />
                ) : (
                    ""
                )}
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                updateClient(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{client && client.name}</h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            <Link
                                                type="button"
                                                to="/suppliers"
                                                className="btn btn-primary"
                                            >
                                                {props.t("Back")}
                                            </Link>
                                            {isEditable ? (
                                                <>
                                                    {loading ? (
                                                        <button
                                                            className="btn btn-primary ms-2 disabled"
                                                            type="button"
                                                            disabled
                                                        >
                                                            {props.t("Loading")}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-primary ms-2"
                                                            type="submit"
                                                        >
                                                            {props.t("Update")}
                                                        </button>
                                                    )}
                                                    <span
                                                        onClick={() => setIsEditable(false)}
                                                        className="btn btn-primary  ms-2"
                                                    >
                                                        {props.t("Cancel")}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {getPermission().suppliers.suppliers.edit
                                                        .is_checked === 1 ? (
                                                        <span
                                                            onClick={() => createNew()}
                                                            className="btn btn-primary ms-2 "
                                                        >
                                                            {props.t("Edit")}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={6}>
                                            <label> {props.t("Reference")}</label>
                                            <div className="d-flex">
                                                <AvField
                                                    style={{
                                                        width: "100px",
                                                    }}
                                                    type="select"
                                                    name="reference"
                                                    value={
                                                        client.reference !== null ? client.reference : ""
                                                    }
                                                    onChange={(e) => getType(e)}
                                                    disabled={isEditable ? false : true}
                                                >
                                                    {allRef &&
                                                        allRef.map((item, i) => (
                                                            <option value={item.prefix} key={i}>
                                                                {item.prefix}
                                                            </option>
                                                        ))}
                                                    <option value="manual"> {props.t("MANUAL")}</option>
                                                </AvField>
                                                <div className="w-100 ps-3">
                                                    <AvField
                                                        className="mb-3 w-100"
                                                        type="number"
                                                        name="reference_number"
                                                        disabled={isEditable ? false : true}
                                                        // value={client.reference_number}
                                                        value={refNum !== null ? refNum : ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Legal Name")}
                                                    type="text"
                                                    readOnly={isEditable ? false : true}
                                                    name="legal_name"
                                                    value={
                                                        client.legal_name !== null ? client.legal_name : ""
                                                    }
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Ced/Ruc")}
                                                    type="text"
                                                    readOnly={isEditable ? false : true}
                                                    name="tin"
                                                    value={client.tin !== null ? client.tin : ""}
                                                    required="required"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Name")}
                                                type="text"
                                                name="name"
                                                readOnly={isEditable ? false : true}
                                                value={client.name !== null ? client.name : ""}
                                            />
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Email")}
                                                    type="email"
                                                    readOnly={isEditable ? false : true}
                                                    name="email"
                                                    value={client.email !== null ? client.email : ""}
                                                    required="required"
                                                />
                                            </div>
                                            <AvField
                                                className="mb-3"
                                                placeholder={props.t("Enter phone number")}
                                                label={props.t("Phone 1")}
                                                value={client.phone_1 !== null ? client.phone_1 : ""}
                                                disabled={isEditable ? false : true}
                                                type="text"
                                                name="phone_1"
                                                validate={{
                                                    maxLength: {
                                                        value: 10,
                                                        errorMessage: props.t(
                                                            "The number cannot be more than 10 digits."
                                                        ),
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Address")}
                                                type="text"
                                                readOnly={isEditable ? false : true}
                                                name="address"
                                                value={client.address !== null ? client.address : ""}
                                            />
                                            <AvField
                                                className="mb-3"
                                                label={props.t("State/Province")}
                                                type="text"
                                                readOnly={isEditable ? false : true}
                                                name="state"
                                                value={client.state !== null ? client.state : ""}
                                            />
                                            <div className="mb-3">
                                                <AvField
                                                    type="select"
                                                    name="country"
                                                    label={props.t("Country")}
                                                    value={client.country !== null ? client.country : ""}
                                                    disabled={isEditable ? false : true}
                                                >
                                                    <option value="">Select</option>
                                                    {countryList().data.map((item, i) => (
                                                        <option key={i} value={item.label}>
                                                            {item.label}
                                                        </option>
                                                    ))}
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <AvField
                                                className="mb-3"
                                                placeholder={props.t("Enter phone number")}
                                                label={props.t("Phone 2")}
                                                value={client.phone_2 !== null ? client.phone_2 : ""}
                                                disabled={isEditable ? false : true}
                                                type="text"
                                                name="phone_2"
                                                validate={{
                                                    maxLength: {
                                                        value: 10,
                                                        errorMessage: props.t(
                                                            "The number cannot be more than 10 digits."
                                                        ),
                                                    },
                                                }}
                                            />
                                            <AvField
                                                className="mb-3"
                                                label={props.t("City/town")}
                                                type="text"
                                                readOnly={isEditable ? false : true}
                                                name="city"
                                                value={client.city !== null ? client.city : ""}
                                            />
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Zip code")}
                                                type="text"
                                                readOnly={isEditable ? false : true}
                                                name="zip_code"
                                                value={client.zip_code !== null ? client.zip_code : ""}
                                            />
                                        </Col>
                                    </Row>
                                    <Nav tabs className="mt-5">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "general",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("general");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {" "}
                                                    {props.t("General")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "commercial",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("commercial");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {" "}
                                                    {props.t("Commercial")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "special_prices",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("special_prices");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Special Prices")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "bank_account",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("bank_account");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {" "}
                                                    {props.t("Bank Account")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "contacts",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("contacts");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {" "}
                                                    {props.t("Contacts")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "other_addresses",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("other_addresses");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Other Addresses")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "attachments",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("attachments");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {" "}
                                                    {props.t("Attachments")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "balance",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("balance");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {" "}
                                                    {props.t("Balance")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "related",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("related");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {" "}
                                                    {props.t("Related")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="py-3">
                                        <TabPane tabId="general">
                                            <Row>
                                                <Col lg="6">
                                                    <AvField
                                                        className="mb-3"
                                                        label={props.t("Fax")}
                                                        type="text"
                                                        readOnly={isEditable ? false : true}
                                                        name="fax"
                                                        value={client.fax !== null ? client.fax : ""}
                                                    />
                                                    <AvField
                                                        className="mb-3"
                                                        label={props.t("Website")}
                                                        type="text"
                                                        readOnly={isEditable ? false : true}
                                                        name="website"
                                                        value={
                                                            client.website !== null ? client.website : ""
                                                        }
                                                        placeholder={props.t("web format")}
                                                    />
                                                </Col>
                                                <Col lg="6">
                                                    <label> {props.t("Supplier Category")} </label>
                                                    <div className="d-flex justify-content-between mb-3">
                                                        <div className="w-100 pe-2">
                                                            <AvField
                                                                type="select"
                                                                name="supplier_category"
                                                                value={
                                                                    client.supplier_category !== null
                                                                        ? client.supplier_category
                                                                        : ""
                                                                }
                                                                disabled={isEditable ? false : true}
                                                            >
                                                                {allCategories &&
                                                                    allCategories.map((category, i) => (
                                                                        <option key={i} value={category.id}>
                                                                            {category.name}
                                                                        </option>
                                                                    ))}
                                                            </AvField>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setModal(true);
                                                            }}
                                                            className="btn btn-primary "
                                                            data-toggle="modal"
                                                            data-target=".bs-example-modal-lg"
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            {props.t("New")}
                                                        </button>
                                                    </div>
                                                    <AvField
                                                        className="mb-3"
                                                        label={props.t("Created from")}
                                                        type="text"
                                                        readOnly={isEditable ? false : true}
                                                        name="created_from"
                                                        value={
                                                            client.created_from !== null
                                                                ? client.created_from
                                                                : ""
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <AvField
                                                        className="mb-3"
                                                        label={props.t("Comments")}
                                                        type="textarea"
                                                        name="comments"
                                                        readOnly={isEditable ? false : true}
                                                        value={
                                                            client.comments !== null ? client.comments : ""
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="commercial">
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <label> {props.t("Payment Option")}</label>
                                                        <div className="d-flex justify-content-between">
                                                            {paymentLoader ? (
                                                                <div className="pe-2">
                                                                    <Spinner
                                                                        animation="border"
                                                                        role="status"
                                                                    ></Spinner>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div className="w-100 pe-2">
                                                                <AvField
                                                                    type="select"
                                                                    name="payment_option_id"
                                                                    disabled={isEditable ? false : true}
                                                                    // isDisabled={paymentLoader ? true : false}
                                                                    value={
                                                                        client.payment_option_id !== null
                                                                            ? client.payment_option_id
                                                                            : ""
                                                                    }
                                                                >
                                                                    <option value="" hidden>
                                                                        {props.t("Select")}
                                                                    </option>
                                                                    {paymentData &&
                                                                        paymentData.map((item, i) => (
                                                                            <option value={item.id} key={i}>
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                </AvField>
                                                            </div>
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                onClick={() => setIsOpenModalNew(true)}
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                }}
                                                                type="button"
                                                                disabled={isEditable ? false : true}
                                                            >
                                                                + {props.t("New")}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="payment_date"
                                                            value={
                                                                client.payment_date !== null
                                                                    ? client.payment_date
                                                                    : ""
                                                            }
                                                            disabled={isEditable ? "" : true}
                                                            label={props.t("Payment Date")}
                                                        >
                                                            <option value="">
                                                                {" "}
                                                                {props.t("Select a Date")}...
                                                            </option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                            <option value="24">24</option>
                                                            <option value="25">25</option>
                                                            <option value="26">26</option>
                                                            <option value="27">27</option>
                                                            <option value="28">28</option>
                                                            <option value="29">29</option>
                                                            <option value="30">30</option>
                                                            <option value="31">31</option>
                                                        </AvField>
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="mb-3"
                                                            label={props.t("Discount (%)")}
                                                            type="text"
                                                            readOnly={isEditable ? false : true}
                                                            name="discount"
                                                            value={
                                                                client.discount !== null ? client.discount : ""
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="currency"
                                                            value={
                                                                client.currency !== null ? client.currency : ""
                                                            }
                                                            disabled={isEditable ? "" : true}
                                                            label={props.t("Currency")}
                                                        >
                                                            {cc.data.map((currency, i) => (
                                                                <option key={i} value={currency.code}>
                                                                    {currency.code} - {currency.currency}
                                                                </option>
                                                            ))}
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Payment Term")}
                                                            type="select"
                                                            value={
                                                                client?.payment_terms_id !== null
                                                                    ? client?.payment_terms_id
                                                                    : ""
                                                            }
                                                            name="payment_terms_id"
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            {paymentTerm &&
                                                                paymentTerm.map((item, i) => (
                                                                    <option value={item.id} key={i}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </AvField>
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="payment_adjustment"
                                                            value={
                                                                client.payment_adjustment !== null
                                                                    ? client.payment_adjustment
                                                                    : ""
                                                            }
                                                            disabled={isEditable ? "" : true}
                                                            label={props.t("Payment Adjustment")}
                                                        >
                                                            <option value="unspecified">
                                                                {" "}
                                                                {props.t("Unspecified")}
                                                            </option>
                                                            <option value="previous_than">
                                                                {props.t("Previous than")}
                                                            </option>
                                                            <option value="later_than">
                                                                {" "}
                                                                {props.t("Later than")}
                                                            </option>
                                                            <option value="closest_to">Closest to</option>
                                                        </AvField>
                                                    </div>
                                                    <AvField
                                                        className="mb-3"
                                                        label="Agent"
                                                        type="text"
                                                        readOnly={isEditable ? false : true}
                                                        name="agent"
                                                        value={client.agent !== null ? client.agent : ""}
                                                    />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="special_prices">
                                            {client !== "" ? <SpecialPrice client={client} /> : ""}
                                        </TabPane>
                                        <TabPane tabId="bank_account">
                                            <Row>
                                                <Col md={6}>
                                                    <h5> {props.t("Bank Accounts")}</h5>
                                                </Col>
                                                <Col>
                                                    <button
                                                        className="btn btn-primary float-end"
                                                        type="button"
                                                        onClick={() => setIsBankOpenModal(true)}
                                                    >
                                                        {props.t("New")}
                                                    </button>
                                                </Col>
                                                <div className="table-responsive">
                                                    <Table striped className="align-middle">
                                                        <thead>
                                                            <tr>
                                                                <th> {props.t("Name")}</th>
                                                                <th> {props.t("Description")}</th>
                                                                <th> {props.t("Account")}</th>
                                                                <th> {props.t("ABA/SWIFT")}</th>
                                                                <th> {props.t("By default")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allBanks &&
                                                                allBanks.map((item, i) => (
                                                                    <SingleList
                                                                        data={item}
                                                                        key={i}
                                                                        setIsBankOpenModal={setIsBankOpenModal}
                                                                    />
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="contacts">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setIsOpenContact(true);
                                                }}
                                                className=" float-end d-flex btn btn-primary mb-3"
                                                data-toggle="modal"
                                                data-target=".bs-example-modal-lg"
                                            >
                                                {props.t("New")}
                                            </button>
                                            {contactLoader ? (
                                                <Loader />
                                            ) : (
                                                <div className="table-responsive">
                                                    <Table striped className="align-middle mt-5">
                                                        <thead>
                                                            <tr>
                                                                <th> {props.t("Name")}</th>
                                                                <th>{props.t("Phone")}</th>
                                                                <th>{props.t("Email")}</th>
                                                                <th>{props.t("Fax")}</th>
                                                                <th>{props.t("Position")}</th>
                                                                <th>{props.t("Comments")}</th>
                                                                <th>{props.t("Delete")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {contactsAll &&
                                                                contactsAll.map((item, i) => (
                                                                    <ListContact data={item} key={i} />
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            )}
                                        </TabPane>
                                        <TabPane tabId="other_addresses">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setIsOpenAddress(true);
                                                }}
                                                className="float-end d-flex btn btn-primary mb-3"
                                                data-toggle="modal"
                                                data-target=".bs-example-modal-lg"
                                            >
                                                {props.t("New")}
                                            </button>
                                            {addressLoader ? (
                                                <Loader />
                                            ) : (
                                                <div className="table-responsive mt-3">
                                                    <Table className="align-middle">
                                                        <thead>
                                                            <tr>
                                                                <th>{props.t("Address")}</th>
                                                                <th>{props.t("City/Town")}</th>
                                                                <th>{props.t("State")}</th>
                                                                <th>{props.t("Zip/Postal Code")}</th>
                                                                <th>{props.t("Country")}</th>
                                                                <th>{props.t("Extra Info")}</th>
                                                                <th>{props.t("Description")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allAddress &&
                                                                allAddress.map((item, i) => (
                                                                    <ListAddress data={item} key={i} />
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            )}
                                        </TabPane>
                                        <TabPane tabId="attachments">
                                            <Row>
                                                <Col md={12}>
                                                    <div className="attach_btn">
                                                        <div
                                                            className="float-end d-flex btn btn-primary ms-2"
                                                            onClick={() => setIsOpenModals(true)}
                                                        >
                                                            {props.t("New")}
                                                        </div>
                                                        <div
                                                            className="float-end d-flex btn btn-primary"
                                                            onClick={() => getSendAttachment()}
                                                        >
                                                            {props.t("Send via email")}
                                                        </div>
                                                        {error2 ? (
                                                            <Alert color="danger">{props.t(error2)}</Alert>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div>
                                                            <Table striped className="align-middle mt-5">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>{props.t("Name")}</th>
                                                                        <th>{props.t("Description")}</th>
                                                                        <th>{props.t("Upload Date")}</th>
                                                                        <th>{props.t("Activity")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {attachDocument &&
                                                                        attachDocument.map((item, i) => (
                                                                            <AllAttachment
                                                                                Attached={item}
                                                                                user={userId}
                                                                                key={i}
                                                                                type="supplier"
                                                                                selectedIds={selectedIds}
                                                                                setSelectedIds={setSelectedIds}
                                                                                selectedName={selectedName}
                                                                                setSelectedName={setSelectedName}
                                                                            />
                                                                        ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="balance">
                                            <div
                                                className="bg-secondary"
                                                style={{
                                                    width: "400px",
                                                    padding: "10px 10px 10px 90px ",
                                                    marginLeft: "60px",
                                                }}
                                            >
                                                <table className="table m-0">
                                                    <thead>
                                                        <tr>
                                                            <td> {props.t("Unpaid Invoices")}:</td>
                                                            <td>{balance.unpaid_purchase}</td>
                                                        </tr>
                                                        <tr>
                                                            <td> {props.t("Available Balance")}:</td>
                                                            <td>{balance.available_balance}</td>
                                                        </tr>
                                                        <tr>
                                                            <td> {props.t("Total Balance")}:</td>
                                                            <td>{balance.total_balance}</td>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <Nav tabs className="mt-4">
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={`${classnames({
                                                            active: customActiveTabOne === "1",
                                                        })}`}
                                                        onClick={() => {
                                                            toggleCustomInner("1");
                                                        }}
                                                    >
                                                        {props.t("Unpaid Invoices")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={`${classnames({
                                                            active: customActiveTabOne === "2",
                                                        })}`}
                                                        onClick={() => {
                                                            toggleCustomInner("2");
                                                        }}
                                                    >
                                                        {props.t("Account Transactions")}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent
                                                activeTab={customActiveTabOne}
                                                className="py-3"
                                            >
                                                <TabPane tabId="1">
                                                    <button className="btn btn-primary mb-3">
                                                        {props.t("Export")}
                                                    </button>
                                                    <div className="table-responsive">
                                                        <Table striped className="align-middle">
                                                            <thead>
                                                                <tr>
                                                                    <th>{props.t("Reference")}</th>
                                                                    <th>{props.t("Title")}</th>
                                                                    <th>{props.t("Created by")}</th>
                                                                    <th>{props.t("Status")}</th>
                                                                    <th>{props.t("Date")}</th>
                                                                    <th>{props.t("Amount")}</th>
                                                                    <th>{props.t("Unpaid")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {unpaidData &&
                                                                    unpaidData.map((item, i) => (
                                                                        <UnpaidPurchaseInvoice
                                                                            data={item}
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <Col>
                                                        <button
                                                            className="btn btn-primary mb-3"
                                                            type="button"
                                                            onClick={() => setIsDepositOpenModal(true)}
                                                        >
                                                            {props.t("Deposit")}
                                                        </button>
                                                        <button
                                                            className="btn btn-primary ms-2 mb-3"
                                                            type="button"
                                                            onClick={() => setIsWithdrawOpenModal(true)}
                                                        >
                                                            {props.t("Withdraw")}
                                                        </button>
                                                        <button className="btn btn-primary ms-2 mb-3">
                                                            {props.t("Export")}
                                                        </button>
                                                    </Col>
                                                    {error3 ? <Alert color="danger">{props.t(error3)}</Alert> : ""}
                                                    <div className="table-responsive">
                                                        <Table striped className="align-middle">
                                                            <thead>
                                                                <tr>
                                                                    {/* <th></th> */}
                                                                    <th>{props.t("Concept")}</th>
                                                                    <th>{props.t("Payment Option")}</th>
                                                                    <th>{props.t("Paid by")}</th>
                                                                    <th>{props.t("Amount")}</th>
                                                                    <th>{props.t("Date")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {transData &&
                                                                    transData.map((item, i) => (
                                                                        <TransactionList
                                                                            data={item}
                                                                            key={i}
                                                                            selectedIds={selectedIds}
                                                                            setSelectedIds={setSelectedIds}
                                                                        />
                                                                    ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    {/* <ProductPurchasePrice
                              product={product}
                              type="product"
                            /> */}
                                                </TabPane>
                                            </TabContent>
                                        </TabPane>
                                        <TabPane tabId="related">
                                            <div className="table-responsive">
                                                <Table striped className="align-middle mt-5">
                                                    <thead>
                                                        <tr>
                                                            <th>{props.t("Reference")}</th>
                                                            <th>{props.t("Title")}</th>
                                                            <th>{props.t("Created By")}</th>
                                                            <th>{props.t("Status")}</th>
                                                            <th>{props.t("Date")}</th>
                                                            <th>{props.t("Amount")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {related &&
                                                            related.map((item, i) => (
                                                                <tr
                                                                    onClick={() =>
                                                                        getRelated(item.reference_type, item.id)
                                                                    }
                                                                    key={i}
                                                                >
                                                                    <td>
                                                                        {item.reference}
                                                                        {item.reference_number}
                                                                    </td>
                                                                    <td>{item.title}</td>
                                                                    <td>{getCurrentUser().email}</td>
                                                                    <td>{item.status}</td>
                                                                    <td>{item.date}</td>
                                                                    <td>{item.amount}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>

                    <Modal size="lg" isOpen={isOpenModal} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Assets")}
                            </h5>
                            <button
                                type="button"
                                className="cross__btn btn btn-primary waves-effect waves-light"
                                onClick={() => setIsOpenModal(false)}
                                aria-hidden="true"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <CreateAssestsSection
                                allData={client}
                                setIsOpenModal={setIsOpenModal}
                                isOpenModal={isOpenModal}
                                clientData={client}
                                workData={client}
                                modalData={isOpenModal}
                                setassetModal={setIsOpenModal}
                            // AssetsData={AssetsData}
                            // clientNameData={clientNameData}
                            />
                        </div>
                    </Modal>
                    <Modal size="lg" isOpen={isOpenModals} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Document")}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => createAttachment(e)}>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Document")}</label>
                                            <input
                                                type="file"
                                                className="ms-3"
                                                name="document"
                                                onChange={filePreview}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Description")}</label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="modal-footer">
                                    <div className="text-end">
                                        <button className="btn btn-primary" type="submit">
                                            {props.t("Submit")}
                                        </button>
                                        <button
                                            className="btn btn-primary ms-2"
                                            type="button"
                                            onClick={() => setIsOpenModals(false)}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                    <Modal size="lg" isOpen={isOpenContact} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Contact")}
                            </h5>
                            <button
                                type="button"
                                className="cross__btn btn btn-primary waves-effect waves-light"
                                onClick={() => setIsOpenContact(false)}
                                aria-hidden="true"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                            <AvForm
                                onValidSubmit={(e, v) => {
                                    createContact(e, v);
                                }}
                            >
                                <Row>
                                    <Col lg={6}>
                                        <AvField
                                            className="mb-3"
                                            label={props.t("Name")}
                                            type="text"
                                            name="name"
                                        />
                                        <AvField
                                            className="mb-3"
                                            label={props.t("Phone")}
                                            type="text"
                                            name="phone"
                                        />
                                        <AvField
                                            className="mb-3"
                                            label={props.t("Email")}
                                            type="email"
                                            name="email"
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            className="mb-3"
                                            label={props.t("Fax")}
                                            type="text"
                                            name="fax"
                                        />
                                        <AvField
                                            className="mb-3"
                                            label={props.t("Position")}
                                            type="text"
                                            name="position"
                                        />
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3 ">
                                            <AvField
                                                className="mb-3"
                                                label={props.t("Comments")}
                                                type="textarea"
                                                name="comments"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="modal-footer">
                                    <div className="text-end">
                                        {contactLoading ? (
                                            <button
                                                className="btn btn-primary  disabled"
                                                type="button"
                                                disabled
                                            >
                                                {props.t("Loading")}
                                            </button>
                                        ) : (
                                            <button className="btn btn-primary " type="submit">
                                                {props.t("Save")}
                                            </button>
                                        )}
                                        <button
                                            className="btn btn-primary ms-2"
                                            type="button"
                                            onClick={() => setIsOpenContact(false)}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </AvForm>
                        </div>
                    </Modal>
                    <Modal size="lg" isOpen={isOpenAddress} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("Other Addresses")}
                            </h5>
                            <button
                                type="button"
                                className="cross__btn btn btn-primary waves-effect waves-light"
                                onClick={() => setIsOpenAddress(false)}
                                aria-hidden="true"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <AvForm
                                onValidSubmit={(e, v) => {
                                    createOtherAddress(e, v);
                                }}
                            >
                                <Row>
                                    <Col lg={6}>
                                        <AvField
                                            className="mb-3"
                                            label={props.t("Address")}
                                            type="text"
                                            name="address"
                                        />
                                        <AvField
                                            className="mb-3"
                                            label={props.t("State/Province")}
                                            type="text"
                                            name="state"
                                        />
                                        <div className="mb-3">
                                            <AvField type="select" name="country" label="Country">
                                                <option value="">Select</option>
                                                {countryList().data.map((item, i) => (
                                                    <option key={i} value={item.label}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </AvField>
                                        </div>
                                        <AvField
                                            className="mb-3"
                                            label={props.t("Type")}
                                            type="select"
                                            name="type"
                                        >
                                            <option value="invoicing">{props.t("Invoicing")}</option>
                                            <option value="delivery">
                                                {props.t("Delivery/Work")}
                                            </option>
                                            <option value="other">{props.t("Other")}</option>
                                        </AvField>
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            className="mb-3"
                                            label={props.t("City/town")}
                                            type="text"
                                            name="city"
                                        />
                                        <AvField
                                            className="mb-3"
                                            label={props.t("Zip code")}
                                            type="text"
                                            name="zip_code"
                                        />
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3 mt-3">
                                            <AvField
                                                label={props.t("Extra Info.")}
                                                type="text"
                                                name="extra_information"
                                            />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <AvField
                                                label={props.t("Description")}
                                                type="textarea"
                                                name="description"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="modal-footer">
                                    <div className="text-end">
                                        {loading ? (
                                            <button
                                                className="btn btn-primary  disabled"
                                                type="button"
                                                disabled
                                            >
                                                {props.t("Loading")}
                                            </button>
                                        ) : (
                                            <button className="btn btn-primary " type="submit">
                                                {props.t("Save")}
                                            </button>
                                        )}
                                        <button
                                            className="btn btn-primary ms-2"
                                            type="button"
                                            onClick={() => setIsOpenAddress(false)}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </AvForm>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(SingleSupplier);
SingleSupplier.propTypes = {
    t: PropTypes.any,
};
