import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import del from "../../assets/images/delete.png";
import ExpList from "../Pro&SerList/expList";
import DropdownList from "react-widgets/DropdownList";
import "react-widgets/styles.css";
import NameCustomDropdownEai from "./Updateproduct/nameDropdownEai";
import { parseInt } from "lodash";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

const ProductTechEstimates = (props) => {
    const [remove, setRemove] = useState(props.i);
    const [product, setProduct] = useState(props.data);
    const [desc, setDesc] = useState(props.data.description);
    const [basePrice, setBasePrice] = useState(props.data.base_price);
    const [discount, setDiscount] = useState(props.data.discount);
    const [quan, setQuan] = useState(props.data.quantity);
    const [amount, setAmount] = useState(props.data.amount);
    const [dataProduct, setDataProduct] = useState([]);
    const [dataService, setDataService] = useState([]);
    const [dataExpenses, setDataExpenses] = useState([]);
    const [dataTax, setDataTax] = useState([]);
    const [dataIncomeTax, setDataIncomeTax] = useState([]);
    const [defaultTax, setDefaultTax] = useState("");
    const [defaultIncomeTax, setDefaultIncomeTax] = useState("0");
    const [isDisabled, setIsDisabled] = useState(props.isEditable);
    const [myAmount, setMyAmount] = useState(0.0);
    const [selectItemModal, setSelectItemModal] = useState(false);
    const [focus, setFocus] = useState(false);
    const [timer, setTimer] = useState(null);
    const [error, setError] = useState("");
    const [clientLoader, setClientLoader] = useState(false);
    const [productNameData, setProductNameData] = useState(props.data.name);
    const [confirmModal, setConfirmModal] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(null);

    const getProductData = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getProduct(e);
            getServices(e);
            getExpenses(e);
        }, 2000);
        setTimer(newTimer);
    };
    //   const getServiceData = (e) => {
    //     clearTimeout(timer);
    //     const newTimer = setTimeout(() => {
    //       getServices(e);
    //     }, 1000);
    //     setTimer(newTimer);
    //   };

    const getProduct = (e) => {
        setDataProduct([]);
        if (e !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/products?search=${e}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    //   setLoader("");
                    if (res.data.status) {
                        // setError("");
                        setDataProduct(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/products`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    //   setLoader("");
                    if (res.data.status) {
                        // setError("");
                        setDataProduct(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        }
    };

    const getServices = (e) => {
        setDataService([]);
        if (e !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/services?search=${e}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    //   setLoader("");
                    if (res.data.status) {
                        setError("");
                        setDataService(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/services`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    //   setLoader("");
                    if (res.data.status) {
                        setError("");
                        setDataService(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        }
    };

    const getExpenses = (e) => {
        setDataExpenses([]);
        if (e !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/expense_investments?search=${e}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setError("");
                        setDataExpenses(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/expense_investments`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setError("");
                        setDataExpenses(res.data.products);
                    } else {
                        setError(res.data.message);
                    }
                });
        }
    };

    const getTaxes = () => {
        setDataTax([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/cosumption-taxes`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setDataTax(res.data.taxes);
                    res.data.taxes?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultTax(item.tax);
                        }
                    });
                }
            });
    };

    const getIncomeTax = () => {
        setDataTax([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/income-taxes`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setDataIncomeTax(res.data.taxes);
                    res.data.taxes?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultIncomeTax(item.tax);
                        }
                    });
                }
            });
    };

    const selectProduct = (e, i, type) => {
        if (type == "SER") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/services/${e}?supplier_id=${props.clientId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setProductNameData(res.data.service.name);
                        let formValues = props.fields.formValues;
                        formValues[i]["reference"] = res.data.service.reference;
                        formValues[i]["reference_id"] = res.data.service.id;
                        formValues[i]["reference_number"] =
                            res.data.service.reference_number;
                        formValues[i]["name"] = res.data.service.name;
                        formValues[i]["description"] =
                            res.data.service.description !== null
                                ? res.data.service.description
                                : "";
                        formValues[i]["base_price"] = res.data.service.purchase_price;
                        formValues[i]["quantity"] = 1;
                        formValues[i]["discount"] = 0;
                        formValues[i]["amount"] = res.data.service.purchase_price;
                        formValues[i]["vat"] =
                            res.data.service.tax !== null ? res.data.service.tax : 0;
                        formValues[i]["tax"] = defaultIncomeTax;
                        formValues[i]["subtotal"] =
                            parseFloat(res.data.service.purchase_price) +
                            (parseFloat(res.data.service.purchase_price) *
                                (parseInt(
                                    res.data.service.tax !== null ? res.data.service.tax : 0
                                ) +
                                    parseInt(defaultIncomeTax))) /
                            100;
                        formValues[i]["taxAmount"] =
                            parseFloat(res.data.service.purchase_price) +
                            (parseFloat(res.data.service.purchase_price) *
                                parseInt(
                                    res.data.service.tax !== null ? res.data.service.tax : 0
                                )) /
                            100 -
                            res.data.service.purchase_price;
                        formValues[i]["otherTaxAmount"] =
                            parseFloat(res.data.service.purchase_price) +
                            (parseFloat(res.data.service.purchase_price) *
                                parseInt(parseInt(defaultIncomeTax))) /
                            100 -
                            res.data.service.purchase_price;
                        // formValues[i]["discAmount"] =
                        //   parseFloat(res.data.service.purchase_price) -
                        //   (res.data.service.purchase_price -
                        //     (res.data.service.purchase_price * res.data.service.discount) / 100) *
                        //     1;
                        props.setFields({ formValues });
                        props.setMainFormDisabled(false);
                        // props.metaDiscount();
                    }
                });
        } else if (type == "EAI") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/expense_investments/${e}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setProductNameData(res.data.expense_and_investment.name);
                        let formValues = props.fields.formValues;
                        formValues[i]["reference"] =
                            res.data.expense_and_investment.reference;
                        formValues[i]["reference_id"] = res.data.expense_and_investment.id;
                        formValues[i]["reference_number"] =
                            res.data.expense_and_investment.reference_number;
                        formValues[i]["name"] = res.data.expense_and_investment.name;
                        formValues[i]["description"] =
                            res.data.expense_and_investment.description;
                        formValues[i]["base_price"] =
                            res.data.expense_and_investment.price;
                        formValues[i]["quantity"] = 1;
                        formValues[i]["discount"] = 0;
                        formValues[i]["amount"] =
                            res.data.expense_and_investment.price;
                        formValues[i]["vat"] =
                            res.data.expense_and_investment.tax !== null
                                ? res.data.expense_and_investment.tax
                                : 0;
                        formValues[i]["tax"] = defaultIncomeTax;
                        formValues[i]["subtotal"] =
                            parseInt(res.data.expense_and_investment.price) +
                            (parseInt(res.data.expense_and_investment.price) *
                                (parseInt(
                                    res.data.expense_and_investment.tax !== null
                                        ? res.data.expense_and_investment.tax
                                        : 0
                                ) +
                                    parseInt(defaultIncomeTax))) /
                            100;
                        formValues[i]["taxAmount"] =
                            parseInt(res.data.expense_and_investment.price) +
                            (parseInt(res.data.expense_and_investment.price) *
                                parseInt(
                                    res.data.expense_and_investment.tax !== null
                                        ? res.data.expense_and_investment.tax
                                        : 0
                                )) /
                            100 -
                            parseInt(res.data.expense_and_investment.price);
                        formValues[i]["otherTaxAmount"] =
                            parseInt(res.data.expense_and_investment.price) +
                            (parseInt(res.data.expense_and_investment.price) *
                                parseInt(defaultIncomeTax)) /
                            100 -
                            parseInt(res.data.expense_and_investment.price);
                        // formValues[i]["discAmount"] =
                        //   parseFloat(res.data.expense_and_investment.price) -
                        //   (res.data.expense_and_investment.price -
                        //     (res.data.expense_and_investment.price *
                        //       res.data.expense_and_investment.discount) /
                        //       100) *
                        //     1;
                        props.setFields({ formValues });
                        // props.metaDiscount();
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/products/${e}?supplier_id=${props.clientId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setProductNameData(res.data.product.name);
                        let formValues = props.fields.formValues;
                        formValues[i]["reference"] = res.data.product.reference;
                        formValues[i]["reference_id"] = res.data.product.id;
                        formValues[i]["reference_number"] =
                            res.data.product.reference_number;
                        formValues[i]["name"] = res.data.product.name;
                        formValues[i]["description"] =
                            res.data.product.description !== null
                                ? res.data.product.description
                                : "";
                        formValues[i]["base_price"] = res.data.product.purchase_price;
                        formValues[i]["quantity"] = 1;
                        formValues[i]["discount"] = 0;
                        formValues[i]["amount"] = res.data.product.purchase_price;
                        formValues[i]["vat"] =
                            res.data.product.tax !== null ? res.data.product.tax : 0;
                        formValues[i]["tax"] = defaultIncomeTax;
                        formValues[i]["subtotal"] =
                            res.data.product.purchase_price +
                            (res.data.product.purchase_price *
                                (parseInt(
                                    res.data.product.tax !== null ? res.data.product.tax : 0
                                ) +
                                    parseInt(defaultIncomeTax))) /
                            100;
                        formValues[i]["taxAmount"] =
                            res.data.product.purchase_price +
                            (res.data.product.purchase_price *
                                parseInt(
                                    res.data.product.tax !== null ? res.data.product.tax : 0
                                )) /
                            100 -
                            res.data.product.purchase_price;
                        formValues[i]["otherTaxAmount"] =
                            res.data.product.purchase_price +
                            (res.data.product.purchase_price *
                                parseInt(parseInt(defaultIncomeTax))) /
                            100 -
                            res.data.product.purchase_price;
                        // formValues[i]["discAmount"] =
                        //   parseFloat(res.data.product.purchase_price) -
                        //   (res.data.product.purchase_price -
                        //     (res.data.product.purchase_price * res.data.product.discount) / 100) *
                        //     1;
                        props.setFields({ formValues });
                        props.setMainFormDisabled(false);
                        // props.metaDiscount();
                    }
                });
        }
    };
    let productOptions = [
        { id: "0", name: 'Add Product', reference: "Product" },
        { id: "0", name: 'Add Service', reference: "Service" },
        { id: "0", name: 'Add EAI', reference: "Expense and Investment" },
    ];
    if (dataProduct !== undefined) {
        dataProduct &&
            dataProduct.map((item) => {
                productOptions.push({
                    id: item.id,
                    name: `${item.reference}${item.reference_number}`,
                    reference: item.reference,
                });
            });
    }

    if (dataService !== undefined) {
        dataService &&
            dataService.map((item) => {
                productOptions.push({
                    id: item.id,
                    name: `${item.reference}${item.reference_number}`,
                    reference: item.reference,
                });
            });
    }

    if (dataExpenses !== undefined) {
        dataExpenses &&
            dataExpenses.map((item) => {
                productOptions.push({
                    id: item.id,
                    name: `${item.reference}${item.reference_number}`,
                    reference: item.reference,
                });
            });
    }

    const selectProduct2 = (e, i) => {
        if (e.reference == "SER") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/services/${e.id}?supplier_id=${props.clientId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setProductNameData(res.data.service.name);
                        let formValues = props.fields.formValues;
                        formValues[i]["reference"] = res.data.service.reference;
                        formValues[i]["reference_id"] = res.data.service.id;
                        formValues[i]["reference_number"] =
                            res.data.service.reference_number;
                        formValues[i]["name"] = res.data.service.name;
                        formValues[i]["description"] =
                            res.data.service.description !== null
                                ? res.data.service.description
                                : "";
                        formValues[i]["base_price"] = res.data.service.purchase_price;
                        formValues[i]["quantity"] = 1;
                        formValues[i]["discount"] = 0;
                        formValues[i]["amount"] = res.data.service.purchase_price;
                        formValues[i]["vat"] =
                            res.data.service.tax !== null ? res.data.service.tax : 0;
                        formValues[i]["tax"] = defaultIncomeTax;
                        formValues[i]["subtotal"] =
                            parseFloat(res.data.service.purchase_price) +
                            (parseFloat(res.data.service.purchase_price) *
                                (parseInt(
                                    res.data.service.tax !== null ? res.data.service.tax : 0
                                ) +
                                    parseInt(defaultIncomeTax))) /
                            100;
                        formValues[i]["taxAmount"] =
                            parseFloat(res.data.service.purchase_price) +
                            (parseFloat(res.data.service.purchase_price) *
                                parseInt(
                                    res.data.service.tax !== null ? res.data.service.tax : 0
                                )) /
                            100 -
                            res.data.service.purchase_price;
                        formValues[i]["otherTaxAmount"] =
                            parseFloat(res.data.service.purchase_price) +
                            (parseFloat(res.data.service.purchase_price) *
                                parseInt(parseInt(defaultIncomeTax))) /
                            100 -
                            res.data.service.purchase_price;
                        // formValues[i]["discAmount"] =
                        //   parseFloat(res.data.service.purchase_price) -
                        //   (res.data.service.purchase_price -
                        //     (res.data.service.purchase_price * res.data.service.discount) / 100) *
                        //     1;
                        props.setFields({ formValues });
                        // props.metaDiscount();
                    }
                });
        } else if (e.reference == "EAI") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/expense_investments/${e.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setProductNameData(res.data.expense_and_investment.name);
                        let formValues = props.fields.formValues;
                        formValues[i]["reference"] =
                            res.data.expense_and_investment.reference;
                        formValues[i]["reference_id"] = res.data.expense_and_investment.id;
                        formValues[i]["reference_number"] =
                            res.data.expense_and_investment.reference_number;
                        formValues[i]["name"] = res.data.expense_and_investment.name;
                        formValues[i]["description"] =
                            res.data.expense_and_investment.description;
                        formValues[i]["base_price"] =
                            res.data.expense_and_investment.price;
                        formValues[i]["quantity"] = 1;
                        formValues[i]["discount"] = 0;
                        formValues[i]["amount"] =
                            res.data.expense_and_investment.price;
                        formValues[i]["vat"] =
                            res.data.expense_and_investment.tax !== null
                                ? res.data.expense_and_investment.tax
                                : 0;
                        formValues[i]["tax"] = defaultIncomeTax;
                        formValues[i]["subtotal"] =
                            parseFloat(res.data.expense_and_investment.price) +
                            (parseFloat(res.data.expense_and_investment.price) *
                                (parseInt(
                                    res.data.expense_and_investment.tax !== null
                                        ? res.data.expense_and_investment.tax
                                        : 0
                                ) +
                                    parseInt(defaultIncomeTax))) /
                            100;
                        formValues[i]["taxAmount"] =
                            parseFloat(res.data.expense_and_investment.price) +
                            (parseFloat(res.data.expense_and_investment.price) *
                                parseInt(
                                    res.data.expense_and_investment.tax !== null
                                        ? res.data.expense_and_investment.tax
                                        : 0
                                )) /
                            100 -
                            parseInt(res.data.expense_and_investment.price);
                        formValues[i]["otherTaxAmount"] =
                            parseFloat(res.data.expense_and_investment.price) +
                            (parseFloat(res.data.expense_and_investment.price) *
                                parseInt(defaultIncomeTax)) /
                            100 -
                            parseInt(res.data.expense_and_investment.price);
                        // formValues[i]["discAmount"] =
                        //   parseFloat(res.data.expense_and_investment.price) -
                        //   (res.data.expense_and_investment.price -
                        //     (res.data.expense_and_investment.price *
                        //       res.data.expense_and_investment.discount) /
                        //       100) *
                        //     1;
                        props.setFields({ formValues });
                        // props.metaDiscount();
                    }
                });
        } else if (e.reference == "PRO") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/products/${e.id}?supplier_id=${props.clientId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    if (res.data.status) {
                        setProductNameData(res.data.product.name);
                        let formValues = props.fields.formValues;
                        formValues[i]["reference"] = res.data.product.reference;
                        formValues[i]["reference_id"] = res.data.product.id;
                        formValues[i]["reference_number"] =
                            res.data.product.reference_number;
                        formValues[i]["name"] = res.data.product.name;
                        formValues[i]["description"] =
                            res.data.product.description !== null
                                ? res.data.product.description
                                : "";
                        formValues[i]["base_price"] = res.data.product.purchase_price;
                        formValues[i]["quantity"] = 1;
                        formValues[i]["discount"] = 0;
                        formValues[i]["amount"] = res.data.product.purchase_price;
                        formValues[i]["vat"] =
                            res.data.product.tax !== null ? res.data.product.tax : 0;
                        formValues[i]["tax"] = defaultIncomeTax;
                        formValues[i]["subtotal"] =
                            parseFloat(res.data.product.purchase_price) +
                            (parseFloat(res.data.product.purchase_price) *
                                (parseInt(
                                    res.data.product.tax !== null ? res.data.product.tax : 0
                                ) +
                                    parseInt(defaultIncomeTax))) /
                            100;
                        formValues[i]["taxAmount"] =
                            parseFloat(res.data.product.purchase_price) +
                            (parseFloat(res.data.product.purchase_price) *
                                parseInt(
                                    res.data.product.tax !== null ? res.data.product.tax : 0
                                )) /
                            100 -
                            res.data.product.purchase_price;
                        formValues[i]["otherTaxAmount"] =
                            parseFloat(res.data.product.purchase_price) +
                            (parseFloat(res.data.product.purchase_price) *
                                parseInt(parseInt(defaultIncomeTax))) /
                            100 -
                            res.data.product.purchase_price;
                        // formValues[i]["discAmount"] =
                        //   parseFloat(res.data.product.purchase_price) -
                        //   (res.data.product.purchase_price -
                        //     (res.data.product.purchase_price * res.data.product.discount) / 100) *
                        //     1;
                        props.setFields({ formValues });
                        // props.metaDiscount();
                    }
                });
        } else {
            let formValues = props.fields.formValues;
            formValues[i]["reference"] = e.reference
            props.setFields({ formValues });
        }
    };

    const removeFields = (e, i) => {
        if (e.length < 1) {
            setProductNameData("");
            let formValues = props.fields.formValues;
            formValues[i]["reference"] = "";
            formValues[i]["reference_id"] = "";
            formValues[i]["reference_number"] = "";
            formValues[i]["name"] = "";
            formValues[i]["description"] = "";
            formValues[i]["base_price"] = "";
            formValues[i]["quantity"] = "";
            formValues[i]["discount"] = "";
            formValues[i]["amount"] = "0";
            formValues[i]["vat"] = "0";
            formValues[i]["tax"] = "0";
            formValues[i]["subtotal"] = "0";
            formValues[i]["taxAmount"] = "0";
            formValues[i]["otherTaxAmount"] = "0";
            formValues[i]["discAmount"] = "0";
            props.setFields({ formValues });
        } else {
            let formValues = props.fields.formValues;
            formValues[i]["name"] = e;
            props.setFields({ formValues });
        }
    };

    useEffect(() => {
        getProduct();
        getServices();
        getExpenses();
        getTaxes();
        getIncomeTax();
    }, []);

    const removeFormFields = (i) => {
        let formValues = props.fields.formValues;
        // setLoader(<Loader />)
        props.setFields({ formValues: [] });
        formValues.splice(i, 1);
        setTimeout(() => {
            // setLoader('')
            props.setFields({ formValues });
            props.metaDiscount();
        }, 500);
    };

    let updateFields = (e, i) => {
        let amount;
        let subtotal;
        let taxValue;
        let otherTaxValue;
        let discValue;
        let quan = parseFloat(product.quantity);
        let disc = product.discount ? parseFloat(product.discount) : 0;
        let bPrice = parseFloat(product.base_price);
        let vat = product.vat;
        let tax = product.tax;
        if (e == "updateProduct") {
            let formValues = props.fields.formValues;
            formValues[i]["isUpdate"] = "1";
            formValues[i]["showPopup"] = showPopup ? "1" : "0";
            props.setFields({ formValues });
        }
        if (e == "notUpdateProduct") {
            let formValues = props.fields.formValues;
            formValues[i]["isUpdate"] = "0";
            formValues[i]["showPopup"] = showPopup ? "1" : "0";
            props.setFields({ formValues });
        }
        if (e?.target?.name == "base_price") {
            if (product?.showPopup === "0" && product.reference) {
                if (typingTimeout) {
                    clearTimeout(typingTimeout);
                }
                const newTimeout = setTimeout(() => {
                    setConfirmModal(true);
                }, 1000);
                setTypingTimeout(newTimeout);
            }
            amount = (e.target.value - (e.target.value * disc) / 100) * quan;
            discValue = e.target.value * quan - amount;
            subtotal = amount + (amount * (parseInt(vat) + parseInt(tax))) / 100;
            setAmount(amount);
            taxValue = amount + (amount * parseInt(vat)) / 100 - amount;
            otherTaxValue = amount + ((amount * parseInt(tax)) / 100 - amount);
            let formValues = props.fields.formValues;
            formValues[i][e.target.name] = e.target.value;
            formValues[i]["amount"] = amount;
            formValues[i]["discAmount"] = discValue;
            formValues[i]["subtotal"] = subtotal;
            formValues[i]["taxAmount"] = taxValue;
            formValues[i]["otherTaxAmount"] = otherTaxValue;
            props.setFields({ formValues });
        }
        if (e?.target?.name == "quantity") {
            amount = (bPrice - (bPrice * disc) / 100) * e.target.value;
            discValue = bPrice * e.target.value - amount;
            subtotal = amount + (amount * (parseInt(vat) + parseInt(tax))) / 100;
            setAmount(amount);
            taxValue = amount + (amount * parseInt(vat)) / 100 - amount;
            otherTaxValue = amount + ((amount * parseInt(tax)) / 100 - amount);
            let formValues = props.fields.formValues;
            formValues[i][e.target.name] = e.target.value;
            formValues[i]["amount"] = amount;
            formValues[i]["discAmount"] = discValue;
            formValues[i]["subtotal"] = subtotal;
            formValues[i]["taxAmount"] = taxValue;
            formValues[i]["otherTaxAmount"] = otherTaxValue;
            props.setFields({ formValues });
        }
        if (e?.target?.name == "discount") {
            amount = (bPrice - (bPrice * e.target.value) / 100) * quan;
            discValue = bPrice * quan - amount;
            subtotal = amount + (amount * (parseInt(vat) + parseInt(tax))) / 100;
            setAmount(amount);
            taxValue = amount + (amount * parseInt(vat)) / 100 - amount;
            otherTaxValue = amount + ((amount * parseInt(tax)) / 100 - amount);
            let formValues = props.fields.formValues;
            formValues[i][e.target.name] = e.target.value;
            formValues[i]["amount"] = amount;
            formValues[i]["discAmount"] = discValue;
            formValues[i]["subtotal"] = subtotal;
            formValues[i]["taxAmount"] = taxValue;
            formValues[i]["otherTaxAmount"] = otherTaxValue;
            props.setFields({ formValues });
        }
        if (e?.target?.name == "description") {
            let formValues = props.fields.formValues;
            formValues[i][e.target.name] = e.target.value;
            props.setFields({ formValues });
        }
        if (e?.target?.name == "vat") {
            subtotal = subtotal =
                parseFloat(product.amount) +
                (parseFloat(product.amount) *
                    (parseInt(e.target.value) + parseInt(tax))) /
                100;
            taxValue =
                parseFloat(product.amount) +
                (parseFloat(product.amount) * parseFloat(e.target.value)) / 100 -
                parseFloat(product.amount);
            otherTaxValue =
                parseFloat(product.amount) +
                (parseFloat(product.amount) * parseFloat(tax)) / 100 -
                parseFloat(product.amount);
            let formValues = props.fields.formValues;
            formValues[i][e.target.name] = e.target.value;
            formValues[i]["subtotal"] = subtotal;
            formValues[i]["taxAmount"] = taxValue;
            formValues[i]["otherTaxAmount"] = otherTaxValue;
            props.setFields({ formValues });
        }
        if (e?.target?.name == "tax") {
            subtotal = subtotal =
                parseFloat(product.amount) +
                (parseFloat(product.amount) *
                    (parseInt(vat) + parseInt(e.target.value))) /
                100;
            taxValue =
                parseFloat(product.amount) +
                (parseFloat(product.amount) * parseFloat(vat)) / 100 -
                parseFloat(product.amount);
            otherTaxValue =
                parseFloat(product.amount) +
                (parseFloat(product.amount) * parseFloat(e.target.value)) / 100 -
                parseFloat(product.amount);
            let formValues = props.fields.formValues;
            formValues[i][e.target.name] = e.target.value;
            formValues[i]["subtotal"] = subtotal;
            formValues[i]["taxAmount"] = taxValue;
            formValues[i]["otherTaxAmount"] = otherTaxValue;
            props.setFields({ formValues });
        }
        props.metaDiscount();
    };

    return (
        <>
            {selectItemModal ? (
                <ExpList
                    selectItemModal={selectItemModal}
                    setSelectItemModal={setSelectItemModal}
                    index={remove}
                    //   clients={template}
                    data={dataProduct}
                    data2={dataService}
                    dataExp={dataExpenses}
                    getProductData={getProductData}
                    selectProduct={selectProduct}
                    getProduct={getProduct}
                    getServices={getServices}
                    setMainFormDisabled={props.setMainFormDisabled}
                />
            ) : null}
            <tr className="" id={remove}>
                <td style={{ width: "2.50%" }}>
                    <button
                        className="btn btn-light ms-3"
                        // onClick={() => setSelectItemModal(true)}
                        onClick={() => {
                            getProduct(), getServices(), getExpenses();
                            setSelectItemModal(true), props.setMainFormDisabled(true);
                        }}
                        type="button"
                        disabled={props.isEditable ? false : true}
                    >
                        <i className="fas fa-search" />
                    </button>
                </td>
                <td style={{ width: "11.50%" }}>
                    <div
                    // style={{ minWidth: "140px" }}
                    >
                        <DropdownList
                            data={productOptions}
                            dataKey="id"
                            textField="name"
                            onChange={(e) => selectProduct2(e, remove)}
                            disabled={props.isEditable ? false : true}
                            // value={`${product.reference} ${product.reference_id} ${product.name}`}
                            value={`${product.reference}${product.reference_id}`}
                        />
                    </div>
                    <Input
                        type="text"
                        name="reference_id"
                        defaultValue={product.reference_number}
                        disabled={props.isEditable ? false : true}
                        hidden
                    />
                    <Input
                        type="text"
                        name="reference"
                        defaultValue={product.reference}
                        disabled={props.isEditable ? false : true}
                        hidden
                    />
                </td>
                {/* <td>
          <AvField
            className="form-control input-style"
            type="select"
            name="reference_id"
            onChange={(e) => {
              selectProduct(e.target.value, remove);
            }}
            disabled={props.isEditable ? false : true}
          >
            <option value="" hidden>
              Select
            </option>
            {dataProduct.map((pro, i) => (
              <option value={pro.id} key={i}>
                {pro.reference}
                {pro.reference_number} {pro.name}
              </option>
            ))}
            {dataService.map((ser, i) => (
              <option value={ser.id} key={i}>
                {ser.reference}
                {ser.reference_number} {ser.name}
              </option>
            ))}
            {dataExpenses.map((eai, i) => (
              <option value={eai.id} key={i}>
                {eai.reference}
                {eai.reference_number} {eai.name}
              </option>
            ))}
          </AvField>
          <Input
            type="text"
            name="reference_id"
            value={product.reference_number}
            disabled={props.isEditable ? false : true}
            hidden
          />
          <Input
            type="text"
            name="reference"
            value={product.reference}
            disabled={props.isEditable ? false : true}
            hidden
          />
        </td> */}
                <td style={{ width: "21%" }}>
                    <div
                    // style={{ minWidth: "150px" }}
                    >
                        <NameCustomDropdownEai
                            getProductData={getProductData}
                            data={dataProduct}
                            data2={dataService}
                            dataExp={dataExpenses}
                            clientLoader={clientLoader}
                            setMainFormDisabled={props.setMainFormDisabled}
                            remove={remove}
                            productNameData={productNameData}
                            isEditable={props.isEditable}
                            //   product={product}
                            selectProduct2={selectProduct2}
                            removeFields={removeFields}
                        />
                    </div>
                    {/* <AvField
            type="text"
            name="name"
            className="form-control input-style"
            id="name"
            value={product.name}
            disabled={props.isEditable ? false : true}
          /> */}
                </td>
                <td style={{ width: "21%" }}>
                    <AvField
                        // style={{
                        //   minWidth: focus ? "250px" : "150px",
                        // }}
                        type={focus ? "textarea" : "text"}
                        name="description"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        onChange={(e) => updateFields(e, remove)}
                        className="form-control input-style"
                        value={product.description}
                        disabled={props.isEditable ? false : true}
                    />
                </td>
                <td style={{ width: "6%" }}>
                    <AvField
                        // style={{ minWidth: "95px" }}
                        type="number"
                        name="base_price"
                        onChange={(e) => updateFields(e, remove)}
                        className="form-control input-style"
                        value={product.base_price}
                        disabled={props.isEditable ? false : true}
                    />
                </td>
                <td style={{ width: "5%" }}>
                    <AvField
                        // style={{ minWidth: "95px" }}
                        type="number"
                        name="quantity"
                        onChange={(e) => updateFields(e, remove)}
                        className="form-control input-style"
                        value={product.quantity}
                        disabled={props.isEditable ? false : true}
                    />
                </td>
                <td style={{ width: "5%" }}>
                    <AvField
                        // style={{ minWidth: "80px" }}
                        type="number"
                        name="discount"
                        className="form-control input-style"
                        onChange={(e) => updateFields(e, remove)}
                        value={product.discount}
                        disabled={props.isEditable ? false : true}
                    />
                </td>
                <td style={{ width: "10%" }}>
                    <input
                        // style={{ minWidth: "100px" }}
                        value={
                            product.amount == "" ? "" : parseFloat(product.amount).toFixed(2)
                        }
                        name="amount"
                        type="text"
                        className="form-control"
                        disabled={props.isEditable ? false : true}
                    />
                </td>
                <td style={{ width: "7.50%" }}>
                    <AvField
                        type="select"
                        name="vat"
                        className="form-control input-style"
                        onChange={(e) => updateFields(e, remove)}
                        value={product.vat}
                        disabled={props.isEditable ? false : true}
                    >
                        {dataTax?.map((item, i) => (
                            <option value={item.tax} key={i}>
                                {item.primary_name} {item.tax}%
                            </option>
                        ))}
                        <option value={0}>{props.t("Do not apply")}</option>
                    </AvField>
                </td>
                <td style={{ width: "7.50%" }}>
                    <AvField
                        type="select"
                        name="tax"
                        className="form-control input-style"
                        onChange={(e) => updateFields(e, remove)}
                        value={product.tax}
                        disabled={props.isEditable ? false : true}
                    >
                        {dataIncomeTax?.map((item, i) => (
                            <option value={item.tax} key={i}>
                                {item.tax}% {item.name}
                            </option>
                        ))}
                        <option value="0">{props.t("Do not apply")}</option>
                    </AvField>
                </td>
                <td style={{ width: "3%" }}>
                    <button
                        type="button-input"
                        className="button-input remove-input barcode-btn"
                        onClick={() => removeFormFields(remove)}
                        disabled={props.isEditable ? false : true}
                    >
                        <img src={del} height="25px" />
                    </button>
                </td>
            </tr>
            {confirmModal ? (
                <SweetAlert
                    title={props.t("Do you want to update the price of the product?")}
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Accept")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        updateFields("updateProduct", remove);
                        setConfirmModal(false);
                    }}
                    onCancel={() => {
                        setConfirmModal(false);
                        updateFields("notUpdateProduct", remove);
                    }}
                >
                    <AvField
                        type="checkbox"
                        name="showPopup"
                        label={props.t("Does not show this message again for this product")}
                        onChange={(e) => {
                            setShowPopup(e.target.checked);
                        }}
                        className="form-checkbox"
                    // value={showPopup}
                    // disabled={props.isEditable ? false : true}
                    />
                </SweetAlert>
            ) : null}
        </>
    );
};
export default withTranslation()(ProductTechEstimates);
ProductTechEstimates.propTypes = {
    t: PropTypes.any,
};
