import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let selectedRoleId = [];

function PaymentTermsList(props) {
  const [payments, setPayments] = useState(props.data && props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const deletePayment = (e) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-terms/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setPayments(null);
        setdynamic_description(res.data.message);
      });
  };
  useEffect(() => {
    selectedRoleId = [];
  }, []);
  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      props.setSelectedIds(selectedRoleId);
    } else {
      selectedRoleId = props.selectedIds;
      var array = selectedRoleId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
      props.setSelectedIds(selectedRoleId);
    }
  };
  if (payments === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td>
            <input
              onChange={(e) => getSelectedId(payments.id, e)}
              name="chk"
              value={payments.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td role="button">
            <Link to={`/payment-term/${payments.id}`}>{payments.name}</Link>
          </td>
          <td role="button">
            <Link to={`/payment-term/${payments.id}`}>
              {payments.description}
            </Link>
          </td>
          {getPermission()?.payment_terms.payment_terms.delete.is_checked ===
          1 ? (
            <td className="text-end">
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                {props.t("Delete")}
              </Button>
            </td>
          ) : (
            ""
          )}
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deletePayment(payments.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("El Documento fue eliminado.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
}
export default withTranslation()(PaymentTermsList);
PaymentTermsList.propTypes = {
  t: PropTypes.any,
};
