import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody, Card, Alert } from "reactstrap";
import MetaTags from "react-meta-tags";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

const SubscriptionPlan = (props) => {
  const { id } = useParams();
  const [priceId] = useState(parseInt(id, 10));
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const getSubscriptionData = () => {
    axios
      .get(`${process.env.REACT_APP_APIKEY}/subscriptions`, {
        headers: {
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setData(res.data.subscription);
        } else {
          setError(res.data.message);
        }
      });
  };
  const getPayment = (priceId, planId) => {
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/create-payment-link`,
        {
          price_id: priceId,
          plan_id: planId,
        },
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        openInNewTab(res.data.url);
      });
  };
  const getExtendTrial = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/extend-subscription`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setSuccess(res.data.message);
          localStorage.setItem("plan_data", JSON.stringify(res.data.data));
        }
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      });
  };
  const openInNewTab = (url) => {
    window.location.href = url;
  };
  useEffect(() => {
    getSubscriptionData();
  }, []);
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Pricing | Clouget</title>
          </MetaTags>
          <Container className="mt-4">
            <Row>
              {data &&
                data.map((item, i) => (
                  <Col xl="4" md="6" key={i}>
                    <Card className="pricing-box h-100">
                      <CardBody
                        className="p-4"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="flex-1 ms-auto text-end">
                          <h4 className="mt-0">{item.name}</h4>
                          <div
                            className="plan__descc"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                        <div className="text-center mt-5">
                          <h1 className="mb-0">
                            <sup>
                              <small>$</small>
                            </sup>
                            {item.price}/
                            <span className="font-size-16">
                              {item.type == "semi_annual"
                                ? props.t("semiannual")
                                : props.t(item.type)}
                            </span>
                          </h1>
                          <div className="mt-3">
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                getPayment(item.stripe_price_id, item.id)
                              }
                            >
                              {props.t("Buy Plan")}
                            </button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Row>
            <div>
              {success ? <Alert color="success">{success}</Alert> : null}
            </div>
            {/* {JSON.parse(localStorage.getItem("plan_data"))
              ?.allow_trial_extend === 1 ? (
              <button
                className="btn btn-primary float-end"
                onClick={() => getExtendTrial()}
              >
                {props.t("Extend Subscription")}
              </button>
            ) : (
              ""
            )}{" "} */}
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};
export default withTranslation()(SubscriptionPlan);
SubscriptionPlan.propTypes = {
  t: PropTypes.any,
};
