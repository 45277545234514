/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBContainer,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { useHistory } from "react-router-dom";

let selectedRoleId = [];
let selectedRoleType = [];
let selectedRoleNum = [];
const AllInvoices = (props) => {
  const history = useHistory();
  const [displayInvoices, setDisplayInvoices] = useState(props?.data);
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModal2, setConfirmModal2] = useState(false);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const delUser = (e) => {
    setdynamic_title("");
    setdynamic_description("");
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/invoices/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setdynamic_title("Deleted");
          setdynamic_description("The invoice has been deleted.");
          setDisplayInvoices(null);
        } else {
          setdynamic_title("Failed");
          setdynamic_description(res.data.message);
        }
        setsuccess_dlg(true);
      });
  };

  const authorizeVeronica = (id, a) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/veronica/${
          displayInvoices?.reference_type === "Refund Invoice"
            ? "create-and-authorize-credit-note"
            : "create-and-authorize-invoice"
        }/${id}?email=${a ? email : null}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          props.setError("");
          props.setSuccess("Invoice Authorized Successfully!");
          props.getInvoiceData();
        } else {
          if (res.data.data !== undefined) {
            props.setError(res.data.data?.detalleError);
          } else {
            props.setError(res.data.message);
          }
          props.setSuccess("");
        }
        setTimeout(() => {
          props.setError("");
          props.setSuccess("");
        }, 5000);
      });
  };

  const notifyVeronica = (id, a) => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/veronica/invoice-notify/${id}?emails=${a ? emails : null}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          props.setError("");
          props.setSuccess(res.data.message);
          // props.getInvoiceData()
        } else {
          if (res.data.result !== undefined) {
            props.setError(res.data.result.messages[0]);
          } else {
            props.setError(res.data.message);
          }
          props.setSuccess("");
        }
        setTimeout(() => {
          props.setError("");
          props.setSuccess("");
        }, 5000);
      });
  };

  const getDuplicate = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/invoices/duplicate_invoice`,
        {
          id: id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res) {
          history.push(`/invoicing-service/${res.data.data.id}`);
        } else {
          props.setError(res.data.message);
        }
        setTimeout(() => {
          props.setError("");
        }, 4000);
      });
  };

  useEffect(() => {
    selectedRoleId = [];
    selectedRoleType = [];
    selectedRoleNum = [];
  }, []);

  const getSelectedId = (e, check, type, num) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      selectedRoleType.push(type);
      selectedRoleNum.push(num);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      const uniqueType = selectedRoleType.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      const uniqueNum = selectedRoleNum.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      selectedRoleType = uniqueType;
      selectedRoleNum = uniqueNum;
      props.setSelectedIds(selectedRoleId);
      props.setSelectedType(selectedRoleType);
      props.setSelectedNum(selectedRoleNum);
    } else {
      selectedRoleId = props.selectedIds;
      selectedRoleType = props.selectedType;
      selectedRoleNum = props.selectedNum;
      var array = selectedRoleId;
      var array2 = selectedRoleType;
      var array3 = selectedRoleNum;
      var index = array.indexOf(e);
      var index2 = array2.indexOf(type);
      var index3 = array3.indexOf(num);
      if (index !== -1) {
        array.splice(index, 1);
      }
      if (index2 !== -1) {
        array2.splice(index2, 1);
      }
      if (index2 !== -1) {
        array3.splice(index3, 1);
      }
      props.setSelectedIds(selectedRoleId);
      props.setSelectedType(selectedRoleType);
      props.setSelectedNum(selectedRoleNum);
    }
  };

  return (
    <>
      {displayInvoices !== null ? (
        <tr>
          <td>
            <input
              onChange={(e) =>
                getSelectedId(
                  displayInvoices.id,
                  e,
                  displayInvoices.reference_type,
                  displayInvoices.reference_number
                )
              }
              name="chk"
              value={displayInvoices.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {displayInvoices.reference}
              {displayInvoices.reference_number}
            </Link>
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {displayInvoices.client_name}
            </Link>
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {displayInvoices.veronica_status === "rejected"
                ? props.t("PENDING")
                : props.t(displayInvoices.veronica_status.toUpperCase())}
            </Link>
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {displayInvoices.created_by_name}
            </Link>
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {props.t(
                displayInvoices.status == "paid"
                  ? "Paid"
                  : displayInvoices.status == "unpaid"
                  ? "Unpaid"
                  : displayInvoices.status == "partially paid"
                  ? "Partially Paid"
                  : displayInvoices.status
              )}
            </Link>
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {displayInvoices.date}
            </Link>
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {displayInvoices.amount == "0.00" ? "" : displayInvoices.amount}
            </Link>
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {displayInvoices.activity}
            </Link>
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {displayInvoices.margin}
            </Link>
          </td>
          <td>
            <Link to={`/invoicing-service/${displayInvoices.id}`}>
              {displayInvoices.amount_due}
            </Link>
          </td>
          <td>
            <div className="btn-group me-2">
              <MDBContainer className="arrow-none">
                <MDBDropdown dropleft group>
                  <MDBDropdownToggle className="btn btn-primary">
                    <i className="fa fa-bars"></i>
                    {/* {props.t("Action")} */}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {/* <MDBDropdownItem>
                      <span
                        className="nav-item dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => props.downloadVeronica(displayInvoices.id, "xml", false)}
                      >
                        {props.t("Download XML")}
                      </span>
                    </MDBDropdownItem> */}
                    {displayInvoices.veronica_status === "pending" ||
                    displayInvoices.veronica_status === "rejected" ? (
                      <MDBDropdownItem>
                        <span
                          className="nav-item dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            displayInvoices?.client_email
                              ? props.authorizeVeronica(displayInvoices.id)
                              : setConfirmModal2(true)
                          }
                        >
                          {props.t("Authorize")}
                        </span>
                      </MDBDropdownItem>
                    ) : (
                      <>
                        <MDBDropdownItem>
                          <span
                            className="nav-item dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.cancelVeronica(displayInvoices.id)
                            }
                          >
                            {props.t("cancel")}
                          </span>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                          <span
                            className="nav-item dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.downloadVeronica(
                                displayInvoices.id,
                                "pdf-ticket",
                                true
                              )
                            }
                          >
                            {props.t("Print TICKET")}
                          </span>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                          <span
                            className="nav-item dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              props.downloadVeronica(
                                displayInvoices.id,
                                "pdf-factura",
                                false
                              )
                            }
                          >
                            {props.t("Print RIDE")}
                          </span>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                          <span
                            className="nav-item dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              notifyVeronica(displayInvoices.id, false)
                            }
                          >
                            {props.t("Notify")}
                          </span>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                          <span
                            className="nav-item dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => setConfirmModal(true)}
                          >
                            {props.t("Notify to")}
                          </span>
                        </MDBDropdownItem>
                      </>
                    )}
                    <MDBDropdownItem>
                      <span
                        className="nav-item dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => getDuplicate(displayInvoices?.id)}
                      >
                        {props.t("Duplicate")}
                      </span>
                    </MDBDropdownItem>
                    {getPermission().invoices.invoices.delete.is_checked ===
                    1 ? (
                      <MDBDropdownItem>
                        <span
                          className="nav-item dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => setconfirm_alert(true)}
                        >
                          {props.t("Delete")}
                        </span>
                      </MDBDropdownItem>
                    ) : (
                      <MDBDropdownItem>
                        <span className="nav-item dropdown-item disabled">
                          {props.t("Delete")}
                        </span>
                      </MDBDropdownItem>
                    )}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBContainer>
            </div>
            {/* {getPermission().invoices.invoices.delete.is_checked === 1 ? (
              <button
                type="button-input"
                className="btn btn-danger"
                onClick={() => setconfirm_alert(true)}
              >
                {props.t("Delete")}
              </button>
            ) : (
              ""
            )} */}
          </td>
        </tr>
      ) : (
        ""
      )}
      {confirmModal ? (
        <SweetAlert
          // title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnText={props.t("Send")}
          // warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            notifyVeronica(displayInvoices.id, true);
            setConfirmModal(false);
          }}
          onCancel={() => setConfirmModal(false)}
        >
          <label>{props.t("Enter emails to notify")}</label>
          <textarea
            className="form-control"
            type="textarea"
            name="emails"
            onChange={(e) => setEmails(e.target.value)}
          />
          <small>{props.t("Write multiple emails with ',' in between.")}</small>
        </SweetAlert>
      ) : null}
      {confirmModal2 ? (
        <SweetAlert
          // title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnText={props.t("Submit")}
          // warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            authorizeVeronica(displayInvoices.id, true);
            setConfirmModal2(false);
          }}
          onCancel={() => setConfirmModal2(false)}
        >
          <label>{props.t("Enter client email")}</label>
          <input
            className="form-control"
            type="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <small>{props.t("")}</small>
        </SweetAlert>
      ) : null}

      {confirm_alert ? (
        <SweetAlert
          title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
          confirmBtnText={props.t("Yes, delete it!")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            delUser(displayInvoices.id);
            setconfirm_alert(false);
          }}
          onCancel={() => setconfirm_alert(false)}
        ></SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success={dynamic_title == "Deleted" ? true : false}
          error={dynamic_title == "Failed" ? true : false}
          title={props.t(dynamic_title)}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {props.t(dynamic_description)}
        </SweetAlert>
      ) : null}
    </>
  );
};

export default withTranslation()(AllInvoices);
AllInvoices.propTypes = {
  t: PropTypes.any,
};
