import React, { useState } from "react";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
import { Modal } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function DeliveryOptionModal(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const CreateNewPayment = (e, value) => {
        e.preventDefault();

        setLoading(true);
        const formData = new FormData();
        formData.append("name", value.name);
        formData.append("description", value.description);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/delivery-options`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    if (props.setDeliveryOptionModal !== undefined) {
                        props.setDeliveryOptionModal(false);
                    }
                    if (props.getDeliveryOption !== undefined) {
                        props.getDeliveryOption();
                    }
                } else {
                    setError(res.data.message);
                }
            });
    };

    return (
        <Modal size="md" isOpen="true" centered={true}>
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myLargeModalLabel">
                    {props.t("New delivery option")}
                </h5>
                <button
                    type="button"
                    className="cross__btn btn btn-primary waves-effect waves-light"
                    onClick={() => props.setDeliveryOptionModal(false)}
                    aria-hidden="true"
                >
                    &times;
                </button>
            </div>
            <div className="modal-body">
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        CreateNewPayment(e, v);
                    }}
                >
                    {error ? (
                        <div className="mb-3">
                            <p className="alert alert-danger">{props.t(error)}</p>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="mb-3 required">
                        <AvField label={props.t("Name")} type="text" name="name" />
                    </div>
                    <div className="mb-3">
                        <AvField
                            label={props.t("Description")}
                            type="textarea"
                            name="description"
                        />
                    </div>
                    <div className="text-end">
                        {loading ? (
                            <button
                                className="btn btn-primary  disabled"
                                type="button"
                                disabled
                            >
                                {props.t("Loading")}
                            </button>
                        ) : (
                            <button className="btn btn-primary " type="submit">
                                {props.t("Submit")}
                            </button>
                        )}
                        <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => props.setDeliveryOptionModal(false)}
                        >
                            {props.t("Cancel")}
                        </button>
                    </div>
                </AvForm>
            </div>
        </Modal>
    );
}
export default withTranslation()(DeliveryOptionModal);
DeliveryOptionModal.propTypes = {
    t: PropTypes.any,
};
