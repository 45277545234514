import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const CustomStates = (props) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);

    const getStates = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/custom_state_types`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.types);
                    localStorage.setItem("CustomStates", JSON.stringify(res.data.types))
                } else {
                    setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getStates();
    }, []);
    return (
        <React.Fragment>
            {/* <div className="page-content"> */}
            <MetaTags>
                <title>Custom States | Clouget</title>
            </MetaTags>
            {/* <Container fluid>
          <div className="company_list"> */}
            <div className="page-title-box">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h6 className="page-title">
                            {props.t("Customize the states of your documents")}
                        </h6>
                    </Col>
                    <Col md={6}>
                        <div className="float-end d-none d-md-block">
                            {/* <Link to="/new-product" className="btn btn-primary"> */}
                            {/*   Create New */}
                            {/* </Link> */}
                            {/* <Link to="/company" className="btn btn-primary float-end">
                      {props.t("Back")}
                    </Link> */}
                        </div>
                    </Col>
                </Row>
            </div>
            <Card>
                <CardBody className="company_list">
                    <div className="table-responsive">
                        <Table striped className="align-middle">
                            <thead>
                                <tr>
                                    <th>{props.t("Type")}</th>
                                    <th>{props.t("States")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    data.map((item, i) => (
                                        <tr key={i}>
                                            <th>
                                                <Link to={`/state/${item.id}`}>
                                                    {props.t(item.name)}
                                                </Link>
                                            </th>
                                            <td>
                                                <Link to={`/state/${item.id}`}>
                                                    {item.states.map((item, i) => (
                                                        <span
                                                            key={i}
                                                            style={{ backgroundColor: item.color }}
                                                            className="badge rounded-pill fs-6 text-dark me-2"
                                                        >
                                                            {props.t(item.name)}
                                                        </span>
                                                    ))}
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                    {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                    {loader}
                </CardBody>
            </Card>
            {/* </div> */}
            {/* </Container>
      </div> */}
        </React.Fragment>
    );
};
export default withTranslation()(CustomStates);
CustomStates.propTypes = {
    t: PropTypes.any,
};
