import React, { useState } from "react";
import PropTypes from "prop-types";
import RateModal from "./rate-modal";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser } from "../../../helpers/Utils";
import { withTranslation } from "react-i18next";

function SingleList(props) {
    const [rate, setRate] = useState(props && props.data);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);

    const deleteRate = () => {
        if (props.type == "service") {
            axios
                .delete(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/service-special-prices/${rate.id}`,
                    {
                        headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setRate(null);
                    setdynamic_description(res.data.message);
                });
        } else {
            axios
                .delete(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/client-special-prices/${rate.id}`,
                    {
                        headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setRate(null);
                    setdynamic_description(res.data.message);
                });
        }
    };

    if (rate === null) {
        return "";
    } else
        return (
            <>
                {isOpenModal ? (
                    <RateModal
                        setIsOpenModal={setIsOpenModal}
                        rateId={rate.id}
                        setRate={setRate}
                        type={props.type}
                    />
                ) : null}
                <tr>
                    <td onClick={() => setIsOpenModal(true)} role="button">
                        {rate.client_name}
                    </td>
                    <td onClick={() => setIsOpenModal(true)} role="button">
                        $ {rate.special_price}
                    </td>
                    <td onClick={() => setIsOpenModal(true)} role="button">
                        {rate.discount}
                    </td>
                    <td onClick={() => setIsOpenModal(true)} role="button">
                        {rate.purchase_margin}
                    </td>
                    <td onClick={() => setIsOpenModal(true)} role="button">
                        {rate.sales_margin}
                    </td>
                    <td className="text-end">
                        <Button
                            color="danger"
                            onClick={() => {
                                setconfirm_alert(true);
                            }}
                        >
                            {props.t("Delete")}
                        </Button>
                    </td>
                </tr>
                {confirm_alert ? (
                    <SweetAlert
                        title={props.t("Are you sure?")}
                        cancelBtnText={props.t("Cancel")}
                        confirmBtnText={props.t("Yes, delete it!")}
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            deleteRate(rate.id);
                            setconfirm_alert(false);
                            setsuccess_dlg(true);
                            setdynamic_title("Deleted");
                            setdynamic_description("Sale Price has been deleted.");
                        }}
                        onCancel={() => setconfirm_alert(false)}
                    ></SweetAlert>
                ) : null}

                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t(dynamic_title)}
                        onConfirm={() => {
                            setsuccess_dlg(false);
                        }}
                    >
                        {props.t(dynamic_description)}
                    </SweetAlert>
                ) : null}
            </>
        );
}

export default withTranslation()(SingleList);
SingleList.propTypes = {
    t: PropTypes.any,
};
