import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Alert, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../../components/Loader";
import ReferenceList from "./reference-list";
import ReferenceModal from "./reference-modal";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../../plans/trialModal";
import { Link } from "react-router-dom";

const ReferencesConfig = (props) => {
  const [reference_modal, setreference_modal] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(<Loader />);
  const [nonActive, setNonActive] = useState(
    JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !== 1 &&
      JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id == null
      ? true
      : false
  );
  const [isTrialModal, setIsTrialModal] = useState(false);
  const createNew = () => {
    if (nonActive) {
      setIsTrialModal(true);
    } else {
      setreference_modal(true);
    }
  };
  const getReferences = () => {
    setData([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.status) {
          setError("");
          setData(res.data.reference);
        } else {
          setError(res.data.message);
        }
      });
  };
  useEffect(() => {
    getReferences();
  }, []);

  return (
    <React.Fragment>
      {reference_modal ? (
        <ReferenceModal
          reference_modal={reference_modal}
          setreference_modal={setreference_modal}
          getReferences={getReferences}
        />
      ) : (
        ""
      )}
      <CreateTrialModal
        isTrialModal={isTrialModal}
        setIsTrialModal={setIsTrialModal}
        nonActive={nonActive}
      />

      {/* <Container fluid className=""> */}
      {/* <div className="company_list"> */}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">{props.t("References")}</h6>
          </Col>
          <Col md={6}>
            {getPermission()?.references.references.create.is_checked === 1 ? (
              <div className="float-end d-none d-md-block">
                <span
                  onClick={() => createNew()}
                  className="btn btn-primary ms-2"
                  //   type="button"
                >
                  {props.t("Create New")}
                </span>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table striped className="align-middle">
              <thead>
                <tr>
                  <th>{props.t("Name")}</th>
                  <th>{props.t("Title")}</th>
                  <th>{props.t("Type")}</th>
                  <th>{props.t("Prefix")}</th>
                  <th>{props.t("Last Reference")}</th>
                  <th>{props.t("Number of digits")}</th>
                  <th>{props.t("By Default")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, i) => <ReferenceList key={i} data={item} />)}
              </tbody>
            </Table>
          </div>
          {error ? <Alert color="danger">{error}</Alert> : ""}
          {loader}
        </CardBody>
      </Card>
      {/* </Container> */}
      {/* </div> */}
    </React.Fragment>
  );
};
export default withTranslation()(ReferencesConfig);
ReferencesConfig.propTypes = {
  t: PropTypes.any,
};
