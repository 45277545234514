import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
    Col,
    Container,
    Row,
    Table,
    Card,
    CardBody,
    Alert,
    Button,
} from "reactstrap";
import "@vtaits/react-color-picker/dist/index.css";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import SingleStateList from "./state-list";
import StateModal from "./create-state-modal";
const axios = require("axios").default;
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
// let cc = require('currency-codes')

const SingleStates = (props) => {
    const { id } = useParams();
    const [stateId] = useState(parseInt(id, 10));
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [state, setState] = useState("");
    const [stateTypes, setStateTypes] = useState([]);

    const getSingleState = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/custom_state_types?custom_state_type_id=${stateId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setState(res.data.types);
                setStateTypes(res.data.types.states);
            });
    };

    const companyUpdateSubmit = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/custom_state_types?custom_state_type_id=${stateId}`,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
            });
    };

    useEffect(() => {
        getSingleState();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{state && state.name} | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="create_company">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h6 className="page-title">{state && props.t(state.name)}</h6>
                                </Col>
                                <Col md={6} className="text-end">
                                    <Link type="button" to="/company" className="btn btn-primary">
                                        {props.t("Back")}
                                    </Link>
                                    {getPermission().custom_states.custom_states.create
                                        .is_checked === 1 ? (
                                        <Button
                                            onClick={() => {
                                                setIsOpenModal(true);
                                            }}
                                            color="success"
                                            className="btn btn-success mt-3 mt-lg-0"
                                        >
                                            {" "}
                                            + {props.t("New State")}{" "}
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </Col>
                            </Row>
                        </div>
                        {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                        {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>{props.t("Color")}</th>
                                                <th>{props.t("Name")}</th>
                                                <th>{props.t("Description")}</th>
                                                <th className="text-end">{props.t("Edit")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {stateTypes.map((item, i) => (
                                                <SingleStateList data={item} key={i} />
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
            <StateModal
                stateId={stateId}
                setIsOpenModal={setIsOpenModal}
                isOpenModal={isOpenModal}
                getSingleState={getSingleState}
            />
        </React.Fragment>
    );
};
export default withTranslation()(SingleStates);
SingleStates.propTypes = {
    t: PropTypes.any,
};
