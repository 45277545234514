import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    Table,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Modal,
    Input,
} from "reactstrap";
import classnames from "classnames";
import CategoryModal from "./expensesModel";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uploadImg from "../../assets/images/def-img.svg";
import Loader from "../../components/Loader";
import ClientAddress from "../../components/ClientAddresses";
import ClientWorkAddress from "../../components/ClientAddresses/workAddress";
import DocumentAttachment from "./DocumentAttechment";
import SendAttachment from "../SendAttachments/index";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import CreateTrialModal from "../plans/trialModal";
import moment from "moment";

const EditExpenses = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const [eiId] = useState(parseInt(id, 10));
    const [data, setData] = useState([]);
    const [clients, setClients] = useState("");
    const [workData, setWorkData] = useState("");
    const [clientNameData, setClientNameData] = useState("");
    const [isOpenAddress, setIsOpenAddress] = useState(false);
    const [isWorkAddress, setIsWorkAddress] = useState(false);
    const [clientData, setClientData] = useState("");
    const [assetData, setAssetData] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [mainImgPreview, setMainImgPreview] = useState(uploadImg);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [docError, setDocError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [image, setImage] = useState("");
    const [mainimage, setMainImage] = useState("");
    const [selectedCate, setSelectedCate] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [referenceType, setReferenceType] = useState("");
    const [document, setDocument] = useState("");
    const [fileDocument, setFileDocument] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [expensesInvestment, setExpensesInvestment] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isOpenModalAttach, setIsOpenModalAttach] = useState(false);
    const [attachDocument, setAttachDocument] = useState([]);
    const [expense, setexpense] = useState("");
    const [dataTax, setDataTax] = useState([]);
    const [defaultTax, setDefaultTax] = useState("");
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");
    const [refNum, setRefNum] = useState("");
    //   const [vat, setVat] = useState("20.00%");
    const [isAttachOpenModal, setIsAttachOpenModal] = useState(false);
    const [attach, setAttach] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [error2, setError2] = useState("");
    const [attachEmail, setAttachEmail] = useState("");
    const [attachEmail2, setAttachEmail2] = useState("");
    const [attachEmail3, setAttachEmail3] = useState("");
    const [emailMessage2, setEmailMessage2] = useState("");
    const [expInvHistory, setExpInvHistory] = useState([]);
    const [historyError, setHistoryError] = useState("");
    const [error3, setError3] = useState("");
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };
    const productCreateSubmit = (e, value) => {
        setLoading(true);

        const formData = new FormData();
        formData.append("reference", value.reference);
        formData.append("reference_number", e.target.reference_number.value);
        formData.append("name", value.name);
        formData.append("price", value.price);
        formData.append("category_id", value.category);
        // formData.append("type", e.target.type.value);
        // formData.append("expense", value.expense);
        formData.append(
            "private_comments",
            e.target.private_comments.value !== null ? private_comments : ""
        );
        formData.append(
            "description",
            value.description !== null ? value.description : ""
        );

        formData.append("images", image);
        formData.append("image", mainimage);
        formData.append("tax", value.tax !== null ? value.tax : "");
        formData.append("created_at", e.target.date.value);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_investments/${eiId}?_method=PUT`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setDocError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setDocError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setDocError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const getSingleExpenses = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_investments/${eiId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setExpensesInvestment(res.data.expense_and_investment);
                    const momentDate = moment(res.data.expense_and_investment?.created_at, "DD-MM-YYYY");
                    setStartDate(momentDate.toDate());
                    setRefNum(res.data.expense_and_investment.reference_number);
                    if (res.data.expense_and_investment.images) {
                        setImgPreview(res.data.expense_and_investment.images);
                    }
                    if (res.data.expense_and_investment.image) {
                        setMainImgPreview(res.data.expense_and_investment.image);
                    }
                    setSelectedCate(res.data.expense_and_investment.category_id);
                    getHistory(
                        res.data.expense_and_investment.id,
                        res.data.expense_and_investment.reference
                    );
                }
            });
    };
    const getTaxes = () => {
        setDataTax([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/cosumption-taxes`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setDataTax(res.data.taxes);
                    res.data.taxes.taxes?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultTax(item.tax);
                        }
                    });
                }
            });
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Expense and investment`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                    res.data.reference?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultRef(item.prefix);
                        }
                    });
                } else {
                    //   setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getSingleExpenses();
        getReferences();
        getTaxes();
    }, []);
    const createAttachment = () => {
        const formData = new FormData();
        formData.append("document", document);
        formData.append("description", expense);
        // formData.append("product_id", eiId);
        formData.append("expense_id", eiId);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense-attachments`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setError("");
                    setDocError("");
                    setSuccess(res.data.message);
                    AllAttach();
                    setIsOpenModalAttach(false);
                } else {
                    //   setError(res.data.message);
                    setDocError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    //   setError("");
                    setDocError;
                    setSuccess("");
                }, 4000);
            });
    };
    const AllAttach = () => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense-attachments?expense_attachment_id=${eiId}`,
                // product-attachments/${eiId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                setAttachDocument(res.data.expense_attachments);
                setIsOpenModalAttach(false);
            });
    };
    useEffect(() => {
        AllAttach();
    }, []);

    const filePreview = (e) => {
        setDocument(e.target.files[0]);
        setFileDocument(URL.createObjectURL(e.target.files[0]));
    };

    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };
    const setComImg2 = (e) => {
        setMainImage(e.target.files[0]);
        setMainImgPreview(URL.createObjectURL(e.target.files[0]));
    };
    const getProducts = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_investments`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.expense_and_investment);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const productCategories = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_categories`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllCategories(res.data.expense_categories);
            });
    };
    let optionAssets = [];
    if (assetData !== undefined) {
        {
            assetData &&
                assetData.map((asset) => {
                    optionAssets.push({
                        value: asset.id,
                        label: asset.name,
                    });
                });
        }
    }

    let optionCategory = [];
    if (allCategories !== undefined) {
        {
            allCategories &&
                allCategories.map((category) => {
                    optionCategory.push({
                        value: category.id,
                        label: category.name,
                    });
                });
        }
    }
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };
    const getType = (e) => {
        setReferenceType(e.target.value);
        setRefNum("");
    };
    const getSendAttachment = () => {
        if (selectedIds.length < 1) {
            setError2("Please select document");
            setTimeout(() => {
                setError2("");
            }, 4000);
            return false;
        }
        setIsAttachOpenModal(true);
    };
    const getSendAttachment2 = () => {
        const formData = new FormData();
        formData.append("send_to", attachEmail);
        formData.append("cc", attachEmail2);
        formData.append("bcc", attachEmail3);
        formData.append("body", emailMessage2);
        formData.append("ids", selectedIds);
        formData.append("name", selectedName);
        formData.append("type", "expense_attachments");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-attachments-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 3000);
            });
    };
    const getHistory = (id, ref) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense-history?id=${id}&reference=${ref}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setExpInvHistory(res.data.data);
                } else {
                    setHistoryError(res.data.message);
                }
            });
    };
    let selectedRoleId = [];
    const getSelectedId = (e, check) => {
        if (check.target.checked) {
            selectedRoleId.push(e);
            const uniqueID = selectedRoleId.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            selectedRoleId = uniqueID;
            setSelectedIds(selectedRoleId);
        } else {
            selectedRoleId = selectedIds;
            var array = selectedRoleId;
            var index = array.indexOf(e);
            if (index !== -1) {
                array.splice(index, 1);
            }
            setSelectedIds(selectedRoleId);
        }
    };
    const getExport = () => {
        if (selectedIds.length > 1) {
            setError3("This action can only be carried out on a single document");
            setTimeout(() => {
                setError3("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError3("Please select document");
            setTimeout(() => {
                setError3("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense-history-export`,
                {
                    ids: ids,
                    reference: expensesInvestment && expensesInvestment.reference,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.url;
                }
            });
    };
    const getEntryType = ($type, $link = "no") => {
        if (
            $type == "Purchase Delivery Note" ||
            $type == "Purchase Delivery Note"
        ) {
            if ($link == "no") {
                return "Purchase Delivery Note";
            }
            return "/purchase-delivery-Notes/";
        }

        if ($type == "Purchase Order" || $type == "Purchase Order") {
            if ($link == "no") {
                return "Purchase Order";
            }
            return "/purchase-order/";
        }

        if ($type == "Purchase Invoice" || $type == "Purchase Invoice") {
            if ($link == "no") {
                return "Purchase Invoice";
            }
            return "/purchase-invoice/";
        }
    };
    useEffect(() => {
        productCategories();
        getProducts();
        selectedRoleId = [];
    }, []);

    return (
        <React.Fragment>
            <SendAttachment
                isAttachOpenModal={isAttachOpenModal}
                setIsAttachOpenModal={setIsAttachOpenModal}
                attach={attach}
                setAttach={setAttach}
                setAttachEmail={setAttachEmail}
                setAttachEmail2={setAttachEmail2}
                setAttachEmail3={setAttachEmail3}
                getSendAttachment2={getSendAttachment2}
                attachDocument={attachDocument}
                emailMessage2={emailMessage2}
                setEmailMessage2={setEmailMessage2}
                success={success}
                error={error}
            />
            <div className="page-content">
                <MetaTags>
                    <title>
                        {expensesInvestment && expensesInvestment.name}| Clouget
                    </title>
                </MetaTags>
                {isOpenAddress ? (
                    <ClientAddress
                        setIsOpenModal={setIsOpenAddress}
                        clients={clients}
                        setClientData={setClientData}
                        setClientNameData={setClientNameData}
                    />
                ) : (
                    ""
                )}

                {isWorkAddress ? (
                    <ClientWorkAddress
                        setIsOpenModal={setIsWorkAddress}
                        clients={clients}
                        setWorkData={setWorkData}
                        setClientNameData={setClientNameData}
                    />
                ) : (
                    ""
                )}
                <CategoryModal
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    getAllCategories={productCategories}
                    setSelectedCategory={setSelectedCategory}
                />
                <CreateTrialModal
                    isTrialModal={isTrialModal}
                    setIsTrialModal={setIsTrialModal}
                    nonActive={nonActive}
                />
                <Container fluid>
                    <div className="create_company">
                        {loader !== "" ? (
                            loader
                        ) : (
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    productCreateSubmit(e, v);
                                }}
                            >
                                <div className="page-title-box">
                                    <Row className="align-items-center">
                                        <Col md={6}>
                                            <h6 className="page-title">
                                                {expensesInvestment && expensesInvestment.name}
                                            </h6>
                                        </Col>

                                        <Col md={6}>
                                            {getPermission().products.products.edit.is_checked ===
                                                1 ? (
                                                <div className="float-end d-flex">
                                                    <Link
                                                        type="button"
                                                        to="/expenses-and-investments"
                                                        className="btn btn-primary"
                                                    >
                                                        {props.t("Back")}
                                                    </Link>
                                                    {isEditable ? (
                                                        <>
                                                            {loading ? (
                                                                <button
                                                                    className="btn btn-primary  disabled"
                                                                    type="button"
                                                                    disabled
                                                                >
                                                                    {props.t("Loading")}
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="btn btn-primary ms-2"
                                                                    type="submit"
                                                                >
                                                                    {props.t("Update")}
                                                                </button>
                                                            )}
                                                            <span
                                                                onClick={() => setIsEditable(false)}
                                                                className="btn btn-primary  ms-2"
                                                            >
                                                                {props.t("Cancel")}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <span
                                                            onClick={() => createNew()}
                                                            className="btn btn-primary ms-2"
                                                        >
                                                            {props.t("Edit")}
                                                        </span>
                                                    )}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                                {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                                {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label>{props.t("Reference")}:</label>
                                                    <div className="d-flex">
                                                        <AvField
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                            type="select"
                                                            name="reference"
                                                            value={expensesInvestment.reference}
                                                            onChange={(e) => getType(e)}
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            {allRef &&
                                                                allRef.map((item, i) => (
                                                                    <option value={item.prefix} key={i}>
                                                                        {item.prefix}
                                                                    </option>
                                                                ))}
                                                        </AvField>
                                                        <div className="w-100 ps-3">
                                                            <Input
                                                                className="w-100 quantity"
                                                                value={refNum}
                                                                type="number"
                                                                step="1"
                                                                min="1"
                                                                name="reference_number"
                                                                disabled={isEditable ? false : true}
                                                                placeholder={
                                                                    referenceType === "eai" ? "Automatic" : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mb-3 required">
                                                    <AvField
                                                        label={props.t("Name")}
                                                        type="text"
                                                        name="name"
                                                        readOnly={isEditable ? false : true}
                                                        value={expensesInvestment.name}
                                                        required="required"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        readOnly={isEditable ? false : true}
                                                        label={props.t("Purchase Price($)")}
                                                        type="number"
                                                        name="price"
                                                        value={expensesInvestment.price}
                                                    ></AvField>
                                                </div>
                                                <AvField
                                                    disabled={isEditable ? false : true}
                                                    type="select"
                                                    name="tax"
                                                    label={props.t("VAT")}
                                                    // value={expensesInvestment.vat}
                                                    value={expensesInvestment?.tax !== null ? expensesInvestment?.tax : "0"}
                                                    className="mb-4"
                                                >
                                                    {dataTax?.map((item, i) => (
                                                        <option value={item.tax} key={i}>
                                                            {dataTax.primary_name} {item.tax}
                                                        </option>
                                                    ))}
                                                    <option value="0">{props.t("Do not Apply")}</option>
                                                </AvField>
                                            </Col>

                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label>{props.t("Date")}</label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(e) => setStartDate(e)}
                                                        dateFormat="dd/MM/yyyy"
                                                        //   minDate={new Date()}
                                                        name="date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                        disabled={isEditable ? false : true}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label>{props.t("Category")}</label>
                                                    <div className="d-flex">
                                                        <div className="w-100 pe-2">
                                                            <AvField
                                                                disabled={isEditable ? false : true}
                                                                type="select"
                                                                name="category"
                                                                value={expensesInvestment.category_id}
                                                            >
                                                                <option value="" hidden>
                                                                    {props.t("Select Expense category")}...
                                                                </option>
                                                                {allCategories &&
                                                                    allCategories.map((category) => (
                                                                        <option value={category.id} key={category.id}>
                                                                            {props.t(category.name)} ({props.t(category.type)})
                                                                        </option>
                                                                    ))}
                                                            </AvField>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setIsOpenModal(true);
                                                            }}
                                                            className="btn btn-primary"
                                                            data-toggle="modal"
                                                            data-target=".bs-example-modal-lg"
                                                            disabled={isEditable ? false : true}
                                                        >
                                                            {props.t("New")}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="company_img img-flex">
                                                    <label>{props.t("Main image")}</label>
                                                    <input
                                                        className="hidden d-none"
                                                        onChange={(e) => setComImg2(e)}
                                                        type="file"
                                                        id="comImg2"
                                                    />
                                                    <label
                                                        className="company_img-box"
                                                        htmlFor={isEditable ? "comImg2" : ""}
                                                    >
                                                        <img src={mainImgPreview} />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: customActiveTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom("1");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("General")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={`${classnames({
                                                        active: customActiveTab === "2",
                                                    })}`}
                                                    onClick={() => {
                                                        toggleCustom("2");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("Images")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={`${classnames({
                                                        active: customActiveTab === "3",
                                                    })}`}
                                                    onClick={() => {
                                                        toggleCustom("3");
                                                    }}
                                                    disabled={isEditable ? true : false}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("Attachments")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={`${classnames({
                                                        active: customActiveTab === "4",
                                                    })} `}
                                                    onClick={() => {
                                                        toggleCustom("4");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("History")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={customActiveTab} className="p-3">
                                            <TabPane tabId="1">
                                                <Row></Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3 mt-3">
                                                            <AvField
                                                                readOnly={isEditable ? false : true}
                                                                label={props.t("Description")}
                                                                type="textarea"
                                                                name="description"
                                                                value={expensesInvestment.description}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                readOnly={isEditable ? false : true}
                                                                label={props.t("Private Comments")}
                                                                type="textarea"
                                                                name="private_comments"
                                                                value={expensesInvestment.private_comments}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="mt-3">
                                                        <div>
                                                            <AvField
                                                                readOnly={isEditable ? false : true}
                                                                label={props.t("Created from")}
                                                                type="text"
                                                                name="created_from"
                                                                value="Web"
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col lg="2">
                                                        <div className="company_img img-flex">
                                                            {/* <label>Image</label> */}
                                                            <input
                                                                className="hidden d-none"
                                                                onChange={(e) => setComImg(e)}
                                                                type="file"
                                                                id="comImg"
                                                            />
                                                            <label
                                                                className="company_img-box"
                                                                htmlFor={isEditable ? "comImg" : ""}
                                                            >
                                                                <img src={imgPreview} />
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="attach_btn">
                                                            <div
                                                                className="float-end d-flex btn btn-primary ms-2"
                                                                onClick={() => setIsOpenModalAttach(true)}
                                                            >
                                                                {props.t("New")}
                                                            </div>
                                                            <div
                                                                className="float-end d-flex btn btn-primary"
                                                                onClick={() => getSendAttachment()}
                                                            >
                                                                {props.t("Send via Email")}
                                                            </div>
                                                            <Table striped className="align-middle mt-5">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>{props.t("Name")}</th>
                                                                        <th>{props.t("Description")}</th>
                                                                        <th>{props.t("Upload Date")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {attachDocument &&
                                                                        attachDocument.map((item) => (
                                                                            <DocumentAttachment
                                                                                key={id}
                                                                                data={item}
                                                                                type="expense"
                                                                                Attached={item}
                                                                                selectedIds={selectedIds}
                                                                                setSelectedIds={setSelectedIds}
                                                                                selectedName={selectedName}
                                                                                setSelectedName={setSelectedName}
                                                                            />
                                                                        ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="4">
                                                <Col>
                                                    <span
                                                        onClick={() => getExport()}
                                                        className="btn btn-primary"
                                                    >
                                                        {props.t("Export")}
                                                    </span>
                                                </Col>
                                                {historyError ? (
                                                    <Alert color="danger">{props.t(historyError)}</Alert>
                                                ) : (
                                                    ""
                                                )}
                                                {error3 ? <Alert color="danger">{props.t(error3)}</Alert> : ""}
                                                <div className="table-responsive">
                                                    <Table striped className="align-middle">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>{props.t("Reference")}</th>
                                                                <th>{props.t("Client")}</th>
                                                                <th>{props.t("Title")}</th>
                                                                <th>{props.t("Created by")}</th>
                                                                <th>{props.t("Status")}</th>
                                                                <th>{props.t("Type")}</th>
                                                                <th>{props.t("Date")}</th>
                                                                <th>{props.t("Amount")}</th>
                                                                <th>{props.t("Unit price")}</th>
                                                                <th>{props.t("Quantity")}</th>
                                                                <td>{props.t("Prod. Total")}</td>
                                                                <th>{props.t("Activity")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {expInvHistory &&
                                                                expInvHistory.map((item, i) => (
                                                                    <tr
                                                                        key={i}
                                                                    // onClick={() =>
                                                                    //   history.push(
                                                                    //     getEntryType(item.reference, "yes") +
                                                                    //       item.id
                                                                    //   )
                                                                    // }
                                                                    // style={{ cursor: "pointer" }}
                                                                    >
                                                                        <td>
                                                                            <input
                                                                                onChange={(e) => getSelectedId(id, e)}
                                                                                name="chk"
                                                                                value={id}
                                                                                className="p-0 d-inline-block"
                                                                                type="checkbox"
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {item.reference}
                                                                            {item.reference_number}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {item.supplier}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {item.title}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {getCurrentUser().email}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {props.t(
                                                                                item.status == "pending"
                                                                                    ? "Pending"
                                                                                    : item.status == "pending invoice"
                                                                                        ? "Pending Invoice"
                                                                                        : item.status == "unpaid"
                                                                                            ? "Unpaid"
                                                                                            : item.status == "paid"
                                                                                                ? "Paid"
                                                                                                : item.status == "closed"
                                                                                                    ? "Closed"
                                                                                                    : item.status == "refused"
                                                                                                        ? "Refused"
                                                                                                        : item.status == " resolved"
                                                                                                            ? "Resolved"
                                                                                                            : item.status == "accepted"
                                                                                                                ? "Accepted"
                                                                                                                : item.status == "in_progress"
                                                                                                                    ? "In Progress"
                                                                                                                    : item.status
                                                                            )}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {props.t(item.type)}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {item.date}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {item.amount}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {item.unit_price}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {item.quantity}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {item.product_total}
                                                                        </td>
                                                                        <td
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    getEntryType(item.type, "yes") +
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            {item.activity}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </AvForm>
                        )}
                    </div>
                    <Modal size="lg" isOpen={isOpenModalAttach} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Document")}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Document")}</label>
                                            <input
                                                type="file"
                                                accept=".pdf, .doc, .docx, .xls, .xlsx, /image*"
                                                className="ms-3"
                                                name="document"
                                                onChange={filePreview}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Description")}</label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                                onChange={(e) => setexpense(e.target.value)}
                                            ></textarea>
                                        </div>
                                    </Col>
                                    {docError ? <Alert color="danger">{props.t(docError)}</Alert> : null}
                                </Row>
                                <div className="modal-footer">
                                    <div className="text-end">
                                        {loading ? (
                                            <button className="btn btn-primary" disabled>
                                                {props.t("Loading")}
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={() => createAttachment()}
                                            >
                                                {props.t("Submit")}
                                            </button>
                                        )}
                                        <button
                                            className="btn btn-primary ms-2"
                                            type="button"
                                            onClick={() => setIsOpenModalAttach(false)}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(EditExpenses);
EditExpenses.propTypes = {
    t: PropTypes.any,
};
