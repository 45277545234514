import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  Tab,
  TabPane,
  TabContent,
  Alert,
} from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getPermission, getCurrentUser } from "../../helpers/Utils";
import MultipleValueTextInput from "react-multivalue-text-input";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SendAttachment = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [change, setChange] = useState(false);

  return (
    <div>
      <Modal size="lg" isOpen={props.isAttachOpenModal} centered={true}>
        {props.success ? <Alert color="success">{props.success}</Alert> : null}
        {props.error ? <Alert color="danger">{props.error}</Alert> : ""}
        <AvForm className="form-horizontal">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.t("Send email with attachments")}
            </h5>
            <button
              type="button"
              className="btn btn-primary text-md btn-sm"
              onClick={() => props.setIsAttachOpenModal(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>

          <Row>
            <Col>
              <div className="modal-body">
                <div>
                  <AvField
                    label={props.t("Address")}
                    type="email"
                    name="address"
                    onChange={(e) => {
                      props.setAttachEmail(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <AvField
                    label={props.t("Cc")}
                    type="email"
                    name="cc"
                    onChange={(e) => {
                      props.setAttachEmail2(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <AvField
                    label={props.t("BCC")}
                    type="text"
                    name="address"
                    onChange={(e) => {
                      props.setAttachEmail3(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <AvField label={props.t("Subject")} type="text" name="subject" />
                </div>

                <div className="mt-1">{props.t("Attachments")}: {props.selectedName}</div>
                <div className="mt-1">
                  <span>{props.t("Message body")}</span>
                  <ReactQuill
                    theme="snow"
                    value={props.emailMessage2}
                    onChange={props.setEmailMessage2}
                  />
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-primary "
                    onClick={() => props.setIsAttachOpenModal(false)}
                    type="submit"
                  >
                    {props.t("Cancel")}
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    type="submit"
                    onClick={() => props.getSendAttachment2()}
                  >
                    {props.t("Send")}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </AvForm>
      </Modal>
    </div>
  );
};

export default withTranslation()(SendAttachment);
SendAttachment.propTypes = {
  t: PropTypes.any,
};