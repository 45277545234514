import React, { useState, useEffect } from "react";
import { Col, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
import SingleCategoryList from "./list";
const axios = require("axios").default;
import Loader from "../../../components/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateNewClient from "./CreateNewClient";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../../plans/trialModal";
import { Link } from "react-router-dom";

const AllClientCategories = (props) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !== 1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id == null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsOpenModal(true);
        }
    };
    const getCategories = () => {
        setData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-categories`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.client_categories);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedIds.push(parseInt(ele[i].value));
                }
            }
        } else {
            var elem = document.getElementsByName("chk");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedIds;
                    var index = array.indexOf(parseInt(elem[j].value));
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };

    const deleteMultiple = () => {
        let ids = selectedIds?.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-categories/batch-delete`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    getCategories();
                    setdynamic_description(res.data.message);
                }
            });
    };

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <>
            {isOpenModal ? (
                <CreateNewClient
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    getCategories={getCategories}
                />
            ) : (
                ""
            )}
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    warning
                    showCancel

                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setconfirm_alert(false);
                        setsuccess_dlg(true);
                        deleteMultiple();
                        setdynamic_title("Deleted");
                        setdynamic_description("Your file has been deleted.");
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    <span className="text-danger">
                        You won&apos;t be able to revert this!
                    </span>
                </SweetAlert>
            ) : null}
            {/* <div className="page-content"> */}
            <div className="company_list">
                <div className="page-title-box">
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h6 className="page-title">
                                {props.t("Client/Supplier Categories")}
                            </h6>
                        </Col>
                        <Col md={6}>
                            <div className="float-end">
                                {getPermission()?.client_and_supplier_categories
                                    .client_and_supplier_categories.delete.is_checked === 1 ? (
                                    <div className="btn-group me-2">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                className="btn btn-primary"
                                            // id="dropdown-basic"
                                            >
                                                {props.t("More")}
                                                {/* <i className="fa fa-caret-down ms-2"></i> */}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item>
                                                    <li>
                                                        <span
                                                            //   className="dropdown-item"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setconfirm_alert(true);
                                                            }}
                                                        >
                                                            {props.t("Batch delete")}
                                                        </span>
                                                    </li>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {getPermission()?.client_and_supplier_categories
                                    .client_and_supplier_categories.create.is_checked === 1 ? (
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => createNew()}
                                    >
                                        {props.t("Create New")}
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
                <Card>
                    <CardBody>
                        <div className="overflow-auto w-100">
                            <Table striped className="align-middle">
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                onClick={(e) => selectAllCheck(e.target)}
                                                className="p-0 d-inline-block"
                                                type="checkbox"
                                            />
                                        </th>
                                        <th>{props.t("Name")}</th>
                                        <th>{props.t("Description")}</th>
                                        <th>{props.t("Rate")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data &&
                                        data.map((item, i) => (
                                            <SingleCategoryList
                                                data={item}
                                                key={i}
                                                i={i}
                                                selectedIds={selectedIds}
                                                setSelectedIds={setSelectedIds}
                                            />
                                        ))}
                                </tbody>
                            </Table>
                        </div>
                        {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                        {loader}
                    </CardBody>
                </Card>
                {/* </div> */}
            </div>
            {/* </div> */}
        </>
    );
};

export default withTranslation()(AllClientCategories);
AllClientCategories.propTypes = {
    t: PropTypes.any,
};
