/* eslint-disable no-undef */
import React, { useState } from "react";
import PdfModal from "./defaultPdfModal";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const DefaultPdfByOptions = (props) => {
  const [defaultPdf, setDefaultPdf] = useState(props.data);
  const [defaultPdf_modal, setDefaultPdf_modal] = useState(false);

  return (
    <>
      {defaultPdf_modal ? (
        <PdfModal
          defaultPdf_modal={defaultPdf_modal}
          setDefaultPdf_modal={setDefaultPdf_modal}
          defaultPdf={defaultPdf}
          setDefaultPdf={setDefaultPdf}
        />
      ) : null}
      <tr style={{ cursor: "pointer" }}>
        <td>
          <span onClick={() => setDefaultPdf_modal(true)}>
            {props.t(defaultPdf.type)}
          </span>
        </td>
        <td>
          <span onClick={() => setDefaultPdf_modal(true)}>
            {props.t(defaultPdf.format)}
          </span>
        </td>
        <td>
          <span onClick={() => setDefaultPdf_modal(true)}>
            {defaultPdf.price_after_tax == "1" ? (
              <i className="fa fa-check" />
            ) : (
              <i className="fa fa-times" />
            )}
          </span>
        </td>
        <td>
          <span onClick={() => setDefaultPdf_modal(true)}>
            {defaultPdf.mailing_format == "1" ? (
              <i className="fa fa-check" />
            ) : (
              <i className="fa fa-times" />
            )}
          </span>
        </td>
        <td>
          <span onClick={() => setDefaultPdf_modal(true)}>
            {defaultPdf.include_main_image == "1" ? (
              <i className="fa fa-check" />
            ) : (
              <i className="fa fa-times" />
            )}
          </span>
        </td>
      </tr>
    </>
  );
};

export default withTranslation()(DefaultPdfByOptions);
DefaultPdfByOptions.propTypes = {
  t: PropTypes.any,
};