import React, { useState, useEffect } from "react";
import RateModal from "./rate-modal";
import { Alert, Table } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser } from "../../../helpers/Utils";
import SingleList from "./single-list";
import Loader from "../../../components/Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function SpecialPrice(props) {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [error, setError] = useState("");
    const [allRates, setAllRates] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [rateLoading, setRateLoading] = useState(false);

    const getAllRates = () => {
        setAllRates([]);
        setRateLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-special-prices?client_id=${props.client && props.client.id
                }&type=sale_price`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setRateLoading(false);
                setLoader("");
                if (res.data.status) {
                    setAllRates(res.data.client_special_prices);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getAllRates();
    }, []);

    return (
        <>
            <RateModal
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                getAllRates={getAllRates}
                client={props.client}
                setMainFormDisabled={props.setMainFormDisabled}
            />
            <button
                type="button"
                onClick={() => {
                    setIsOpenModal(true);
                }}
                className="btn btn-primary float-end d-flex ms-2"
                data-toggle="modal"
                data-target=".bs-example-modal-lg"
            >
                {props.t("New")}
            </button>
            <div className="table-responsive mt-3">
                <Table striped className="align-middle">
                    <thead className="table-light">
                        <tr>
                            <th>{props.t("Product / Service")}</th>
                            <th>{props.t("Special Sales Price")}</th>
                            <th>{props.t("Discount (%)")}</th>
                            <th>{props.t("Purchase Margin (%)")}</th>
                            <th>{props.t("Sales Margin (%)")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    {/* {rateLoading ? (
            "Loading..."
          ) : ( */}
                    <tbody>
                        {allRates &&
                            allRates.map((item, i) => (
                                <SingleList data={item} key={i} client={props.client} />
                                //   type={props.type}
                            ))}
                    </tbody>
                    {/* )} */}
                </Table>
                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                {loader}
            </div>
        </>
    );
}
export default withTranslation()(SpecialPrice);
SpecialPrice.propTypes = {
    t: PropTypes.any,
};
