import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import {
    getCurrentUser,
    getFilterDate,
    setFilterDate,
} from "../../../helpers/Utils";
const axios = require("axios").default;
// import AllReceipts from "../../../components/AllInvoices/all-recipt-list";
import ListPurchaseReceipts from "./listPurchaseReceipts";
import Loader from "../../../components/Loader";
import MultipleChoice from "../../../components/filters/multiChoice";
import DateFilter from "../../../components/filters/dateFilter";
import InputFilter from "../../../components/filters/searchFilter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PurchaseReceipts = (props) => {
    const [ref_filter, setRef_filter] = useState(false);
    const [supName_filter, setSupName_filter] = useState(false);
    const [title_filter, setTitle_filter] = useState(false);
    const [createdby_filter, setCreatedBy_filter] = useState(false);
    const [amount_filter, setAmount_filter] = useState(false);
    //   const [statusPO_filter, setstatusPO_filter] = useState(false);
    const [dateExp_filter, setdateExp_filter] = useState(false);
    const [datePay_filter, setdatePay_filter] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [timer, setTimer] = useState(null);
    const [assignUsers, setAssignUsers] = useState([]);
    const [paymentData, setPaymentData] = useState("");
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const getSearchData = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getInvoiceData(e);
        }, 1000);
        setTimer(newTimer);
    };

    const getInvoiceData = (e) => {
        setInvoiceData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-receipts?type=pinv&search=${e != undefined ? e : ""}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setInvoiceData(res.data.purchase_receipts);
                } else {
                    setError(res.data.message);
                }
            });
    };
    const getSearchedList = (e, type) => {
        setError("");
        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "supplier_name") {
            localStorage.setItem("supplierName", e);
        }
        if (type == "dateExp") {
            setFilterDate(e);
        }
        if (type == "datePay") {
            localStorage.setItem("datePay", JSON.stringify(e));
        }
        if (type == "amount") {
            localStorage.setItem("amount", e);
        }
        setInvoiceData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-receipts?type=pinv&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&supplier_name=${localStorage.getItem("supplierName") !== null
                    ? localStorage.getItem("supplierName")
                    : ""
                }&amount=${localStorage.getItem("amount") !== null
                    ? localStorage.getItem("amount")
                    : ""
                }&exp_start_date=${getFilterDate() && getFilterDate().from !== null
                    ? getFilterDate().from
                    : ""
                }&exp_end_date=${getFilterDate() && getFilterDate().to !== null
                    ? getFilterDate().to
                    : ""
                }&payment_start_date=${JSON.parse(localStorage.getItem("datePay")).from !== null
                    ? JSON.parse(localStorage.getItem("datePay")).from
                    : ""
                }&payment_end_date=${JSON.parse(localStorage.getItem("datePay")).to !== null
                    ? JSON.parse(localStorage.getItem("datePay")).to
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setInvoiceData(res.data.purchase_receipts);
                    setRef_filter(false);
                    setSupName_filter(false);
                    setTitle_filter(false);
                    setdateExp_filter(false);
                    setdatePay_filter(false);
                    setCreatedBy_filter(false);
                    setAmount_filter(false);
                    //   setstatusPO_filter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };
    const NewpaymentShow = () => {
        setPaymentLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setPaymentData(res.data.PaymentOptions);
                setPaymentLoader(false);
            });
    };

    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };
    const getExport = () => {
        let ids = selectedIds?.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-receipts-export`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.url;
                }
            });
    };

    useEffect(() => {
        getInvoiceData();
        NewpaymentShow();
        getAssignUser();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Purchase Receipts | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h6 className="page-title">{props.t("Purchase Receipts")}</h6>
                                </Col>
                                <Col md={6}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getSearchData(e.target.value)}
                                            />
                                        </div>
                                        <span
                                            className="btn btn-primary"
                                            onClick={() => getExport()}
                                        >
                                            {props.t("Export")}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Reference")}
                                                        {ref_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setRef_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("reference") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setRef_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            ref_filter={ref_filter}
                                                            setRef_filter={setRef_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Supplier")}
                                                        {supName_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setSupName_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("supplierName") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setSupName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setSupName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            supName_filter={supName_filter}
                                                            setSupName_filter={setSupName_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{props.t("Concept")}</th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Expiration Date")}
                                                        {dateExp_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setdateExp_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {getFilterDate() &&
                                                                    getFilterDate().from == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setdateExp_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setdateExp_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <DateFilter
                                                            dateExp_filter={dateExp_filter}
                                                            setdateExp_filter={setdateExp_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Payment Date")}
                                                        {datePay_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setdatePay_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {JSON.parse(localStorage.getItem("datePay")) &&
                                                                    JSON.parse(localStorage.getItem("datePay"))
                                                                        .from == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setdatePay_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setdatePay_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <DateFilter
                                                            datePay_filter={datePay_filter}
                                                            setdatePay_filter={setdatePay_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{props.t("Payment Option")}</th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Amount")}
                                                        {amount_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setAmount_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("amount") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setAmount_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setAmount_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            amount_filter={amount_filter}
                                                            setAmount_filter={setAmount_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{props.t("Status")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceData &&
                                                invoiceData.map((item) => (
                                                    <ListPurchaseReceipts
                                                        data={item}
                                                        key={item.id}
                                                        getAssignUser={getAssignUser}
                                                        NewpaymentShow={NewpaymentShow}
                                                        paymentData={paymentData}
                                                        assignUsers={assignUsers}
                                                        paymentLoader={paymentLoader}
                                                        selectedIds={selectedIds}
                                                        setSelectedIds={setSelectedIds}
                                                    />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {loader}
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(PurchaseReceipts);
PurchaseReceipts.propTypes = {
    t: PropTypes.any,
};
