import React, { useState } from "react";
import PropTypes from "prop-types";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;
import SweetAlert from "react-bootstrap-sweetalert";
import UpdateStockSingle from "./updateSingleStock";
import { withTranslation } from "react-i18next";

const SingleStockData = (props) => {
  const [user, setUser] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  //   const [isOpenModals, setIsOpenModals] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  //   const [selectedCategory, setSelectedCategory] = useState("");

  const deleteUser = (Id) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/product-stock/${Id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setUser(null);
        setdynamic_description(res.data.message);
        props.getSingleProduct();
      });
  };

  if (user === null) {
    return "";
  } else
    return (
      <>
        {isOpenModal ? (
          <UpdateStockSingle
            setIsOpenModal={setIsOpenModal}
            setUser={setUser}
            isOpenModal={isOpenModal}
            data={user}
            getSingleProduct={props.getSingleProduct}
            proName={props.getStock}
            setMainFormDisabled={props.setMainFormDisabled}
          />
        ) : null}
        <tr>
          <td
            onClick={() => {
              setIsOpenModal(true), props.setMainFormDisabled(true);
            }}
          >
            {props.t(user.warehouse)}
          </td>
          <td
            onClick={() => {
              setIsOpenModal(true), props.setMainFormDisabled(true);
            }}
          >
            {user.stock}
          </td>
          <td
            onClick={() => {
              setIsOpenModal(true), props.setMainFormDisabled(true);
            }}
          >
            {user.virtual_stock}
          </td>
          <td
            onClick={() => {
              setIsOpenModal(true), props.setMainFormDisabled(true);
            }}
          >
            {user.minimum_stock}
          </td>
          <td
            onClick={() => {
              setIsOpenModal(true), props.setMainFormDisabled(true);
            }}
          >
            {props.t(user.location)}
          </td>
          <td>
            <button
              className="btn btn-danger"
              onClick={() => {
                setconfirm_alert(true);
              }}
            >
              {props.t("Delete")}
            </button>
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
            cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteUser(user.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("Stock has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
};

export default withTranslation()(SingleStockData);
SingleStockData.propTypes = {
  t: PropTypes.any,
};
