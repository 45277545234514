import React from "react"
import MetaTags from 'react-meta-tags'
import { Container, Table, Card, CardBody } from "reactstrap"

const SettingPage = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Setting | Clouget</title>
        </MetaTags>
        <Container fluid>
          <div className="company_list">
            <div className="page-title-box">
				<h6 className="page-title">Setting</h6>
            </div>
            <Card>
              <CardBody>
                <Table striped className="align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Image</th>
                      <th>Reference</th>
                      <th>Name</th>
                      {/* <th>Stock</th> */}
                      {/* <th>Virtual Stock</th> */}
                      <th>Price</th>
                      <th>Promotional</th>
                      <th>Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SettingPage