import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Input,
} from "reactstrap";
import classnames from "classnames";
import CategoryModal from "./expensesModel";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uploadImg from "../../assets/images/def-img.svg";
import ClientAddress from "../../components/ClientAddresses";
import ClientWorkAddress from "../../components/ClientAddresses/workAddress";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const CreateExpenses = (props) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [clients, setClients] = useState("");
    const [workData, setWorkData] = useState("");
    const [clientNameData, setClientNameData] = useState("");
    const [isOpenAddress, setIsOpenAddress] = useState(false);
    const [isWorkAddress, setIsWorkAddress] = useState(false);
    const [clientData, setClientData] = useState("");
    const [assetData, setAssetData] = useState("");
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [mainImgPreview, setMainImgPreview] = useState(uploadImg);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [image, setImage] = useState("");
    const [mainimage, setMainImage] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [referenceType, setReferenceType] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [dataTax, setDataTax] = useState([]);
    const [defaultTax, setDefaultTax] = useState("");
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");
    const [startDate, setStartDate] = useState(new Date());

    const productCreateSubmit = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("reference", value.reference);
        formData.append("reference_number", e.target.reference_number.value);
        formData.append("name", value.name);
        formData.append("price", value.price);
        formData.append("category_id", value.category);
        formData.append("private_comments", e.target.private_comments.value);
        formData.append("description", value.description);
        formData.append("images", image);
        formData.append("image", mainimage);
        formData.append("tax", e.target.tax.value);
        formData.append("created_at", e.target.date.value);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_investments`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    history.push(
                        `/edit-expenses-and-investments/${res.data.expense_and_investment.id}`
                    );
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };
    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };
    const setComImg2 = (e) => {
        setMainImage(e.target.files[0]);
        setMainImgPreview(URL.createObjectURL(e.target.files[0]));
    };
    const getProducts = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_investments`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setData(res.data.products);
                } else {
                    // setError(res.data.message);
                }
            });
    };

    const productCategories = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_categories`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllCategories(res.data.expense_categories);
            });
    };

    let optionAssets = [];
    if (assetData !== undefined) {
        {
            assetData &&
                assetData.map((asset) => {
                    optionAssets.push({
                        value: asset.id,
                        label: asset.name,
                    });
                });
        }
    }

    let optionCategory = [];
    if (allCategories !== undefined) {
        {
            allCategories &&
                allCategories.map((category) => {
                    optionCategory.push({
                        value: category.id,
                        label: category.name,
                    });
                });
        }
    }
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };
    const getType = (e) => {
        setReferenceType(e.target.value);
    };

    const getTaxes = () => {
        setDataTax([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/cosumption-taxes`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setDataTax(res.data.taxes);
                    res.data.taxes.taxes?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultTax(item.tax);
                        }
                    });
                }
            });
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Expense and investment`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                    res.data.reference?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultRef(item.prefix);
                        }
                    });
                } else {
                    //   setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        productCategories();
        getProducts();
        getTaxes();
        getReferences();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title> New expense and investment| Clouget</title>
                </MetaTags>
                {isOpenAddress ? (
                    <ClientAddress
                        setIsOpenModal={setIsOpenAddress}
                        clients={clients}
                        setClientData={setClientData}
                        setClientNameData={setClientNameData}
                    />
                ) : (
                    ""
                )}
                {isWorkAddress ? (
                    <ClientWorkAddress
                        setIsOpenModal={setIsWorkAddress}
                        clients={clients}
                        setWorkData={setWorkData}
                        setClientNameData={setClientNameData}
                    />
                ) : (
                    ""
                )}
                <CategoryModal
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    getAllCategories={productCategories}
                    setSelectedCategory={setSelectedCategory}
                />
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                productCreateSubmit(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">
                                            {props.t("New expense and investment")}
                                        </h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary " type="submit">
                                                    {props.t("Submit")}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label>{props.t("Reference")}:</label>
                                                <div className="d-flex">
                                                    <AvField
                                                        style={{
                                                            width: "100px",
                                                        }}
                                                        type="select"
                                                        name="reference"
                                                        value={defaultRef}
                                                        onChange={(e) => getType(e)}
                                                    >
                                                        {allRef &&
                                                            allRef.map((item, i) => (
                                                                <option value={item.prefix} key={i}>
                                                                    {item.prefix}
                                                                </option>
                                                            ))}
                                                    </AvField>
                                                    <div className="w-100 ps-3">
                                                        <Input
                                                            className="w-100 quantity"
                                                            type="number"
                                                            step="1"
                                                            min="1"
                                                            name="reference_number"
                                                            placeholder={props.t("Automatic")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Name")}
                                                    type="text"
                                                    name="name"
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 ">
                                                <AvField
                                                    label={props.t("Purchase Price ($)")}
                                                    type="number"
                                                    name="price"
                                                    value="0.00"
                                                />
                                            </div>
                                            <AvField
                                                type="select"
                                                name="tax"
                                                label={props.t("VAT")}
                                                className="mb-4"
                                                value={defaultTax}
                                            >
                                                {dataTax?.map((item, i) => (
                                                    <option value={item.tax} key={i}>
                                                        {dataTax.primary_name} {item.tax}
                                                    </option>
                                                ))}
                                                <option value="0">{props.t("Do not apply")}</option>
                                            </AvField>
                                        </Col>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label>{props.t("Date")}</label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(e) => setStartDate(e)}
                                                    dateFormat="dd/MM/yyyy"
                                                    //   minDate={new Date()}
                                                    autoComplete="off"
                                                    name="date"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Category")}</label>
                                                <div className="d-flex">
                                                    <div className="w-100 pe-2">
                                                        <AvField
                                                            type="select"
                                                            name="category"
                                                            value={selectedCategory}
                                                        >
                                                            <option value="" hidden>
                                                                {props.t("Select category")}...
                                                            </option>
                                                            {allCategories &&
                                                                allCategories.map((category) => (
                                                                    <option value={category.id} key={category.id}>
                                                                        {props.t(category.name)} ({props.t(category.type)})
                                                                    </option>
                                                                ))}
                                                        </AvField>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setIsOpenModal(true);
                                                        }}
                                                        className="btn btn-primary"
                                                        data-toggle="modal"
                                                        data-target=".bs-example-modal-lg"
                                                    >
                                                        {props.t("New")}
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <div className="d-flex ">
                        <div className="mb-3">
                          <label htmlFor="Expense">Expense</label>
                          <input
                            id="Expense"
                            type="radio"
                            className="ms-2"
                            value="expense"
                            name="type"
                            onChange={(e) => getExpenseType(e.target.value)}
                          />
                        </div>
                        <div className="mb-3 ms-4">
                          <label htmlFor="Investment">Investment</label>
                          <input
                            id="Investment"
                            type="radio"
                            className="ms-2"
                            name="type"
                            value="investment"
                            onChange={(e) => getExpenseType(e.target.value)}
                          />
                        </div> */}
                                            {/* </div> */}
                                            <div className="company_img img-flex">
                                                <label>{props.t("Main image")}</label>
                                                <input
                                                    className="hidden d-none"
                                                    onChange={(e) => setComImg2(e)}
                                                    type="file"
                                                    id="comImg2"
                                                />
                                                <label className="company_img-box" htmlFor="comImg2">
                                                    <img src={mainImgPreview} />
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("General")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "2",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Images")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "3",
                                                })}disabled`}
                                                onClick={() => {
                                                    toggleCustom("3");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Attachments")}
                                                </span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "4",
                                                })} `}
                                                onClick={() => {
                                                    toggleCustom("4");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("History")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="p-3">
                                        <TabPane tabId="1">
                                            <Row></Row>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-3 mt-3">
                                                        <AvField
                                                            label={props.t("Description")}
                                                            type="textarea"
                                                            name="description"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Private Comments")}
                                                            type="textarea"
                                                            name="private_comments"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-3">
                                                    <div>
                                                        <AvField
                                                            label={props.t("Created from")}
                                                            type="text"
                                                            name="created_from"
                                                            value="Web"
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col lg="6">
                                                    <div className="company_img img-flex">
                                                        <input
                                                            className="hidden d-none"
                                                            onChange={(e) => setComImg(e)}
                                                            type="file"
                                                            id="comImg"
                                                        />
                                                        <label className="company_img-box" htmlFor="comImg">
                                                            <img src={imgPreview} />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(CreateExpenses);
CreateExpenses.propTypes = {
    t: PropTypes.any,
};
