import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPasswordPage from "../pages/Authentication/ResetPassword";
import TermsConditionsPage from "../pages/Authentication/TermsConditions";
import PricayPolicyPage from "../pages/Authentication/PrivacyPolicy";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Role CRUD
import AllSuppliers from "../pages/Purchases/suppliers";
import CreateSupplier from "../pages/Purchases/create/create-supplier";
import SingleSupplier from "../pages/Purchases/edit/edit-supplier";
import SubscriptionPlan from "../pages/plans";
import CreateCompany from "../pages/Company/create-company";
import AllCompanies from "../pages/Company/all";
import SingleCompany from "../pages/Company/single-company";
import AllBankAccounts from "../pages/BankAccounts";
import AllRoles from "../pages/Role";
import CreateRole from "../pages/Role/create-role";
import SingleRole from "../pages/Role/single-role";
import AllProducts from "../pages/Products/all";
import CreateProduct from "../pages/Products/create-product";
import SingleProduct from "../pages/Products/single-product";
import AllCategory from "../pages/Category";
import SettingPage from "../pages/Setting";
import Templates from "../pages/Templates/all";
import TechnicalCustom from "../pages/TechnicalServices/all";
import CreateServiceNew from "../pages/TechnicalServices/create-incidents";
import EditServiceNew from "../pages/TechnicalServices/edit/technical-incident-edit";
import EditTechnicalTable from "../pages/TechnicalServices/edit/technical-tables-edit";
import CreateTemplate from "../pages/Templates/create-template";
import SingleTemplate from "../pages/Templates/single-template";
import InvoicingCustom from "../pages/Invoicing/all";
import Users from "../pages/Users/all";
import NewUser from "../pages/Users/create-user";
import SingleUser from "../pages/Users/single-user";
import CustomStates from "../pages/States/all";
import SingleStates from "../pages/States/single-state";
import AllPriceRate from "../pages/PriceRate";
import AllServices from "../pages/Services/all";
import CreateService from "../pages/Services/create-service";
import SingleService from "../pages/Services/single-service";
import EmailConfigurationPage from "../pages/EmailConfiguration";
import AssestCustom from "../pages/Assests/all";
import CreateAssests from "../pages/Assests/create-assests";
import EditSale from "../pages/Sales/edit/sales-edit";
import InvoicingServiceEdit from "../pages/Invoicing/edit/invoice-edit";
import Clients from "../pages/Clients";
import CreateClient from "../pages/Clients/create";
import SingleClient from "../pages/Clients/single-user";
import Contacts from "../pages/Contacts";
import CreateContact from "../pages/Contacts/create";
import InvoicingService from "../pages/Invoicing/invoices-create";
import RefundInvoiceCreate from "../pages/Invoicing/refund-invoice-create";
import Sales from "../pages/Sales/all";
import CreateServiceSale from "../pages/Sales/create-new-sales";
import CreateServiceOrder from "../pages/Sales/create-new-order";
import CreateServiceDelivery from "../pages/Sales/create-new-delivery";
import Order from "../pages/Sales/order";
import SendEmail from "../pages/sendEmail/index";
import DeliveryNotes from "../pages/Sales/delivery-notes";
import EditAssests from "../pages/Assests/single-assets";
import Estimates from "../pages/TechnicalServices/estimates";
import WorkOrder from "../pages/TechnicalServices/work-orders";
import WorkDelivery from "../pages/TechnicalServices/work-delivery-notes";
import CreateServiceEstimates from "../pages/TechnicalServices/create-new-estimates";
import CreateServiceWorkDelivery from "../pages/TechnicalServices/create-new-work-delivery";
import CreateServiceWorkOrder from "../pages/TechnicalServices/create-new-work-order";
import InvoiceReceipts from "../pages/Invoicing/invoice-receipts";
import RefundInvoices from "../pages/Invoicing/refund-invoices";
import RefundReceipts from "../pages/Invoicing/refund-receipts";
import SentInvoiceSummary from "../pages/Invoicing/sent-invoice-summary";
import ReferencesConfig from "../pages/BasicConfiguration/References";
import PaymentTerms from "../pages/BasicConfiguration/PaymentTerms";
import PurchaseOrder from "../pages/Purchases/PurchaseOrder/purchaseOrder";
import PurchaseOrderCreate from "../pages/Purchases/PurchaseOrder/createPurchase";
import PurchaseOrderEdit from "../pages/Purchases/PurchaseOrder/editPurchaseOrder";
import PurchaseDelivery from "../pages/Purchases/PurchaseDelivery/purchaseDelivery";
import PurchaseDeliveryCreate from "../pages/Purchases/PurchaseDelivery/createPurchase";
import PurchaseDeliveryEdit from "../pages/Purchases/PurchaseDelivery/editPurchaseDelivery";
import PurchaseDeliveryNote from "../pages/Purchases/PurchaseDeliveryNote/purchaseDeliveryNote";
import PurchaseDeliveryNoteCreate from "../pages/Purchases/PurchaseDeliveryNote/createPurchaseDeliveryNote";
import PurchaseDeliveryNoteEdit from "../pages/Purchases/PurchaseDeliveryNote/editPurchaseDeliveryNote";
import PurchaseReceipts from "../pages/Purchases/purchaseReceipts/purchaseReceipts";
import ReportOverview from "../pages/Reports/overview";
import TaxSummary from "../pages/Reports/taxSummary";
import ConsumptionTax from "../pages/AdvanceConfiguration/Taxes/consumption";
import IncomeTax from "../pages/AdvanceConfiguration/Taxes/Income";
import ImprotExport from "../pages/ImportExport/index";
import ClientCategory from "../pages/Management-listings/Client-supplier-catagory";
import AllPaymentOptions from "../pages/Management-listings/payment-options";
import CreatePaymentTerm from "../pages/BasicConfiguration/PaymentTerms/CreatePaymentTerm";
import EditPaymentTerm from "../pages/BasicConfiguration/PaymentTerms/EditPaymentTermList";
import AllDeliveryOptions from "../pages/Management-listings/delivery-options";
import AllExpenseCategory from "../pages/Management-listings/Expense-Categories";
import StockValuation from "../pages/Reports/stockValuation";
import ReportCashflowOverview from "../pages/Reports/cashFlow/overview";
import ReportSalesOverview from "../pages/Reports/sales/overview";
import TechnicalServiceOverview from "../pages/Reports/technicalService/overview";
import InvoicingByItem from "../pages/Reports/Invoicing/byItems";
import InvoicingByAgent from "../pages/Reports/Invoicing/byAgent";
import InvoicingByClient from "../pages/Reports/Invoicing/byclient";
import PurchaseByItem from "../pages/Reports/purchases/purchaseByItem";
import OfProfit from "../pages/Reports/ofevolution/ofProfit";
import evolutionClient from "../pages/Reports/ofevolution/invoicingByClient";
import evolutionAgent from "../pages/Reports/ofevolution/invoicingbyAgent";
import evolutionItem from "../pages/Reports/ofevolution/invoicingByItem";
import PurchaseByPro from "../pages/Reports/ofevolution/purchaseByProvider";
import PurchasesByItem from "../pages/Reports/ofevolution/purchaseByItem";
import PurchaseBySupplier from "../pages/Reports/purchases/bySuppliers";
import SaleByClient from "../pages/Reports/sales/saleByClient";
import SaleByAgent from "../pages/Reports/sales/saleByAgent";
import SaleByItem from "../pages/Reports/sales/saleByItem";
import CashFlowAgent from "../pages/Reports/cashFlow/cashFlowAgent";
import CashFlowPayment from "../pages/Reports/cashFlow/byPaymentOption";
import TechClient from "../pages/Reports/technicalService/techByClient";
import TechItems from "../pages/Reports/technicalService/techByItem";
import TechAgent from "../pages/Reports/technicalService/techByAgent";
import IncidentAgent from "../pages/Reports/technicalService/incidentAgent";
import IncidentClient from "../pages/Reports/technicalService/incidentClient";
import TicketsAndExpenses from "../pages/Purchases/TicketsAndExpenses/ticketsAndExpens";
import CreateTicketsAndExpenses from "../pages/Purchases/TicketsAndExpenses/createTickets";
import EditTicketsAndExpenses from "../pages/Purchases/TicketsAndExpenses/editTicketsAndExpenes";
import PurchaseInvoiceSummary from "../pages/Purchases/purchaseInvoiceSummary/purchaseInvoiceSummary";
import ExpensesAndInvestments from "../pages/expensesAndInvestments/expensesAndInvestments";
import CreateExpenses from "../pages/expensesAndInvestments/CreateExpensesAndInvestments";
import EditExpenses from "../pages/expensesAndInvestments/editExpensesAndInvestments";
import myMembership from "../pages/plans/myMembership";
import Pages404 from "../pages/PageNotFound/index";

const userRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/not-found", component: Pages404 },

    //profile
    { path: "/plans", component: SubscriptionPlan },
    { path: "/membership", component: myMembership },
    { path: "/profile", component: UserProfile },
    { path: "/create-company", component: CreateCompany },
    { path: "/companies", component: AllCompanies },
    { path: "/company", component: SingleCompany },
    { path: "/bank-accounts", component: AllBankAccounts },
    { path: "/assets", component: AssestCustom },
    { path: "/create-assests", component: CreateAssests },
    { path: "/assets/:id", component: EditAssests },
    { path: "/roles", component: AllRoles },
    { path: "/products", component: AllProducts },
    { path: "/new-product", component: CreateProduct },
    { path: "/product/:id", component: SingleProduct },
    { path: "/category", component: AllCategory },
    { path: "/setting", component: SettingPage },
    { path: "/templates", component: Templates },
    { path: "/technical-services", component: TechnicalCustom },
    { path: "/technical-services/:id", component: EditServiceNew },
    { path: "/create-new-teachnical", component: CreateServiceNew },
    { path: "/new-template", component: CreateTemplate },
    { path: "/template/:id", component: SingleTemplate },
    { path: "/users", component: Users },
    { path: "/new-user", component: NewUser },
    { path: "/user/:id", component: SingleUser },
    { path: "/custom-states", component: CustomStates },
    { path: "/state/:id", component: SingleStates },
    { path: "/rates", component: AllPriceRate },
    { path: "/services", component: AllServices },
    { path: "/new-service", component: CreateService },
    { path: "/service/:id", component: SingleService },
    { path: "/email-configuration", component: EmailConfigurationPage },
    { path: "/new-role", component: CreateRole },
    { path: "/role/:id", component: SingleRole },
    { path: "/clients", component: Clients },
    { path: "/client/new", component: CreateClient },
    { path: "/client/:id", component: SingleClient },
    { path: "/contacts", component: Contacts },
    { path: "/contact/new", component: CreateContact },
    { path: "/invoicing", component: InvoicingCustom },
    { path: "/sales", component: Sales },
    { path: "/sales/:id", component: EditSale },
    { path: "/invoicing-service/:id", component: InvoicingServiceEdit },
    { path: "/create-new-sale", component: CreateServiceSale },
    { path: "/create-new-delivery", component: CreateServiceDelivery },
    { path: "/create-new-order", component: CreateServiceOrder },
    { path: "/order", component: Order },
    { path: "/send", component: SendEmail },
    { path: "/delivery", component: DeliveryNotes },
    { path: "/estimates", component: Estimates },
    { path: "/work-order", component: WorkOrder },
    { path: "/work-delivery", component: WorkDelivery },
    { path: "/create-new-estimates", component: CreateServiceEstimates },
    { path: "/create-new-work-delivery", component: CreateServiceWorkDelivery },
    { path: "/technical-service/:id", component: EditTechnicalTable },
    { path: "/create-new-work-order", component: CreateServiceWorkOrder },
    { path: "/invoice-receipts", component: InvoiceReceipts },
    { path: "/refund-invoices", component: RefundInvoices },
    { path: "/refund-receipts", component: RefundReceipts },
    { path: "/sent-invoice-summary", component: SentInvoiceSummary },
    { path: "/references", component: ReferencesConfig },
    { path: "/payment-term", component: PaymentTerms },
    { path: "/payment-term/:id", component: EditPaymentTerm },
    { path: "/create-payment-term", component: CreatePaymentTerm },
    { path: "/consumption-tax", component: ConsumptionTax },
    { path: "/import-export", component: ImprotExport },

    { path: "/income-tax", component: IncomeTax },
    { path: "/client-categories", component: ClientCategory },
    { path: "/payment-options", component: AllPaymentOptions },
    { path: "/delivery-options", component: AllDeliveryOptions },
    { path: "/expense-category", component: AllExpenseCategory },
    { path: "/suppliers", component: AllSuppliers },
    { path: "/suppliers/:id", component: SingleSupplier },
    { path: "/create-supplier", component: CreateSupplier },
    { path: "/purchase-order", component: PurchaseOrder },
    { path: "/create-purchase-order", component: PurchaseOrderCreate },
    { path: "/purchase-order/:id", component: PurchaseOrderEdit },
    { path: "/purchase-invoice", component: PurchaseDelivery },
    { path: "/create-purchase-delivery", component: PurchaseDeliveryCreate },
    { path: "/purchase-invoice/:id", component: PurchaseDeliveryEdit },
    { path: "/purchase-delivery-Notes", component: PurchaseDeliveryNote },
    { path: "/purchase-receipts", component: PurchaseReceipts },
    { path: "/tickets-and-expenses", component: TicketsAndExpenses },
    { path: "/create-tickets-and-expenses", component: CreateTicketsAndExpenses },
    { path: "/tickets-and-expenses/:id", component: EditTicketsAndExpenses },
    { path: "/purchase-invoice-summary", component: PurchaseInvoiceSummary },
    {
        path: "/create-purchase-delivery-notes",
        component: PurchaseDeliveryNoteCreate,
    },
    {
        path: "/purchase-delivery-Notes/:id",
        component: PurchaseDeliveryNoteEdit,
    },
    {
        path: "/reports/overview",
        component: ReportOverview,
    },
    {
        path: "/reports/taxSummary",
        component: TaxSummary,
    },
    {
        path: "/reports/stockValuation",
        component: StockValuation,
    },

    {
        path: "/reports/cashflow/overview",
        component: ReportCashflowOverview,
    },
    {
        path: "/reports/sales/overview",
        component: ReportSalesOverview,
    },
    {
        path: "/reports/technicalService/overview",
        component: TechnicalServiceOverview,
    },
    {
        path: "/reports/Invoicing/byItems",
        component: InvoicingByItem,
    },
    {
        path: "/reports/Invoicing/byAgent",
        component: InvoicingByAgent,
    },
    {
        path: "/reports/Invoicing/byclient",
        component: InvoicingByClient,
    },
    {
        path: "/reports/purchases/purchaseByItem",
        component: PurchaseByItem,
    },
    {
        path: "/reports/purchases/bySuppliers",
        component: PurchaseBySupplier,
    },
    {
        path: "/reports/sales/sale-by-client",
        component: SaleByClient,
    },
    {
        path: "/reports/sales/sale-by-agent",
        component: SaleByAgent,
    },
    {
        path: "/reports/sales/sale-by-item",
        component: SaleByItem,
    },
    {
        path: "/reports/ofevolution/ofProfit",
        component: OfProfit,
    },
    {
        path: "/reports/ofevolution/client",
        component: evolutionClient,
    },
    {
        path: "/reports/ofevolution/agent",
        component: evolutionAgent,
    },
    {
        path: "/reports/ofevolution/item",
        component: evolutionItem,
    },
    {
        path: "/reports/ofevolution/purchasesItem",
        component: PurchasesByItem,
    },
    {
        path: "/reports/ofevolution/purchasesprovider",
        component: PurchaseByPro,
    },
    {
        path: "/reports/cashFlow/cashFlowAgent",
        component: CashFlowAgent,
    },
    {
        path: "/reports/cashFlow/byPaymentOption",
        component: CashFlowPayment,
    },
    {
        path: "/reports/technicalService/tech-by-client",
        component: TechClient,
    },
    {
        path: "/reports/technicalService/tech-by-item",
        component: TechItems,
    },
    {
        path: "/reports/technicalService/tech-by-agent",
        component: TechAgent,
    },
    {
        path: "/reports/technicalService/incidentClient",
        component: IncidentClient,
    },
    {
        path: "/reports/technicalService/incidentAgent",
        component: IncidentAgent,
    },
    {
        path: "/expenses-and-investments",
        component: ExpensesAndInvestments,
    },
    {
        path: "/create-expenses-and-investments",
        component: CreateExpenses,
    },
    {
        path: "/edit-expenses-and-investments/:id",
        component: EditExpenses,
    },
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

    // Custom Pages
    { path: "/invoicing-service", component: InvoicingService },
    { path: "/refund-invoice-create", component: RefundInvoiceCreate },
];
// { path: "/create-new-teachnical", component: CreateServiceNew },

const authRoutes = [
    { path: "/reset-password", component: ResetPasswordPage },
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
    { path: "/register", component: Register },
    { path: "/terms-conditions", component: TermsConditionsPage },
    { path: "/privacy-policy", component: PricayPolicyPage },
];

export { userRoutes, authRoutes };
