/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, Table, Alert, Col, Row } from "reactstrap";
import MultiType from "../../AllCharts/chartjs/barchart";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../../components/Loader";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AssignUserList from "../../../components/AssignUserList/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let fromDate = "";
let toDate = "";
let agentId = "";
let paid = "";
const CashFlowPayment = (props) => {
    //   const [data, setData] = useState([]);
    const [invoiced, setInvoiced] = useState([]);
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignUsers, setAssignUsers] = useState([]);
    const [assignedUserId, setAssignedUserId] = useState(getCurrentUser().id);
    const [assignedUserName, setAssignedUserName] = useState(
        `${props.t("Select an agent to filter")}`
    );
    const [selectedType, SetSelectedType] = useState("");
    const [cashFlowHistory, setCashFlowHistory] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const startYear = 2017;
    const endYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = endYear; year >= startYear; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }
    const overviewGraph = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/cash-flow?type=paymentOption`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setData(res.data.data);
                    setInvoiced(res.data.data.invoice_items);
                }
                setLoader(false);
            });
    };

    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };
    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };
    function handleSelectedType(e) {
        SetSelectedType(e);
    }
    const getHistory = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/cashFlow-payment-option-history?referenceType=Refund Invoice`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setCashFlowHistory(res.data.data);
                }
            });
    };
    const getExport = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/cashFlow-payment-option-history?referenceType=Refund Invoice&export=download`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.location.href = res.data.url;
                }
            });
    };
    const searchData = () => {
        setInvoiced([]);
        setLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/cash-flow?type=paymentOption&startDate=${fromDate}&endDate=${toDate}&agent_id=${agentId}&paid=${paid}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setInvoiced(res.data.data.invoice_items);
                }
                setLoader(false);
            });
        setCashFlowHistory([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/cashFlow-payment-option-history?startDate=${fromDate}&endDate=${toDate}&agent_id=${agentId}&paid=${paid}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setCashFlowHistory(res.data.data);
                } else {
                    setHistoryError(res.data.message);
                }
            });
    };
    const getDate = (e) => {
        if (e.target.name == "start_date") {
            fromDate = e.target.value;
            toDate = e.target.value;
            searchData();
        } else if (e.target.name == "from_date") {
            fromDate = e.target.value;
        } else if (e.target.name == "to_date") {
            toDate = e.target.value;
            searchData();
        } else if (e.target.name == "annual") {
            if (e.target.value == "all_date") {
                fromDate = "";
                toDate = "";
                searchData();
            }
        } else if (e.target.name == "year") {
            fromDate = `01-01-${e.target.value}`;
            toDate = `31-12-${e.target.value}`;
            searchData();
        } else if (e.target.name == "month") {
            if (e.target.value == "january") {
                fromDate = `01-01-${year}`;
                toDate = `31-01-${year}`;
            } else if (e.target.value == "february") {
                fromDate = `01-02-${year}`;
                toDate = `28-02-${year}`;
            } else if (e.target.value == "march") {
                fromDate = `01-03-${year}`;
                toDate = `31-03-${year}`;
            } else if (e.target.value == "april") {
                fromDate = `01-04-${year}`;
                toDate = `30-04-${year}`;
            } else if (e.target.value == "may") {
                fromDate = `01-05-${year}`;
                toDate = `31-05-${year}`;
            } else if (e.target.value == "june") {
                fromDate = `01-06-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "july") {
                fromDate = `01-07-${year}`;
                toDate = `31-07-${year}`;
            } else if (e.target.value == "august") {
                fromDate = `01-08-${year}`;
                toDate = `31-08-${year}`;
            } else if (e.target.value == "september") {
                fromDate = `01-09-${year}`;
                toDate = `30-09-${year}`;
            } else if (e.target.value == "october") {
                fromDate = `01-10-${year}`;
                toDate = `31-10-${year}`;
            } else if (e.target.value == "november") {
                fromDate = `01-11-${year}`;
                toDate = `30-11-${year}`;
            } else if (e.target.value == "december") {
                fromDate = `01-12-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        } else if (e.target.name == "quarter") {
            if (e.target.value == "first_quarter") {
                fromDate = `01-01-${year}`;
                toDate = `31-03-${year}`;
            } else if (e.target.value == "second_quarter") {
                fromDate = `01-04-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "third_quarter") {
                fromDate = `01-07-${year}`;
                toDate = `30-09-${year}`;
            } else if (e.target.value == "forth_quarter") {
                fromDate = `01-10-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        } else if (e.target.name == "semester") {
            if (e.target.value == "first_semester") {
                fromDate = `01-01-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "second_semester") {
                fromDate = `01-07-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        } else if (e.target.name === "paid") {
            if (e.target.value == "1") {
                paid = "1";
            } else if (e.target.value == "0") {
                paid = "0";
            } else {
                paid = "";
            }
            searchData();
        }
    };
    const getAgent = (e) => {
        agentId = e;
        searchData();
    };
    useEffect(() => {
        overviewGraph();
        getAssignUser();
        getHistory();
    }, []);
    return (
        <>
            {" "}
            {assignedModal ? (
                <AssignUserList
                    setAssignedModal={setAssignedModal}
                    getAssignedUser={getAssignedUser}
                    data={assignUsers}
                    getData={getAgent}
                />
            ) : (
                ""
            )}{" "}
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title> CashFlow -| Clouget</title>
                    </MetaTags>
                    <Container>
                        <div className="page-title-box">
                            <AvForm>
                                <Row>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="annual"
                                            value={selectedType}
                                            onChange={(e) => {
                                                handleSelectedType(e.target.value), getDate(e);
                                            }}
                                        >
                                            <option value="year">{props.t("Year")}</option>
                                            <option value="semester">{props.t("Semester")}</option>
                                            <option value="quarter">{props.t("Quarter")}</option>
                                            <option value="month">{props.t("Month")}</option>
                                            <option value="day">{props.t("Day")}</option>
                                            <option value="between_dates">
                                                {props.t("Between dates")}
                                            </option>
                                            <option value="all_date">{props.t("All Dates")}</option>
                                        </AvField>
                                    </Col>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="paid"
                                            onChange={(e) => getDate(e)}
                                        >
                                            <option value="1">{props.t("Paid")}</option>
                                            <option value="0">{props.t("Unpaid")}</option>
                                            <option value="">{props.t("All")}</option>
                                        </AvField>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <div className="mt-2">
                                            {selectedType == "semester" ? (
                                                <>
                                                    <AvField
                                                        type="select"
                                                        name="semester"
                                                        onChange={(e) => getDate(e)}
                                                    >
                                                        <option value="" disabled>
                                                            {props.t("Select semester")}
                                                        </option>
                                                        <option value="first_semester">
                                                            1st {props.t("semester")}
                                                        </option>
                                                        <option value="second_semester">
                                                            2nd {props.t("semester")}
                                                        </option>
                                                    </AvField>
                                                </>
                                            ) : selectedType == "quarter" ? (
                                                <>
                                                    <AvField
                                                        type="select"
                                                        name="quarter"
                                                        onChange={(e) => getDate(e)}
                                                    >
                                                        <option value="" disabled>
                                                            {props.t("Select quarter")}
                                                        </option>
                                                        <option value="first_quarter">
                                                            1st {props.t("quarter")}
                                                        </option>
                                                        <option value="second_quarter">
                                                            2nd {props.t("quarter")}
                                                        </option>
                                                        <option value="third_quarter">
                                                            3rd {props.t("quarter")}
                                                        </option>
                                                        <option value="forth_quarter">
                                                            4th {props.t("quarter")}
                                                        </option>
                                                    </AvField>
                                                </>
                                            ) : selectedType == "day" ? (
                                                <>
                                                    <AvField
                                                        onChange={(e) => getDate(e)}
                                                        dateFormat="MMMM d, yyyy"
                                                        type="date"
                                                        name="start_date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                </>
                                            ) : selectedType == "between_dates" ? (
                                                <>
                                                    <label>{props.t("From")}</label>
                                                    <AvField
                                                        onChange={(e) => getDate(e)}
                                                        dateFormat="MMMM d, yyyy"
                                                        name="from_date"
                                                        type="date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                    <label>{props.t("To")}</label>
                                                    <AvField
                                                        onChange={(e) => getDate(e)}
                                                        dateFormat="MMMM d, yyyy"
                                                        name="to_date"
                                                        type="date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                </>
                                            ) : selectedType == "month" ? (
                                                <>
                                                    <AvField
                                                        type="select"
                                                        name="month"
                                                        onChange={(e) => getDate(e)}
                                                    >
                                                        <option value="" disabled>
                                                            {props.t("Select month")}
                                                        </option>
                                                        <option value="january"> {props.t("January")}</option>
                                                        <option value="february"> {props.t("February")}</option>
                                                        <option value="march"> {props.t("March")}</option>
                                                        <option value="april"> {props.t("April")}</option>
                                                        <option value="may"> {props.t("May")}</option>
                                                        <option value="june"> {props.t("June")}</option>
                                                        <option value="July"> {props.t("July")}</option>
                                                        <option value="august"> {props.t("August")}</option>
                                                        <option value="september"> {props.t("September")}</option>
                                                        <option value="october"> {props.t("October")}</option>
                                                        <option value="november"> {props.t("November")}</option>
                                                        <option value="december"> {props.t("December")}</option>
                                                    </AvField>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <select
                                            id="year-select"
                                            name="year"
                                            className="is-untouched is-pristine av-valid form-control mt-2"
                                            onChange={(e) => {
                                                getDate(e), setYear(e.target.value);
                                            }}
                                        >
                                            {yearOptions}
                                        </select>
                                    </Col>
                                    <Col md={4}>
                                        <span
                                            className="form-control mt-2"
                                            onClick={() => setAssignedModal(true)}
                                        >
                                            {assignedUserName}
                                        </span>
                                    </Col>
                                </Row>
                            </AvForm>
                        </div>
                    </Container>
                    <Container>
                        <div className="page-title-box">
                            <h6 className="page-title">
                                {props.t("Payment Options by Balance")}
                            </h6>
                        </div>
                        <Card>
                            <CardBody>
                                {loader ? <Loader /> : <MultiType data={invoiced} />}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => getExport()}
                                    >
                                        {props.t("Export")}
                                    </button>
                                </div>
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>{props.t("Name")}</th>
                                                <th>{props.t("Deposits")}($)</th>
                                                <th>{props.t("Withdrawals")}($)</th>
                                                <th>{props.t("Balance")}($)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cashFlowHistory &&
                                                cashFlowHistory.map((item, i) => (
                                                    <tr key={i} style={{ cursor: "pointer" }}>
                                                        <td>{item.name}</td>
                                                        <td>{item.deposit}</td>
                                                        <td>{item.withdrawals}</td>
                                                        <td>{item.balance}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                {loader}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        </>
    );
};

export default withTranslation()(CashFlowPayment);
CashFlowPayment.propTypes = {
    t: PropTypes.any,
};
