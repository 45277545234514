import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ProductAttachment = (props) => {
  const [user, setUser] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isOpenModals, setIsOpenModals] = useState(false);

  let selectedRoleId = [];
  let selectedRoleName = [];
  const deleteUser = (Id) => {
    if (props.type == "service") {
      axios
        .delete(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/service-attachments/${Id}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setUser(null);
          setdynamic_description(res.data.message);
        });
    } else {
      axios
        .delete(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/product-attachments/${Id}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setUser(null);
          setdynamic_description(res.data.message);
        });
    }
  };

  const updateTechnical = (e, value) => {
    if (props.type == "service") {
      const formData = new FormData();
      formData.append("description", value.description);
      formData.append("service_id", props.user);
      axios
        .post(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/service-attachments/${user.id}?_method=PUT`,
          formData,
          {
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setUser(res.data.service_attachment);
          setIsOpenModals(false);
        });
    } else {
      const formData = new FormData();
      formData.append("description", value.description);
      formData.append("product_id", props.user);
      axios
        .post(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/product-attachments/${user.id}?_method=PUT`,
          formData,
          {
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setUser(res.data.product_attachment);
          setIsOpenModals(false);
        });
    }
  };
  const getSelectedId = (e, check, name) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      selectedRoleName.push(name);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      const uniqueName = selectedRoleName.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      selectedRoleName = uniqueName;
      props.setSelectedIds(selectedRoleId);
      props.setSelectedName(selectedRoleName);
    } else {
      selectedRoleId = props.selectedIds;
      selectedRoleName = props.selectedName;
      var array = selectedRoleId;
      var array2 = selectedRoleName;
      var index = array.indexOf(e);
      var index2 = array2.indexOf(name);
      if (index !== -1) {
        array.splice(index, 1);
      }
      if (index2 !== -1) {
        array2.splice(index2, 1);
      }
      props.setSelectedIds(selectedRoleId);
      props.setSelectedName(selectedRoleName);
    }
  };
  useEffect(() => {
    selectedRoleId = [];
    selectedRoleName = [];
  }, []);
  if (user === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td>
            <input
              onChange={(e) => getSelectedId(user.id, e, user.name)}
              name="chk2"
              value={user.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>
            <a href={user.document} target="_blank" rel="noreferrer">
              {user.name}
            </a>
          </td>
          <td onClick={() => setIsOpenModals(true)}>{user.description}</td>
          <td onClick={() => setIsOpenModals(true)}>{user.updated_at}</td>
          <td>
            <button
              className="btn btn-danger"
              onClick={() => {
                setconfirm_alert(true);
              }}
            >
              {props.t("Delete")}
            </button>
            <a
              href={user.document}
              className="btn btn-danger ms-4"
              target="_blank"
              rel="noreferrer"
              download
            >
              {props.t("Download")}
            </a>
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteUser(user.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("File has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}

        <Modal size="lg" isOpen={isOpenModals} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.t("Edit")}
            </h5>
          </div>
          <div className="modal-body">
            <AvForm
              onValidSubmit={(e, v) => {
                updateTechnical(e, v);
              }}
            >
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <label>{props.t("Description")}</label>
                    <AvField
                      name="description"
                      type="textarea"
                      className="form-control"
                      defaultValue={user.description}
                    ></AvField>
                  </div>
                </Col>
              </Row>
              <div className="modal-footer">
                <div className="text-end">
                  <button className="btn btn-primary" type="submit">
                    {props.t("Submit")}
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    type="button"
                    onClick={() => setIsOpenModals(false)}
                  >
                    {props.t("Cancel")}
                  </button>
                </div>
              </div>
            </AvForm>
          </div>
        </Modal>
      </>
    );
};

export default withTranslation()(ProductAttachment);
ProductAttachment.propTypes = {
  t: PropTypes.any,
};