import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser } from "../../helpers/Utils";

function SingleList(data) {
  const [company, setCompany] = useState(data.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const deleteCompany = (Id) => {
    axios
      .delete(`${process.env.REACT_APP_APIKEY}/companies/${Id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setCompany(null);
        setdynamic_description(res.data.message);
      });
  };

  if (company === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td>{company.id}</td>
          <td role="button">
            <Link to={`/company/${company.id}`}>{company.name}</Link>
          </td>
          <td role="button">
            <Link to={`/company/${company.id}`}>{company.email}</Link>
          </td>
          <td role="button">
            <Link to={`/company/${company.id}`}>{company.phone}</Link>
          </td>
          <td role="button">
            <Link to={`/company/${company.id}`}>{company.country}</Link>
          </td>
          <td role="button">
            <Link to={`/company/${company.id}`}>{company.currency}</Link>
          </td>
          <td role="button">
            <Link to={`/company/${company.id}`}>
              <img
                style={{ objectFit: "cover" }}
                height="45"
                width="45"
                className="rounded-circle"
                src={company.logo}
              />
            </Link>
          </td>
          <td className="text-end">
            <Button
              color="danger"
              onClick={() => {
                setconfirm_alert(true);
              }}
            >
              Delete
            </Button>
            <Link
              to={`/company/${company.id}`}
              className="btn btn-primary ms-2 text-light"
            >
              Edit
            </Link>
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title="Are you sure?"
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteCompany(company.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("El Documento fue eliminado.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
}

export default SingleList;
