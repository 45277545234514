import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import { Row, Card, Alert } from "reactstrap";
const axios = require("axios").default;
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    registerUser,
    apiError,
    registerUserFailed,
} from "../../store/actions";
import { connect } from "react-redux";
import { setCurrentUser, setPermission } from "../../helpers/Utils";
import logoSm from "../../assets/images/logo-sm.png";
import loginbg from "../../assets/images/login-bg.jpg";
import { withTranslation } from "react-i18next";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

const Register = (props) => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [passField1, setPassField1] = useState(false);
    const history = useHistory();

    const toggle = () => {
        setPassField1(!passField1);
    };

    // handleValidSubmit
    const handleValidSubmit = (event, values) => {
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_APIKEY}/register`, {
                company_name: companyName,
                commercial_name: companyName,
                name: values.name,
                surname: values.surname,
                mobile_number: phone,
                email: values.email,
                country: country,
                password: values.password,
            })
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    localStorage.setItem("companyId", res.data.user.company_id);
                    setError("");
                    setSuccess(res.data.message);
                    setPermission(res.data.permissions);
                    setCurrentUser(res.data.user);
                    history.push("/company");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
            });
    };

    const getCountry = (a, b, c, d) => {
        setCountry(b.name);
        let str = d;
        const after = str.slice(str.indexOf(" ") + 1);
        setPhone(d);
    };

    useEffect(() => {
        apiError("");
    }, []);

    return (
        <React.Fragment>
            <MetaTags>
                <title>Register | Clouget</title>
            </MetaTags>
            <div
                className="accountbg"
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(${loginbg})`,
                }}
            ></div>
            <div className="wrapper-page account-page-full">
                <LanguageDropdown />
                <Card className="shadow-none">
                    <div className="card-block">
                        <div className="account-box">
                            <div className="card-box shadow-none pb-5 ps-5 pe-5">
                                <div className="p-2">
                                    <div className="text-center">
                                        <a className="logo logo-admin">
                                            <img src={logoSm} height="45" alt="logo" />
                                        </a>
                                    </div>
                                    <h4 className="text-center mt-4 font-size-18">
                                        {props.t("Free Register")}
                                    </h4>
                                    <p className="text-muted text-center">
                                        {props.t("Get your free Billing account now.")}
                                    </p>
                                    <AvForm
                                        className="mt-4"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v);
                                        }}
                                    >
                                        {success ? (
                                            <Alert color="success">{props.t(success)}</Alert>
                                        ) : null}

                                        {error ? (
                                            <Alert color="danger">{props.t(error)}</Alert>
                                        ) : null}
                                        <div className="mb-3">
                                            <AvField
                                                id="name"
                                                name="name"
                                                label={props.t("Name")}
                                                className="form-control"
                                                placeholder={props.t("Enter Name")}
                                                type="text"
                                            // required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                id="name"
                                                name="surname"
                                                label={props.t("Lastname")}
                                                className="form-control"
                                                placeholder={props.t("Enter Lastname")}
                                                type="text"
                                            // required
                                            />
                                        </div>
                                        <label> {props.t("Company Name")}</label>
                                        <div className="input-group mb-3">
                                            <input
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                type="text"
                                                placeholder={props.t("Enter Company Name")}
                                                className="form-control"
                                                aria-describedby="basic-addon2"
                                            />
                                            {/* <span className="input-group-text" id="basic-addon2">
                        {props.t(".example.com")}
                      </span> */}
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                id="email"
                                                name="email"
                                                label={props.t("Email")}
                                                className="form-control"
                                                placeholder={props.t("Enter email")}
                                                type="email"
                                            // required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label> {props.t("Phone")}</label>
                                            <PhoneInput
                                                placeholder={props.t("Enter phone number")}
                                                // value={phone}
                                                onChange={(s, d, f, g) => getCountry(s, d, f, g)}
                                                inputStyle={{ width: "100%" }}
                                            // rules={{ required: true }}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                name="password"
                                                label={props.t("Password")}
                                                type={passField1 ? "text" : "password"}
                                                // required
                                                placeholder={props.t("Enter Password")}
                                            />
                                            {passField1 ? (
                                                <span
                                                    className="float-end"
                                                    style={{ marginTop: "-27px", marginRight: "10px" }}
                                                >
                                                    <i
                                                        className="fa fa-eye-slash"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => toggle()}
                                                    ></i>
                                                </span>
                                            ) : (
                                                <span
                                                    className="float-end"
                                                    style={{ marginTop: "-27px", marginRight: "10px" }}
                                                >
                                                    <i
                                                        className="fa fa-eye"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => toggle()}
                                                    ></i>
                                                </span>
                                            )}
                                        </div>
                                        <Row className="mb-3">
                                            <div className="mb-3 row">
                                                <div className="col-12 text-end">
                                                    {loading ? (
                                                        <button
                                                            className="btn btn-primary  disabled"
                                                            type="button"
                                                            disabled
                                                        >
                                                            {props.t("Loading")}
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-primary " type="submit">
                                                            {props.t("Register")}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </Row>
                                        <Row className="mt-2 mb-0">
                                            <div className="mt-2 mb-0 row">
                                                <div className="col-12 mt-2">
                                                    <p className="mb-0 text-center">
                                                        {props.t("By registering you agree to our")}{" "}
                                                        <Link to="/terms-conditions" className="text-primary">
                                                            {props.t("Terms of Use")}
                                                        </Link>
                                                        {" "}{props.t("and")}{" "}
                                                        <Link to="/privacy-policy" className="text-primary">
                                                            {props.t("Privacy policy")}.
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </Row>
                                        <div className="text-center">
                                            <p>
                                                {props.t("Already have an account ?")}{" "}
                                                <Link to="/login" className="fw-medium text-primary">
                                                    {" "}
                                                    {props.t("Login")}
                                                </Link>{" "}
                                            </p>
                                            <p>© {new Date().getFullYear()} Clouget</p>
                                        </div>
                                    </AvForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </React.Fragment>
    );
};

Register.propTypes = {
    registerUser: PropTypes.func,
    registerUserFailed: PropTypes.func,
    registrationError: PropTypes.any,
    user: PropTypes.any,
    t: PropTypes.any,
};

const mapStatetoProps = (state) => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
    registerUser,
    apiError,
    registerUserFailed,
})(withTranslation()(Register));
