import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";

let selectedId = [];
let selectedName = [];

const MultipleChoice = (props) => {
  const removeName = () => {
    selectedName = [];
  };

  const removeIds = () => {
    selectedId = [];
  };

  const getname = (e) => {
    if (selectedName && selectedName.includes(e)) {
      var array = selectedName;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
    } else {
      selectedName.push(e);
    }
  };

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedId.push(e);
      const uniqueID = selectedId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedId = uniqueID;
    } else {
      var array = selectedId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
  };

  return (
    <div>
      {/* {props.leadsource_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={(e) => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadsource"
                    defaultChecked={
                      localStorage.getItem("leadSource") &&
                      localStorage.getItem("leadSource").includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedId, "leadsource")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "leadsource"), removeIds();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
      {/* {props.tag_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={(e) => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="tag"
                    defaultChecked={
                      localStorage.getItem("leadTag") &&
                      localStorage.getItem("leadTag").includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedId, "tag")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "tag"), removeIds();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
      {/* {props.label_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={(e) => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="label"
                    defaultChecked={
                      localStorage.getItem("leadLabel") &&
                      localStorage.getItem("leadLabel").includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={() => props.getSearchedLeads(selectedId, "label")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "label"), removeIds();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
      {/* {props.leadholder_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={(e) => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadholder"
                    defaultChecked={
                      localStorage.getItem("leadHolder") &&
                      localStorage.getItem("leadHolder").includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedId, "leadholder")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "leadholder"), removeIds();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
      {/* {props.role_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={(e) => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="role"
                    defaultChecked={
                      localStorage.getItem("leadRole") &&
                      localStorage.getItem("leadRole").includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedId, "role")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "role"), removeIds();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
      {/* {props.maturedby_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={(e) => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadmaturedby"
                    defaultChecked={
                      localStorage.getItem("leadmaturedby") &&
                      localStorage.getItem("leadmaturedby").includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedId, "leadmaturedby")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "leadmaturedby"), removeIds();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
      {/* {props.followups_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={(e) => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="f-status"
                    defaultChecked={
                      localStorage.getItem("followupStatus") &&
                      localStorage.getItem("followupStatus").includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedId, "f-status")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "f-status"), removeIds();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
      {/* {props.dead_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={(e) => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="d-status"
                    defaultChecked={
                      localStorage.getItem("deadStatus") &&
                      localStorage.getItem("deadStatus").includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">
                    {item.reason}
                  </span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedId, "d-status")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "d-status"), removeIds();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
      {/* {props.day_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("1")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("1")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Monday</span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("2")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("2")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Tuesday</span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("3")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("3")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Wednesday</span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("4")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("4")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Thrusday</span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("5")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("5")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Friday</span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("6")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("6")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Saturday</span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("7")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("7")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Sunday</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedName, "day")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "day"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
      {props.status_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("pending")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("status") &&
                  localStorage.getItem("status").includes("pending")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Pending")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("refused")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("status") &&
                  localStorage.getItem("status").includes("refused")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Refused")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("accepted")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("status") &&
                  localStorage.getItem("status").includes("accepted")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Accepted")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("closed")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("status") &&
                  localStorage.getItem("status").includes("closed")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Closed")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedList(selectedName, "status")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "status"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {props.statusSo_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("pending")}
                className=""
                type="checkbox"
                name="statusSo"
                defaultChecked={
                  localStorage.getItem("statusSo") &&
                  localStorage.getItem("statusSo").includes("pending")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Pending")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("refused")}
                className=""
                type="checkbox"
                name="statusSo"
                defaultChecked={
                  localStorage.getItem("statusSo") &&
                  localStorage.getItem("statusSo").includes("refused")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Refused")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("in_progress")}
                className=""
                type="checkbox"
                name="statusSo"
                defaultChecked={
                  localStorage.getItem("statusSo") &&
                  localStorage.getItem("statusSo").includes("in_progress")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("In Progress")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("closed")}
                className=""
                type="checkbox"
                name="statusSo"
                defaultChecked={
                  localStorage.getItem("statusSo") &&
                  localStorage.getItem("statusSo").includes("closed")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Closed")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedList(selectedName, "statusSo")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "statusSo"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {props.statusSdn_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("pending_invoice")}
                type="checkbox"
                name="statusSdn"
                defaultChecked={
                  localStorage.getItem("statusSdn") &&
                  localStorage.getItem("statusSdn").includes("pending_invoice")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Pending Invoice")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("in_progress")}
                type="checkbox"
                name="statusSdn"
                defaultChecked={
                  localStorage.getItem("statusSdn") &&
                  localStorage.getItem("statusSdn").includes("in_progress")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("In Progress")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("closed")}
                type="checkbox"
                name="statusSdn"
                defaultChecked={
                  localStorage.getItem("statusSdn") &&
                  localStorage.getItem("statusSdn").includes("closed")
                }
              />
              <span className="ms-2 fw-normal text-muted">
                {props.t("Closed")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("invoice")}
                type="checkbox"
                name="statusSdn"
                defaultChecked={
                  localStorage.getItem("statusSdn") &&
                  localStorage.getItem("statusSdn").includes("invoice")
                }
              />
              <span className="ms-2 fw-normal text-muted">
                {props.t("Invoice")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedList(selectedName, "statusSdn")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "statusSdn"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {props.statusWI_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("pending")}
                className=""
                type="checkbox"
                name="statusWI"
                defaultChecked={
                  localStorage.getItem("statusWI") &&
                  localStorage.getItem("statusWI").includes("pending")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Pending")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("refused")}
                className=""
                type="checkbox"
                name="statusWI"
                defaultChecked={
                  localStorage.getItem("statusWI") &&
                  localStorage.getItem("statusWI").includes("refused")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Refused")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("resolved")}
                className=""
                type="checkbox"
                name="statusWI"
                defaultChecked={
                  localStorage.getItem("statusWI") &&
                  localStorage.getItem("statusWI").includes("resolved")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Resolved")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("closed")}
                className=""
                type="checkbox"
                name="statusWI"
                defaultChecked={
                  localStorage.getItem("statusWI") &&
                  localStorage.getItem("statusWI").includes("closed")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Closed")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedList(selectedName, "statusWI")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "statusWI"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {props.statusWO_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("pending")}
                className=""
                type="checkbox"
                name="statusWO"
                defaultChecked={
                  localStorage.getItem("statusWO") &&
                  localStorage.getItem("statusWO").includes("pending")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Pending")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("refused")}
                className=""
                type="checkbox"
                name="statusWO"
                defaultChecked={
                  localStorage.getItem("statusWO") &&
                  localStorage.getItem("statusWO").includes("refused")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Refused")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("in_progress")}
                className=""
                type="checkbox"
                name="statusWO"
                defaultChecked={
                  localStorage.getItem("statusWO") &&
                  localStorage.getItem("statusWO").includes("in_progress")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("In Progress")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("closed")}
                className=""
                type="checkbox"
                name="statusWO"
                defaultChecked={
                  localStorage.getItem("statusWO") &&
                  localStorage.getItem("statusWO").includes("closed")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Closed")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedList(selectedName, "statusWO")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "statusWO"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {props.statusWDN_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("pending_invoice")}
                className=""
                type="checkbox"
                name="statusWDN"
                defaultChecked={
                  localStorage.getItem("statusWDN") &&
                  localStorage.getItem("statusWDN").includes("pending_invoice")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Pending Invoice")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("in_progress")}
                className=""
                type="checkbox"
                name="statusWDN"
                defaultChecked={
                  localStorage.getItem("statusWDN") &&
                  localStorage.getItem("statusWDN").includes("in_progress")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("IN Progress")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("closed")}
                className=""
                type="checkbox"
                name="statusWDN"
                defaultChecked={
                  localStorage.getItem("statusWDN") &&
                  localStorage.getItem("statusWDN").includes("closed")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Closed")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("invoiced")}
                className=""
                type="checkbox"
                name="statusWDN"
                defaultChecked={
                  localStorage.getItem("statusWDN") &&
                  localStorage.getItem("statusWDN").includes("invoiced")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Invoiced")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedList(selectedName, "statusWDN")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "statusWDN"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {props.statusINV_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("unpaid")}
                className=""
                type="checkbox"
                name="statusINV"
                defaultChecked={
                  localStorage.getItem("statusINV") &&
                  localStorage.getItem("statusINV").includes("unpaid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Unpaid")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("draft")}
                className=""
                type="checkbox"
                name="statusINV"
                defaultChecked={
                  localStorage.getItem("statusINV") &&
                  localStorage.getItem("statusINV").includes("draft")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Draft")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("partially_paid")}
                className=""
                type="checkbox"
                name="statusINV"
                defaultChecked={
                  localStorage.getItem("statusINV") &&
                  localStorage.getItem("statusINV").includes("partially_paid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Partially Paid")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("paid")}
                className=""
                type="checkbox"
                name="statusINV"
                defaultChecked={
                  localStorage.getItem("statusINV") &&
                  localStorage.getItem("statusINV").includes("paid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Paid")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedList(selectedName, "statusINV")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "statusINV"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}{" "}
      {props.statusVeronica_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("authorized")}
                className=""
                type="checkbox"
                name="statusVeronica"
                defaultChecked={
                  localStorage.getItem("statusVeronica") &&
                  localStorage.getItem("statusVeronica").includes("authorized")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("AUTHORIZED")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("pending")}
                className=""
                type="checkbox"
                name="statusVeronica"
                defaultChecked={
                  localStorage.getItem("statusVeronica") &&
                  localStorage.getItem("statusVeronica").includes("pending")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("UNAUTHORIZED")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("cancelled")}
                className=""
                type="checkbox"
                name="statusVeronica"
                defaultChecked={
                  localStorage.getItem("statusVeronica") &&
                  localStorage.getItem("statusVeronica").includes("cancelled")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("CANCELLED")}
              </span>
            </tr>
            {/* <tr>
              <input
                onChange={(e) => getname("pending")}
                className=""
                type="checkbox"
                name="statusVeronica"
                defaultChecked={
                  localStorage.getItem("statusVeronica") &&
                  localStorage.getItem("statusVeronica").includes("pending")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("PENDING")}
              </span>
            </tr> */}
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) =>
              props.getSearchedList(selectedName, "statusVeronica")
            }
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "statusVeronica"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}{" "}
      {props.statusPINV_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("unpaid")}
                className=""
                type="checkbox"
                name="statusPINV"
                defaultChecked={
                  localStorage.getItem("statusPINV") &&
                  localStorage.getItem("statusPINV").includes("unpaid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Unpaid")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("partially_paid")}
                className=""
                type="checkbox"
                name="statusPINV"
                defaultChecked={
                  localStorage.getItem("statusPINV") &&
                  localStorage.getItem("statusPINV").includes("partially_paid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Partially Paid")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("paid")}
                className=""
                type="checkbox"
                name="statusPINV"
                defaultChecked={
                  localStorage.getItem("statusPINV") &&
                  localStorage.getItem("statusPINV").includes("paid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Paid")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedList(selectedName, "statusPINV")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "statusPINV"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {props.statusPO_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("awaiting")}
                className=""
                type="checkbox"
                name="statusPO"
                defaultChecked={
                  localStorage.getItem("statusPO") &&
                  localStorage.getItem("statusPO").includes("awaiting")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Awaiting")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("refused")}
                className=""
                type="checkbox"
                name="statusPO"
                defaultChecked={
                  localStorage.getItem("statusPO") &&
                  localStorage.getItem("statusPO").includes("refused")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Refused")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("in_progress")}
                className=""
                type="checkbox"
                name="statusPO"
                defaultChecked={
                  localStorage.getItem("statusPO") &&
                  localStorage.getItem("statusPO").includes("in_progress")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("In Progress")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("received")}
                className=""
                type="checkbox"
                name="statusPO"
                defaultChecked={
                  localStorage.getItem("statusPO") &&
                  localStorage.getItem("statusPO").includes("received")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Received")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedName, "statusPO")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "statusPO"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {props.statusPDN_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("pending")}
                className=""
                type="checkbox"
                name="statusPDN"
                defaultChecked={
                  localStorage.getItem("statusPDN") &&
                  localStorage.getItem("statusPDN").includes("pending")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Pending")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("refused")}
                className=""
                type="checkbox"
                name="statusPDN"
                defaultChecked={
                  localStorage.getItem("statusPDN") &&
                  localStorage.getItem("statusPDN").includes("refused")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Refused")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("in_progress")}
                className=""
                type="checkbox"
                name="statusPDN"
                defaultChecked={
                  localStorage.getItem("statusPDN") &&
                  localStorage.getItem("statusPDN").includes("in_progress")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("In Progress")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("invoiced")}
                className=""
                type="checkbox"
                name="statusPDN"
                defaultChecked={
                  localStorage.getItem("statusPDN") &&
                  localStorage.getItem("statusPDN").includes("invoiced")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Invoiced</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedLeads(selectedName, "statusPDN")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedLeads("", "statusPDN"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {props.statusTAE_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={(e) => getname("unpaid")}
                className=""
                type="checkbox"
                name="statusTAE"
                defaultChecked={
                  localStorage.getItem("statusTAE") &&
                  localStorage.getItem("statusTAE").includes("unpaid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Unpaid")}
              </span>
            </tr>
            <tr>
              <input
                onChange={(e) => getname("paid")}
                className=""
                type="checkbox"
                name="statusTAE"
                defaultChecked={
                  localStorage.getItem("statusTAE") &&
                  localStorage.getItem("statusTAE").includes("paid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">
                {props.t("Paid")}
              </span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => props.getSearchedList(selectedName, "statusTAE")}
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) => {
              props.getSearchedList("", "statusTAE"), removeName();
            }}
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default withTranslation()(MultipleChoice);
MultipleChoice.propTypes = {
  t: PropTypes.any,
};
