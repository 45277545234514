import React, { useState, useEffect } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const CreateNewClient = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [rate, setRate] = useState([]);

    const getAllRates = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/rates`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setRate(res.data.rates);
                    setError("");
                } else {
                    // setError(res.data.message);
                }
            });
    };
    const submitRole = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-categories${props.category ? `/${props.category.id}?_method=PUT` : ""
                }`,
                {
                    name: value.name,
                    rate: value.rate,
                    type: e.target.type.value,
                    description: value.description,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    if (props.category) {
                        props.setCategory(res.data.client);
                    } else {
                        props.getCategories();
                    }
                    props.setIsOpenModal(false);
                    setError("");
                    setSuccess("");
                }, 2000);
            });
    };

    useEffect(() => {
        {
            getAllRates();
        }
    }, []);

    return (
        <div>
            <Modal size="md" isOpen={props.isOpenModal} centered={true}>
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        submitRole(e, v);
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            {props.category
                                ? props.category.name
                                : `${props.t("New Client and Supplier Category")}`}
                        </h5>
                        <button
                            type="button"
                            className="btn btn-primary text-md btn-sm"
                            onClick={() => props.setIsOpenModal(false)}
                            aria-hidden="true"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        {success ? (
                            <div className="mb-3">
                                <Alert color="success">{props.t(success)}</Alert>
                            </div>
                        ) : null}

                        {error ? (
                            <div className="mb-3">
                                <Alert color="danger">{props.t(error)}</Alert>
                            </div>
                        ) : null}

                        <div className="mb-3 required">
                            <label>{props.t("Name")}</label>
                            <AvField
                                type="text"
                                name="name"
                                value={props.category && props.category.name}
                                required="required"
                            ></AvField>
                        </div>
                        <div className="mb-3">
                            <label>{props.t("Type")}</label>
                            <AvField
                                type="select"
                                name="type"
                                value={props.category && props.category.type}
                            >
                                <option>{props.t("Client")}</option>
                                <option>{props.t("Supplier")}</option>
                            </AvField>
                        </div>
                        <div className="mb-3">
                            <label>{props.t("Rate")}</label>
                            <AvField
                                type="select"
                                name="rate"
                                value={props.category && props.category.rate}
                            >
                                <option value="">{props.t("Without Rate")}</option>
                                {rate?.map((item) => (
                                    <option value={item.id} key={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </AvField>
                        </div>
                        <div className="mb-3">
                            <AvField
                                label={props.t("Description")}
                                type="textarea"
                                name="description"
                                value={props.category && props.category.description}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-end">
                            {loading ? (
                                <button
                                    className="btn btn-primary disabled"
                                    type="button"
                                    disabled
                                >
                                    {props.t("Loading")}
                                </button>
                            ) : (
                                <button className="btn btn-primary" type="submit">
                                    {props.category
                                        ? `${props.t("Update")}`
                                        : `${props.t("Submit")}`}
                                </button>
                            )}
                            <button
                                className="btn btn-primary ms-2"
                                type="button"
                                onClick={() => props.setIsOpenModal(false)}
                            >
                                {props.t("Cancel")}
                            </button>
                        </div>
                    </div>
                </AvForm>
            </Modal>
        </div>
    );
};
export default withTranslation()(CreateNewClient);
CreateNewClient.propTypes = {
    t: PropTypes.any,
};
