import React from "react";
import {
  AvForm,
} from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

let selectedId;

const InputFilter = (props) => {
  const getSelectedId = (e) => {
    selectedId = e;
  };

  return (
    <div>
      {/* {props.id_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) => props.getSearchedList(selectedId, "id")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="id"
                defaultValue={
                  localStorage.getItem("leadID") &&
                  localStorage.getItem("leadID")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
            {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "id")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )} */}
      {props.ref_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) =>
              props.getSearchedList(selectedId, "reference_number")
            }
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="reference_number"
                defaultValue={
                  localStorage.getItem("reference") &&
                  localStorage.getItem("reference")
                }
              />
            </div>

            <button
              className="btn btn-primary"
              type="submit"
            //   onClick={(e) =>
            //     props.getSearchedList(selectedId, "reference_number")
            //   }
            >
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "reference_number")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.cliName_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) =>
              props.getSearchedList(selectedId, "client_name")
            }
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="client_name"
                defaultValue={
                  localStorage.getItem("clientName") &&
                  localStorage.getItem("clientName")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "client_name")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.supName_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) =>
              props.getSearchedList(selectedId, "supplier_name")
            }
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="supplier_name"
                defaultValue={
                  localStorage.getItem("supplierName") &&
                  localStorage.getItem("supplierName")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "supplier_name")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.name_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) => props.getSearchedList(selectedId, "name")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="name"
                defaultValue={
                  localStorage.getItem("name") && localStorage.getItem("name")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "name")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.legalName_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) =>
              props.getSearchedList(selectedId, "legal_name")
            }
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="legal_name"
                defaultValue={
                  localStorage.getItem("legalName") &&
                  localStorage.getItem("legalName")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "legal_name")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.tin_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) =>
              props.getSearchedList(selectedId, "tin")
            }
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="tin"
                defaultValue={
                  localStorage.getItem("tinNumber") &&
                  localStorage.getItem("tinNumber")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "tin")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.title_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) => props.getSearchedList(selectedId, "title")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="title"
                defaultValue={
                  localStorage.getItem("title") && localStorage.getItem("title")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "title")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.createdby_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) =>
              props.getSearchedList(selectedId, "created_by_name")
            }
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="created_by_name"
                defaultValue={
                  localStorage.getItem("createdBy") &&
                  localStorage.getItem("createdBy")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "created_by_name")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}{" "}
      {props.employee_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) =>
              props.getSearchedList(selectedId, "employee_name")
            }
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="employee_name"
                defaultValue={
                  localStorage.getItem("employee") &&
                  localStorage.getItem("employee")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "employee_name")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.amount_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) => props.getSearchedList(selectedId, "amount")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="number"
                name="amount"
                defaultValue={
                  localStorage.getItem("amount") &&
                  localStorage.getItem("amount")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "amount")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.amount_filter2 ? (
        <div className="filter-1">
          <AvForm
          // onValidSubmit={(e) => props.getSearchedList(selectedId, "amount2")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="number"
                name="amount"
                defaultValue={
                  localStorage.getItem("amount2") &&
                  localStorage.getItem("amount2")
                }
              />
            </div>

            <button
              className="btn btn-primary"
              type="button"
              onClick={() => props.getSearchedList(selectedId, "amount2")}
            >
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "amount2")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.email_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) => props.getSearchedList(selectedId, "email")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="email"
                defaultValue={
                  localStorage.getItem("email") && localStorage.getItem("email")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "email")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.phone1_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) => props.getSearchedList(selectedId, "phone_1")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="number"
                // min="0"
                name="phone_1"
                defaultValue={
                  localStorage.getItem("phone1") &&
                  localStorage.getItem("phone1")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "phone_1")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.price_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) => props.getSearchedList(selectedId, "price")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                // min="0"
                name="price"
                defaultValue={
                  localStorage.getItem("price") && localStorage.getItem("price")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "price")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.proName_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) =>
              props.getSearchedList(selectedId, "product_name")
            }
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                // min="0"
                name="name"
                defaultValue={
                  localStorage.getItem("productName") &&
                  localStorage.getItem("productName")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "product_name")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.phone_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) => props.getSearchedList(selectedId, "phone")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="number"
                // min="0"
                name="phone"
                defaultValue={
                  localStorage.getItem("phone") && localStorage.getItem("phone")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "phone")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.serial_filter ? (
        <div className="filter-1">
          <AvForm
            onValidSubmit={(e) =>
              props.getSearchedList(selectedId, "serial_number")
            }
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="number"
                // min="0"
                name="serial_number"
                defaultValue={
                  localStorage.getItem("serialNumber") &&
                  localStorage.getItem("serialNumber")
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "serial_number")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
      {props.paidBy_filter ? (
        <div className="filter-1">
          <AvForm
          // onValidSubmit={(e) => props.getSearchedList(selectedId, "paid_by")}
          >
            <div className="mb-3">
              <input
                onChange={(e) => getSelectedId(e.target.value)}
                className="form-control"
                type="text"
                name="paid_by"
                defaultValue={
                  localStorage.getItem("paidBy") &&
                  localStorage.getItem("paidBy")
                }
              />
            </div>

            <button
              className="btn btn-primary"
              type="button"
              onClick={() => props.getSearchedList(selectedId, "paid_by")}
            >
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "paid_by")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default withTranslation()(InputFilter);
InputFilter.propTypes = {
  t: PropTypes.any,
};