import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import SingleList from "./single-list";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";
import { useHistory, Link } from "react-router-dom";

const Users = (props) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            history.push("/new-user");
        }
    };
    const getTemplate = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.users);
                } else {
                    setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getTemplate();
    }, []);
    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            {/* <div className="page-content">
        <MetaTags>
          <title>Users | Clouget</title>
        </MetaTags>
        <Container fluid>
          <div className="company_list"> */}
            <div className="page-title-box">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h6 className="page-title">{props.t("Users")}</h6>
                    </Col>
                    <Col md={6}>
                        {getPermission().users.users.create.is_checked === 1 ? (
                            <div className="float-end d-none d-md-block">
                                <button
                                    className="btn btn-primary ms-2"
                                    onClick={() => createNew()}
                                >
                                    {props.t("New")}
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                        {/* <Link to="/company" className="btn btn-primary float-end">
              {props.t("Back")}
            </Link> */}
                    </Col>
                </Row>
            </div>
            <Card>
                <CardBody>
                    <div className="table-responsive">
                        <Table striped className="align-middle">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{props.t("Image")}</th>
                                    <th>{props.t("Name")}</th>
                                    <th>{props.t("Surname")}</th>
                                    <th>{props.t("Email")}</th>
                                    <th>{props.t("Phone")}</th>
                                    <th>{props.t("Role")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    data.map((item, i) => (
                                        <SingleList data={item} key={i} i={i} />
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                    {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                    {loader}
                </CardBody>
            </Card>
            {/* </div>
        </Container>
      </div> */}
        </React.Fragment>
    );
};

export default withTranslation()(Users);
Users.propTypes = {
    t: PropTypes.any,
};
