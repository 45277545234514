/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Table, Card, CardBody, Alert, Row, Col } from "reactstrap";
import MultiType from "../../AllCharts/chartjs/barchart";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../../components/Loader";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ProSerList from "../../../components/Pro&SerList/index";
import ClientList from "../../../components/ClientList/index";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
let referenceType = "";
let selectedYear = "";
let dateType = "";
let dateSubType = "";
let selectedMonth = "";
let clientId = "";
let taxes = "";
let proId = "";
let serId = "";

const evolutionAgent = (props) => {
  //   const [data, setData] = useState([]);
  const [invoiced, setInvoiced] = useState([]);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [error, setError] = useState("");
  const [selectedType, setSelectedType] = useState("all_dates");
  const [assignProductName, setAssignProductName] = useState(
    `${props.t("Select a product to filter")}`
  );
  const [clientNameData, setClientNameData] = useState(
    `${props.t("Select an client to filter")}`
  );
  const [selectClientModal, setSelectClientModal] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [selectItemModal, setSelectItemModal] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  const [timer, setTimer] = useState(null);
  const [clientLoader, setClientLoader] = useState(false);
  const [dataService, setDataService] = useState([]);
  const [mainFormDisabled, setMainFormDisabled] = useState(false);
  const [evolutionHistory, setEvolutionHistory] = useState([]);
  const [allRef, setAllRef] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const startYear = 2017;
  const endYear = new Date().getFullYear();
  const yearOptions = [];

  for (let year = endYear; year >= startYear; year--) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  const getClients = (e) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getClientData(e);
    }, 1000);
    setTimer(newTimer);
  };
  const getClientId = (e) => {
    clientId = e;
    searchData();
  };
  const getClientData = (e) => {
    setAllClients([]);
    let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
      "companyId"
    )}/clients`;
    if (e !== undefined) {
      url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
        "companyId"
      )}/clients?search=${e}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setAllClients(res.data.clients);
        } else {
          setAllClients([]);
        }
      });
  };
  const clientDetail = (e) => {
    let index = allClients.findIndex((x) => x.id == e);
    if (index != -1) {
      setClientNameData(allClients[index].legal_name);
    }
  };
  const overviewGraph = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/invoicing-by-agent?type=graph&after_tax="1"`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setInvoiced(res.data.data);
        }
        setLoader(false);
      });
  };

  const getProductData = (e) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getProduct(e);
      getServices(e);
    }, 1000);
    setTimer(newTimer);
  };
  const getProduct = (e) => {
    setDataProduct([]);
    setClientLoader(true);
    if (e !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/products?search=${e}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setDataProduct(res.data.products);
          } else {
            setError(res.data.message);
          }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/products`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setLoader("");
          if (res.data.status) {
            setError("");
            setDataProduct(res.data.products);
          } else {
            setError(res.data.message);
          }
          setClientLoader(false);
        });
    }
  };

  const getServices = (e) => {
    setDataService([]);
    if (e !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/services?search=${e}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          //   setLoader("");
          if (res.data.status) {
            setError("");
            setDataService(res.data.products);
          } else {
            setError(res.data.message);
          }
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/services`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setLoader("");
          if (res.data.status) {
            setError("");
            setDataService(res.data.products);
          } else {
            setError(res.data.message);
          }
        });
    }
  };
  const selectProduct = (e, i, type) => {
    if (type == "SER") {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/services/${e}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          console.log(res);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
          )}/products/${e}`,
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          console.log(res);
        });
    }
  };
  function handleSelectedType(e) {
    setSelectedType(e);
  }
  const getHistory = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/invoicing-by-agent?type=history&after_tax="1"`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setEvolutionHistory(res.data.data);
        }
      });
  };
  const getExport = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/invoicing-by-agent?type=history&after_tax="1"&export=download`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          window.location.href = res.data.url;
        }
      });
  };
  const getReferences = () => {
    setAllRef([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references?type=Normal Invoice,Refund Invoice`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoader("");
        if (res.data.status) {
          setError("");
          setAllRef(res.data.reference);
        }
      });
  };
  const getType = (e) => {
    if (e.target.name == "reference") {
      referenceType = e.target.value;
    }
    searchData();
  };
  const searchData = () => {
    setInvoiced([]);
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/invoicing-by-agent?type=graph&reference=${referenceType}&year=${selectedYear}&date_type=${dateType}&date_sub_type=${dateSubType}&month=${selectedMonth}&product_id=${proId}&service_id=${serId}&client_id=${clientId}&after_tax=${taxes}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setInvoiced(res.data.data);
        }
        setLoader(false);
      });
    setEvolutionHistory([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/invoicing-by-agent?type=history&reference=${referenceType}&year=${selectedYear}&date_type=${dateType}&date_sub_type=${dateSubType}&month=${selectedMonth}&product_id=${proId}&service_id=${serId}&client_id=${clientId}&after_tax=${taxes}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setEvolutionHistory(res.data.data);
        }
      });
  };
  const getDate = (e) => {
    if (e.target.name == "date_type") {
      if (e.target.value == "all_dates") {
        dateType = "all_dates";
        dateSubType = "year";
        selectedMonth = "";
        setCurrentMonth(new Date().getMonth() + 1);
        selectedYear = "";
        searchData();
      } else if (e.target.value == "month") {
        dateType = "month";
        dateSubType = "day";
        selectedMonth = new Date().getMonth() + 1;
        setCurrentMonth(new Date().getMonth() + 1);
        selectedYear = "";
        searchData();
      }
    } else if (e.target.name == "year") {
      selectedYear = e.target.value;
      dateSubType = "quarter";
      searchData();
    } else if (e.target.name == "month") {
      setCurrentMonth(e.target.value);
      if (e.target.value == "1") {
        selectedMonth = 1;
      } else if (e.target.value == "2") {
        selectedMonth = 2;
      } else if (e.target.value == "3") {
        selectedMonth = 3;
      } else if (e.target.value == "4") {
        selectedMonth = 4;
      } else if (e.target.value == "5") {
        selectedMonth = 5;
      } else if (e.target.value == "6") {
        selectedMonth = 6;
      } else if (e.target.value == "7") {
        selectedMonth = 7;
      } else if (e.target.value == "8") {
        selectedMonth = 8;
      } else if (e.target.value == "9") {
        selectedMonth = 9;
      } else if (e.target.value == "10") {
        selectedMonth = 10;
      } else if (e.target.value == "11") {
        selectedMonth = 11;
      } else if (e.target.value == "12") {
        selectedMonth = 12;
      }
      searchData();
    } else if (e.target.name == "date_sub_type") {
      dateSubType = e.target.value;
      searchData();
    }
  };
  const getAgent = (e) => {
    agentId = e;
    searchData();
  };

  const getProId = (e, type) => {
    if (type == "PRO") {
      proId = e;
    } else if (type == "SER") {
      serId = e;
    }
    searchData();
  };

  const getTax = (e) => {
    if (e.target.name == "taxes") {
      taxes = e.target.value;
    }
    searchData();
  };
  useEffect(() => {
    getReferences();
    overviewGraph();
    getHistory();
  }, []);
  return (
    <>
      {selectClientModal ? (
        <ClientList
          setSelectClientModal={setSelectClientModal}
          clients={allClients}
          clientDetail={clientDetail}
          getClients={getClients}
          getData={getClientId}
        />
      ) : (
        ""
      )}
      {selectItemModal ? (
        <ProSerList
          selectItemModal={selectItemModal}
          setSelectItemModal={setSelectItemModal}
          // index={remove}
          //   clients={template}
          data={dataProduct}
          data2={dataService}
          selectProduct={selectProduct}
          getProduct={getProduct}
          setAssignProductName={setAssignProductName}
          getServices={getServices}
          //   getServiceData={getServiceData}
          getProductData={getProductData}
          setMainFormDisabled={setMainFormDisabled}
          getData={getProId}
        />
      ) : (
        ""
      )}
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>invoice - ByAgent| Clouget</title>
          </MetaTags>
          <Container>
            <div className="page-title-box">
              <AvForm>
                <Row>
                  <Col md={2}>
                    <AvField
                      type="select"
                      name="date_type"
                      value={selectedType}
                      onChange={(e) => {
                        handleSelectedType(e.target.value), getDate(e);
                      }}
                    >
                      <option value="year">{props.t("Year")}</option>
                      <option value="month">{props.t("Month")}</option>
                      <option value="all_dates">{props.t("All Dates")}</option>
                    </AvField>
                  </Col>
                  <Col md={2}>
                    <AvField
                      type="select"
                      name="date_sub_type"
                      onChange={(e) => getDate(e)}
                    >
                      {selectedType == "year" ? (
                        <>
                          <option value="quarter">{props.t("Quarter")}</option>
                          <option value="month">{props.t("Month")}</option>
                        </>
                      ) : (
                        ""
                      )}
                      {selectedType === "month" ? (
                        <option value="day">{props.t("Day")}</option>
                      ) : (
                        ""
                      )}
                      {selectedType === "all_dates" ? (
                        <>
                          <option value="year">{props.t("Year")}</option>
                          <option value="quarter">{props.t("Quarter")}</option>
                          <option value="month">Month</option>
                        </>
                      ) : (
                        ""
                      )}
                    </AvField>
                  </Col>
                  <Col md={2}>
                    <AvField
                      type="select"
                      name="taxes"
                      onChange={(e) => getTax(e)}
                    >
                      <option value="1">{props.t("After tax")}</option>
                      <option value="0">{props.t("Before tax")}</option>
                    </AvField>
                  </Col>
                  <Col md={2}>
                    <AvField type="select" name="agents">
                      <option value="by_agent">{props.t("By Agent")}</option>
                      <option value="by_client">{props.t("By Client")}</option>
                    </AvField>
                  </Col>
                  <Col md={2}>
                    <AvField
                      type="select"
                      name="reference"
                      onChange={(e) => getType(e)}
                    >
                      <option value="">{props.t("Reference")}</option>
                      {allRef &&
                        allRef.map((item, i) => (
                          <option key={i} value={item.prefix}>
                            {item.prefix}
                          </option>
                        ))}
                    </AvField>
                  </Col>
                </Row>
                <Row>
                  {selectedType == "year" ? (
                    <Col md={2}>
                      <div className="mt-2">
                        <select
                          id="year-select"
                          name="year"
                          className="is-untouched is-pristine av-valid form-control"
                          onChange={(e) => {
                            getDate(e), setYear(e.target.value);
                          }}
                        >
                          {yearOptions}
                        </select>
                      </div>
                    </Col>
                  ) : selectedType == "month" ? (
                    <Col md={2}>
                      <div className="mt-2">
                        <AvField
                          type="select"
                          name="month"
                          value={currentMonth}
                          onChange={(e) => getDate(e)}
                        >
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </AvField>
                      </div>
                    </Col>
                  ) : selectedType == "all_dates" ? (
                    ""
                  ) : (
                    ""
                  )}
                  <Col md={4}>
                    <span
                      className="form-control mt-2"
                      onClick={() => {
                        setSelectItemModal(true), getProduct(), getServices();
                        setMainFormDisabled();
                      }}
                    >
                      {assignProductName}
                    </span>
                  </Col>
                  <Col md={4}>
                    <span
                      className="form-control mt-2"
                      onClick={() => {
                        setSelectClientModal(true), getClientData();
                      }}
                      type="button"
                    >
                      {clientNameData}
                    </span>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </Container>
          <Container>
            <div className="page-title-box">
              <h6 className="page-title">{props.t("Invoicing By Agent")}</h6>
            </div>
            <Card>
              <CardBody>
                {loader ? <Loader /> : <MultiType data={invoiced?.data} />}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => getExport()}
                  >
                    {props.t("Export")}
                  </button>
                </div>
                <div className="table-responsive">
                  <Table striped className="align-middle">
                    <thead>
                      <tr>
                        <th>{props.t("Name")}</th>
                        {evolutionHistory.labels?.map((item, i) => (
                          <th key={`${item}_${i}`}>{item}</th>
                        ))}
                        <th>{props.t("total")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {evolutionHistory?.data?.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <Link
                              to={`/user/${item.id}`}
                              style={{ color: " inherit" }}
                            >
                              {item.name}
                            </Link>
                          </td>
                          {item?.data.map((sub, i) => (
                            <td key={`${sub}_${i}`}>
                              <Link
                                to={`/user/${item.id}`}
                                style={{ color: " inherit" }}
                              >
                                {sub}
                              </Link>
                            </td>
                          ))}
                          <td>
                            <Link
                              to={`/user/${item.id}`}
                              style={{ color: " inherit" }}
                            >
                              {item.total}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};
export default withTranslation()(evolutionAgent);
evolutionAgent.propTypes = {
  t: PropTypes.any,
};
