/* eslint-disable react/prop-types */
import React from "react";
import uploadImg from "../../assets/images/def-img.svg";
import { Modal, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function ClientList(props) {
  const selectedUser = (id, name) => {
    props.getAssignedUser(id, name);
    props.setAssignedModal(false);
    if (props.getData !== undefined) {
      props.getData(id);
    }
  };
  const unassigned = () => {
    props.getAssignedUser("", "");
    props.setAssignedModal(false);
  };
  return (
    <Modal size="xl" isOpen="true" centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          {props.t("Employee selection")}
        </h5>
        <button
          type="button"
          className="cross__btn btn btn-primary waves-effect waves-light"
          onClick={() => props.setAssignedModal(false)}
          aria-hidden="true"
        >
          &times;
        </button>
      </div>
      <div className="modal-body table-responsive">
        <Table hover className="align-middle">
          <thead>
            <tr>
              <th>#</th>
              <th>{props.t("Image")}</th>
              <th>{props.t("Name")}</th>
              <th>{props.t("Surname")}</th>
              <th>{props.t("Email")}</th>
              <th>{props.t("Phone")}</th>
              <th>{props.t("Role")}</th>
              <th>{props.t("GPS")}</th>
            </tr>
          </thead>
          <tbody>
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i} onClick={() => selectedUser(item.id, item.name)}>
                  <td>{i + 1}</td>
                  <td role="button" className="py-1">
                    <img
                      height="40"
                      width="40"
                      style={{ objectFit: "cover" }}
                      src={item.image ? item.image : uploadImg}
                    />
                  </td>
                  <td role="button" className="py-1">
                    {item.name}
                  </td>
                  <td role="button" className="py-1">
                    {item.surname}
                  </td>
                  <td role="button" className="py-1">
                    {item.email}
                  </td>
                  <td role="button" className="py-1">
                    {item.phone_1}
                  </td>
                  <td role="button" className="py-1">
                    {item.role}
                  </td>
                  <td role="button" className="py-1">
                    <span className="badge rounded-pill bg-success">
                      {props.t("Yes")}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="modal-footer">
          <button
            className="btn btn-primary"
            onClick={() => props.setAssignedModal(false)}
          >
            {props.t("Cancel")}
          </button>
          <button className="btn btn-primary" onClick={() => unassigned()}>
            {props.t("Unassigned")}
          </button>
        </div>
      </div>
    </Modal>
  );
}
export default withTranslation()(ClientList);
ClientList.propTypes = {
  t: PropTypes.any,
};
