import React, { useState, useEffect } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";
import Loader from "../../../components/Loader";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function RateModal(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [rate, setRate] = useState("");
    const [productLoader, setProductLoader] = useState("");
    const [products, setProducts] = useState([]);
    const [productData, setProductData] = useState("");
    const [purchasePrice, setPurchasePrice] = useState("");
    const [salesPrice, setSalesPrice] = useState("");
    const [purchaseMargin, setPurchaseMargin] = useState("");
    const [salesMargin, setSalesMargin] = useState("");
    const [discount, setDiscount] = useState("");
    const [specialPrice, setSpecialPrice] = useState("");

    const getSingleRate = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/rates/${props.rateId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setRate(res.data.rate);
                    setError("");
                    props.getAllRates();
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const getSpecialPrice = (e) => {
        let SP = e;
        let NP = salesPrice - SP;
        let SM = (NP / salesPrice) * 100;
        let PM = (NP / SP) * 100;
        let Disc = 100 * ((purchasePrice - SP) / purchasePrice);
        setPurchaseMargin(PM.toFixed(2));
        setSalesMargin(SM.toFixed(2));
        setDiscount(Disc.toFixed(2));
    };

    const getDiscount = (e) => {
        let Disc = e;
        let SP = purchasePrice - (purchasePrice * Disc) / 100;
        let NP = salesPrice - SP;
        let SM = (NP / salesPrice) * 100;
        let PM = (NP / SP) * 100;
        setPurchaseMargin(PM.toFixed(2));
        setSalesMargin(SM.toFixed(2));
        setSpecialPrice(SP.toFixed(2));
    };

    const getSalesMargin = (e) => {
        let SM = e;
        let NP = (salesPrice * SM) / 100;
        let SP = salesPrice - NP;
        let PM = (NP / SP) * 100;
        let Disc = 100 * ((purchasePrice - SP) / purchasePrice);
        setPurchaseMargin(PM.toFixed(2));
        setDiscount(Disc.toFixed(2));
        setSpecialPrice(SP.toFixed(2));
    };
    const getPurchaseMargin = (e) => {
        let PM = e;
        let NP = (PM * purchasePrice) / 100;
        let SP = NP + purchasePrice;
        let SM = (NP / SP) * 100;
        let NP1 = (salesPrice * SM) / 100;
        let SP1 = salesPrice - NP1;
        let Disc = 100 * ((purchasePrice - SP1) / purchasePrice);
        setSalesMargin(SM.toFixed(2));
        setDiscount(Disc.toFixed(2));
        setSpecialPrice(SP1.toFixed(2));
    };

    const submitPrice = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-special-prices${props.rateId !== undefined ? `/${props.rateId}?_method=PUT` : ""
                }`,
                {
                    supplier_id: props.client.id,
                    product_id: productData && productData.id,
                    purchase_price: value.purchase_price,
                    sales_price: value.sales_price,
                    purchase_margin: value.purchase_margin,
                    sales_margin: value.sales_margin,
                    discount: value.discount,
                    special_price: value.special_price,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    props.getAllRates();
                    props.setIsOpenModal(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const getProduct = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setProducts(res.data.products);
            });
    };

    const getSinglePrice = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-special-prices/${props.rateId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setProductData(res.data.supplier_special_price);
                setPurchasePrice(res.data.supplier_special_price.purchase_price);
                setSalesPrice(res.data.supplier_special_price.price);
                setPurchaseMargin(res.data.supplier_special_price.purchase_margin);
                setSalesMargin(res.data.supplier_special_price.sales_margin);
                setDiscount(res.data.supplier_special_price.discount);
                setSpecialPrice(res.data.supplier_special_price.purchase_price);
            });
    };

    let productOptions = [];
    if (products !== undefined) {
        products &&
            products.map((item) => {
                productOptions.push({ value: item.id, label: item.name });
            });
    }
    function selectProduct(e) {
        setProductLoader(<Loader />);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products/${e.value}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setProductData(res.data.product);
                setPurchasePrice(res.data.product.purchase_price);
                setSalesPrice(res.data.product.price);
                setPurchaseMargin(res.data.product.purchase_margin);
                setSalesMargin(res.data.product.sales_margin);
                setDiscount(res.data.product.discount);
                setSpecialPrice(res.data.product.purchase_price);
                setProductLoader("");
            });
    }

    useEffect(() => {
        getProduct();
        if (props.rateId !== undefined) {
            getSinglePrice();
        }
    }, []);

    return (
        <Modal size="lg" isOpen={props.isOpenModal} centered={true}>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    submitPrice(e, v);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.rateId
                            ? productData.product_name
                            : props.t("New Special Purchase Price")}
                    </h5>
                    <button
                        type="button"
                        className="btn btn-primary text-md btn-sm"
                        onClick={() => props.setIsOpenModal(false)}
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {success ? (
                        <div className="mb-3">
                            <Alert color="success">{props.t(success)}</Alert>
                        </div>
                    ) : null}

                    {error ? (
                        <div className="mb-3">
                            <Alert color="danger">{props.t(error)}</Alert>
                        </div>
                    ) : null}

                    <div className="mb-3">
                        <AvField
                            label={props.t("Client")}
                            type="text"
                            name="name"
                            value={props.client && props.client.name}
                            readOnly
                        />
                    </div>
                    <div className="mb-3">
                        <label>{props.t("Product / Service")}</label>
                        {props.rateId !== undefined ? (
                            <AvField
                                type="text"
                                name="name"
                                value={productData.product_name}
                                readOnly
                                disabled
                            />
                        ) : (
                            <div className="position-relative">
                                <Select options={productOptions} onChange={selectProduct} />
                                <div className="small_loader">{productLoader}</div>
                            </div>
                        )}
                    </div>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("Purchase Price ($)")}
                                    type="text"
                                    name="purchase_price"
                                    value={purchasePrice}
                                    readOnly
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("Purchase Margin (%)")}
                                    type="text"
                                    name="purchase_margin"
                                    value={purchaseMargin}
                                    onChange={(e) => {
                                        getPurchaseMargin(e.target.value),
                                            setPurchaseMargin(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("Discount (%)")}
                                    type="text"
                                    name="discount"
                                    value={specialPrice < salesPrice ? discount : "0.00"}
                                    onChange={(e) => {
                                        getDiscount(e.target.value), setDiscount(e.target.value);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("Sales Price ($)")}
                                    type="text"
                                    name="sales_price"
                                    value={salesPrice}
                                    readOnly
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("Sales Margin (%)")}
                                    type="text"
                                    name="sales_margin"
                                    value={discount == "100" ? "0.00" : salesMargin}
                                    onChange={(e) => {
                                        getSalesMargin(e.target.value),
                                            setSalesMargin(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <AvField
                                    label={props.t("Special Price ($)")}
                                    type="text"
                                    name="special_price"
                                    value={specialPrice}
                                    onChange={(e) => {
                                        getSpecialPrice(e.target.value),
                                            setSpecialPrice(e.target.value);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <div className="text-end">
                        {loading ? (
                            <button
                                className="btn btn-primary disabled"
                                type="button"
                                disabled
                            >
                                {props.t("Loading")}
                            </button>
                        ) : (
                            <button className="btn btn-primary" type="submit">
                                {props.rateId ? props.t("Update") : props.t("Submit")}
                            </button>
                        )}
                        <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => props.setIsOpenModal(false)}
                        >
                            {props.t("Cancel")}
                        </button>
                    </div>
                </div>
            </AvForm>
        </Modal>
    );
}
export default withTranslation()(RateModal);
RateModal.propTypes = {
    t: PropTypes.any,
};
