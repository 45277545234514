import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import { Row, Card, Alert } from "reactstrap";
import { withTranslation } from "react-i18next";
const axios = require("axios").default;


const TermsConditions = (props) => {
    const [content, setContent] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const getContent = () => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_APIKEY}/terms-and-conditions`)
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setContent(res.data.data);
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getContent();
    }, []);

    return (
        <React.Fragment>
            <MetaTags>
                <title>Terms of Use | Clouget</title>
            </MetaTags>
            <section className="container mt-5">
                <div className="row mb-4">
                    <div className="col-6">
                        <h1 className="fs-2">{props.t("Terms of Use")}</h1>
                    </div>
                    <div className="col-6 text-end">
                        <button className="btn btn-primary " type="button" onClick={() => window.history.go(-1)}>
                            {props.t("Back")}
                        </button>
                    </div>
                </div>
                <Row className="row">
                    {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                    {loading ?
                        <div className="text-center">
                            Loading...
                        </div>
                        :
                        <p style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: content?.description }} />
                    }
                </Row>
            </section>
        </React.Fragment>
    )
};
export default withTranslation()(TermsConditions);
TermsConditions.propTypes = {
    t: PropTypes.any,
};