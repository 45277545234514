/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Table, Card, CardBody, Row, Col } from "reactstrap";
import MultiType from "../../AllCharts/chartjs/barchart";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../../components/Loader";
import AssignUserList from "../../../components/AssignUserList/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import BarChart from "../../AllCharts/chartjs/barchart1";
import { Link } from "react-router-dom";

let referenceType = "";
let fromDate = "";
let toDate = "";
let agentId = "";
let category = "";
const IncidentClient = (props) => {
    const [incidents, setIncidents] = useState([]);
    const [loader, setLoader] = useState(true);
    const [selectedType, SetSelectedType] = useState("");
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignUsers, setAssignUsers] = useState([]);
    const [assignedUserId, setAssignedUserId] = useState(getCurrentUser().id);
    const [assignedUserName, setAssignedUserName] = useState(
        `${props.t("Select an agent to filter")}`
    );
    const [techHistory, setTechHistory] = useState([]);
    const [error, setError] = useState("");
    const [allRef, setAllRef] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const startYear = 2017;
    const endYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = endYear; year >= startYear; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }
    const overviewGraph = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/technical?type=incidents_by_client`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setIncidents(res.data.data);
                }
                setLoader(false);
            });
    };
    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };
    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };
    const getHistory = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/incident-by-client-history`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setTechHistory(res.data.data);
                }
            });
    };
    const getExport = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/incident-by-client-history?export=download`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.location.href = res.data.url;
                }
            });
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Incident`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                }
            });
    };
    const searchData = () => {
        setIncidents([]);
        setLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/technical?type=incidents_by_client&reference=${referenceType}&startDate=${fromDate}&endDate=${toDate}&agent_id=${agentId}&category=${category}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setIncidents(res.data.data);
                }
                setLoader(false);
            });
        setTechHistory([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/incident-by-client-history?reference=${referenceType}&startDate=${fromDate}&endDate=${toDate}&agent_id=${agentId}&category=${category}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setTechHistory(res.data.data);
                }
            });
    };
    const getType = (e) => {
        if (e.target.name == "reference") {
            referenceType = e.target.value;
        }
        searchData();
    };
    const getDate = (e) => {
        if (e.target.name == "start_date") {
            fromDate = e.target.value;
            toDate = e.target.value;
            searchData();
        } else if (e.target.name == "from_date") {
            fromDate = e.target.value;
        } else if (e.target.name == "to_date") {
            toDate = e.target.value;
            searchData();
        } else if (e.target.name == "annual") {
            if (e.target.value == "all_date") {
                fromDate = "";
                toDate = "";
                searchData();
            }
        } else if (e.target.name == "year") {
            fromDate = `01-01-${e.target.value}`;
            toDate = `31-12-${e.target.value}`;
            searchData();
        } else if (e.target.name == "month") {
            if (e.target.value == "january") {
                fromDate = `01-01-${year}`;
                toDate = `31-01-${year}`;
            } else if (e.target.value == "february") {
                fromDate = `01-02-${year}`;
                toDate = `28-02-${year}`;
            } else if (e.target.value == "march") {
                fromDate = `01-03-${year}`;
                toDate = `31-03-${year}`;
            } else if (e.target.value == "april") {
                fromDate = `01-04-${year}`;
                toDate = `30-04-${year}`;
            } else if (e.target.value == "may") {
                fromDate = `01-05-${year}`;
                toDate = `31-05-${year}`;
            } else if (e.target.value == "june") {
                fromDate = `01-06-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "july") {
                fromDate = `01-07-${year}`;
                toDate = `31-07-${year}`;
            } else if (e.target.value == "august") {
                fromDate = `01-08-${year}`;
                toDate = `31-08-${year}`;
            } else if (e.target.value == "september") {
                fromDate = `01-09-${year}`;
                toDate = `30-09-${year}`;
            } else if (e.target.value == "october") {
                fromDate = `01-10-${year}`;
                toDate = `31-10-${year}`;
            } else if (e.target.value == "november") {
                fromDate = `01-11-${year}`;
                toDate = `30-11-${year}`;
            } else if (e.target.value == "december") {
                fromDate = `01-12-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        } else if (e.target.name == "quarter") {
            if (e.target.value == "first_quarter") {
                fromDate = `01-01-${year}`;
                toDate = `31-03-${year}`;
            } else if (e.target.value == "second_quarter") {
                fromDate = `01-04-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "third_quarter") {
                fromDate = `01-07-${year}`;
                toDate = `30-09-${year}`;
            } else if (e.target.value == "forth_quarter") {
                fromDate = `01-10-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        } else if (e.target.name == "semester") {
            if (e.target.value == "first_semester") {
                fromDate = `01-01-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "second_semester") {
                fromDate = `01-07-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        }
    };
    const getAgent = (e) => {
        agentId = e;
        searchData();
    };
    const getCatagory = (e) => {
        if (e.target.name == "clients") {
            category = e.target.value;
        }
        searchData();
    };
    useEffect(() => {
        overviewGraph();
        getAssignUser();
        getHistory();
        getReferences();
    }, []);

    function handleSelectedType(e) {
        SetSelectedType(e);
    }
    return (
        <>
            {assignedModal ? (
                <AssignUserList
                    setAssignedModal={setAssignedModal}
                    getAssignedUser={getAssignedUser}
                    data={assignUsers}
                    getData={getAgent}
                />
            ) : (
                ""
            )}
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title> TechnicalService - IncidentClients| Clouget</title>
                    </MetaTags>
                    <Container>
                        <div className="page-title-box">
                            <AvForm>
                                <Row>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="annual"
                                            value={selectedType}
                                            onChange={(e) => {
                                                handleSelectedType(e.target.value), getDate(e);
                                            }}
                                        >
                                            <option value="year">{props.t("Year")}</option>
                                            <option value="semester">{props.t("Semester")}</option>
                                            <option value="quarter">{props.t("Quarter")}</option>
                                            <option value="month">{props.t("Month")}</option>
                                            <option value="day">{props.t("Day")}</option>
                                            <option value="between_dates">
                                                {props.t("Between dates")}
                                            </option>
                                            <option value="all_date">{props.t("All Dates")}</option>
                                        </AvField>
                                    </Col>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="clients"
                                            onChange={(e) => getCatagory(e)}
                                        >
                                            <option value="clients">{props.t("Clients")}</option>
                                            <option value="client_categories">
                                                {props.t("Client Category")}
                                            </option>
                                        </AvField>
                                    </Col>
                                    <Col md={2}>
                                        <AvField
                                            style={{
                                                width: "100px",
                                            }}
                                            type="select"
                                            name="reference"
                                            onChange={(e) => getType(e)}
                                        >
                                            <option value="">{props.t("Reference")}</option>
                                            {allRef &&
                                                allRef.map((item, i) => (
                                                    <option key={i} value={item.prefix}>
                                                        {item.prefix}
                                                    </option>
                                                ))}
                                        </AvField>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <div className="mt-2">
                                            {selectedType == "semester" ? (
                                                <>
                                                    <AvField
                                                        type="select"
                                                        name="semester"
                                                        onChange={(e) => getDate(e)}
                                                    >
                                                        <option value="" disabled>
                                                            {props.t("Select semester")}
                                                        </option>
                                                        <option value="first_semester">
                                                            1st {props.t("semester")}
                                                        </option>
                                                        <option value="second_semester">
                                                            2nd {props.t("semester")}
                                                        </option>
                                                    </AvField>
                                                </>
                                            ) : selectedType == "quarter" ? (
                                                <>
                                                    <AvField
                                                        type="select"
                                                        name="quarter"
                                                        onChange={(e) => getDate(e)}
                                                    >
                                                        <option value="" disabled>
                                                            {props.t("Select quarter")}
                                                        </option>
                                                        <option value="first_quarter">
                                                            1st {props.t("quarter")}
                                                        </option>
                                                        <option value="second_quarter">
                                                            2nd {props.t("quarter")}
                                                        </option>
                                                        <option value="third_quarter">
                                                            3rd {props.t("quarter")}
                                                        </option>
                                                        <option value="forth_quarter">
                                                            4th {props.t("quarter")}
                                                        </option>
                                                    </AvField>
                                                </>
                                            ) : selectedType == "day" ? (
                                                <>
                                                    <AvField
                                                        onChange={(e) => getDate(e)}
                                                        dateFormat="MMMM d, yyyy"
                                                        type="date"
                                                        name="start_date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                </>
                                            ) : selectedType == "between_dates" ? (
                                                <>
                                                    <label>{props.t("From")}</label>
                                                    <AvField
                                                        onChange={(e) => getDate(e)}
                                                        dateFormat="MMMM d, yyyy"
                                                        name="from_date"
                                                        type="date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                    <label>{props.t("To")}</label>
                                                    <AvField
                                                        onChange={(e) => getDate(e)}
                                                        dateFormat="MMMM d, yyyy"
                                                        name="to_date"
                                                        type="date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                </>
                                            ) : selectedType == "month" ? (
                                                <>
                                                    <AvField
                                                        type="select"
                                                        name="month"
                                                        onChange={(e) => getDate(e)}
                                                    >
                                                        <option value="" disabled>
                                                            {props.t("Select month")}
                                                        </option>
                                                        <option value="january"> {props.t("January")}</option>
                                                        <option value="february"> {props.t("February")}</option>
                                                        <option value="march"> {props.t("March")}</option>
                                                        <option value="april"> {props.t("April")}</option>
                                                        <option value="may"> {props.t("May")}</option>
                                                        <option value="june"> {props.t("June")}</option>
                                                        <option value="July"> {props.t("July")}</option>
                                                        <option value="august"> {props.t("August")}</option>
                                                        <option value="september"> {props.t("September")}</option>
                                                        <option value="october"> {props.t("October")}</option>
                                                        <option value="november"> {props.t("November")}</option>
                                                        <option value="december"> {props.t("December")}</option>
                                                    </AvField>
                                                </>
                                            ) : selectedType == "all_dates" ? (
                                                ""
                                            ) : (
                                                <select
                                                    id="year-select"
                                                    name="year"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                    onChange={(e) => {
                                                        getDate(e), setYear(e.target.value);
                                                    }}
                                                >
                                                    {yearOptions}
                                                </select>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <span
                                            className="form-control mt-2"
                                            onClick={() => setAssignedModal(true)}
                                        >
                                            {assignedUserName}
                                        </span>
                                    </Col>
                                </Row>
                            </AvForm>
                        </div>
                    </Container>
                    <Container>
                        <div className="page-title-box">
                            <h6 className="page-title">
                                {props.t("Clients with more incidents")}
                            </h6>
                        </div>
                        <Card>
                            <CardBody>
                                {loader ? (
                                    <Loader />
                                ) : (
                                    // <MultiType data={incidents?.data} />
                                    <BarChart data={incidents} />
                                )}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => getExport()}
                                    >
                                        {props.t("Export")}
                                    </button>
                                </div>
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>{props.t("Name")}</th>
                                                <th>{props.t("Pending")}</th>
                                                <th>{props.t("Refused")}</th>
                                                <th>{props.t("Resolved")}</th>
                                                {/* <th>{props.t("Accepted")}</th> */}
                                                <th>{props.t("Closed")}</th>
                                                <th>{props.t("Total")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {techHistory &&
                                                techHistory.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <Link
                                                                to={
                                                                    item.name == "Without Client"
                                                                        ? `/reports/technicalService/incidentClient`
                                                                        : `/client/${item.id}`
                                                                }
                                                                style={{ color: " inherit" }}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                to={
                                                                    item.name == "Without Client"
                                                                        ? `/reports/technicalService/incidentClient`
                                                                        : `/client/${item.id}`
                                                                }
                                                                style={{ color: " inherit" }}
                                                            >
                                                                {item.pending}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                to={
                                                                    item.name == "Without Client"
                                                                        ? `/reports/technicalService/incidentClient`
                                                                        : `/client/${item.id}`
                                                                }
                                                                style={{ color: " inherit" }}
                                                            >
                                                                {item.refused}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                to={
                                                                    item.name == "Without Client"
                                                                        ? `/reports/technicalService/incidentClient`
                                                                        : `/client/${item.id}`
                                                                }
                                                                style={{ color: " inherit" }}
                                                            >
                                                                {item.resolved}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                to={
                                                                    item.name == "Without Client"
                                                                        ? `/reports/technicalService/incidentClient`
                                                                        : `/client/${item.id}`
                                                                }
                                                                style={{ color: " inherit" }}
                                                            >
                                                                {item.closed}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Link
                                                                to={
                                                                    item.name == "Without Client"
                                                                        ? `/reports/technicalService/incidentClient`
                                                                        : `/client/${item.id}`
                                                                }
                                                                style={{ color: " inherit" }}
                                                            >
                                                                {item.total}
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {/* {error ? <Alert color="danger">{props.t(error)}</Alert> : ""} */}
                                {loader}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        </>
    );
};
export default withTranslation()(IncidentClient);
IncidentClient.propTypes = {
    t: PropTypes.any,
};
