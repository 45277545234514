import React from "react";
import { Spinner } from "reactstrap";

function Loader(props) {
  return (
    <div className="text-center py-5">
      <Spinner className="ms-2" color="dark" />
    </div>
  );
}

export default Loader;
