import React,{ useState, useEffect } from "react"
import MetaTags from 'react-meta-tags'
import { Col, Container, Row, Table, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { getCurrentUser } from "../../helpers/Utils"
import SingleList from "./single-list"
const axios = require('axios').default
import Loader from "../../components/Loader"

const AllCompanies = () => {
  const [data, setData] = useState([])
  const [error, setError] = useState('')
  const [loader, setLoader] = useState(<Loader />)

  const getCompanies = () => {
    axios
    .get(`${process.env.REACT_APP_APIKEY}/companies`, {
      headers: {
        Authorization: `Bearer ${getCurrentUser().token}`,
      },
    })
    .then(function (res) {
      setLoader('')
      if (res.data.status) {
        setError('')
        setData(res.data.companies)
      } else {
        setError(res.data.message)
      }
    })
  }
  useEffect(() => {
    getCompanies()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Companies | Clouget</title>
        </MetaTags>
        <Container fluid>
          <div className="company_list">
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={6}>
                  <h6 className="page-title">All Companies</h6>
                </Col>
                <Col md={6}>
                  <div className="float-end d-none d-md-block">
                    <Link to="/create-company" className="btn btn-primary">
                      Create New
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
            {error}
            <Card>
              <CardBody>
                <Table striped className="align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Country</th>
                      <th>Currency</th>
                      <th rowSpan="2">Logo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, i) =>(
                      <SingleList data={item} key={i} i={i} />
                    ))}
                  </tbody>
                </Table>
                {loader}
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllCompanies