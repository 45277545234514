import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Modal, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function ReferenceModal(props) {
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const submitReference = (e, value) => {
    let defaultBY = "0";
    if (value.by_default) {
      defaultBY = "1";
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("name", value.name);
    formData.append("type", value.type);
    // formData.append("title", value.title);
    formData.append("prefix", value.prefix);
    formData.append("last_reference", value.last_reference);
    formData.append("by_default", defaultBY);
    formData.append("number_of_digit", value.number_of_digit);

    if (showMore) {
      formData.append("title", value.title);
      formData.append("template_id", value.template_id);
      formData.append("comments", value.comments);
      formData.append("addendum", value.addendum);
    }

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/references${
          props.reference ? `/${props.reference.id}?_method=PUT` : ""
        }`,
        formData,
        // {
        //   name: value.name,
        //   title: value.title,
        //   type: value.type,
        //   prefix: value.prefix,
        //   last_reference: value.last_reference,
        //   number_of_digit: value.number_of_digit,
        //   by_default: defaultBY,
        // },
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          props.setreference_modal(false);
        } else {
          setError(res.data.message);
          setSuccess("");
        }

        setTimeout(() => {
          if (props.reference) {
            props.setReference(res.data.reference);
          } else {
            props.getReferences();
          }

          setError("");
          setSuccess("");
        }, 2000);
      });
  };

  const getType = (e) => {
    if (
      e == "Client Asset" ||
      e == "Expense and investment" ||
      e == "Client" ||
      e == "Expense" ||
      e == "Incident" ||
      e == "Product" ||
      e == "Potential Client" ||
      e == "Service" ||
      e == "Supplier"
    ) {
      setShowMore(false);
    } else {
      setShowMore(true);
      getTemplates(e);
      setTitle(e);
    }
  };

  const getTemplates = (e) => {
    setTemplateData([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/my_templates?type=${e}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setTemplateData(res.data.templates);
        }
      });
  };

  useEffect(() => {
    if (props.reference !== undefined) {
      getType(props.reference.type);
    }
  }, []);

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        props.setreference_modal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <>
      <Modal size="lg" isOpen={props.reference_modal} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {props.reference
              ? `${props.t(props.reference.name)}`
              : `${props.t("New Reference")}`}
          </h5>
        </div>
        <div className="modal-body">
          {success ? (
            <div className="mb-3">
              <Alert color="success">{success}</Alert>
            </div>
          ) : null}

          {error ? (
            <div className="mb-3">
              <Alert color="danger">{error}</Alert>
            </div>
          ) : null}
          <AvForm
            onValidSubmit={(e, v) => {
              submitReference(e, v);
            }}
          >
            <Row>
              <Col md={6}>
                <div className="mb-3 required">
                  <AvField
                    label={props.t("Name")}
                    type="text"
                    name="name"
                    value={props.reference && props.reference.name}
                    required="required"
                  />
                </div>
                <div className="mb-3 required">
                  <AvField
                    label={props.t("Prefix")}
                    type="text"
                    name="prefix"
                    value={props.reference && props.reference.prefix}
                    disabled={props.reference ? true : false}
                    required="required"
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label={props.t("Number of Digits")}
                    type="number"
                    name="number_of_digit"
                    defaultValue="5"
                    value={props.reference && props.reference.number_of_digit}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3 required">
                  <AvField
                    label={props.t("Type")}
                    type="select"
                    name="type"
                    value={props.reference && props.reference.type}
                    onChange={(e) => getType(e.target.value)}
                    disabled={props.reference ? true : false}
                    required="required"
                  >
                    <option value="" hidden>
                      {props.t("Select type")}
                    </option>
                    <option value="Product">{props.t("Product")}</option>
                    <option value="Service">{props.t("Service")}</option>
                    <option value="Expense and investment">
                      {props.t("Expense and Investment")}
                    </option>
                    <option value="Client Asset">
                      {props.t("Client Asset")}
                    </option>
                    <option value="Client">{props.t("Client")}</option>
                    <option value="Potential Client">
                      {props.t("Potential Client")}
                    </option>
                    <option value="Incident">{props.t("Incident")}</option>
                    <option value="Sales Estimate">
                      {props.t("Sales Estimate")}
                    </option>
                    <option value="Sales Order">
                      {props.t("Sales Order")}
                    </option>
                    <option value="Sales Delivery Note">
                      {props.t("Sales Delivery Note")}
                    </option>
                    <option value="Work Estimate">
                      {props.t("Work Estimate")}
                    </option>
                    <option value="Work Order">{props.t("Work Order")}</option>
                    <option value="Work Delivery Note">
                      {props.t("Work Delivery Note")}
                    </option>
                    <option value="Normal Invoice">
                      {props.t("Normal Invoice")}
                    </option>
                    <option value="Refund Invoice">
                      {props.t("Refund Invoice")}
                    </option>
                    <option value="Supplier">{props.t("Supplier")}</option>
                    <option value="Purchase Order">
                      {props.t("Purchase Order")}
                    </option>
                    <option value="Purchase Delivery Note">
                      {props.t("Purchase Delivery Note")}
                    </option>
                    <option value="Purchase Invoice">
                      {props.t("Purchase Invoice")}
                    </option>
                    <option value="Expense">{props.t("Expense")}</option>
                  </AvField>
                </div>
                <div className="mb-3">
                  <AvField
                    label={props.t("Last Reference")}
                    type="number"
                    defaultValue="0"
                    name="last_reference"
                    value={props.reference && props.reference.last_reference}
                  />
                </div>
                <div className="mb-3">
                  <label>{props.t("By Default")}</label>
                  <AvField
                    type="checkbox"
                    name="by_default"
                    defaultChecked={
                      props.reference && props.reference.by_default == "1"
                        ? true
                        : false
                    }
                    // className="ms-2"
                  />
                </div>
              </Col>
            </Row>
            {showMore ? (
              <>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Title")}
                        type="text"
                        name="title"
                        value={props.t(title)}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        label={props.t("Template")}
                        type="select"
                        name="template_id"
                        value={props.reference && props.reference.template_id}
                      >
                        <option value="" hidden>
                          {props.t("select")}
                        </option>
                        {templateData?.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>
                <div className="mb-3">
                  <AvField
                    label={props.t("Comments")}
                    type="textarea"
                    name="comments"
                    value={props.reference && props.reference.comments}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label={props.t("Addendum")}
                    type="textarea"
                    name="addendum"
                    value={props.reference && props.reference.addendum}
                  />
                </div>
              </>
            ) : (
              ""
            )}
            <div className="modal-footer">
              <div className="text-end">
                {loading ? (
                  <button
                    type="button"
                    className="btn btn-primary disabled"
                    disabled
                  >
                    {props.t("Loading")}
                  </button>
                ) : (
                  <button className="btn btn-primary" type="submit">
                    {props.reference
                      ? `${props.t("Update")}`
                      : `${props.t("Save")}`}
                  </button>
                )}
                <button
                  className="btn btn-primary ms-2"
                  type="button"
                  onClick={() => props.setreference_modal(false)}
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </div>
          </AvForm>
        </div>
      </Modal>
    </>
  );
}
export default withTranslation()(ReferenceModal);
ReferenceModal.propTypes = {
  t: PropTypes.any,
};
