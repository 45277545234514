/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let selectedRoleId = [];
let selectedRoleType = [];
let selectedRoleNum = [];
import { Alert } from "reactstrap";
const ListPurchaseDelivery = (props) => {
    // const [displayInvoices, setDisplayInvoices] = useState(props.data);
    const [displayPurchaseDelivery, setDisplayPurchaseDelivery] = useState(
        props.data
    );
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [error, setError] = useState("");

    const delUser = (e) => {
        setdynamic_title("");
        setdynamic_description("");
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-tables/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setdynamic_title("Deleted");
                    setdynamic_description("File has been deleted.");
                    setDisplayPurchaseDelivery(null);
                } else {
                    setdynamic_title("Failed");
                    setdynamic_description(res.data.message);
                }
                setsuccess_dlg(true);
            });
    };
    useEffect(() => {
        selectedRoleId = [];
        selectedRoleType = [];
        selectedRoleNum = [];
    }, []);
    const getSelectedId = (e, check, type, num) => {
        if (check.target.checked) {
            selectedRoleId.push(e);
            selectedRoleType.push(type);
            selectedRoleNum.push(num);
            const uniqueID = selectedRoleId.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            const uniqueType = selectedRoleType.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            const uniqueNum = selectedRoleNum.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            selectedRoleId = uniqueID;
            selectedRoleType = uniqueType;
            selectedRoleNum = uniqueNum;
            props.setSelectedIds(selectedRoleId);
            props.setSelectedType(selectedRoleType);
            props.setSelectedNum(selectedRoleNum);
        } else {
            selectedRoleId = props.selectedIds;
            selectedRoleType = props.selectedType;
            selectedRoleNum = props.selectedNum;
            var array = selectedRoleId;
            var array2 = selectedRoleType;
            var array3 = selectedRoleNum;
            var index = array.indexOf(e);
            var index2 = array2.indexOf(type);
            var index3 = array3.indexOf(num);
            if (index !== -1) {
                array.splice(index, 1);
            }
            if (index2 !== -1) {
                array2.splice(index2, 1);
            }
            if (index2 !== -1) {
                array3.splice(index3, 1);
            }
            props.setSelectedIds(selectedRoleId);
            props.setSelectedType(selectedRoleType);
            props.setSelectedNum(selectedRoleNum);
        }
    };
    return (
        <>
            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
            {displayPurchaseDelivery !== null ? (
                <tr>
                    <td>
                        <input
                            onChange={(e) =>
                                getSelectedId(
                                    displayPurchaseDelivery.id,
                                    e,
                                    displayPurchaseDelivery.reference_type,
                                    displayPurchaseDelivery.reference_number
                                )
                            }
                            name="chk"
                            value={displayPurchaseDelivery.id}
                            className="p-0 d-inline-block"
                            type="checkbox"
                        />
                    </td>
                    <td role="button" className="py-1">
                        <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
                            {displayPurchaseDelivery.reference}
                            {displayPurchaseDelivery.reference_number}
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
                            {displayPurchaseDelivery.supplier_name}
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
                            {displayPurchaseDelivery.purchase_document_ref}
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
                            {displayPurchaseDelivery.title}
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
                            {displayPurchaseDelivery.created_by_name}
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
                            {props.t(
                                displayPurchaseDelivery.status == "partially paid"
                                    ? "Partially Paid"
                                    : displayPurchaseDelivery.status == "unpaid"
                                        ? "Unpaid"
                                        : displayPurchaseDelivery.status == "paid"
                                            ? "Paid"
                                            : displayPurchaseDelivery.status
                            )}
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
                            {displayPurchaseDelivery.date}
                        </Link>
                    </td>
                    <td role="button">
                        <Link to={`/purchase-invoice/${displayPurchaseDelivery.id}`}>
                            {displayPurchaseDelivery.amount == "0.00"
                                ? "0.00"
                                : displayPurchaseDelivery.amount}
                        </Link>
                    </td>
                    <td>
                        {getPermission().purchase_invoices.purchase_invoices.delete
                            .is_checked === 1 ? (
                            <button
                                type="button-input"
                                className="btn btn-danger"
                                onClick={() => setconfirm_alert(true)}
                            >
                                {props.t("Delete")}
                            </button>
                        ) : (
                            ""
                        )}
                    </td>
                </tr>
            ) : (
                ""
            )}
            {confirm_alert ? (
                <SweetAlert
                    title={props.t("Are you sure?")}
                    cancelBtnText={props.t("Cancel")}
                    confirmBtnText={props.t("Yes, delete it!")}
                    warning
                    showCancel

                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        delUser(displayPurchaseDelivery.id);
                        setconfirm_alert(false);
                    }}
                    onCancel={() => setconfirm_alert(false)}
                ></SweetAlert>
            ) : null}

            {success_dlg ? (
                <SweetAlert
                    success={dynamic_title == "Deleted" ? true : false}
                    error={dynamic_title == "Failed" ? true : false}
                    title={props.t(dynamic_title)}
                    onConfirm={() => {
                        setsuccess_dlg(false);
                    }}
                >
                    {props.t(dynamic_description)}
                </SweetAlert>
            ) : null}
        </>
    );
};
export default withTranslation()(ListPurchaseDelivery);
ListPurchaseDelivery.propTypes = {
    t: PropTypes.any,
};
