/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
    Container,
    Table,
    Card,
    CardBody,
    Alert,
    Col,
    Row,
    Modal,
} from "reactstrap";
import MultiType from "../../AllCharts/chartjs/barchart";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../../components/Loader";
import AssignUserList from "../../../components/AssignUserList/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ClientList from "../../../components/ClientList/index";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
let referenceType = "";
let fromDate = "";
let toDate = "";
let agentId = "";
let clientId = "";
let state = "";
let catalog = "";
let refType = "";
const SaleByItem = (props) => {
    const history = useHistory();
    const [invoiced, setInvoiced] = useState([]);
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignUsers, setAssignUsers] = useState([]);
    const [assignedUserId, setAssignedUserId] = useState(getCurrentUser().id);
    const [assignedUserName, setAssignedUserName] = useState(
        `${props.t("Select an agent to filter")}`
    );
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectClientName, setSelectClientName] = useState(
        "Select an client to filter"
    );
    const [selectType, setSelectType] = useState("");
    let clientName = "";
    const [selectedType, setSelectedType] = useState("");
    // const [fromDate, setFromDate] = useState("");
    // const [toDate, setToDate] = useState("");
    const [selectItemModal, setSelectItemModal] = useState(false);
    const [dataProduct, setDataProduct] = useState([]);
    const [timer, setTimer] = useState(null);
    const [clientLoader, setClientLoader] = useState(false);
    const [dataService, setDataService] = useState([]);
    const [selectClientModal, setSelectClientModal] = useState(false);
    const [selectClientId, setSelectClientId] = useState("");
    const [clients, setClients] = useState("");
    const [allClients, setAllClients] = useState([]);
    const [clientNameData, setClientNameData] = useState(
        `${props.t("Select an client to filter")}`
    );
    const [workData, setWorkData] = useState("");
    const [clientData, setClientData] = useState("");
    const [paymentOption, setPaymentOption] = useState("");
    const [itemHistory, setItemHistory] = useState([]);
    const [historyError, setHistoryError] = useState("");
    const [allRef, setAllRef] = useState([]);
    const [stateType, setStateType] = useState("");
    const [year, setYear] = useState(new Date().getFullYear());
    const startYear = 2017;
    const endYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = endYear; year >= startYear; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }
    const overviewGraph = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/sales?type=items&referenceType=Sales Estimate`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setInvoiced(res.data.data.sales_items);
                }
                setLoader(false);
            });
    };
    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };
    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };
    const clientDetail = (e) => {
        setSelectClientId(e);
        let index = allClients.findIndex((x) => x.id == e);
        if (index != -1) {
            setClientNameData(allClients[index].legal_name);
            setClientData(allClients[index].address);
            setWorkData(allClients[index].address);
            setClients(allClients[index]);
            //   getSingleClientsAddress(allClients[index].id);
            AssetsData(allClients[index].id);
            setPaymentOption(allClients[index].payment_option_id);
        }
    };
    const AssetsData = () => { };

    const getClients = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getClientData(e);
        }, 1000);
        setTimer(newTimer);
    };
    const getClientData = (e) => {
        setAllClients([]);
        let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
        )}/clients`;
        if (e !== undefined) {
            url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
            )}/clients?search=${e}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.status) {
                    setAllClients(res.data.clients);
                } else {
                    setAllClients([]);
                }
            });
    };
    function handleSelectedType(e) {
        setSelectedType(e);
    }
    function handleSelectType(e) {
        setSelectType(e);
    }
    const getHistory = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/sales-items-history?referenceType=Sales Estimate`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setItemHistory(res.data.data);
                } else {
                    setHistoryError(res.data.message);
                }
            });
    };
    const getExport = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/sales-items-history?referenceType=Sales Estimate&export=download`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.location.href = res.data.url;
                }
            });
    };
    const getReferences = (e) => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=${e !== undefined ? e : "Sales Estimate"}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                }
            });
    };
    const searchData = () => {
        setInvoiced([]);
        setLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/sales?type=items&reference=${referenceType}&state=${stateType}&startDate=${fromDate}&endDate=${toDate}&agent_id=${agentId}&client_id=${clientId}&status=${state}&category=${catalog}&referenceType=${refType == "" ? "Sales Estimate" : refType
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setInvoiced(res.data.data.sales_items);
                }
                setLoader(false);
            });
        setItemHistory([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/sales-items-history?reference=${referenceType}&state=${stateType}&startDate=${fromDate}&endDate=${toDate}&agent_id=${agentId}&client_id=${clientId}&status=${state}&category=${catalog}&referenceType=${refType == "" ? "Sales Estimate" : refType
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setItemHistory(res.data.data);
                } else {
                    setHistoryError(res.data.message);
                }
            });
    };
    const getType = (e) => {
        if (e.target.name == "reference") {
            referenceType = e.target.value;
        } else if (e.target.name == "states") {
            setStateType(e.target.value);
        }
        searchData();
    };
    const getType1 = (e) => {
        if (e.target.name == "sales") {
            refType = e.target.value;
        }
        searchData();
    };
    const getDate = (e) => {
        if (e.target.name == "start_date") {
            fromDate = e.target.value;
            toDate = e.target.value;
            searchData();
        } else if (e.target.name == "from_date") {
            fromDate = e.target.value;
        } else if (e.target.name == "to_date") {
            toDate = e.target.value;
            searchData();
        } else if (e.target.name == "annual") {
            if (e.target.value == "all_date") {
                fromDate = "";
                toDate = "";
                searchData();
            }
        } else if (e.target.name == "year") {
            fromDate = `01-01-${e.target.value}`;
            toDate = `31-12-${e.target.value}`;
            searchData();
        } else if (e.target.name == "month") {
            if (e.target.value == "january") {
                fromDate = `01-01-${year}`;
                toDate = `31-01-${year}`;
            } else if (e.target.value == "february") {
                fromDate = `01-02-${year}`;
                toDate = `28-02-${year}`;
            } else if (e.target.value == "march") {
                fromDate = `01-03-${year}`;
                toDate = `31-03-${year}`;
            } else if (e.target.value == "april") {
                fromDate = `01-04-${year}`;
                toDate = `30-04-${year}`;
            } else if (e.target.value == "may") {
                fromDate = `01-05-${year}`;
                toDate = `31-05-${year}`;
            } else if (e.target.value == "june") {
                fromDate = `01-06-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "july") {
                fromDate = `01-07-${year}`;
                toDate = `31-07-${year}`;
            } else if (e.target.value == "august") {
                fromDate = `01-08-${year}`;
                toDate = `31-08-${year}`;
            } else if (e.target.value == "september") {
                fromDate = `01-09-${year}`;
                toDate = `30-09-${year}`;
            } else if (e.target.value == "october") {
                fromDate = `01-10-${year}`;
                toDate = `31-10-${year}`;
            } else if (e.target.value == "november") {
                fromDate = `01-11-${year}`;
                toDate = `30-11-${year}`;
            } else if (e.target.value == "december") {
                fromDate = `01-12-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        } else if (e.target.name == "quarter") {
            if (e.target.value == "first_quarter") {
                fromDate = `01-01-${year}`;
                toDate = `31-03-${year}`;
            } else if (e.target.value == "second_quarter") {
                fromDate = `01-04-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "third_quarter") {
                fromDate = `01-07-${year}`;
                toDate = `30-09-${year}`;
            } else if (e.target.value == "forth_quarter") {
                fromDate = `01-10-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        } else if (e.target.name == "semester") {
            if (e.target.value == "first_semester") {
                fromDate = `01-01-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "second_semester") {
                fromDate = `01-07-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        }
    };
    const getAgent = (e) => {
        agentId = e;
        searchData();
    };
    const getClientId = (e) => {
        clientId = e;
        searchData();
    };
    const getStatus = (e) => {
        if (e.target.name == "state") {
            state = e.target.value;
        }
        searchData();
    };
    const getCatalog = (e) => {
        if (e.target.name == "catalogs") {
            catalog = e.target.value;
        }
        searchData();
    };
    const getEntryType = ($referenceType, $link = "no") => {
        if ($referenceType == "PRO" || $referenceType == "pro") {
            if ($link == "no") {
                return "Product";
            }
            return "/product/";
        }
        if ($referenceType == "SER" || $referenceType == "ser") {
            if ($link == "no") {
                return "Service";
            }
            return "/service/";
        }
    };
    useEffect(() => {
        overviewGraph();
        getAssignUser();
        getHistory();
        getReferences();
    }, []);
    return (
        <>
            {assignedModal ? (
                <AssignUserList
                    setAssignedModal={setAssignedModal}
                    getAssignedUser={getAssignedUser}
                    data={assignUsers}
                    getData={getAgent}
                />
            ) : (
                ""
            )}{" "}
            {selectClientModal ? (
                <ClientList
                    setSelectClientModal={setSelectClientModal}
                    setSelectClientId={setSelectClientId}
                    clients={allClients}
                    clientDetail={clientDetail}
                    getClients={getClients}
                    getData={getClientId}
                />
            ) : (
                ""
            )}
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>sales - byItem| Clouget</title>
                    </MetaTags>
                    <Container>
                        <div className="page-title-box">
                            <AvForm>
                                <Row>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="annual"
                                            value={selectedType}
                                            onChange={(e) => {
                                                handleSelectedType(e.target.value), getDate(e);
                                            }}
                                        >
                                            <option value="year">{props.t("Year")}</option>
                                            <option value="semester">{props.t("Semester")}</option>
                                            <option value="quarter">{props.t("Quarter")}</option>
                                            <option value="month">{props.t("Month")}</option>
                                            <option value="day">{props.t("Day")}</option>
                                            <option value="between_dates">
                                                {props.t("Between dates")}
                                            </option>
                                            <option value="all_date">{props.t("All Dates")}</option>
                                        </AvField>
                                    </Col>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="sales"
                                            onChange={(e) => {
                                                getReferences(e.target.value),
                                                    getType1(e),
                                                    handleSelectType(e.target.value);
                                            }}
                                        >
                                            <option value="Sales Estimate">
                                                {props.t("Sales Estimate")}
                                            </option>
                                            <option value="Sales Order">
                                                {props.t("Sales Order")}
                                            </option>
                                            <option value="Sales Delivery Note">
                                                {props.t("Sales Delivery Note")}
                                            </option>
                                        </AvField>
                                    </Col>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="catalogs"
                                            onChange={(e) => getCatalog(e)}
                                        >
                                            <option value="catalog">{props.t("Catalog")}</option>
                                            <option value="product_categories">
                                                {props.t("Product Categories")}
                                            </option>
                                        </AvField>
                                    </Col>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="state"
                                            onChange={(e) => getStatus(e)}
                                        >
                                            {selectType == "Sales Order" ? (
                                                <>
                                                    <option value="">{props.t("State")}</option>
                                                    <option value="Pending">{props.t("Pending")}</option>
                                                    <option value="Refused">{props.t("Refused")}</option>
                                                    <option value="In Progress">
                                                        {props.t("In progress")}
                                                    </option>
                                                    <option value="Closed">{props.t("Closed")}</option>
                                                </>
                                            ) : (
                                                <>
                                                    {selectType == "Sales Delivery Note" ? (
                                                        <>
                                                            <option value="">{props.t("State")}</option>
                                                            <option value="Pending Invoice">
                                                                {props.t("Pending Invoice")}
                                                            </option>
                                                            <option value="Refused">
                                                                {props.t("Refused")}
                                                            </option>
                                                            <option value="Closed">
                                                                {props.t("Closed")}
                                                            </option>
                                                            <option value="Invoiced">
                                                                {props.t("Invoiced")}
                                                            </option>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <option value="">{props.t("State")}</option>
                                                            <option value="Pending">
                                                                {props.t("Pending")}
                                                            </option>
                                                            <option value="Refused">
                                                                {props.t("Refused")}
                                                            </option>
                                                            <option value="Accepted">
                                                                {props.t("Accepted")}
                                                            </option>
                                                            <option value="Closed">
                                                                {props.t("Closed")}
                                                            </option>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </AvField>
                                    </Col>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="reference"
                                            onChange={(e) => getType(e)}
                                        >
                                            <option value="">{props.t("Reference")}</option>
                                            {allRef &&
                                                allRef.map((item, i) => (
                                                    <option key={i} value={item.prefix}>
                                                        {item.prefix}
                                                    </option>
                                                ))}
                                        </AvField>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <div className="mt-2">
                                            {selectedType == "semester" ? (
                                                <>
                                                    <AvField
                                                        type="select"
                                                        name="semester"
                                                        onChange={(e) => getDate(e)}
                                                    >
                                                        <option value="first_semester">
                                                            1st {props.t("semester")}
                                                        </option>
                                                        <option value="second_semester">
                                                            2nd {props.t("semester")}
                                                        </option>
                                                    </AvField>
                                                </>
                                            ) : selectedType == "quarter" ? (
                                                <>
                                                    <AvField
                                                        type="select"
                                                        name="quarter"
                                                        onChange={(e) => getDate(e)}
                                                    >
                                                        <option value="first_quarter">
                                                            1st {props.t("quarter")}
                                                        </option>
                                                        <option value="second_quarter">
                                                            2nd {props.t("quarter")}
                                                        </option>
                                                        <option value="third_quarter">
                                                            3rd {props.t("quarter")}
                                                        </option>
                                                        <option value="forth_quarter">
                                                            4th {props.t("quarter")}
                                                        </option>
                                                    </AvField>
                                                </>
                                            ) : selectedType == "day" ? (
                                                <>
                                                    <AvField
                                                        onChange={(e) => getDate(e)}
                                                        dateFormat="MMMM d, yyyy"
                                                        type="date"
                                                        name="start_date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                </>
                                            ) : selectedType == "between_dates" ? (
                                                <>
                                                    <label>{props.t("From")}</label>
                                                    <AvField
                                                        onChange={(e) => getDate(e)}
                                                        dateFormat="MMMM d, yyyy"
                                                        name="from_date"
                                                        type="date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                    <label>{props.t("To")}</label>
                                                    <AvField
                                                        onChange={(e) => getDate(e)}
                                                        dateFormat="MMMM d, yyyy"
                                                        name="to_date"
                                                        type="date"
                                                        autoComplete="off"
                                                        className="is-untouched is-pristine av-valid form-control"
                                                    />
                                                </>
                                            ) : selectedType == "month" ? (
                                                <>
                                                    <AvField
                                                        type="select"
                                                        name="month"
                                                        onChange={(e) => getDate(e)}
                                                    >
                                                        <option value="january"> {props.t("January")}</option>
                                                        <option value="february"> {props.t("February")}</option>
                                                        <option value="march"> {props.t("March")}</option>
                                                        <option value="april"> {props.t("April")}</option>
                                                        <option value="may"> {props.t("May")}</option>
                                                        <option value="june"> {props.t("June")}</option>
                                                        <option value="July"> {props.t("July")}</option>
                                                        <option value="august"> {props.t("August")}</option>
                                                        <option value="september"> {props.t("September")}</option>
                                                        <option value="october"> {props.t("October")}</option>
                                                        <option value="november"> {props.t("November")}</option>
                                                        <option value="december"> {props.t("December")}</option>
                                                    </AvField>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <select
                                            id="year-select"
                                            name="year"
                                            className="is-untouched is-pristine av-valid form-control mt-2"
                                            onChange={(e) => {
                                                getDate(e), setYear(e.target.value);
                                            }}
                                        >
                                            {yearOptions}
                                        </select>
                                    </Col>
                                    <Col md={4}>
                                        <span
                                            className="form-control mt-2"
                                            onClick={() => setAssignedModal(true)}
                                        >
                                            {assignedUserName}
                                        </span>
                                    </Col>
                                    <Col md={4}>
                                        <span
                                            className="form-control mt-2"
                                            onClick={() => {
                                                setSelectClientModal(true), getClientData();
                                            }}
                                            type="button"
                                        >
                                            {clientNameData}
                                        </span>
                                    </Col>
                                </Row>
                            </AvForm>
                        </div>
                    </Container>
                    <Container>
                        <div className="page-title-box">
                            <h6 className="page-title">
                                {props.t("Best selling products (before taxes)")}
                            </h6>
                        </div>
                        <Card>
                            <CardBody>
                                {loader ? <Loader /> : <MultiType data={invoiced} />}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Col md={2}>
                                    <div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => getExport()}
                                        >
                                            {props.t("Export")}
                                        </button>
                                    </div>
                                </Col>
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>{props.t("Reference")}</th>
                                                <th>{props.t("Name")}</th>
                                                <th>{props.t("Pending")}</th>
                                                <th>{props.t("Refused")}</th>
                                                <th>{props.t("Accepted")}</th>
                                                <th>{props.t("Closed")}</th>
                                                <th>{props.t("Total")}</th>
                                                <th>{props.t("Units")}</th>
                                                <th>{props.t("Amount")}($)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemHistory &&
                                                itemHistory.map((item, i) => (
                                                    <tr
                                                        key={i}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() =>
                                                            history.push(
                                                                getEntryType(item.referenceType, "yes") +
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        <td>{item.reference}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.pending}</td>
                                                        <td>{item.refused}</td>
                                                        <td>{item.accepted}</td>
                                                        <td>{item.closed}</td>
                                                        <td>{item.total}</td>
                                                        <td>{item.units}</td>
                                                        <td>{item.amount}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                {loader}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        </>
    );
};
export default withTranslation()(SaleByItem);
SaleByItem.propTypes = {
    t: PropTypes.any,
};
