/* eslint-disable react/prop-types */
import React from "react";
import { Modal, Table, Col } from "reactstrap";
import { getCurrentUser } from "../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function ClientList(props) {
    const getClient = (e) => {
        if (props.getData !== undefined) {
            props.getData(e.id);
        }
        props.clientDetail(e);
        if (props.setSelectedClient !== undefined) {
            props.setSelectedClient({
                label: e.name,
                value: e.id,
            });
        }
        props.setSelectClientModal(false);
    };

    return (
        <Modal size="xl" isOpen="true" centered={true}>
            <div className="modal-header">
                <Col md={6}>
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.t("Client Selection")}
                    </h5>
                </Col>
                <Col md={6}>
                    <div className="float-end d-flex">
                        <div className="me-3">
                            <input
                                className="form-control"
                                type="search"
                                placeholder={props.t("Search here")}
                                onChange={(e) => props.getClients(e.target.value)}
                            />
                        </div>
                        <div>
                            <button
                                type="button"
                                className="cross__btn btn btn-primary waves-effect waves-light"
                                onClick={() => props.setSelectClientModal(false)}
                                aria-hidden="true"
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                </Col>
            </div>
            <div className="modal-body table-responsive">
                <Table hover className="align-middle">
                    <thead>
                        <tr>
                            <th>{props.t("Reference")}</th>
                            <th>{props.t("Legal Name")}</th>
                            <th>{props.t("Name")}</th>
                            <th>{props.t("Ced/Ruc")}</th>
                            <th>{props.t("Phone")}</th>
                            <th>{props.t("Email")}</th>
                            {/* <th>Address</th> */}
                            <th>{props.t("Agent")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.clients?.map((item, i) => (
                            <tr key={i} onClick={() => getClient(item)}>
                                <td role="button">
                                    {item.reference}
                                    {item.reference_number}
                                </td>
                                <td role="button">{item.legal_name}</td>
                                <td role="button">{item.name}</td>
                                <td role="button">{item.tin}</td>
                                <td role="button">{item.phone_1}</td>
                                <td role="button">{item.email}</td>
                                <td role="button">{getCurrentUser().name}</td>
                                {/* <td role="button">{item.address}</td> */}

                                <td role="button">
                                    <span className="badge rounded-pill bg-success">
                                        {props.t("Yes")}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Modal>
    );
}
export default withTranslation()(ClientList);
ClientList.propTypes = {
    t: PropTypes.any,
};
