import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Modal,
    Input,
} from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import AssetsNew from "../../components/Assests/assests-new";
import { useHistory } from "react-router-dom";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import ClientList from "../../components/ClientList";
import AssignUserList from "../../components/AssignUserList";
import { Link } from "react-router-dom";
import ClientAddress from "../../components/ClientAddresses";
import ClientCustomDropdown from "../../components/ClientDropdown";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

let searchedClient;

const CreateServiceNew = (props) => {
    const history = useHistory();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModals, setIsOpenModals] = useState(false);
    const [newDisable, setNewDisable] = useState(false);
    const [clientAddress, setClientAddress] = useState("");
    const [clientFull, setClientFull] = useState("");
    const [referenceType, setReferenceType] = useState("");
    const [priorityName, setPriorityName] = useState("verylow");
    const [startDate, setStartDate] = useState(new Date());
    const [assignDate, setAssignDate] = useState("");
    const [closingDate, setClosingDate] = useState("");
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [isOpenAddress, setIsOpenAddress] = useState(false);
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [attachActive, setAttachActive] = useState(false);
    const [document, setDocument] = useState("");
    const [fileDocument, setFileDocument] = useState("");
    const [clientNameData, setClientNameData] = useState("");
    const [clientNameData2, setClientNameData2] = useState("");
    const [assetNameData, setAssetNameData] = useState("");
    const [assetID, setAssetID] = useState("");
    const [assetAll, setAssetAll] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [modelClient, setModelClient] = useState(false);
    const [clientSelect, setClientSelect] = useState("");
    const [selectedAsset, setSelectedAsset] = useState("");
    const [assestsLoader, setAssestsLoader] = useState(false);
    const [clientData, setClientData] = useState("");
    const [selectClientModal, setSelectClientModal] = useState(false);
    const [selectClientModal2, setSelectClientModal2] = useState(false);
    const [selectClientId, setSelectClientId] = useState("");
    const [selectClientId2, setSelectClientId2] = useState("");
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignedUserId, setAssignedUserId] = useState(getCurrentUser().id);
    const [assignedUserName, setAssignedUserName] = useState(
        getCurrentUser().name
    );
    const [assignUsers, setAssignUsers] = useState([]);
    const [clientLoader, setClientLoader] = useState(false);
    const [mainFormDisabled, setMainFormDisabled] = useState(false);
    const [timer, setTimer] = useState(null);
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");
    const [byDefaultStatus, setbyDefaultStatus] = useState("Pending");
    const [paginationData, setPaginationData] = useState("");

    const getClients = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getClientData(e);
        }, 1000);
        setTimer(newTimer);
    };
    const createTechnical = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("reference", value.reference);
        formData.append("reference_number", e.target.reference_number.value);
        formData.append("client_id", clientSelect);
        formData.append("asset_id", selectedAsset);
        formData.append("assigned_to", assignedUserId);
        formData.append("assigned_to_name", assignedUserName);
        formData.append("priority", value.priority);
        formData.append("status", value.status);
        formData.append("date", e.target.date.value);
        formData.append("notifications", value.notifications);
        formData.append("description", value.description);
        formData.append("assigned_date", e.target.assigned_date.value);
        formData.append("closing_date", closingDate);
        formData.append("address", value.address !== null ? value.address : "");
        formData.append("invoice_to", selectClientId2);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/technical-incidents`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setLoading(false);
                    setSuccess(res.data.message);
                    history.push(
                        `/technical-services/${res.data.technical_incidents.id}`
                    );
                    setError("");
                } else {
                    setSuccess("");
                    setError(res.data.message);
                    setLoading(false);
                }
            });
    };

    const getType = (e) => {
        setReferenceType(e.target.value);
    };
    const getPriorityType = (e) => {
        setPriorityName(e.target.value);
    };

    const clientDetail = (e) => {
        setClientSelect(e.id);
        setClientAddress(e.address);
        setClientFull(e);
        setClientNameData(e.legal_name);
        getAssests(e.id);
        setNewDisable(true);
        setModelClient(false);
    };

    const clientDetail2 = (e) => {
        setSelectClientId2(e);
        let index = data.findIndex((x) => x.id == e);
        if (index != -1) {
            setClientNameData2(data[index].name);
        }
    };

    const assestDetail = (e) => {
        let index = assetAll.findIndex((x) => x.id == e.target.value);
        if (index != -1) {
            setAssetNameData(assetAll[index].name);
        }
        setNewDisable(true);
    };

    const getClientData = (e) => {
        setClientLoader(true);
        setData([]);
        let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
        )}/get-all-clients`;
        if (e !== undefined) {
            searchedClient = e;
            url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
            )}/get-all-clients?search=${searchedClient}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.status) {
                    setData(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setData([]);
                }
                setClientLoader(false);
            });
    };

    const getAllClientData = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-all-clients`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAllData(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setAllData([]);
                }
            });
    };

    const paginateData = (link) => {
        setPaginationData("");
        setAllData([]);
        setClientLoader(true);
        let url = `${link}&search=${searchedClient ? searchedClient : ""}`;
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setClientLoader(false);
                if (res.data.status) {
                    setError("");
                    setAllData(res.data.clients.data);
                    setPaginationData(res.data.clients);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const getAssests = (e) => {
        setAssetAll([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets?client_id=${e}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAssestsLoader(true);
                if (res.data.status) {
                    setAssetAll(res.data.client_assets);
                }
                setAssestsLoader(false);
            });
    };

    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };

    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };

    let optionAssets = [];

    if (assetAll !== undefined) {
        {
            assetAll &&
                assetAll.map((asset) => {
                    optionAssets.push({
                        value: asset.id,
                        label: asset.name,
                    });
                });
        }
    }

    function handleAssets(selectedcate) {
        setSelectedAsset(selectedcate.value);
    }
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const filePreview = (e) => {
        setDocument(e.target.files[0]);
        setFileDocument(URL.createObjectURL(e.target.files[0]));
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Incident`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                    res.data.reference?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultRef(item.prefix);
                        }
                    });
                } else {
                    //   setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getAllClientData();
        getClientData();
        getAssignUser();
        getReferences();
    }, []);

    return (
        <React.Fragment>
            {isOpenAddress ? (
                <ClientAddress
                    setIsOpenModal={setIsOpenAddress}
                    clients={data}
                    setClientData={setClientData}
                    setClientNameData={setClientNameData}
                />
            ) : (
                ""
            )}
            <div className="page-content">
                <MetaTags>
                    <title>New Service | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="tech_service">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                createTechnical(e, v);
                            }}
                            disabled={mainFormDisabled ? true : false}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{props.t("New Incident")}</h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary " type="submit">
                                                    {props.t("Submit")}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? (
                                <Alert color="danger">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: props.t(error),
                                        }}
                                    />
                                </Alert>
                            ) : null}
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label>{props.t("Reference")}</label>
                                                <div className="d-flex">
                                                    <AvField
                                                        style={{
                                                            width: "100px",
                                                        }}
                                                        type="select"
                                                        name="reference"
                                                        value={defaultRef}
                                                        onChange={(e) => getType(e)}
                                                    >
                                                        {allRef &&
                                                            allRef.map((item, i) => (
                                                                <option value={item.prefix} key={i}>
                                                                    {item.prefix}
                                                                </option>
                                                            ))}
                                                    </AvField>
                                                    <div className="w-100 ps-3">
                                                        <Input
                                                            className="w-100 quantity"
                                                            type="number"
                                                            step="1"
                                                            min="1"
                                                            name="reference_number"
                                                            placeholder={props.t("Automatic")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 required">
                                                <label>{props.t("Client")}</label>
                                                <div className="d-flex">
                                                    <div className="pe-2 w-100 client_custom-dropdown">
                                                        <ClientCustomDropdown
                                                            data={data}
                                                            getClients={getClients}
                                                            getClientData={getClientData}
                                                            clientLoader={clientLoader}
                                                            setMainFormDisabled={setMainFormDisabled}
                                                            setClientSelect={setClientSelect}
                                                            setClientAddress={setClientAddress}
                                                            setClientFull={setClientFull}
                                                            setClientNameData={setClientNameData}
                                                            clientDetail={clientDetail}
                                                            getAssests={getAssests}
                                                            clientNameData={clientNameData}
                                                            paginationData={paginationData}
                                                            paginateData={paginateData}
                                                        />
                                                    </div>
                                                    <div className="pe-2">
                                                        <button
                                                            className="btn btn-light"
                                                            onClick={() => {
                                                                setSelectClientModal(true), getClientData();
                                                            }}
                                                            type="button"
                                                        >
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </div>
                                                    <Link
                                                        className={`btn btn-light ${selectClientId == "" ? "disabled" : ""
                                                            }`}
                                                        to={`/client/${selectClientId}`}
                                                        target="_blank"
                                                    >
                                                        <i className="fas fa-eye" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Assigned to")}</label>
                                                <span
                                                    onClick={() => setAssignedModal(true)}
                                                    className="form-control"
                                                    style={{
                                                        minHeight: "35px",
                                                    }}
                                                >
                                                    {assignedUserName}
                                                </span>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Priority")}
                                                    type="select"
                                                    name="priority"
                                                    onChange={(e) => getPriorityType(e)}
                                                >
                                                    <option value="verylow">{props.t("Very Low")}</option>
                                                    <option value="low">{props.t("Low")}</option>
                                                    <option value="normal">{props.t("Normal")}</option>
                                                    <option value="high">{props.t("High")}</option>
                                                    <option value="veryhigh">
                                                        {props.t("Very High")}
                                                    </option>
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Status")}
                                                    type="select"
                                                    name="status"
                                                    value={byDefaultStatus}
                                                >
                                                    {JSON.parse(
                                                        localStorage.getItem("CustomStates")
                                                    )?.map((item) =>
                                                        item.name === "Incident" ? (
                                                            <>
                                                                {item.states?.map((option) =>
                                                                    option.name !== "Refused" ? (
                                                                        <option value={option.name} key={option.id}>
                                                                            {props.t(option.name)}
                                                                        </option>
                                                                    ) : (
                                                                        ""
                                                                    )
                                                                )}
                                                            </>
                                                        ) : (
                                                            ""
                                                        )
                                                    )}
                                                    {/* <option value="Pending">{props.t("Pending")}</option> */}
                                                    {/* <option value="Refused">{props.t("Refused")}</option> */}
                                                    {/* <option value="Resolved">
                            {props.t("Resolved")}
                          </option>
                          <option value="Closed">{props.t("Closed")}</option> */}
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Status Changed by")}
                                                    type="text"
                                                    name="name"
                                                    value={getCurrentUser().name}
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Created by")}
                                                    type="text"
                                                    name="created_by"
                                                    value={getCurrentUser().name}
                                                    disabled
                                                    readOnly
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Date")}</label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(e) => setStartDate(e)}
                                                    dateFormat="dd/MM/yyyy"
                                                    type="date"
                                                    //   minDate={new Date()}
                                                    name="date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Invoice to")}</label>
                                                <div className="d-flex">
                                                    <div className="pe-2 w-100">
                                                        <span
                                                            onClick={() => setSelectClientModal2(true)}
                                                            className="form-control"
                                                            style={{
                                                                minHeight: "35px",
                                                            }}
                                                        >
                                                            {clientNameData2}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Created from")}
                                                    type="text"
                                                    name="name"
                                                    value={props.t("Web")}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Assets")}:</label>
                                                <div className="d-flex justify-content-between">
                                                    <div className="w-100 pe-2">
                                                        {assestsLoader ? (
                                                            ""
                                                        ) : (
                                                            <Select
                                                                options={optionAssets}
                                                                onChange={handleAssets}
                                                                isDisabled={newDisable ? false : true}
                                                            />
                                                        )}
                                                    </div>
                                                    <button
                                                        className="btn btn-primary waves-effect waves-light"
                                                        onClick={() => setIsOpenModal(true)}
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                        }}
                                                        type="button"
                                                        disabled={newDisable ? false : true}
                                                    >
                                                        + {props.t("New")}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Notifications")}
                                                    type="select"
                                                    name="notifications"
                                                >
                                                    <option value="" hidden>
                                                        {props.t("Options")}
                                                    </option>
                                                    <option value="notify_creation">
                                                        {props.t("Notify creation")}
                                                    </option>
                                                    <option value="notify_closing">
                                                        {props.t("Notify closing")}
                                                    </option>
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Address")}
                                                    type="text"
                                                    value={clientAddress}
                                                    name="address"
                                                //   onClick={() => setIsOpenAddress(true)}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Description")}
                                                    type="textarea"
                                                    name="description"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Assigned Date")}</label>
                                                <DatePicker
                                                    selected={assignDate}
                                                    onChange={(e) => setAssignDate(e)}
                                                    dateFormat="dd/MM/yyyy"
                                                    type="date"
                                                    name="assigned_date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Closing Date")}</label>
                                                <DatePicker
                                                    selected={closingDate}
                                                    onChange={(e) => setClosingDate(e)}
                                                    dateFormat="dd/MM/yyyy"
                                                    name="closing_date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                className={`${classnames({
                                                    active: customActiveTab === "1",
                                                })}disabled`}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                                disabled
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Attachments")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "2",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                                disabled
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Related")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="p-3">
                                        <TabPane tabId="2"></TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                    <Modal size="lg" isOpen={isOpenModal} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Assets")}
                            </h5>
                            <button
                                type="button"
                                className="cross__btn btn btn-primary waves-effect waves-light"
                                onClick={() => setIsOpenModal(false)}
                                aria-hidden="true"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <AssetsNew
                                clientData={clientFull}
                                AssetsData={getAssests}
                                setassetModal={setIsOpenModal}
                                clientNameData={clientNameData}
                            />
                        </div>
                    </Modal>
                    <Modal size="lg" isOpen={isOpenModals} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Document")}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => createAttachment(e)}>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Document")}</label>
                                            <input
                                                type="file"
                                                className="ms-3"
                                                name="document"
                                                onChange={filePreview}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Description")}</label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="modal-footer">
                                    <div className="text-end">
                                        <button className="btn btn-primary" type="submit">
                                            {props.t("Submit")}
                                        </button>
                                        <button
                                            className="btn btn-primary ms-2"
                                            type="button"
                                            onClick={() => setIsOpenModals(false)}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                    {selectClientModal ? (
                        <ClientList
                            setSelectClientModal={setSelectClientModal}
                            setSelectClientId={setSelectClientId}
                            clients={allData}
                            clientDetail={clientDetail}
                            getClients={getClients}
                        />
                    ) : (
                        ""
                    )}
                    {selectClientModal2 ? (
                        <ClientList
                            setSelectClientModal={setSelectClientModal2}
                            setSelectClientId={setSelectClientId2}
                            clients={allData}
                            clientDetail={clientDetail2}
                        />
                    ) : (
                        ""
                    )}
                    {assignedModal ? (
                        <AssignUserList
                            setAssignedModal={setAssignedModal}
                            getAssignedUser={getAssignedUser}
                            data={assignUsers}
                        />
                    ) : (
                        ""
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(CreateServiceNew);
CreateServiceNew.propTypes = {
    t: PropTypes.any,
};
