import React, { useState } from "react"
import { Modal, Alert, Input } from "reactstrap"
const axios = require('axios').default
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"

export default function StateModal(props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [simple_color, setsimple_color] = useState(0)
  const [color, setcolor] = useState("")

  const onDrag = c1 => {
    setcolor(c1)
  }

  const submitState = (e, value) => {
    setLoading(true)
    axios
    .post(`${process.env.REACT_APP_APIKEY}/${localStorage.getItem('companyId')}/custom_states`, {
      type_id: props.stateId,
      name: value.name,
      description: value.description,
      color: color,
    }, {
      headers: {
        Authorization: `Bearer ${getCurrentUser().token}`,
      },
    })
    .then(function (res) {
      setLoading(false)
      if (res.data.status) {
        setError('')
        setSuccess(res.data.message)
        props.getSingleState()
        props.setIsOpenModal(false)
      } else {
        setError(res.data.message)
        setSuccess('')
      }
      setTimeout(() => {
        setError('')
        setSuccess('')
      }, 2500)
    })
  }


  return (
    <Modal
      size="md"
      isOpen={props.isOpenModal}
      centered={true}
    >
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => submitState(e, v)
        }
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myLargeModalLabel"
          >
          New State
          </h5>
          <button type="button" className="btn btn-primary text-md btn-sm" onClick={() => props.setIsOpenModal(false)} aria-hidden="true">&times;</button>
        </div>
        <div className="modal-body">
          {success ? (
            <div className="mb-3">
              <Alert color="success">
                {success}
              </Alert>
            </div>
          ) : null}

          {error ? (
            <div className="mb-3">
              <Alert color="danger">
                {error}
              </Alert>
            </div>
          ) : null}
          
          <div className="mb-3">
            <AvField
              label="Name"
              type="text"
              name="name"
            />
          </div>
          <div className="mb-3">
            <AvField
              label="Description"
              type="text"
              name="description"
            />
          </div>
          <div className="mb-3">
            <label>Corporate Color</label>
              <Input
                type="text"
                name="corporate_color"
                className="colorpicker-default"
                value={color}
                onClick={() => {
                  setsimple_color(!simple_color)
                }}
                readOnly
              />
              {simple_color ? (
                <ColorPicker
                  saturationHeight={100}
                  saturationWidth={100}
                  value={color}
                  onDrag={onDrag}
                />
              ) : null}
          </div>
        </div>
        <div className="modal-footer">
          <div className="text-end">
            {loading ? 
              <button
                className="btn btn-primary disabled"
                type="button"
                disabled
              >
                Loading
              </button>
            : 
              <button
                className="btn btn-primary"
                type="submit"
              >
               Submit
              </button>
            }
            <button
              className="btn btn-primary ms-2"
              type="button"
              onClick={() => props.setIsOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </AvForm>
    </Modal>
  )
}