import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
    AvForm,
    AvField,
    AvCheckboxGroup,
    AvCheckbox,
} from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Table,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Modal,
} from "reactstrap";
import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import Switch from "react-switch";
import CategoryModal from "../Category/category-modal";
import Select from "react-select";
import { getCurrentUser } from "../../helpers/Utils";
import { useHistory } from "react-router-dom";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

let varBasePrice = 0;
let varPurchasePrice = 0;

const CreateProduct = (props) => {
    const history = useHistory();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalStock, setIsOpenModalStock] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [discError, setDiscError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [image, setImage] = useState("");
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [selectedCate, setSelectedCate] = useState("");
    const [activeMargin, setActiveMargin] = useState(true);
    const [switch1, setSwitch1] = useState(true);
    const [allCategories, setAllCategories] = useState([]);
    const [referenceType, setReferenceType] = useState("PRO");
    const [wharehouseStock, setWharehouseStock] = useState("");
    const [wharehouseminStock, setWharehouseminStock] = useState("");
    const [wharehouseLocation, setWharehouseLocation] = useState("");
    const [minPrice, setMinPrice] = useState(0.0);
    const [disc, setDisc] = useState(0.0);
    const [basePrice, setBasePrice] = useState(0.0);
    const [purchasePrice, setPurchasePrice] = useState(0.0);
    const [purchaseMargin, setPurchaseMargin] = useState(0.0);
    const [salesMargin, setSalesMargin] = useState(0.0);
    const [images, setImages] = useState("");
    const [imgPreviews, setImgPreviews] = useState(uploadImg);
    const [dataTax, setDataTax] = useState([]);
    const [defaultTax, setDefaultTax] = useState("0");
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const productCreateSubmit = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", value.name);
        formData.append("price", value.price);
        formData.append("reference", value.reference);
        formData.append("reference_number", value.reference_number);
        formData.append("purchase_price", value.purchase_price);
        formData.append("barcode", value.barcode);
        formData.append("image", image);
        formData.append("product_category_id", selectedCate);
        // formData.append("is_active", value.is_active);
        formData.append("description", value.description);
        formData.append("private_comments", e.target.private_comments.value);
        formData.append("created_from", value.created_from);
        formData.append("active_margin", value.active_margin);
        formData.append("purchase_margin", value.purchase_margin);
        formData.append("sales_margin", value.sales_margin);
        formData.append("discount", disc);
        formData.append("minimum_price", minPrice);
        formData.append("tax", e.target.tax.value);
        formData.append("is_promotional", value.is_promotional ? "1" : "0");
        formData.append("manage_stock", switch1 ? "1" : "0");
        formData.append("main_image", images);
        formData.append("stock", wharehouseStock);
        formData.append("minimum_stock", wharehouseminStock);
        formData.append("virtual_stock", wharehouseStock);
        formData.append("location", wharehouseLocation);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    history.push(`/product/${res.data.product.id}`);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            ></div>
        );
    };

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            ></div>
        );
    };

    const getIdMargin = (e) => {
        if (e.target.checked) {
            setActiveMargin(false);
        } else {
            setActiveMargin(true);
        }
    };

    const productCategories = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product_categories`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllCategories(res.data.product_categories);
            });
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Product`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                    res.data.reference?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultRef(item.prefix);
                        }
                    });
                } else {
                    //   setError(res.data.message);
                }
            });
    };

    const cencelStock = () => {
        setIsOpenModalStock(false);
        setWharehouseStock("");
        setWharehouseminStock("");
        setWharehouseLocation("");
    };

    // Product Categories/Set Categories
    let optionCategory = [];
    if (allCategories !== undefined) {
        {
            allCategories &&
                allCategories.map((category) => {
                    optionCategory.push({
                        value: category.id,
                        label: category.name,
                    });
                });
        }
    }
    function handleSelectCategory(selectedcate) {
        setSelectedCate(selectedcate.value);
    }

    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    const getType = (e) => {
        setReferenceType(e.target.value);
    };

    const calcDiscount = (e) => {
        const bPrice = parseFloat(basePrice);
        const mPrice = parseFloat(minPrice);
        if (bPrice > 0) {
            const discAmount = bPrice - (bPrice * e) / 100;
            const discount = bPrice - discAmount;
            if (discount > mPrice) {
                setDiscError("The discount cannot exceed the minimum price.");
            } else {
                setDisc(e);
                setDiscError("");
            }
        } else {
            setDiscError("First add the base price.");
        }
        setTimeout(() => {
            setDiscError("");
        }, 5000);
    };

    const marginCalc = (e) => {
        if (e.name === "price") {
            setBasePrice(e.value);
            //   setPurchaseMargin(PM.toFixed(2));
            varBasePrice = e.value;
        } else if (e.name === "purchase_price") {
            setPurchasePrice(e.value);
            varPurchasePrice = e.value;
        } else if (e.name === "sales_margin") {
            setSalesMargin(e.value);
            varSalesMargin = e.value;
        } else if (e.name === "purchase_margin") {
            setPurchaseMargin(e.value);
            varPurchaseMargin = e.value;
        }
        let pMargin = ((varBasePrice - varPurchasePrice) / varPurchasePrice) * 100;
        let sMargin = ((varBasePrice - varPurchasePrice) / varBasePrice) * 100;

        if (
            !isNaN(pMargin) ||
            pMargin == Number.POSITIVE_INFINITY ||
            pMargin == Number.NEGATIVE_INFINITY
        ) {
            setPurchaseMargin(pMargin.toFixed(2));
        }
        if (!isNaN(sMargin)) {
            setSalesMargin(sMargin.toFixed(2));
        }
    };

    const getSalesMargin = (e) => {
        let SM = e;
        let SP = parseFloat(purchasePrice) / (1 - SM / 100);
        let NP = SP - parseFloat(purchasePrice);
        let PM = (NP / parseFloat(purchasePrice)) * 100;
        setPurchaseMargin(PM.toFixed(2));
        setSalesMargin(SM);
        setBasePrice(SP.toFixed(2));
    };

    const getPurchaseMargin = (e) => {
        let PM = e;
        let NP = (PM * parseFloat(purchasePrice)) / 100;
        let SP = parseFloat(NP) + parseFloat(purchasePrice);
        let SM = (NP / SP) * 100;
        setPurchaseMargin(PM);
        setSalesMargin(SM.toFixed(2));
        setBasePrice(SP.toFixed(2));
    };

    const getTaxes = () => {
        setDataTax([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/cosumption-taxes`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setDataTax(res.data.taxes);
                    res.data.taxes?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultTax(item.tax);
                        }
                    });
                }
            });
    };

    useEffect(() => {
        productCategories();
        getTaxes();
        getReferences();
    }, []);

    const setComImgs = (e) => {
        setImages(e.target.files[0]);
        setImgPreviews(URL.createObjectURL(e.target.files[0]));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>New Product | Clouget</title>
                </MetaTags>
                <CategoryModal
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    getAllCategories={productCategories}
                />
                <Modal size="lg" isOpen={isOpenModalStock} centered={true}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            {props.t("Add warehouse")}
                        </h5>
                        <button
                            type="button"
                            className="btn btn-primary text-md btn-sm"
                            onClick={() => setIsOpenModalStock(false)}
                            aria-hidden="true"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <AvForm className="form-horizontal">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3 required">
                                        <AvField
                                            label={props.t("Stock")}
                                            type="number"
                                            name="stock"
                                            onChange={(e) => setWharehouseStock(e.target.value)}
                                            value={wharehouseStock}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            label={props.t("Minimum Stock")}
                                            type="number"
                                            name="minimum_stock"
                                            onChange={(e) => setWharehouseminStock(e.target.value)}
                                            value={wharehouseminStock}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <AvField
                                            label={props.t("Virtual Stock")}
                                            type="number"
                                            readOnly
                                            name="virtual_stock"
                                            onChange={(e) => setWharehouseStock(e.target.value)}
                                            value={wharehouseStock}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            label={props.t("Location")}
                                            type="text"
                                            name="location"
                                            onChange={(e) => setWharehouseLocation(e.target.value)}
                                            value={wharehouseLocation}
                                        />
                                    </div>
                                </div>
                            </div>
                        </AvForm>
                    </div>
                    <div className="modal-footer">
                        <div className="text-end">
                            {loading ? (
                                <button className="btn btn-primary" type="button">
                                    {props.t("Loading")}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => setIsOpenModalStock(false)}
                                >
                                    {props.t("Submit")}
                                </button>
                            )}
                            <button
                                className="btn btn-primary ms-2"
                                type="button"
                                onClick={() => cencelStock()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                productCreateSubmit(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{props.t("New Product")}</h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary " type="submit">
                                                    {props.t("Submit")}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? (
                                <Alert color="success">{props.t(success)}</Alert>
                            ) : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={5}>
                                            <div className="mb-3">
                                                <label>{props.t("Reference")}</label>
                                                <div className="d-flex">
                                                    <AvField
                                                        style={{
                                                            width: "100px",
                                                        }}
                                                        type="select"
                                                        name="reference"
                                                        value={defaultRef}
                                                        // value="PRO"
                                                        onChange={(e) => getType(e)}
                                                    >
                                                        {allRef &&
                                                            allRef.map((item, i) => (
                                                                <option value={item.prefix} key={i}>
                                                                    {item.prefix}
                                                                </option>
                                                            ))}
                                                        <option value="manual">
                                                            {props.t("MANUAL/MANUAL")}
                                                        </option>
                                                    </AvField>
                                                    <div className="w-100 ps-3">
                                                        <AvField
                                                            className="w-100"
                                                            type="number"
                                                            name="reference_number"
                                                            placeholder={props.t("Automatic")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Name")}
                                                    type="text"
                                                    name="name"
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Base Sales Price ($)")}
                                                    type="number"
                                                    name="price"
                                                    value={basePrice}
                                                    onChange={(e) => marginCalc(e.target)}
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField label="Barcode" type="text" name="barcode" />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Product Category")}</label>
                                                <div className="d-flex">
                                                    <div className="w-100 pe-2">
                                                        <Select
                                                            options={optionCategory}
                                                            onChange={handleSelectCategory}
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setIsOpenModal(true);
                                                        }}
                                                        className="btn btn-primary"
                                                        data-toggle="modal"
                                                        data-target=".bs-example-modal-lg"
                                                    >
                                                        {props.t("New")}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Stock")}
                                                    type="number"
                                                    name="barcode"
                                                    value="0.00"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Virtual Stock")}
                                                    type="number"
                                                    name="barcode"
                                                    value="0.00"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Minimum Stock")}
                                                    type="number"
                                                    name="barcode"
                                                    value="0.00"
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="company_img">
                                                <label>{props.t("Main image")}</label>
                                                <input
                                                    className="hidden d-none"
                                                    onChange={(e) => setComImg(e)}
                                                    type="file"
                                                    accept="/image*"
                                                    id="comImg"
                                                />
                                                <label className="company_img-box" htmlFor="comImg">
                                                    <img src={imgPreview} />
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("General")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Commercial")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "3",
                                                })} disabled`}
                                                onClick={() => {
                                                    toggleCustom("3");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Rates")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "4",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("4");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Stock")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "5",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("5");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Images")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "6",
                                                })} disabled`}
                                                onClick={() => {
                                                    toggleCustom("6");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Attachments")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "7",
                                                })} disabled`}
                                                onClick={() => {
                                                    toggleCustom("7");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("History")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="p-3">
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col lg="12">
                                                    {/* <div className="mb-1">
                                                        <AvCheckboxGroup name="is_active">
                                                            <AvCheckbox
                                                                label={props.t("Hidden")}
                                                                value="1"
                                                            />
                                                        </AvCheckboxGroup>
                                                    </div> */}
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Description")}
                                                            type="textarea"
                                                            name="description"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Private Comments")}
                                                            type="textarea"
                                                            name="private_comments"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Created from")}
                                                            type="text"
                                                            name="created_from"
                                                            value={props.t("Web")}
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Purchase Price ($)")}
                                                            type="number"
                                                            name="purchase_price"
                                                            value={purchasePrice}
                                                            onChange={(e) => marginCalc(e.target)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Active Margin")}
                                                            type="checkbox"
                                                            name="active_margin"
                                                            onChange={(e) => getIdMargin(e)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Purchase Margin (%)")}
                                                            type="number"
                                                            name="purchase_margin"
                                                            value={
                                                                purchaseMargin == "NaN"
                                                                    ? "0.00"
                                                                    : purchaseMargin == "" ||
                                                                        purchaseMargin == "Infinity"
                                                                        ? "0.00"
                                                                        : purchaseMargin
                                                            }
                                                            onChange={(e) =>
                                                                getPurchaseMargin(e.target.value)
                                                            }
                                                            disabled={activeMargin ? true : false}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Minimum Price ($)")}
                                                            type="number"
                                                            name="minimum_price"
                                                            onChange={(e) => setMinPrice(e.target.value)}
                                                            value={minPrice}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="form-control"
                                                            type="select"
                                                            name="tax"
                                                            label={props.t("Tax")}
                                                            value={defaultTax}
                                                        >
                                                            {dataTax?.map((item, i) => (
                                                                <option value={item.tax} key={i}>
                                                                    {dataTax.primary_name} {item.tax}
                                                                </option>
                                                            ))}
                                                            <option value="0">
                                                                {props.t("Do not apply")}
                                                            </option>
                                                        </AvField>
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Promotional")}
                                                            name="is_promotional"
                                                            type="checkbox"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Base Sales Price ($)")}
                                                            type="number"
                                                            name="price"
                                                            value={basePrice}
                                                            onChange={(e) => marginCalc(e.target)}
                                                            disabled={activeMargin ? false : true}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Sales Margin (%)")}
                                                            type="number"
                                                            name="sales_margin"
                                                            value={
                                                                basePrice == ""
                                                                    ? "--"
                                                                    : salesMargin == "NaN"
                                                                        ? "0.00"
                                                                        : salesMargin == "" ||
                                                                            salesMargin == "Infinity"
                                                                            ? "0.00"
                                                                            : salesMargin
                                                            }
                                                            onChange={(e) => getSalesMargin(e.target.value)}
                                                            disabled={activeMargin ? true : false}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Discount (%)")}
                                                            type="number"
                                                            name="discount"
                                                            onChange={(e) => calcDiscount(e.target.value)}
                                                            value={disc}
                                                        />
                                                    </div>
                                                    {discError ? (
                                                        <Alert color="danger">{props.t(discError)}</Alert>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <label>{props.t("Manage stock")}</label>
                                            <div className="mb-1">
                                                <Switch
                                                    uncheckedIcon={<Offsymbol />}
                                                    checkedIcon={<OnSymbol />}
                                                    onColor="#626ed4"
                                                    onChange={() => {
                                                        setSwitch1(!switch1);
                                                    }}
                                                    checked={switch1}
                                                />
                                                {switch1 && (
                                                    <>
                                                        {wharehouseStock === "" ? (
                                                            <button
                                                                className="btn btn-primary float-end"
                                                                onClick={() => setIsOpenModalStock(true)}
                                                                type="button"
                                                            >
                                                                {props.t("Add New")}
                                                            </button>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="table-responsive mt-3">
                                                            <Table striped className="align-middle">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th>{props.t("Wharehouse")}</th>
                                                                        <th>{props.t("Stock")}</th>
                                                                        <th>{props.t("Virtual Stock")}</th>
                                                                        <th>{props.t("Minimum Stock")}</th>
                                                                        <th>{props.t("Location")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            role="button"
                                                                            onClick={() => setIsOpenModalStock(true)}
                                                                        >
                                                                            {props.t("Main Warehouse")}
                                                                        </td>
                                                                        <td
                                                                            role="button"
                                                                            onClick={() => setIsOpenModalStock(true)}
                                                                        >
                                                                            {wharehouseStock}
                                                                        </td>
                                                                        <td
                                                                            role="button"
                                                                            onClick={() => setIsOpenModalStock(true)}
                                                                        >
                                                                            {wharehouseStock}
                                                                        </td>
                                                                        <td
                                                                            role="button"
                                                                            onClick={() => setIsOpenModalStock(true)}
                                                                        >
                                                                            {wharehouseminStock}
                                                                        </td>
                                                                        <td
                                                                            role="button"
                                                                            onClick={() => setIsOpenModalStock(true)}
                                                                        >
                                                                            {wharehouseLocation}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                            {error ? (
                                                                <Alert color="danger">{error}</Alert>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Row>
                                                <Col lg="3">
                                                    <div className="form-inline company_img img-flex">
                                                        <input
                                                            className="hidden d-none"
                                                            onChange={(e) => setComImgs(e)}
                                                            type="file"
                                                            id="comImgs"
                                                            name="image"
                                                        />
                                                        <label
                                                            className="company_img-box"
                                                            htmlFor="comImgs"
                                                        >
                                                            <img src={imgPreviews} />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(CreateProduct);
CreateProduct.propTypes = {
    t: PropTypes.any,
};
