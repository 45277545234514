import React from "react";
import { Modal, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const CreateTrialModal = (props) => {
  return (
    <>
      <Modal isOpen={props.isTrialModal} centered={true} size="md">
        <div className="modal-header">
          <h5>Upgrade to PRO</h5>
          <button
            type="button"
            className="btn btn-primary text-md btn-sm"
            onClick={() => props.setIsTrialModal(false)}
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <h4>
                You have reached the demo time limit.<br></br>Start using
                Billing with your data now.
              </h4>
            </Col>
            <Col md={1}></Col>
          </Row>
        </div>
        <Link className="btn btn-primary" to="/plans">
          Buy now
        </Link>
      </Modal>
    </>
  );
};
export default CreateTrialModal;
