/* eslint-disable no-undef */
import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Col, Row, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ListContact = (props) => {
  //   const [displayPurchaseDelivery, setDisplayPurchaseDelivery] = useState(
  //     props.data
  //   );
  const [displayContact, setDisplayContact] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [contact, setContact] = useState("");
  const [loading, setLoading] = useState(false);
  const [dynamic_description, setdynamic_description] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [name, setName] = useState(props.data.name);
  const [phone, setPhone] = useState(props.data.phone);
  const [email, setEmail] = useState(props.data.email);
  const [fax, setFax] = useState(props.data.fax);
  const [position, setPosition] = useState(props.data.position);
  const [comments, setComments] = useState(props.data.comments);

  const delContact = () => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-contacts/${displayContact.id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setDisplayContact(null);
        setdynamic_description(res.data.message);
      });
  };
  const getSingleContact = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-contacts/${displayContact.id}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setContact(res.data.client_contact);
        }
      });
  };
  //   useEffect(() => {
  //     getSingleContact();
  //   }, []);
  const clientContact = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("fax", fax);
    formData.append("position", position);
    formData.append("comments", comments);

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-contacts/${displayContact.id}?_method=PUT`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          setIsOpenContact(false);
          setLoading(false);
          setDisplayContact(res.data.client_contact);
          //   getSingleContact();
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };
  if (displayContact === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td
            role="button"
            onClick={() => {
              setIsOpenContact(true), getSingleContact();
            }}
          >
            {displayContact.name}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenContact(true), getSingleContact();
            }}
          >
            {displayContact.phone}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenContact(true), getSingleContact();
            }}
          >
            {displayContact.email}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenContact(true), getSingleContact();
            }}
          >
            {displayContact.fax}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenContact(true), getSingleContact();
            }}
          >
            {displayContact.position}
          </td>
          <td
            role="button"
            onClick={() => {
              setIsOpenContact(true), getSingleContact();
            }}
          >
            {displayContact.comments}
          </td>
          <td>
            <button
              type="button-input"
              className="btn btn-danger"
              onClick={() => setconfirm_alert(true)}
            >
              {props.t("Delete")}
            </button>
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
            cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              delContact(displayContact.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("Contact has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
        <Modal size="lg" isOpen={isOpenContact} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.t("Edit Contact")}
            </h5>
            <button
              type="button"
              className="cross__btn btn btn-primary waves-effect waves-light"
              onClick={() => setIsOpenContact(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <AvForm
            //   onValidSubmit={(e, v) => {
            //     supplierContact(e, v);
            //   }}
            >
              <Row>
                <Col lg={6}>
                  <AvField
                    className="mb-3"
                    label={props.t("Name")}
                    type="text"
                    name="name"
                    value={contact.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <AvField
                    className="mb-3"
                    label={props.t("Phone")}
                    type="text"
                    name="phone"
                    value={contact.phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <AvField
                    className="mb-3"
                    label={props.t("Email")}
                    type="email"
                    name="email"
                    value={contact.email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
                <Col lg={6}>
                  <AvField
                    className="mb-3"
                    label={props.t("Fax")}
                    type="text"
                    name="fax"
                    value={contact.fax}
                    onChange={(e) => setFax(e.target.value)}
                  />
                  <AvField
                    className="mb-3"
                    label={props.t("Position")}
                    type="text"
                    name="position"
                    value={contact.position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </Col>
                <Col lg={12}>
                  <div className="mb-3 ">
                    <AvField
                      className="mb-3"
                      label={props.t("Comments")}
                      type="textarea"
                      name="comments"
                      value={contact.comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <div className="modal-footer">
                <div className="text-end">
                  {loading ? (
                    <button
                      className="btn btn-primary  disabled"
                      type="button"
                      disabled
                    >
                      {props.t("Loading")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary "
                      type="button"
                      onClick={() => clientContact()}
                      //   type="submit"
                    >
                      {props.t("Update")}
                    </button>
                  )}
                  <button
                    className="btn btn-primary ms-2"
                    type="button"
                    onClick={() => setIsOpenContact(false)}
                  >
                    {props.t("Cancel")}
                  </button>
                </div>
              </div>
            </AvForm>
          </div>
        </Modal>
      </>
    );
};

export default withTranslation()(ListContact);
ListContact.propTypes = {
  t: PropTypes.any,
};
