/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import RateModal from "../../pages/PriceRate/rate-modal";
import { Alert, Col, Row, CardBody, Card, Table } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import SingleList from "../../pages/PriceRate/single-list";
import Loader from "../Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../../pages/plans/trialModal";
import { Link } from "react-router-dom";

function PriceRateContainer(props) {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [error, setError] = useState("");
    const [allRates, setAllRates] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !== 1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id == null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsOpenModal(true);
        }
    };
    const getAllRates = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/rates`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setAllRates(res.data.rates);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getAllRates();
    }, []);

    return (
        <>
            <RateModal
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                getAllRates={getAllRates}
            />
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <div className="page-title-box">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h6 className="page-title">{props.t("Rates")}</h6>
                    </Col>
                    <Col md={6}>
                        {getPermission().price_rates.price_rates.create.is_checked === 1 ? (
                            <div className="float-end d-none d-md-block">
                                <button
                                    type="button"
                                    onClick={() => {
                                        createNew();
                                    }}
                                    className="btn btn-primary ms-2"
                                    data-toggle="modal"
                                    data-target=".bs-example-modal-lg"
                                >
                                    {props.t("New")}
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
            </div>
            <Card>
                <CardBody>
                    <div className="mdb-datatable">
                        <Table striped className="align-middle">
                            <thead>
                                <tr>
                                    <th>{props.t("Name")}</th>
                                    <th>{props.t("Description")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {allRates &&
                                    allRates.map((item, i) => <SingleList data={item} key={i} />)}
                            </tbody>
                        </Table>
                        {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                        {loader}
                    </div>
                </CardBody>
            </Card>
        </>
    );
}
export default withTranslation()(PriceRateContainer);
PriceRateContainer.propTypes = {
    t: PropTypes.any,
};
