import React, { useState, useEffect } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function CategoryModal(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [category, setCategory] = useState(props.category);

    const getSingleCategory = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product_categories/${props.categoryId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    if (res.data) {
                        setCategory(res.data.product_category);
                    }
                    setError("");
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    props.setIsOpenModal(false);
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const submitCategory = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product_categories${props.categoryId ? `/${props.categoryId}?_method=PUT` : ""
                }`,
                {
                    name: value.name,
                    description: value.description,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    if (props.categoryId) {
                        props.setCategory(res.data.product_category);
                        props.setIsOpen(false);
                    } else {
                        props.getAllCategories();
                    }
                    props.setIsOpenModal(false);
                    setError("");
                    setSuccess("");
                }, 1000);
            });
    };

    useEffect(() => {
        if (props.categoryId !== undefined) {
            getSingleCategory();
        }
    }, []);

    return (
        <Modal size="md" isOpen={props.isOpenModal} centered={true}>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    submitCategory(e, v);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.categoryId ? category?.name !== null ? category?.name : "" : props.t("New Product Category")}
                    </h5>
                    <button
                        type="button"
                        className="btn btn-primary text-md btn-sm"
                        onClick={() => props.setIsOpenModal(false)}
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {success ? (
                        <div className="mb-3">
                            <Alert color="success">{props.t(success)}</Alert>
                        </div>
                    ) : null}

                    {error ? (
                        <div className="mb-3">
                            <Alert color="danger">{props.t(error)}</Alert>
                        </div>
                    ) : null}

                    <div className="mb-3 required">
                        <AvField
                            label={props.t("Name")}
                            type="text"
                            name="name"
                            value={category?.name !== null ? category?.name : ""}
                            required="required"
                        />
                    </div>
                    <div className="mb-3">
                        <AvField
                            label={props.t("Description")}
                            type="textarea"
                            name="description"
                            value={
                                category?.description !== null ? category?.description : ""
                            }
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="text-end">
                        {loading ? (
                            <button
                                className="btn btn-primary disabled"
                                type="button"
                                disabled
                            >
                                {props.t("Loading")}
                            </button>
                        ) : (
                            <button className="btn btn-primary" type="submit">
                                {props.categoryId
                                    ? `${props.t("Update")}`
                                    : `${props.t("Submit")}`}
                            </button>
                        )}
                        <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => props.setIsOpenModal(false)}
                        >
                            {props.t("Cancel")}
                        </button>
                    </div>
                </div>
            </AvForm>
        </Modal>
    );
}
export default withTranslation()(CategoryModal);
CategoryModal.propTypes = {
    t: PropTypes.any,
};
