/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Table, Card, CardBody, Alert, Row, Col } from "reactstrap";
import MultiType from "../../AllCharts/chartjs/barchart";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../../components/Loader";
import AssignUserList from "../../../components/AssignUserList/index";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ClientList from "../../../components/supplierList";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
let referenceType = "";
let selectedYear = "";
let dateType = "";
let dateSubType = "";
let selectedMonth = "";
let agentId = "";
let supplierId = "";
const PurchasesByItem = (props) => {
    const history = useHistory();
    //   const [data, setData] = useState([]);
    const [invoiced, setInvoiced] = useState([]);
    const [loader, setLoader] = useState(true);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [selectedType, SetSelectedType] = useState("");
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignUsers, setAssignUsers] = useState([]);
    const [assignedUserId, setAssignedUserId] = useState(getCurrentUser().id);
    const [assignedUserName, setAssignedUserName] = useState(
        `${props.t("Select an agent to filter")}`
    );
    const [clientNameData, setClientNameData] = useState(
        `${props.t("Select a supplier to filter")}`
    );
    const [selectClientModal, setSelectClientModal] = useState(false);
    const [selectClientId, setSelectClientId] = useState("");
    const [allClients, setAllClients] = useState([]);
    const [clientData, setClientData] = useState("");
    const [clients, setClients] = useState("");
    const [paymentOption, setPaymentOption] = useState("");
    const [allAddress, setAllAddress] = useState("");
    const [evolutionHistory, setEvolutionHistory] = useState("");
    const [allRef, setAllRef] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const startYear = 2017;
    const endYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = endYear; year >= startYear; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }
    const overviewGraph = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/purchases-by-item?type=graph`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setData(res.data.data);
                    setInvoiced(res.data.data);
                }
                setLoader(false);
            });
    };
    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };
    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };
    const clientDetail = (e) => {
        setSelectClientId(e);
        let index = allClients.findIndex((x) => x.id == e);
        if (index != -1) {
            setClientNameData(allClients[index].legal_name);
            setClientData(allClients[index].address);
            setClients(allClients[index]);
            getSingleClientsAddress(allClients[index].id);
            //   AssetsData(allClients[index].id);
            setPaymentOption(allClients[index].payment_option_id);
        }
    };
    const getSingleClientsAddress = (e) => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/supplier-addresses?supplier_id=${e}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllAddress(res.data.supplier_addresses);
            });
    };
    let optionClients = [];
    if (allClients !== undefined) {
        {
            allClients &&
                allClients.map((client) => {
                    optionClients.push({
                        value: client.id,
                        label: client.name,
                    });
                });
        }
    }
    const getSuppliers = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getClientData(e);
        }, 1000);
        setTimer(newTimer);
    };
    const getClientData = (e) => {
        setAllClients([]);
        let url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
            "companyId"
        )}/suppliers`;
        if (e !== undefined) {
            url = `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
            )}/suppliers?search=${e}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.status) {
                    setAllClients(res.data.suppliers);
                } else {
                    setAllClients([]);
                }
            });
    };
    function handleSelectedType(e) {
        SetSelectedType(e);
    }
    const getHistory = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/purchases-by-item?type=history`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setEvolutionHistory(res.data.data);
                } else {
                    setHistoryError(res.data.message);
                }
            });
    };
    const getExport = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/purchases-by-item?type=history&export=download`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.location.href = res.data.url;
                }
            });
    };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Purchase Invoice`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                }
            });
    };
    const getType = (e) => {
        if (e.target.name == "reference") {
            referenceType = e.target.value;
        }
        searchData();
    };
    const searchData = () => {
        setInvoiced([]);
        setLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/purchases-by-item?type=graph&reference=${referenceType}&year=${selectedYear}&date_type=${dateType}&date_sub_type=${dateSubType}&month=${selectedMonth}&agentId=${agentId}&supplierId=${supplierId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setInvoiced(res.data.data);
                }
                setLoader(false);
            });
        setEvolutionHistory([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/purchases-by-item?type=history&reference=${referenceType}&year=${selectedYear}&date_type=${dateType}&date_sub_type=${dateSubType}&month=${selectedMonth}&agentId=${agentId}&supplierId=${supplierId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setEvolutionHistory(res.data.data);
                } else {
                    setHistoryError(res.data.message);
                }
            });
    };
    const getDate = (e) => {
        if (e.target.name == "date_type") {
            if (e.target.value == "all_dates") {
                dateType = "all_dates";
                dateSubType = "year";
                selectedMonth = "";
                setCurrentMonth(new Date().getMonth() + 1);
                selectedYear = "";
                searchData();
            } else if (e.target.value == "month") {
                dateType = "month";
                dateSubType = "day";
                selectedMonth = new Date().getMonth() + 1;
                setCurrentMonth(new Date().getMonth() + 1);
                selectedYear = "";
                searchData();
            }
        } else if (e.target.name == "year") {
            selectedYear = e.target.value;
            dateSubType = "quarter";
            searchData();
        } else if (e.target.name == "month") {
            setCurrentMonth(e.target.value);
            if (e.target.value == "1") {
                selectedMonth = 1;
            } else if (e.target.value == "2") {
                selectedMonth = 2;
            } else if (e.target.value == "3") {
                selectedMonth = 3;
            } else if (e.target.value == "4") {
                selectedMonth = 4;
            } else if (e.target.value == "5") {
                selectedMonth = 5;
            } else if (e.target.value == "6") {
                selectedMonth = 6;
            } else if (e.target.value == "7") {
                selectedMonth = 7;
            } else if (e.target.value == "8") {
                selectedMonth = 8;
            } else if (e.target.value == "9") {
                selectedMonth = 9;
            } else if (e.target.value == "10") {
                selectedMonth = 10;
            } else if (e.target.value == "11") {
                selectedMonth = 11;
            } else if (e.target.value == "12") {
                selectedMonth = 12;
            }
            searchData();
        } else if (e.target.name == "date_sub_type") {
            dateSubType = e.target.value;
            searchData();
        }
    };
    const getAgent = (e) => {
        agentId = e;
        searchData();
    };
    const getSup = (e) => {
        supplierId = e;
        searchData();
    };
    const getEntryType = ($referenceType, $link = "no") => {
        if ($referenceType == "PRO" || $referenceType == "pro") {
            if ($link == "no") {
                return "Product";
            }
            return "/product/";
        }
        if ($referenceType == "SER" || $referenceType == "ser") {
            if ($link == "no") {
                return "Service";
            }
            return "/service/";
        }
        if ($referenceType == "EAI" || $referenceType == "eai") {
            if ($link == "no") {
                return "Expense And Investment";
            }
            return "/edit-expenses-and-investments/";
        }
    };
    useEffect(() => {
        overviewGraph();
        getAssignUser();
        getClientData();
        getHistory();
        getReferences();
    }, []);
    return (
        <>
            {assignedModal ? (
                <AssignUserList
                    setAssignedModal={setAssignedModal}
                    getAssignedUser={getAssignedUser}
                    data={assignUsers}
                    getData={getAgent}
                />
            ) : (
                ""
            )}
            {selectClientModal ? (
                <ClientList
                    setSelectClientModal={setSelectClientModal}
                    setSelectClientId={setSelectClientId}
                    clients={allClients}
                    clientDetail={clientDetail}
                    getSuppliers={getSuppliers}
                    getData={getSup}
                />
            ) : (
                ""
            )}
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>ofEvolution - purchases | Clouget</title>
                    </MetaTags>
                    <Container>
                        <div className="page-title-box">
                            <AvForm>
                                <Row>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="date_type"
                                            value={selectedType}
                                            onChange={(e) => {
                                                handleSelectedType(e.target.value), getDate(e);
                                            }}
                                        >
                                            <option value="year">{props.t("Year")}</option>
                                            <option value="month">{props.t("Month")}</option>
                                            <option value="all_dates">{props.t("All Dates")}</option>
                                        </AvField>
                                    </Col>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="date_sub_type"
                                            onChange={(e) => getDate(e)}
                                        >
                                            {selectedType == "year" ? (
                                                <>
                                                    <option value="quarter">{props.t("Quarter")}</option>
                                                    <option value="month">{props.t("Month")}</option>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {selectedType === "month" ? (
                                                <option value="day">{props.t("Day")}</option>
                                            ) : (
                                                ""
                                            )}
                                            {selectedType === "all_dates" ? (
                                                <>
                                                    <option value="year">{props.t("Year")}</option>
                                                    <option value="quarter">{props.t("Quarter")}</option>
                                                    <option value="month">{props.t("Month")}</option>
                                                </>
                                            ) : (
                                                <>
                                                    <option value="quarter">{props.t("Quarter")}</option>
                                                    <option value="month">{props.t("Month")}</option>
                                                </>
                                            )}
                                        </AvField>
                                    </Col>
                                    <Col md={2}>
                                        <AvField
                                            type="select"
                                            name="reference"
                                            onChange={(e) => getType(e)}
                                        >
                                            <option value="">{props.t("Reference")}</option>
                                            {allRef &&
                                                allRef.map((item, i) => (
                                                    <option key={i} value={item.prefix}>
                                                        {item.prefix}
                                                    </option>
                                                ))}
                                        </AvField>
                                    </Col>
                                </Row>
                                <Row>
                                    {selectedType == "year" ? (
                                        <Col md={2}>
                                            <div className="mt-2">
                                                <select
                                                    id="year-select"
                                                    name="year"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                    onChange={(e) => {
                                                        getDate(e), setYear(e.target.value);
                                                    }}
                                                >
                                                    {yearOptions}
                                                </select>
                                            </div>
                                        </Col>
                                    ) : selectedType == "month" ? (
                                        <Col md={2}>
                                            <div className="mt-2">
                                                <AvField
                                                    type="select"
                                                    name="month"
                                                    value={currentMonth}
                                                    onChange={(e) => getDate(e)}
                                                >
                                                    <option value="1">January</option>
                                                    <option value="2">February</option>
                                                    <option value="3">March</option>
                                                    <option value="4">April</option>
                                                    <option value="5">May</option>
                                                    <option value="6">June</option>
                                                    <option value="7">July</option>
                                                    <option value="8">August</option>
                                                    <option value="9">September</option>
                                                    <option value="10">October</option>
                                                    <option value="11">November</option>
                                                    <option value="12">December</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                    ) : selectedType == "all_dates" ? (
                                        ""
                                    ) : (
                                        ""
                                    )}
                                    <Col md={4}>
                                        <span
                                            className="form-control mt-2"
                                            onClick={() => setAssignedModal(true)}
                                        >
                                            {assignedUserName}
                                        </span>
                                    </Col>
                                    <Col md={4}>
                                        <span
                                            className="form-control mt-2"
                                            onClick={() => {
                                                setSelectClientModal(true), getClientData();
                                            }}
                                        >
                                            {clientNameData}
                                        </span>
                                    </Col>
                                </Row>
                            </AvForm>
                        </div>
                    </Container>
                    <Container>
                        <div className="page-title-box">
                            <h6 className="page-title">{props.t("Purchases By Item")}</h6>
                        </div>
                        <Card>
                            <CardBody>
                                {loader ? <Loader /> : <MultiType data={invoiced?.data} />}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => getExport()}
                                    >
                                        {props.t("Export")}
                                    </button>
                                </div>
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>{props.t("Name")}</th>
                                                {evolutionHistory.labels?.map((item, i) => (
                                                    <th key={`${item}_${i}`}>{item}</th>
                                                ))}
                                                <th>{props.t("total")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {evolutionHistory?.data?.map((item, i) => (
                                                <tr
                                                    key={i}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                        history.push(
                                                            getEntryType(item.referenceType, "yes") + item.id
                                                        )
                                                    }
                                                >
                                                    <td>{item.name}</td>
                                                    {item?.data.map((sub, i) => (
                                                        <td key={`${sub}_${i}`}>{sub}</td>
                                                    ))}
                                                    <td>{item.total}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                {loader}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        </>
    );
};
export default withTranslation()(PurchasesByItem);
PurchasesByItem.propTypes = {
    t: PropTypes.any,
};
