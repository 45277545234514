/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function RateModal(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [rate, setRate] = useState("");
    const [productId, setProductId] = useState(props.product && props.product.id);

    const submitRole = (e, value) => {
        if (props.type == "service") {
            setLoading(true);
            axios
                .post(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/rates`,
                    //   ${
                    //     props.rateId ? `/${props.rateId}?_method=PUT` : ""
                    //   }`,
                    {
                        // service_id: productId,
                        name: value.name,
                        description: value.description,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoading(false);
                    if (res.data.status) {
                        setError("");
                        setSuccess(res.data.message);
                        if (props.rateId) {
                            props.setRate(res.data.rate);
                            props.setIsOpen(false);
                        } else {
                            props.getAllRates();
                        }
                        props.setIsOpenModal(false);
                    } else {
                        setError(res.data.message);
                        setSuccess("");
                    }
                });
        } else {
            setLoading(true);
            axios
                .post(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/rates`,
                    //   ${
                    //     props.rateId ? `/${props.rateId}?_method=PUT` : ""
                    //   }`,
                    {
                        name: value.name,
                        description: value.description,
                        // product_id: productId,
                        // purchase_price: purchasePrice,
                        // purchase_margin: value.purchase_margin,
                        // sales_price: props.product && props.product.price,
                        // sales_margin: value.sales_margin,
                        // discount: value.discount,
                        // special_price: value.special_price,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoading(false);
                    if (res.data.status) {
                        setError("");
                        setSuccess(res.data.message);
                        if (props.rateId) {
                            props.setRate(res.data.rate);
                            props.setIsOpen(false);
                        } else {
                            props.getAllRates();
                        }
                        props.setIsOpenModal(false);
                    } else {
                        setError(res.data.message);
                        setSuccess("");
                    }
                });
        }
    };

    return (
        <Modal size="md" isOpen={props.isOpenModal} centered={true}>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    submitRole(e, v);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {/* {props.rateId ? rate.name : "New Rate"} */}
                        {props.t("New Rate")}
                    </h5>
                    <button
                        type="button"
                        className="btn btn-primary text-md btn-sm"
                        onClick={() => props.setIsOpenModal(false)}
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {success ? (
                        <div className="mb-3">
                            <Alert color="success">{props.t(success)}</Alert>
                        </div>
                    ) : null}

                    {error ? (
                        <div className="mb-3">
                            <Alert color="danger">{props.t(error)}</Alert>
                        </div>
                    ) : null}

                    <div className="mb-3 required">
                        <AvField
                            label={props.t("Name")}
                            type="text"
                            name="name"
                            //   value={rate && rate.name}
                            required="required"
                        />
                    </div>
                    <div className="mb-3">
                        <AvField
                            label={props.t("Description")}
                            type="textarea"
                            name="description"
                        //   value={rate && rate.description}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="text-end">
                        {loading ? (
                            <button
                                className="btn btn-primary disabled"
                                type="button"
                                disabled
                            >
                                {props.t("Loading")}
                            </button>
                        ) : (
                            <button className="btn btn-primary" type="submit">
                                {/* {props.rateId ? "Update" : "Submit"} */} {props.t("Submit")}
                            </button>
                        )}
                        <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => props.setIsOpenModal(false)}
                        >
                            {props.t("Cancel")}
                        </button>
                    </div>
                </div>
            </AvForm>
        </Modal>
    );
}
export default withTranslation()(RateModal);
RateModal.propTypes = {
    t: PropTypes.any,
};
