import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function SingleList(props) {
  const [template, setTemplate] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [loader, setLoader] = useState(<Loader />);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const deleteTemplate = (Id) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/my_templates/${Id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setTemplate(null);
        setdynamic_description(res.data.message);
      });
  };

  const getDuplicateTemplate = (Id) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/my_templates-duplicate/${Id}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");

        if (res.data.status) {
          history.push(`/template/${res.data.data.id}`);
          setError("");
          setLoading(false);
        } else {
          setError(res.data.message);
        }
      });
  };

  if (template === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td>
            <Link style={{ color: " inherit" }} to={`/template/${template.id}`}>
              {props.i + 1}
            </Link>
          </td>
          <td role="button" className="py-1">
            <Link style={{ color: " inherit" }} to={`/template/${template.id}`}>
              {props.t(template.name)}
            </Link>
          </td>
          <td role="button" className="py-1">
            <Link style={{ color: " inherit" }} to={`/template/${template.id}`}>
              {props.t(template.design)}
            </Link>
          </td>
          <td role="button" className="py-1">
            <Link style={{ color: " inherit" }} to={`/template/${template.id}`}>
              {props.t(template.document_type)}
            </Link>
          </td>
          <td role="button" className="py-1 text-center">
            <Link style={{ color: " inherit" }} to={`/template/${template.id}`}>
              {template.is_default == "0" ? <i className="fa fa-check"></i> : 1}
            </Link>
          </td>


          <td className="text-end">
            {loading ? (
              <button className="btn btn-primary me-2" type="button" disabled>
                {props.t("Loading")}
              </button>
            ) : (
              <Button onClick={() => getDuplicateTemplate(template.id)} className="me-2">
                {props.t("Duplicate")}
              </Button>
            )}
            {getPermission().my_templates.my_templates.delete.is_checked ===
            1 ? (
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                {props.t("Delete")}
              </Button>
            ) : (
              ""
            )}
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteTemplate(template.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("El Documento fue eliminado.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
}
export default withTranslation()(SingleList);
SingleList.propTypes = {
  t: PropTypes.any,
};
