import React, { useState } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import {
    AvForm,
    AvField,
    AvRadio,
    AvRadioGroup,
} from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const CreateExpenseCategory = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const submitRole = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_categories${props.expense ? `/${props.expense.id}?_method=PUT` : ""
                }`,
                {
                    name: value.name,
                    type: value.type,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    if (props.expense) {
                        props.setExpense(res.data.expense_category);
                    } else {
                        props.getExpenseCategory();
                    }
                    props.setIsOpenModal(false);
                    setError("");
                    setSuccess("");
                }, 2000);
            });
    };

    return (
        <div>
            <Modal size="md" isOpen={props.isOpenModal} centered={true}>
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        submitRole(e, v);
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            {props.expense
                                ? props.t(props.expense.name)
                                : `${props.t("Create new category for expense")}`}
                        </h5>
                        <button
                            type="button"
                            className="btn btn-primary text-md btn-sm"
                            onClick={() => props.setIsOpenModal(false)}
                            aria-hidden="true"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        {success ? (
                            <div className="mb-3">
                                <Alert color="success">{props.t(success)}</Alert>
                            </div>
                        ) : null}

                        {error ? (
                            <div className="mb-3">
                                <Alert color="danger">{props.t(error)}</Alert>
                            </div>
                        ) : null}

                        <div className="mb-3 required">
                            <AvField
                                label={props.t("Name")}
                                type="text"
                                name="name"
                                value={props.t(props.expense && props.expense.name)}
                                required="required"
                            />
                        </div>
                        <div className="mb-3">
                            <label>{props.t("Type")}</label>
                            <AvRadioGroup
                                name="type"
                                value={props.expense && props.expense.type}
                                defaultValue="expense"
                                inline
                            >
                                <AvRadio label={props.t("Expense")} value="expense" />
                                <AvRadio label={props.t("Investment")} value="investment" />
                            </AvRadioGroup>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="text-end">
                            {loading ? (
                                <button
                                    className="btn btn-primary disabled"
                                    type="button"
                                    disabled
                                >
                                    {props.t("Loading")}
                                </button>
                            ) : (
                                <button className="btn btn-primary" type="submit">
                                    {props.expense
                                        ? `${props.t("Update")}`
                                        : `${props.t("Submit")}`}
                                </button>
                            )}
                            <button
                                className="btn btn-primary ms-2"
                                type="button"
                                onClick={() => props.setIsOpenModal(false)}
                            >
                                {props.t("Cancel")}
                            </button>
                        </div>
                    </div>
                </AvForm>
            </Modal>
        </div>
    );
};
export default withTranslation()(CreateExpenseCategory);
CreateExpenseCategory.propTypes = {
    t: PropTypes.any,
};
