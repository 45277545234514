import React, { useState, useEffect } from "react";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";
import { CardBody, Col, Row, Modal } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

function ClientCategory(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [rateModal, setRateModal] = useState(false);
  const [allRates, setAllRates] = useState(false);
  const [allRatesData, setAllRatesData] = useState(false);

  const createClientCategory = (e, value) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", value.name);
    formData.append("description", value.description);
    formData.append("rate", value.rate);
    formData.append("type", e.target.type.value);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-categories`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          props.CategoriesClient();
          // props.NewpaymentShow();
          ShowRates();
          props.setIsOpenModal(false);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  const createRate = (e, value) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("rate", value.rate);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-rates`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          props.CategoriesClient();
          // props.setIsOpenModal(false)
          ShowRates();
          setRateModal(false);
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };

  const ShowAllRates = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/rates`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllRatesData(res.data.rates);
      });
  };

  const ShowRates = () => {
    setAllRates("");
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-rates`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllRates(res.data.client_rates);
      });
  };

  useEffect(() => {
    ShowRates();
    ShowAllRates();
  }, []);

  return (
    <>
      <Modal size="md" isOpen="true" centered={true}>
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => createClientCategory(e, v)}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.t("New client and supplier category")}
            </h5>
            <button
              type="button"
              className="cross__btn btn btn-primary waves-effect waves-light"
              onClick={() => props.setIsOpenModal(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <CardBody>
              <Row>
                <Col lg={12} md={12} className="mt-3">
                  <div className="required">
                    {" "}
                    <AvField type="text" name="name" label={props.t("Name")} />
                  </div>
                  <div className="mt-3">
                    <label>{props.t("Type")}</label>
                    <AvField type="select" name="type">
                      <option>{props.t("Client")}</option>
                      <option>{props.t("Supplier")}</option>
                    </AvField>
                  </div>
                </Col>

                <Col lg={12} md={12} className="mt-3">
                  <label>{props.t("Rate")}:</label>
                  <div className="d-flex justify-content-between">
                    <div className="w-100">
                      <AvField type="select" name="rate">
                        <option value="withoutrate">
                          {props.t("Without rate")}
                        </option>
                        {/* {allRates &&
                          allRates.map((rates) => (
                            <option value={rates.id}>{rates.rate}</option>
                          ))} */}
                        {allRatesData &&
                          allRatesData.map((rates) => (
                            <option value={rates.id} key={rates.id}>
                              {rates.name}
                            </option>
                          ))}
                      </AvField>
                    </div>
                    {/* <button
                      type="button"
                      onClick={() => {
                        setRateModal(true);
                      }}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target=".bs-example-modal-lg"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      New
                    </button> */}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3 mt-3">
                    <AvField
                      label={props.t("Description")}
                      type="textarea"
                      name="description"
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </div>
          <div className="modal-footer">
            <div className="text-end">
              <button className="btn btn-primary" type="submit">
                {props.t("Submit")}
              </button>
              <button
                className="btn btn-primary ms-2"
                type="button"
                onClick={() => props.setIsOpenModal(false)}
              >
                {props.t("Cancel")}
              </button>
            </div>
          </div>
        </AvForm>
      </Modal>
      <Modal size="md" isOpen={rateModal} centered={true}>
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => createRate(e, v)}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.t("Create Rate")}
            </h5>
            <button
              type="button"
              className="cross__btn btn btn-primary waves-effect waves-light"
              onClick={() => setRateModal(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body p-0">
            <CardBody>
              <Row>
                <Col lg={12} md={12} className="mt-3">
                  <AvField type="text" name="rate" label={props.t("Rate")} />
                </Col>
              </Row>
            </CardBody>
          </div>
          <div className="modal-footer">
            <div className="text-end">
              {loading ? (
                <button className="btn btn-primary" disabled>
                  {props.t("Loading")}
                </button>
              ) : (
                <button className="btn btn-primary" type="submit">
                  {props.t("Submit")}
                </button>
              )}
              <button
                className="btn btn-primary ms-2"
                type="button"
                onClick={() => setRateModal(false)}
              >
                {props.t("Cancel")}
              </button>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  );
}
export default withTranslation()(ClientCategory);
ClientCategory.propTypes = {
  t: PropTypes.any,
};
