import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import InputFilter from "../../components/filters/searchFilter";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Contacts = (props) => {
    const [error, setError] = useState("");
    const [contacts, setContacts] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [timer, setTimer] = useState(null);
    const [name_filter, setName_filter] = useState(false);
    const [phone_filter, setPhone_filter] = useState(false);
    const [email_filter, setEmail_filter] = useState(false);

    const getSearchData = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getContactAll(e);
        }, 1000);
        setTimer(newTimer);
    };

    const getContactAll = (e) => {
        setContacts([]);
        if (e !== undefined) {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/client-contacts?search=${e}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setContacts(res.data.clients);
                    } else {
                        setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/client-contacts`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setError("");
                        setContacts(res.data.clients);
                    } else {
                        setError(res.data.message);
                    }
                });
        }
    };
    const getSearchedList = (e, type) => {
        setError("");
        if (type == "name") {
            localStorage.setItem("name", e);
        }
        if (type == "phone") {
            localStorage.setItem("phone", e);
        }
        if (type == "email") {
            localStorage.setItem("email", e);
        }
        setContacts([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-contacts?&name=${localStorage.getItem("name") !== null
                    ? localStorage.getItem("name")
                    : ""
                }&phone=${localStorage.getItem("phone") !== null
                    ? localStorage.getItem("phone")
                    : ""
                }&email=${localStorage.getItem("email") !== null
                    ? localStorage.getItem("email")
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setContacts(res.data.clients);
                    setName_filter(false);
                    setPhone_filter(false);
                    setEmail_filter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };

    useEffect(() => {
        getContactAll();
    }, []);
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Contacts | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h6 className="page-title">{props.t("Contacts")}</h6>
                                </Col>
                                <Col md={6}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getSearchData(e.target.value)}
                                            />
                                        </div>
                                        <Link to="/contact/new" className="btn btn-primary">
                                            {props.t("New")}
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Name")}
                                                        {name_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setName_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("name") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setName_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            name_filter={name_filter}
                                                            setName_filter={setName_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Phone")}
                                                        {phone_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setPhone_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("phone") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setPhone_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setPhone_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            phone_filter={phone_filter}
                                                            setPhone_filter={setPhone_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>
                                                    <div
                                                        className="position-relative"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        {props.t("Email")}
                                                        {email_filter ? (
                                                            <>
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setEmail_filter(false)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {localStorage.getItem("email") == "" ? (
                                                                    <span
                                                                        className=""
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => setEmail_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className=""
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "orange",
                                                                        }}
                                                                        onClick={() => setEmail_filter(true)}
                                                                    >
                                                                        <i className="fa fa-filter ms-2"></i>
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        <InputFilter
                                                            email_filter={email_filter}
                                                            setEmail_filter={setEmail_filter}
                                                            getSearchedList={getSearchedList}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{props.t("Client")}</th>
                                                <th>{props.t("Fax")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contacts &&
                                                contacts.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>{item.name}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.client_name}</td>
                                                        <td>{item.fax}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                {loader}
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Contacts);
Contacts.propTypes = {
    t: PropTypes.any,
};
