import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table, Card, CardBody, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import {
    getCurrentUser,
    getFilterDate,
    setFilterDate,
} from "../../../helpers/Utils";
const axios = require("axios").default;
// import AllReceipts from "../../../components/AllInvoices/all-recipt-list";
import ListTicketsAndExpenses from "./listTicketsAndExpenes";
import Loader from "../../../components/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";
import MultipleChoice from "../../../components/filters/multiChoice";
import DateFilter from "../../../components/filters/dateFilter";
import InputFilter from "../../../components/filters/searchFilter";

const TicketsAndExpenses = () => {
    const history = useHistory();
    const [ref_filter, setRef_filter] = useState(false);
    const [supName_filter, setSupName_filter] = useState(false);
    //   const [title_filter, setTitle_filter] = useState(false);
    const [employee_filter, setEmployee_filter] = useState(false);
    const [amount_filter, setAmount_filter] = useState(false);
    const [statusTAE_filter, setstatusTAE_filter] = useState(false);
    const [date_filter, setdate_filter] = useState(false);
    const [datePay_filter, setdatePay_filter] = useState(false);
    const [ticketData, setTicketData] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [timer, setTimer] = useState(null);

    const getSearchData = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getInvoiceData(e);
        }, 1000);
        setTimer(newTimer);
    };

    const getInvoiceData = (e) => {
        setTicketData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-tickets?search=${e != undefined ? e : ""}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setTicketData(res.data.data);
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };
    const getSearchedList = (e, type) => {
        setError("");
        if (type == "reference_number") {
            localStorage.setItem("reference", e);
        }
        if (type == "supplier_name") {
            localStorage.setItem("supplierName", e);
        }
        if (type == "title") {
            localStorage.setItem("title", e);
        }
        if (type == "employee_name") {
            localStorage.setItem("employee", e);
        }
        if (type == "statusTAE") {
            localStorage.setItem("statusTAE", e);
        }
        if (type == "date") {
            setFilterDate(e);
        }
        if (type == "datePay") {
            localStorage.setItem("datePay", JSON.stringify(e));
        }
        if (type == "amount") {
            localStorage.setItem("amount", e);
        }
        setTicketData([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-tickets?&status=${localStorage.getItem("statusTAE") !== null
                    ? localStorage.getItem("statusTAE")
                    : ""
                }&reference_number=${localStorage.getItem("reference") !== null
                    ? localStorage.getItem("reference")
                    : ""
                }&supplier_name=${localStorage.getItem("supplierName") !== null
                    ? localStorage.getItem("supplierName")
                    : ""
                }&employee_name=${localStorage.getItem("employee") !== null
                    ? localStorage.getItem("employee")
                    : ""
                }&amount=${localStorage.getItem("amount") !== null
                    ? localStorage.getItem("amount")
                    : ""
                }&start_date=${getFilterDate() && getFilterDate().from !== null
                    ? getFilterDate().from
                    : ""
                }&end_date=${getFilterDate() && getFilterDate().to !== null
                    ? getFilterDate().to
                    : ""
                }&payment_start_date=${JSON.parse(localStorage.getItem("datePay")).from !== null
                    ? JSON.parse(localStorage.getItem("datePay")).from
                    : ""
                }&payment_end_date=${JSON.parse(localStorage.getItem("datePay")).to !== null
                    ? JSON.parse(localStorage.getItem("datePay")).to
                    : ""
                }`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setLoader("");
                    setTicketData(res.data.data);
                    setRef_filter(false);
                    setSupName_filter(false);
                    setdate_filter(false);
                    setEmployee_filter(false);
                    setAmount_filter(false);
                    setstatusTAE_filter(false);
                    setdatePay_filter(false);
                } else {
                    setError(res.data.message);
                    //   setlead_filter(false);
                }
            });
    };
    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedIds.push(parseInt(ele[i].value));
                }
            }
        } else {
            var elem = document.getElementsByName("chk");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedIds;
                    var index = array.indexOf(parseInt(elem[j].value));
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };

    const deleteMultiple = () => {
        let ids = selectedIds.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-tickets/batch-delete`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    setError("");
                    setSelectedIds([]);
                    getInvoiceData();
                    setdynamic_description(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };

    const getExport = () => {
        let ids = selectedIds?.join(",");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets/export`,
                {
                    ids: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSelectedIds([]);
                    window.location.href = res.data.file_url;
                }
            });
    };
    const getDuplicate = () => {
        if (selectedIds.length > 1) {
            setError("This action can only be carried out on a single document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        if (selectedIds.length < 1) {
            setError("Please select document");
            setTimeout(() => {
                setError("");
            }, 4000);
            return false;
        }
        let ids = selectedIds[0];
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/purchase-tickets/duplicate-tickets`,
                {
                    id: ids,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res) {
                    history.push(`/tickets-and-expenses/${res.data.data.id}`);
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };
    useEffect(() => {
        getInvoiceData();
    }, []);

    return (
        <React.Fragment>
            {/* {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false);
            setsuccess_dlg(true);
            deleteMultiple();
            setdynamic_title("Deleted");
            setdynamic_description("Your file has been deleted.");
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">
            You won&apos;t be able to revert this!
          </span>
        </SweetAlert>
      ) : null} */}
            <div className="page-content">
                <MetaTags>
                    <title> Tickets And Expenses | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="company_list">
                        <div className="page-title-box">
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <h6 className="page-title"> Tickets And Expenses</h6>
                                </Col>
                                <Col md={6}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder="Search here"
                                                onChange={(e) => getSearchData(e.target.value)}
                                            />
                                        </div>
                                        <div className="btn-group me-2">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className="btn btn-primary"
                                                // id="dropdown-basic"
                                                >
                                                    More
                                                    {/* <i className="fa fa-caret-down ms-2"></i> */}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {/* <Dropdown.Item>
                            <li className="nav-item mb-2">
                              {" "}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  selectedIds.length < 1
                                    ? setError("Please select document")
                                    : setconfirm_alert(true);
                                }}
                              >
                                Batch delete
                              </span>
                            </li>
                          </Dropdown.Item> */}
                                                    <Dropdown.Item>
                                                        <li className="nav-item mb-2">
                                                            {" "}
                                                            <span
                                                                // className="dropdown-item"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => getDuplicate()}
                                                            >
                                                                Duplicate
                                                            </span>
                                                        </li>

                                                        {/* </ul> */}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <li className="nav-item mb-2">
                                                            <span
                                                                // className="dropdown-item"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => getExport()}
                                                            >
                                                                Export
                                                            </span>
                                                        </li>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <Link
                                            to="/create-tickets-and-expenses"
                                            className="btn btn-primary"
                                        >
                                            Create New
                                        </Link>
                                    </div>
                                </Col>
                                {/* <Col md={6}>
                  <div className="float-end d-none d-md-block">
                    <Link
                      to="/create-tickets-and-expenses"
                      className="btn btn-primary"
                    >
                      Create New
                    </Link>
                  </div>
                </Col> */}
                            </Row>
                        </div>
                        <Card>
                            <CardBody>
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                <Table striped className="align-middle">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    onClick={(e) => selectAllCheck(e.target)}
                                                    className="p-0 d-inline-block"
                                                    type="checkbox"
                                                />
                                            </th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    Reference
                                                    {ref_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setRef_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {localStorage.getItem("reference") == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setRef_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setRef_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <InputFilter
                                                        ref_filter={ref_filter}
                                                        setRef_filter={setRef_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    Supplier
                                                    {supName_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setSupName_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {localStorage.getItem("supplierName") == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setSupName_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setSupName_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <InputFilter
                                                        supName_filter={supName_filter}
                                                        setSupName_filter={setSupName_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    Employee
                                                    {employee_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setEmployee_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {localStorage.getItem("employee") == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setEmployee_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setEmployee_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <InputFilter
                                                        employee_filter={employee_filter}
                                                        setEmployee_filter={setEmployee_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    Status
                                                    {statusTAE_filter ? (
                                                        <>
                                                            <span
                                                                className=""
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setstatusTAE_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {localStorage.getItem("statusTAE") == "" ? (
                                                                <span
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setstatusTAE_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setstatusTAE_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <MultipleChoice
                                                        statusTAE_filter={statusTAE_filter}
                                                        setstatusTAE_filter={setstatusTAE_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    Date
                                                    {date_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setdate_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {getFilterDate() && getFilterDate().from == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setdate_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setdate_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <DateFilter
                                                        date_filter={date_filter}
                                                        setdate_filter={setdate_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    Payment Date
                                                    {datePay_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setdatePay_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {JSON.parse(localStorage.getItem("datePay")) &&
                                                                JSON.parse(localStorage.getItem("datePay"))
                                                                    .from == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setdatePay_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setdatePay_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <DateFilter
                                                        datePay_filter={datePay_filter}
                                                        setdatePay_filter={setdatePay_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="position-relative"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    Amount
                                                    {amount_filter ? (
                                                        <>
                                                            <span
                                                                style={{ cursor: "pointer", color: "orange" }}
                                                                onClick={() => setAmount_filter(false)}
                                                            >
                                                                <i className="fa fa-filter ms-2"></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {localStorage.getItem("amount") == "" ? (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => setAmount_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className=""
                                                                    style={{ cursor: "pointer", color: "orange" }}
                                                                    onClick={() => setAmount_filter(true)}
                                                                >
                                                                    <i className="fa fa-filter ms-2"></i>
                                                                </span>
                                                            )}
                                                        </>
                                                    )}
                                                    <InputFilter
                                                        amount_filter={amount_filter}
                                                        setAmount_filter={setAmount_filter}
                                                        getSearchedList={getSearchedList}
                                                    />
                                                </div>
                                            </th>
                                            {/* <th>Concept</th>
                      <th>Expiration Date</th>
                      <th>Payment Option</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ticketData &&
                                            ticketData.map((item, i) => (
                                                <ListTicketsAndExpenses
                                                    key={i}
                                                    data={item}
                                                    //   key={i}
                                                    selectedIds={selectedIds}
                                                    setSelectedIds={setSelectedIds}
                                                />
                                            ))}
                                    </tbody>
                                </Table>

                                {loader}
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TicketsAndExpenses;
