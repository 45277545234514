import React from "react"
import MetaTags from 'react-meta-tags'
import { Container} from "reactstrap"
import AllClientCategories from "./all"

export default function ClientCategory() {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client-Supplier Categories | Clouget</title>
        </MetaTags>
        <Container fluid>
            <AllClientCategories />
        </Container>
      </div>
    </React.Fragment>
  )
}