/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import MultiType from "../AllCharts/chartjs/barchart";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let fromDate = `01-01-${new Date().getFullYear()}`;
let toDate = `31-12-${new Date().getFullYear()}`;
let selectedStatus = "";
const ReportOverview = (props) => {
    //   const [data, setData] = useState([]);
    const [profit, setProfit] = useState([]);
    const [salesInvoicing, setSalesInvoicing] = useState([]);
    const [purchaseInvoicing, setPurchaseInvoicing] = useState([]);
    const [expenseDistribution, setExpenseDistribution] = useState([]);
    const [loader, setLoader] = useState(true);
    const [selectedType, SetSelectedType] = useState("");
    const [year, setYear] = useState(new Date().getFullYear());
    const startYear = 2017;
    const endYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = endYear; year >= startYear; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }
    const overviewGraph = () => {
        setProfit([]);
        setSalesInvoicing([]);
        setPurchaseInvoicing([]);
        setExpenseDistribution([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/overview?startDate=${fromDate}&endDate=${toDate}&documentStatus=${selectedStatus}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    //   setData(res.data.data);
                    setProfit(res.data.data.profit);
                    setSalesInvoicing(res.data.data.sales_invoicing);
                    setPurchaseInvoicing(res.data.data.purchase_invoicing);
                    setExpenseDistribution(res.data.data.expense_distribution);
                }
                setLoader(false);
            });
    };
    function handleSelectedType(e) {
        SetSelectedType(e);
    }
    const searchData = () => {
        setProfit([]);
        setSalesInvoicing([]);
        setPurchaseInvoicing([]);
        setExpenseDistribution([]);
        setLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/overview?startDate=${fromDate}&endDate=${toDate}&documentStatus=${selectedStatus}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setProfit(res.data.data.profit);
                    setSalesInvoicing(res.data.data.sales_invoicing);
                    setPurchaseInvoicing(res.data.data.purchase_invoicing);
                    setExpenseDistribution(res.data.data.expense_distribution);
                }
                setLoader(false);
            });
    };
    const getDate = (e) => {
        if (e.target.name == "start_date") {
            fromDate = e.target.value;
            toDate = e.target.value;
            searchData();
        } else if (e.target.name == "from_date") {
            fromDate = e.target.value;
        } else if (e.target.name == "to_date") {
            toDate = e.target.value;
            searchData();
        } else if (e.target.name == "annual") {
            if (e.target.value == "all_date") {
                fromDate = "";
                toDate = "";
                searchData();
            }
        } else if (e.target.name == "year") {
            fromDate = `01-01-${e.target.value}`;
            toDate = `31-12-${e.target.value}`;
            searchData();
        } else if (e.target.name == "month") {
            if (e.target.value == "january") {
                fromDate = `01-01-${year}`;
                toDate = `31-01-${year}`;
            } else if (e.target.value == "february") {
                fromDate = `01-02-${year}`;
                toDate = `28-02-${year}`;
            } else if (e.target.value == "march") {
                fromDate = `01-03-${year}`;
                toDate = `31-03-${year}`;
            } else if (e.target.value == "april") {
                fromDate = `01-04-${year}`;
                toDate = `30-04-${year}`;
            } else if (e.target.value == "may") {
                fromDate = `01-05-${year}`;
                toDate = `31-05-${year}`;
            } else if (e.target.value == "june") {
                fromDate = `01-06-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "july") {
                fromDate = `01-07-${year}`;
                toDate = `31-07-${year}`;
            } else if (e.target.value == "august") {
                fromDate = `01-08-${year}`;
                toDate = `31-08-${year}`;
            } else if (e.target.value == "september") {
                fromDate = `01-09-${year}`;
                toDate = `30-09-${year}`;
            } else if (e.target.value == "october") {
                fromDate = `01-10-${year}`;
                toDate = `31-10-${year}`;
            } else if (e.target.value == "november") {
                fromDate = `01-11-${year}`;
                toDate = `30-11-${year}`;
            } else if (e.target.value == "december") {
                fromDate = `01-12-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        } else if (e.target.name == "quarter") {
            if (e.target.value == "first_quarter") {
                fromDate = `01-01-${year}`;
                toDate = `31-03-${year}`;
            } else if (e.target.value == "second_quarter") {
                fromDate = `01-04-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "third_quarter") {
                fromDate = `01-07-${year}`;
                toDate = `30-09-${year}`;
            } else if (e.target.value == "forth_quarter") {
                fromDate = `01-10-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        } else if (e.target.name == "semester") {
            if (e.target.value == "first_semester") {
                fromDate = `01-01-${year}`;
                toDate = `30-06-${year}`;
            } else if (e.target.value == "second_semester") {
                fromDate = `01-07-${year}`;
                toDate = `31-12-${year}`;
            }
            searchData();
        }
    };
    const getExport = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/overview?startDate=${fromDate}&endDate=${toDate}&documentStatus=${selectedStatus}&export=download`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.location.href = res.data.url;
                }
            });
    };
    const getExport2 = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/reports/invoices?startDate=${fromDate}&endDate=${toDate}&documentStatus=${selectedStatus}&export=download`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    window.open(res.data.data.urlReporte, '_blank');
                }
            });
    };
    useEffect(() => {
        overviewGraph();
    }, []);
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Reports - Overview | Clouget</title>
                </MetaTags>
                <Container>
                    <div className="page-title-box">
                        <AvForm>
                            <Row>
                                <Col md={2}>
                                    <AvField
                                        type="select"
                                        name="annual"
                                        value={selectedType}
                                        onChange={(e) => {
                                            handleSelectedType(e.target.value), getDate(e);
                                        }}
                                    >
                                        <option value="year">{props.t("Year")}</option>
                                        <option value="semester">{props.t("Semester")}</option>
                                        <option value="quarter">{props.t("Quarter")}</option>
                                        <option value="month">{props.t("Month")}</option>
                                        <option value="day">{props.t("Day")}</option>
                                        <option value="between_dates">
                                            {props.t("Between dates")}
                                        </option>
                                        <option value="all_date">{props.t("All Dates")}</option>
                                    </AvField>
                                </Col>
                                <Col md={2}>
                                    <AvField
                                        type="select"
                                        name="document_status"
                                        value={selectedStatus}
                                        onChange={(e) => {
                                            selectedStatus = e.target.value, searchData();
                                        }}
                                    >
                                        <option value="">{props.t("Select Document type")}</option>
                                        <option value="authorized">{props.t("AUTHORIZED")}</option>
                                        <option value="unauthorized">{props.t("UNAUTHORIZED")}</option>
                                        <option value="cancelled">{props.t("Cancelled")}</option>
                                    </AvField>
                                </Col>
                                <Col md={8}>
                                    <div className="float-end d-flex">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => getExport()}
                                        >
                                            {props.t("Export")}
                                        </button>
                                        <button
                                            className="btn btn-primary ms-2"
                                            onClick={() => getExport2()}
                                        >
                                            SRI{" "}{props.t("Export")}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <div className="mt-2">
                                        {selectedType == "semester" ? (
                                            <>
                                                <AvField
                                                    type="select"
                                                    name="semester"
                                                    onChange={(e) => getDate(e)}
                                                >
                                                    <option value="" disabled>
                                                        {props.t("Select semester")}
                                                    </option>
                                                    <option value="first_semester">
                                                        1st {props.t("semester")}
                                                    </option>
                                                    <option value="second_semester">
                                                        2nd {props.t("semester")}
                                                    </option>
                                                </AvField>
                                            </>
                                        ) : selectedType == "quarter" ? (
                                            <>
                                                <AvField
                                                    type="select"
                                                    name="quarter"
                                                    onChange={(e) => getDate(e)}
                                                >
                                                    <option value="" disabled>
                                                        {props.t("Select quarter")}
                                                    </option>
                                                    <option value="first_quarter">
                                                        1st {props.t("quarter")}
                                                    </option>
                                                    <option value="second_quarter">
                                                        2nd {props.t("quarter")}
                                                    </option>
                                                    <option value="third_quarter">
                                                        3rd {props.t("quarter")}
                                                    </option>
                                                    <option value="forth_quarter">
                                                        4th {props.t("quarter")}
                                                    </option>
                                                </AvField>
                                            </>
                                        ) : selectedType == "day" ? (
                                            <>
                                                <AvField
                                                    onChange={(e) => getDate(e)}
                                                    dateFormat="MMMM d, yyyy"
                                                    type="date"
                                                    name="start_date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                />
                                            </>
                                        ) : selectedType == "between_dates" ? (
                                            <>
                                                <label>{props.t("From")}</label>
                                                <AvField
                                                    onChange={(e) => getDate(e)}
                                                    dateFormat="MMMM d, yyyy"
                                                    name="from_date"
                                                    type="date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                />
                                                <label>{props.t("To")}</label>
                                                <AvField
                                                    onChange={(e) => getDate(e)}
                                                    dateFormat="MMMM d, yyyy"
                                                    name="to_date"
                                                    type="date"
                                                    autoComplete="off"
                                                    className="is-untouched is-pristine av-valid form-control"
                                                />
                                            </>
                                        ) : selectedType == "month" ? (
                                            <>
                                                <AvField
                                                    type="select"
                                                    name="month"
                                                    onChange={(e) => getDate(e)}
                                                >
                                                    <option value="" disabled>
                                                        {props.t("Select month")}
                                                    </option>
                                                    <option value="january"> {props.t("January")}</option>
                                                    <option value="february"> {props.t("February")}</option>
                                                    <option value="march"> {props.t("March")}</option>
                                                    <option value="april"> {props.t("April")}</option>
                                                    <option value="may"> {props.t("May")}</option>
                                                    <option value="june"> {props.t("June")}</option>
                                                    <option value="july"> {props.t("July")}</option>
                                                    <option value="august"> {props.t("August")}</option>
                                                    <option value="september"> {props.t("September")}</option>
                                                    <option value="october"> {props.t("October")}</option>
                                                    <option value="november"> {props.t("November")}</option>
                                                    <option value="december"> {props.t("December")}</option>
                                                </AvField>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <select
                                        id="year-select"
                                        name="year"
                                        className="is-untouched is-pristine av-valid form-control mt-2"
                                        onChange={(e) => {
                                            getDate(e), setYear(e.target.value);
                                        }}
                                    >
                                        {yearOptions}
                                    </select>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </Container>
                <Container>
                    <div className="page-title-box">
                        <h6 className="page-title">{props.t("Profit")}</h6>
                    </div>
                    <Card>
                        <CardBody>
                            {loader ? <Loader /> : <MultiType data={profit} />}
                        </CardBody>
                    </Card>
                </Container>
                <Container>
                    <div className="page-title-box">
                        <h6 className="page-title">{props.t("Sales Invoicing")}</h6>
                    </div>
                    <Card>
                        <CardBody>
                            {loader ? <Loader /> : <MultiType data={salesInvoicing} />}
                        </CardBody>
                    </Card>
                </Container>
                <Container>
                    <div className="page-title-box">
                        <h6 className="page-title">{props.t("Purchase Invoicing")}</h6>
                    </div>
                    <Card>
                        <CardBody>
                            {loader ? <Loader /> : <MultiType data={purchaseInvoicing} />}
                        </CardBody>
                    </Card>
                </Container>
                <Container>
                    <div className="page-title-box">
                        <h6 className="page-title">{props.t("Expense Distribution")}</h6>
                    </div>
                    <Card>
                        <CardBody>
                            {loader ? <Loader /> : <MultiType data={expenseDistribution} />}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(ReportOverview);
ReportOverview.propTypes = {
    t: PropTypes.any,
};
