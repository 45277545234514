export const getCurrentUser = () => {
  let user = null;
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("current_user") != null
          ? JSON.parse(localStorage.getItem("current_user"))
          : null;
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js  : getCurrentUser -> error",
        error
      );
      user = null;
    }
  }
  return user;
};

export const setCurrentUser = (user) => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("current_user", JSON.stringify(user));
      } else {
        localStorage.removeItem("current_user");
      }
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js : setCurrentUser -> error",
        error
      );
    }
  }
};

export const getCompanyLogo = () => {
  let user = null;
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("current_logo") != null
          ? JSON.parse(localStorage.getItem("current_logo"))
          : null;
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js  : getCompanyLogo -> error",
        error
      );
      user = null;
    }
  }
  return user;
};

export const setCompanyLogo = (user) => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("current_logo", JSON.stringify(user));
      } else {
        localStorage.removeItem("current_logo");
      }
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js : setCompanyLogo -> error",
        error
      );
    }
  }
};

export const getCurrentCompany = () => {
  let user = null;
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("current_company") != null
          ? JSON.parse(localStorage.getItem("current_company"))
          : null;
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js  : getCurrentCompany -> error",
        error
      );
      user = null;
    }
  }
  return user;
};

export const setCurrentCompany = (user) => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("current_company", JSON.stringify(user));
      } else {
        localStorage.removeItem("current_company");
      }
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js : setCurrentCompany -> error",
        error
      );
    }
  }
};

export const getPermission = () => {
  let user = null;
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("current_permissions") != null
          ? JSON.parse(localStorage.getItem("current_permissions"))
          : null;
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js  : getPermission -> error",
        error
      );
      user = null;
    }
  }
  return user;
};

export const setPermission = (user) => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("current_permissions", JSON.stringify(user));
      } else {
        localStorage.removeItem("current_permissions");
      }
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js : setPermission -> error", error);
    }
  }
};
export const getProfileImg = () => {
  let user = null;
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("profile_image") != null
          ? JSON.parse(localStorage.getItem("profile_image"))
          : null;
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js  : getProfileImg -> error",
        error
      );
      user = null;
    }
  }
  return user;
};

export const setProfileImg = (user) => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("profile_image", JSON.stringify(user));
      } else {
        localStorage.removeItem("profile_image");
      }
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js : setProfileImg -> error", error);
    }
  }
};

export const getFilterDate = () => {
  let user = null;
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("filter_dates") != null
          ? JSON.parse(localStorage.getItem("filter_dates"))
          : null;
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js  : getFilterDate -> error",
        error
      );
      user = null;
    }
  }
  return user;
};

export const setFilterDate = (user) => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("filter_dates", JSON.stringify(user));
      } else {
        localStorage.removeItem("filter_dates");
      }
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js : setFilterDate -> error", error);
    }
  }
};
