import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import CategoryModal from "./category-modal";
import { Container, Alert, Col, Row, CardBody, Card, Table } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../helpers/Utils";
import SingleList from "./single-list";
import Loader from "../../components/Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../plans/trialModal";

function AllCategory(props) {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [error, setError] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [timer, setTimer] = useState(null);
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsOpenModal(true);
        }
    };
    const getSearchData = (e) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getAllCategories(e);
        }, 1000);
        setTimer(newTimer);
    };

    const getAllCategories = (e) => {
        setAllCategories([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/product_categories?search=${e !== undefined ? e : ""}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setAllCategories(res.data.product_categories);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getAllCategories();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <CategoryModal
                    isOpenModal={isOpenModal}
                    setIsOpenModal={setIsOpenModal}
                    getAllCategories={getAllCategories}
                />
                <CreateTrialModal
                    isTrialModal={isTrialModal}
                    setIsTrialModal={setIsTrialModal}
                    nonActive={nonActive}
                />
                <MetaTags>
                    <title>Category | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={6}>
                                <h6 className="page-title">{props.t("Category")}</h6>
                            </Col>
                            {getPermission().product_categories.product_categories.create
                                .is_checked === 1 ? (
                                <Col md={6}>
                                    <div className="float-end d-flex">
                                        <div className="me-2">
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder={props.t("Search here")}
                                                onChange={(e) => getSearchData(e.target.value)}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => createNew()}
                                            className="btn btn-primary"
                                            data-toggle="modal"
                                            data-target=".bs-example-modal-lg"
                                        >
                                            {props.t("New")}
                                        </button>
                                    </div>
                                </Col>
                            ) : (
                                ""
                            )}
                        </Row>
                    </div>
                    <Card>
                        <CardBody>
                            <div className="mdb-datatable">
                                <div className="table-responsive">
                                    <Table striped className="align-middle">
                                        <thead>
                                            <tr>
                                                <th>{props.t("Name")}</th>
                                                <th>{props.t("Description")}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allCategories &&
                                                allCategories.map((category) => (
                                                    <SingleList data={category} key={category.id} />
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {loader}
                                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                                {/* <Pagination */}
                                {/*   aria-label="Page navigation example" */}
                                {/*   listClassName="justify-content-end" */}
                                {/* > */}
                                {/*   <PaginationItem disabled> */}
                                {/*     <PaginationLink href="#" tabIndex="-1"> */}
                                {/*       Previous */}
                                {/*     </PaginationLink> */}
                                {/*   </PaginationItem> */}
                                {/*   <PaginationItem> */}
                                {/*     <PaginationLink href="#">1</PaginationLink> */}
                                {/*   </PaginationItem> */}
                                {/*   <PaginationItem> */}
                                {/*     <PaginationLink href="#">2</PaginationLink> */}
                                {/*   </PaginationItem> */}
                                {/*   <PaginationItem> */}
                                {/*     <PaginationLink href="#">3</PaginationLink> */}
                                {/*   </PaginationItem> */}
                                {/*   <PaginationItem> */}
                                {/*     <PaginationLink href="#">Next</PaginationLink> */}
                                {/*   </PaginationItem> */}
                                {/* </Pagination> */}
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default withTranslation()(AllCategory);
AllCategory.propTypes = {
    t: PropTypes.any,
};
