/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Modal, Alert, Col, Row, Input, Spinner } from "reactstrap";
import countryList from "react-select-country-list";
import { getCurrentUser } from "../../helpers/Utils";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const axios = require("axios").default;

const ClientClientModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [referenceType, setReferenceType] = useState("cli");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState(null);

  const createTechnical = (e, value) => {
    setLoading(true);
    const formData = new FormData();
    let subject_to_vat = "";
    let subject_to_income_tax = "";
    formData.append("reference", value.reference);
    formData.append("reference_number", value.reference_number);
    formData.append("legal_name", value.legal_name);
    formData.append("tin", value.tin);
    formData.append("phone_1", value.phone_1);
    formData.append("address", value.address);
    formData.append("type", value.type);
    formData.append("state", value.state);
    formData.append("name", value.name);
    formData.append("email", value.email);
    formData.append("city", value.city);
    formData.append("zip_code", value.zip_code);
    // if (e.target.subject_to_vat.value == "on") {
    // 	subject_to_vat = 1;
    // }
    formData.append("subject_to_vat", "1");
    // if (e.target.subject_to_income_tax.value == "on") {
    // 	subject_to_income_tax = 1;
    // }
    formData.append("subject_to_income_tax", "1");
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/clients`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          // props.getClientData();
          props.selectOption(res.data.client);
          props.setClientSelect(res.data.client.id);
          props.setClientAddress(res.data.client.address);
          props.setClientFull(res.data.client);
          props.setClientNameData(res.data.client.legal_name);
          setSuccess(res.data.message);
          props.getAssests(res.data.client.id);
          // setTimeout(() => {
          //     props.setCreateNew(false);
          //   }, 3000);
          setTimeout(() => {
            props.setCreateNew(false);
            props.setOpen(false);
            setLoading(false);
            setSuccess("");
          }, 4000);
        } else {
          setError(res.data.message);
          setLoading(false);
          setSuccess("");
        }
      });
  };

  const getType = (e) => {
    setReferenceType(e.target.value);
  };

  const getCountry = (e) => {
    setPhone(e);
  };

  const closeModal = () => {
    props.setMainFormDisabled(false);
    props.setCreateNew(false);
  };

  const getSingleCompany = () => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/companies/${localStorage.getItem(
          "companyId"
        )}?company_id=${localStorage.getItem("companyId")}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader(false);
        setCompany(res.data.company);
      });
  };

  useEffect(() => {
    getSingleCompany();
  }, []);

  return (
    <Modal size="lg" isOpen={props.createNew} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          {props.t("New Client")}
        </h5>
        <button
          type="button"
          className="btn btn-primary text-md btn-sm"
          onClick={() => closeModal()}
          aria-hidden="true"
        >
          &times;
        </button>
      </div>
      <div className="modal-body">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            createTechnical(e, v);
          }}
        >
          {error ? <Alert color="danger">{error}</Alert> : null}
          {success ? <Alert color="success">{success}</Alert> : null}
          <Row>
            <Col lg={6}>
              <label>{props.t("Reference")}</label>
              <div className="d-flex">
                <AvField
                  style={{
                    width: "100px",
                  }}
                  type="select"
                  name="reference"
                  value="cli"
                  onChange={(e) => getType(e)}
                >
                  <option value="cli">CLI</option>
                  <option value="manual">MANUAL</option>
                </AvField>
                <div className="w-100 ps-3">
                  <AvField
                    className="mb-3 w-100"
                    type="number"
                    name="reference_number"
                    placeholder={referenceType === "cli" ? "Automatic" : ""}
                  />
                </div>
              </div>
              <div className="mb-3 required">
                <AvField
                  label={props.t("Legal Name")}
                  type="text"
                  name="legal_name"
                  value={props?.name}
                  required="required"
                />
              </div>

              <div className="mb-3 required">
                <AvField
                  label={props.t("Ced/Ruc")}
                  type="number"
                  name="tin"
                  required="required"
                  onKeyPress={(e) => {
                    if (e.target.value.length == 13) e.preventDefault();
                  }}
                />
              </div>
              {/* <div className="mb-3">
                <label>{props.t("Phone 1")}</label>
                <PhoneInput
                  placeholder={props.t("Enter phone number")}
                  value={phone}
                  onChange={(e) => getCountry(e)}
                  inputProps={{
                    name: 'phone_1',
                    required: true,
                    autoFocus: true
                  }}
                  inputStyle={{ width: "100%" }}
                />
              </div> */}
              <AvField
                className="mb-3"
                placeholder={props.t("Enter phone number")}
                label={props.t("Phone 1")}
                type="number"
                name="phone_1"
                maxLength={10}
                validate={{
                  maxLength: {
                    value: 10,
                    errorMessage: props.t(
                      "The number cannot be more than 10 digits."
                    ),
                  },
                }}
              />
            </Col>
            <Col lg={6}>
              <AvField
                className="mb-3"
                label={props.t("Type")}
                type="select"
                name="type"
              >
                <option value="client">{props.t("Client")}</option>
                <option value="potential_client">
                  {props.t("Potential Client")}
                </option>
              </AvField>
              <div className="">
                <AvField
                  className="mb-3"
                  label={props.t("Name")}
                  type="text"
                  name="name"
                />
              </div>
              <div className="mb-3">
                <AvField label={props.t("Email")} type="text" name="email" />
              </div>
            </Col>
          </Row>
          {loader ? (
            <Spinner />
          ) : (
            <Row>
              <Col lg={6}>
                <AvField
                  className="mb-3"
                  label={props.t("Address")}
                  type="text"
                  name="address"
                  value={company?.city}
                />
                <AvField
                  className="mb-3"
                  label={props.t("State/Province")}
                  type="text"
                  name="state"
                  value={company?.state}
                />
                <div className="mb-3">
                  <AvField
                    type="select"
                    name="country"
                    label={props.t("Country")}
                    defaultValue="Ecuador"
                  >
                    {countryList().data.map((item, i) => (
                      <option value={item.label} key={i}>
                        {item.label}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col lg={6}>
                <AvField
                  className="mb-3"
                  label={props.t("City/Town")}
                  type="text"
                  name="city"
                  value={company?.city}
                />
                <AvField
                  className="mb-3"
                  label={props.t("Zip code")}
                  type="text"
                  name="zip_code"
                  value={company?.pincode}
                />
              </Col>
            </Row>
          )}
          {/* <Row>
						<Col lg={6}>
							<div className="mb-3">
								<label>Subject to VAT:</label>
								<Input type="checkbox" className="ms-2" name="subject_to_vat" />
							</div>
						</Col>
						<Col lg={6}>
							<div className="mb-3">
								<label>Subject to Income Tax:</label>
								<Input
									type="checkbox"
									className="ms-2"
									name="subject_to_income_tax"
								/>
							</div>
						</Col>
					</Row> */}
          <div className="text-end">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => closeModal()}
            >
              {props.t("Cancel")}
            </button>
            {loading ? (
              <button
                className="btn btn-primary ms-2 disabled"
                type="button"
                disabled
              >
                {props.t("Loading")}
              </button>
            ) : (
              <button className="btn btn-primary ms-2" type="submit">
                {props.t("Save")}
              </button>
            )}
          </div>
        </AvForm>
      </div>
    </Modal>
  );
};

export default withTranslation()(ClientClientModal);
ClientClientModal.propTypes = {
  t: PropTypes.any,
};
