import React, { useState, useEffect } from "react";
import CreateNewClient from "./CreateNewClient";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../../plans/trialModal";
let selectedRoleId = [];

function SingleCategoryList(props) {
  const [category, setCategory] = useState(props.data && props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [nonActive, setNonActive] = useState(
    JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !== 1 &&
      JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id == null
      ? true
      : false
  );
  const [isTrialModal, setIsTrialModal] = useState(false);
  const createNew = () => {
    if (nonActive) {
      setIsTrialModal(true);
    } else {
      setIsOpenModal(true);
    }
  };
  const deleteCategory = (e) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-categories/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setCategory(null);
        setdynamic_description(res.data.message);
      });
  };
  useEffect(() => {
    selectedRoleId = [];
  }, []);
  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      data.setSelectedIds(selectedRoleId);
    } else {
      selectedRoleId = data.selectedIds;
      var array = selectedRoleId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
      data.setSelectedIds(selectedRoleId);
    }
  };
  if (category === null) {
    return "";
  } else
    return (
      <>
        <CreateTrialModal
          isTrialModal={isTrialModal}
          setIsTrialModal={setIsTrialModal}
          nonActive={nonActive}
        />
        {isOpenModal ? (
          <CreateNewClient
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            category={category}
            setCategory={setCategory}
          />
        ) : null}
        <tr>
          <td>
            <input
              onChange={(e) => getSelectedId(category.id, e)}
              name="chk"
              value={category.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td
            role="button"
            onClick={
              getPermission()?.client_and_supplier_categories
                .client_and_supplier_categories.edit.is_checked === 1
                ? () => createNew()
                : ""
            }
          >
            {category.name}
          </td>
          <td
            role="button"
            onClick={
              getPermission()?.client_and_supplier_categories
                .client_and_supplier_categories.edit.is_checked === 1
                ? () => createNew()
                : ""
            }
          >
            {category.description}
          </td>
          <td
            role="button"
            onClick={
              getPermission()?.client_and_supplier_categories
                .client_and_supplier_categories.edit.is_checked === 1
                ? () => createNew()
                : ""
            }
          >
            {category.rate}
          </td>
          <td className="text-end">
            {getPermission()?.client_and_supplier_categories
              .client_and_supplier_categories.delete.is_checked === 1 ? (
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                {props.t("Delete")}
              </Button>
            ) : (
              ""
            )}
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteCategory(category.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("El Documento fue eliminado.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
}
export default withTranslation()(SingleCategoryList);
SingleCategoryList.propTypes = {
  t: PropTypes.any,
};
