import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";
const axios = require("axios").default;

import { Row, Col, Card, Alert } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { loginUser, apiError } from "../../store/actions";
import logoSm from "../../assets/images/logo-sm.png";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import {
  setCompanyLogo,
  setCurrentUser,
  setPermission,
  setProfileImg,
} from "../../helpers/Utils";
import loginbg from "../../assets/images/login-bg.jpg";
import { withTranslation } from "react-i18next";

const Login = (props) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [passField1, setPassField1] = useState(false);
  const history = useHistory();

  const toggle = () => {
    setPassField1(!passField1);
  };

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_APIKEY}/login`, {
        company_name: companyName,
        email: values.email,
        password: values.password,
      })
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          localStorage.setItem("companyId", res.data.user.company_id);
          setError("");
          setSuccess(res.data.message);
          setCurrentUser(res.data.user);
          setCompanyLogo(res.data.user.logo);
          setPermission(res.data.permissions);
          setProfileImg(res.data.user.image);
          axios
            .get(
              `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                "companyId"
              )}/get-current-user`,

              {
                headers: {
                  Authorization: `Bearer ${res.data.user.token}`,
                },
              }
            )
            .then(function (res) {
              if (res.data.status) {
                localStorage.setItem(
                  "plan_data",
                  JSON.stringify(res.data.data)
                );
                history.push("/dashboard");
              }
            });
        } else {
          setError(res.data.message);
          setSuccess("");
        }
      });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Clouget</title>
      </MetaTags>
      <div
        className="accountbg"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${loginbg})`,
        }}
      ></div>
      <div className="wrapper-page account-page-full">
        <LanguageDropdown />
        <Card className="shadow-none">
          <div className="card-block">
            <div className="account-box">
              <div className="card-box shadow-none p-5">
                <div className="text-center mt-2">
                  <a className="logo logo-admin">
                    <img src={logoSm} height="45" alt="logo" />
                  </a>
                </div>
                <h4 className="font-size-18 mt-4 text-center">
                  {props.t("Welcome Back !")}
                </h4>
                <p className="text-muted text-center">
                  {props.t("Sign in to continue to Clouget.")}
                </p>

                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}
                >
                  {success ? (
                    <Alert color="success">{props.t(success)}</Alert>
                  ) : null}

                  {error ? (
                    <Alert color="danger">{props.t(error)}</Alert>
                  ) : null}

                  <label>{props.t("Company Name")}</label>
                  <div className="input-group mb-3">
                    <input
                      onChange={(e) => setCompanyName(e.target.value)}
                      type="text"
                      placeholder={props.t("Enter Company Name")}
                      className="form-control"
                      aria-describedby="basic-addon2"
                      name="commercial_name"
                    />
                    {/* <span className="input-group-text" id="basic-addon2">
                      {props.t(".example.com")}
                    </span> */}
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="email"
                      label={props.t("Email")}
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      // required
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="password"
                      label={props.t("Password")}
                      type={passField1 ? "text" : "password"}
                      // required

                      placeholder={props.t("Enter Password")}
                    />
                    {passField1 ? (
                      <span
                        className="float-end"
                        style={{ marginTop: "-27px", marginRight: "10px" }}
                      >
                        <i
                          className="fa fa-eye-slash"
                          style={{ cursor: "pointer" }}
                          onClick={() => toggle()}
                        ></i>
                      </span>
                    ) : (
                      <span
                        className="float-end"
                        style={{ marginTop: "-27px", marginRight: "10px" }}
                      >
                        <i
                          className="fa fa-eye"
                          style={{ cursor: "pointer" }}
                          onClick={() => toggle()}
                        ></i>
                      </span>
                    )}
                  </div>

                  <Row className="mb-3">
                    <Col sm={6}>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {props.t("Remember me")}
                        </label>
                      </div>
                    </Col>
                    <Col sm={6} className="text-end">
                      {loading ? (
                        <button
                          className="btn btn-primary  disabled"
                          type="button"
                          disabled
                        >
                          {props.t("Loading")}
                        </button>
                      ) : (
                        <button className="btn btn-primary " type="submit">
                          {props.t("Log In")}
                        </button>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-0 row">
                    <div className="col-12 mt-4">
                      <Link to="/forgot-password">
                        <i className="mdi mdi-lock"></i>{" "}
                        {props.t("Forgot your password?")}
                      </Link>
                    </div>
                  </Row>
                </AvForm>
                <div className="mt-5 text-center">
                  <p>
                    {props.t("Don't have an account ?")}{" "}
                    <Link to="/register" className="fw-medium text-primary">
                      {" "}
                      {props.t("Signup now")}{" "}
                    </Link>{" "}
                  </p>
                  <p>© {new Date().getFullYear()} Clouget</p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(withTranslation()(Login))
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  t: PropTypes.any,
};
