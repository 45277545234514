import React, { useState, useEffect } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";

export default function CategoryModal(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [category, setCategory] = useState("");

    const getSingleCategory = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_categories/${props.categoryId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setCategory(res.data.expense_categories);
                    setError("");
                } else {
                    setError(res.data.message);
                }
                props.getAllRoles();
                setTimeout(() => {
                    props.setIsOpenModal(false);
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const submitCategory = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/expense_categories${props.categoryId ? `/${props.categoryId}?_method=PUT` : ""
                }`,
                {
                    name: value.name,
                    type: e.target.type.value,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    props.setSelectedCategory(res.data.expense_category.id);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    if (props.categoryId) {
                        props.setCategory(res.data.expense_categories);
                        props.setIsOpen(false);
                    } else {
                        props.getAllCategories();
                    }
                    props.setIsOpenModal(false);
                    setError("");
                    setSuccess("");
                }, 1000);
            });
    };

    useEffect(() => {
        if (props.categoryId !== undefined) {
            getSingleCategory();
        }
    }, []);

    return (
        <Modal size="md" isOpen={props.isOpenModal} centered={true}>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    submitCategory(e, v);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.categoryId
                            ? category.name
                            : "Create New Category For Expense"}
                    </h5>
                    <button
                        type="button"
                        className="btn btn-primary text-md btn-sm"
                        onClick={() => props.setIsOpenModal(false)}
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {success ? (
                        <div className="mb-3">
                            <Alert color="success">{props.t(success)}</Alert>
                        </div>
                    ) : null}

                    {error ? (
                        <div className="mb-3">
                            <Alert color="danger">{props.t(error)}</Alert>
                        </div>
                    ) : null}

                    <div className="mb-3 required">
                        <AvField
                            label="Name"
                            type="text"
                            name="name"
                            value={category && category.name}
                        />
                    </div>
                    <div className="d-flex ">
                        <div className="mb-3">
                            <label htmlFor="Expense">Expense</label>
                            <input
                                id="Expense"
                                type="radio"
                                className="ms-2"
                                value="expense"
                                name="type"
                            // onChange={(e) => getExpenseType(e.target.value)}
                            />
                        </div>
                        <div className="mb-3 ms-4">
                            <label>Investment</label>
                            <input
                                // id="Investment"
                                type="radio"
                                className="ms-2"
                                // name="type"
                                // value="investment"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="text-end">
                        {loading ? (
                            <button
                                className="btn btn-primary disabled"
                                type="button"
                                disabled
                            >
                                Loading
                            </button>
                        ) : (
                            <button className="btn btn-primary" type="submit">
                                {props.categoryId ? "Update" : "Submit"}
                            </button>
                        )}
                        <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => props.setIsOpenModal(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </AvForm>
        </Modal>
    );
}
