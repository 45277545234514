import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

let selectedRoleId = [];
let selectedRoleName = [];

const CreateRole = (props) => {
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(<Loader />);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [permissions, setPermissions] = useState([]);

    const getAllPermissions = () => {
        setLoader(<Loader />);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/permissions`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setPermissions(res.data.permissions);
                } else {
                    setError(res.data.message);
                }
            });
    };

    const submitRole = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/roles`,
                {
                    name: value.name,
                    permissions: selectedRoleId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const getSingleRole = (name, check) => {
        localStorage.setItem("selectedRole", "");
        if (check.target.checked) {
            selectedRoleName.push(name);
            const uniqueID = selectedRoleName.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            selectedRoleName = uniqueID;
        } else {
            var array = selectedRoleName;
            var index = array.indexOf(name);
            if (index !== -1) {
                array.splice(index, 1);
            }
        }
        localStorage.setItem("selectedRole", selectedRoleName);
        setLoader(<Loader />);
        setPermissions([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/get-master-role-permissions?names=${localStorage.getItem(
                    "selectedRole"
                )}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setPermissions(res.data.permissions);
                    res.data.selected_permissions &&
                        res.data.selected_permissions.map((item) => {
                            selectedRoleId.push(item);
                        });
                } else {
                    // setError(res.data.message);
                    getAllPermissions();
                }
                setLoader("");
            });
    };

    const getSelectedId = (e, check) => {
        if (check.target.checked) {
            selectedRoleId.push(e);
            const uniqueID = selectedRoleId.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            selectedRoleId = uniqueID;
        } else {
            var array = selectedRoleId;
            var index = array.indexOf(e);
            if (index !== -1) {
                array.splice(index, 1);
            }
        }
    };

    const selectAllCheck = (check) => {
        if (check.checked) {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedRoleId.push(ele[i].value);
                }
            }
        } else {
            var elem = document.getElementsByName("chk");
            for (var j = 0; j < elem.length; j++) {
                if (elem[j].type == "checkbox") {
                    elem[j].checked = false;
                    var array = selectedRoleId;
                    var index = array.indexOf(elem[j].value);
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };

    const selectByBatch = (e) => {
        let batchIdMain = e.target.id;
        let batchIdFormated = batchIdMain.toLowerCase();
        let batchId = batchIdFormated.replace(/\s/g, "");

        if (e.target.checked) {
            var ele = document.getElementsByClassName(batchId);
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    selectedRoleId.push(ele[i].value);
                }
            }
        } else {
            var eleme = document.getElementsByClassName(batchId);
            for (var k = 0; k < eleme.length; k++) {
                if (eleme[k].type == "checkbox") {
                    eleme[k].checked = false;
                    var array = selectedRoleId;
                    var index = array.indexOf(eleme[k].value);
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
        }
    };

    useEffect(() => {
        getAllPermissions();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>New Role | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                submitRole(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{props.t("New Role")}</h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            <Link
                                                type="button"
                                                to="/company"
                                                className="btn btn-primary"
                                            >
                                                {props.t("Back")}
                                            </Link>
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary ms-2 " type="submit">
                                                    {props.t("Submit")}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <div className="mb-3 required">
                                        <AvField
                                            label={props.t("Role Name")}
                                            type="text"
                                            name="name"
                                            required="required"
                                        />
                                    </div>
                                    {loader !== "" ? (
                                        loader
                                    ) : (
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    {permissions &&
                                                        permissions.map((item, i) => (
                                                            <>
                                                                <div
                                                                    className={`row border-bottom py-4 ${item.name}`}
                                                                    key={i}
                                                                >
                                                                    <div className="col-md-3">
                                                                        <label>
                                                                            {item.name !== "Permissions" ? (
                                                                                item.name !== "Visible Roles" ? (
                                                                                    <input
                                                                                        className={`all_permission p-0 d-inline-block`}
                                                                                        type="checkbox"
                                                                                        name="chk"
                                                                                        value={item.id}
                                                                                        defaultChecked={
                                                                                            item.is_checked == "yes"
                                                                                        }
                                                                                        id={item.name}
                                                                                        onClick={(e) => selectByBatch(e)}
                                                                                    />
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                ""
                                                                            )}{" "}
                                                                            {item.name}
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-9">
                                                                        <div className="row">
                                                                            <div className="col-md-7"></div>
                                                                            <div className="col-md-5">
                                                                                {item.children[0] &&
                                                                                    item.children[0].children.length !==
                                                                                    0 ? (
                                                                                    <>
                                                                                        <table className="table fixed-width">
                                                                                            <tr>
                                                                                                <th>View</th>
                                                                                                <th>Edit</th>
                                                                                                <th>Create</th>
                                                                                                <th>Delete</th>
                                                                                                <th>
                                                                                                    {item.name.indexOf(
                                                                                                        "Connect"
                                                                                                    ) === 0
                                                                                                        ? "Send"
                                                                                                        : ""}
                                                                                                </th>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        {item.children &&
                                                                            item.children.map((child, i) => (
                                                                                <div className="row" key={i}>
                                                                                    <div className="col-md-7">
                                                                                        {child.is_checkbox !== 0 ? (
                                                                                            <>
                                                                                                {child.name ===
                                                                                                    "All permissions" ? (
                                                                                                    <>
                                                                                                        <input
                                                                                                            className={`all_permission p-0 d-inline-block ${item.name
                                                                                                                .replace(/\s/g, "")
                                                                                                                .toLowerCase()}`}
                                                                                                            name={`view_${child.id}`}
                                                                                                            type="checkbox"
                                                                                                            value={child.id}
                                                                                                            onClick={(e) =>
                                                                                                                selectAllCheck(e.target)
                                                                                                            }
                                                                                                            defaultChecked={
                                                                                                                child.is_checked ===
                                                                                                                    "yes"
                                                                                                                    ? "checked"
                                                                                                                    : ""
                                                                                                            }
                                                                                                            onChange={(e) =>
                                                                                                                getSelectedId(
                                                                                                                    child.id,
                                                                                                                    e
                                                                                                                )
                                                                                                            }
                                                                                                        />{" "}
                                                                                                        {child.name}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {child.parent_id === 1 ? (
                                                                                                            <>
                                                                                                                <input
                                                                                                                    className={`${item.name ===
                                                                                                                            "Visible Roles"
                                                                                                                            ? "visible_role"
                                                                                                                            : ""
                                                                                                                        } p-0 d-inline-block ${item.name
                                                                                                                            .replace(/\s/g, "")
                                                                                                                            .toLowerCase()}`}
                                                                                                                    name={
                                                                                                                        item.name ===
                                                                                                                            "Visible Roles"
                                                                                                                            ? "visible_role"
                                                                                                                            : "chk"
                                                                                                                    }
                                                                                                                    defaultChecked={
                                                                                                                        child.is_checked ===
                                                                                                                            "yes"
                                                                                                                            ? "checked"
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                    value={child.id}
                                                                                                                    type="checkbox"
                                                                                                                    onChange={(e) => {
                                                                                                                        getSelectedId(
                                                                                                                            child.id,
                                                                                                                            e
                                                                                                                        ),
                                                                                                                            getSingleRole(
                                                                                                                                child.name,
                                                                                                                                e
                                                                                                                            );
                                                                                                                    }}
                                                                                                                />{" "}
                                                                                                                {child.name}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <input
                                                                                                                    className={`${item.name ===
                                                                                                                            "Visible Roles"
                                                                                                                            ? "visible_role"
                                                                                                                            : ""
                                                                                                                        } p-0 d-inline-block ${item.name
                                                                                                                            .replace(/\s/g, "")
                                                                                                                            .toLowerCase()}`}
                                                                                                                    name={
                                                                                                                        item.name ===
                                                                                                                            "Visible Roles"
                                                                                                                            ? "visible_role"
                                                                                                                            : "chk"
                                                                                                                    }
                                                                                                                    defaultChecked={
                                                                                                                        child.is_checked ===
                                                                                                                            "yes"
                                                                                                                            ? "checked"
                                                                                                                            : ""
                                                                                                                    }
                                                                                                                    value={child.id}
                                                                                                                    type="checkbox"
                                                                                                                    onChange={(e) =>
                                                                                                                        getSelectedId(
                                                                                                                            child.id,
                                                                                                                            e
                                                                                                                        )
                                                                                                                    }
                                                                                                                />{" "}
                                                                                                                {child.name}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <label>{child.name}</label>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="col-md-5">
                                                                                        <table className="table p-0 m-0 fixed-width">
                                                                                            <tr>
                                                                                                <td className="text-center p-0">
                                                                                                    {child.children &&
                                                                                                        child.children.map(
                                                                                                            (option) => (
                                                                                                                <>
                                                                                                                    {option.name.indexOf(
                                                                                                                        "view"
                                                                                                                    ) === 0 ? (
                                                                                                                        <input
                                                                                                                            name="chk"
                                                                                                                            value={option.id}
                                                                                                                            onChange={(e) =>
                                                                                                                                getSelectedId(
                                                                                                                                    option.id,
                                                                                                                                    e
                                                                                                                                )
                                                                                                                            }
                                                                                                                            defaultChecked={
                                                                                                                                option.is_checked ==
                                                                                                                                "yes"
                                                                                                                            }
                                                                                                                            className={`p-0 d-inline-block ${item.name
                                                                                                                                .replace(
                                                                                                                                    /\s/g,
                                                                                                                                    ""
                                                                                                                                )
                                                                                                                                .toLowerCase()}`}
                                                                                                                            type="checkbox"
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                </td>
                                                                                                <td className="text-center p-0">
                                                                                                    {child.children &&
                                                                                                        child.children.map(
                                                                                                            (option) => (
                                                                                                                <>
                                                                                                                    {option.name.indexOf(
                                                                                                                        "edit"
                                                                                                                    ) === 0 ? (
                                                                                                                        <input
                                                                                                                            name="chk"
                                                                                                                            onChange={(e) =>
                                                                                                                                getSelectedId(
                                                                                                                                    option.id,
                                                                                                                                    e
                                                                                                                                )
                                                                                                                            }
                                                                                                                            value={option.id}
                                                                                                                            defaultChecked={
                                                                                                                                option.is_checked ==
                                                                                                                                "yes"
                                                                                                                            }
                                                                                                                            className={`p-0 d-inline-block ${item.name
                                                                                                                                .replace(
                                                                                                                                    /\s/g,
                                                                                                                                    ""
                                                                                                                                )
                                                                                                                                .toLowerCase()}`}
                                                                                                                            type="checkbox"
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                </td>
                                                                                                <td className="text-center p-0">
                                                                                                    {child.children &&
                                                                                                        child.children.map(
                                                                                                            (option) => (
                                                                                                                <>
                                                                                                                    {option.name.indexOf(
                                                                                                                        "create"
                                                                                                                    ) === 0 ? (
                                                                                                                        <input
                                                                                                                            name="chk"
                                                                                                                            onChange={(e) =>
                                                                                                                                getSelectedId(
                                                                                                                                    option.id,
                                                                                                                                    e
                                                                                                                                )
                                                                                                                            }
                                                                                                                            defaultChecked={
                                                                                                                                option.is_checked ==
                                                                                                                                "yes"
                                                                                                                            }
                                                                                                                            value={option.id}
                                                                                                                            className={`p-0 d-inline-block ${item.name
                                                                                                                                .replace(
                                                                                                                                    /\s/g,
                                                                                                                                    ""
                                                                                                                                )
                                                                                                                                .toLowerCase()}`}
                                                                                                                            type="checkbox"
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                </td>
                                                                                                <td className="text-center p-0">
                                                                                                    {child.children &&
                                                                                                        child.children.map(
                                                                                                            (option) => (
                                                                                                                <>
                                                                                                                    {option.name.indexOf(
                                                                                                                        "delete"
                                                                                                                    ) === 0 ? (
                                                                                                                        <input
                                                                                                                            name="chk"
                                                                                                                            onChange={(e) =>
                                                                                                                                getSelectedId(
                                                                                                                                    option.id,
                                                                                                                                    e
                                                                                                                                )
                                                                                                                            }
                                                                                                                            defaultChecked={
                                                                                                                                option.is_checked ==
                                                                                                                                "yes"
                                                                                                                            }
                                                                                                                            value={option.id}
                                                                                                                            className={`p-0 d-inline-block ${item.name
                                                                                                                                .replace(
                                                                                                                                    /\s/g,
                                                                                                                                    ""
                                                                                                                                )
                                                                                                                                .toLowerCase()}`}
                                                                                                                            type="checkbox"
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                </td>
                                                                                                <td className="text-center p-0">
                                                                                                    {child.children &&
                                                                                                        child.children.map(
                                                                                                            (option) => (
                                                                                                                <>
                                                                                                                    {option.name.indexOf(
                                                                                                                        "send"
                                                                                                                    ) === 0 ? (
                                                                                                                        <input
                                                                                                                            name="chk"
                                                                                                                            onChange={(e) =>
                                                                                                                                getSelectedId(
                                                                                                                                    option.id,
                                                                                                                                    e
                                                                                                                                )
                                                                                                                            }
                                                                                                                            defaultChecked={
                                                                                                                                option.is_checked ==
                                                                                                                                "yes"
                                                                                                                            }
                                                                                                                            value={option.id}
                                                                                                                            className={`p-0 d-inline-block ${item.name
                                                                                                                                .replace(
                                                                                                                                    /\s/g,
                                                                                                                                    ""
                                                                                                                                )
                                                                                                                                .toLowerCase()}`}
                                                                                                                            type="checkbox"
                                                                                                                        />
                                                                                                                    ) : (
                                                                                                                        ""
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )
                                                                                                        )}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(CreateRole);
CreateRole.propTypes = {
    t: PropTypes.any,
};
