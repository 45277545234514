import React from "react";
import { Line } from "react-chartjs-2";

function LineChart(props) {
    let chartData = {
        labels: props.data.labels,
        datasets: props.data.data,
    };

    console.log("chartData", chartData);

    return <Line data={chartData} />;
}

export default LineChart;
