import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvRadio,
    AvCheckboxGroup,
    AvCheckbox,
} from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import countryList from "react-select-country-list";
import Select from "react-select";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NewUser = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [selectedCalendar, setSelectedCalendar] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [image, setImage] = useState("");
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [allRoles, setAllRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [password, setPassword] = useState("");
    const [checkConfiguration, setCheckConfiguration] = useState("gmail");
    const [phone, setPhone] = useState("");
    const [phone2, setPhone2] = useState("");
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };
    console.log(props, "sas");
    const newUserSubmit = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", value.name);
        formData.append("surname", value.surname);
        formData.append("username", value.username);
        formData.append("email", value.email);
        formData.append("phone_1", phone);
        formData.append("phone_2", phone2);
        formData.append("role", selectedRole);
        formData.append("tin", value.tin);
        formData.append("password", value.password);
        formData.append("image", image);
        formData.append("position", value.position);
        formData.append("calendar", selectedCalendar);
        formData.append("address", value.address);
        formData.append("city", value.city);
        formData.append("state", value.state);
        formData.append("country", value.country);
        formData.append("pincode", value.pincode);
        formData.append("language", selectedLanguage);
        formData.append("has_access", value.has_access);
        formData.append("use_email_configuartion", value.use_email_configuartion);
        formData.append(
            "gmail_sender_name",
            value.gmail_sender_name !== undefined ? value.gmail_sender_name : ""
        );
        formData.append(
            "gmail_email_address",
            value.gmail_email_address !== undefined ? value.gmail_email_address : ""
        );
        formData.append(
            "smtp_sender_name",
            value.smtp_sender_name !== undefined ? value.smtp_sender_name : ""
        );
        formData.append(
            "smtp_email_address",
            value.smtp_email_address !== undefined ? value.smtp_email_address : ""
        );
        formData.append(
            "smtp_server",
            value.smtp_server !== undefined ? value.smtp_server : ""
        );
        formData.append(
            "smtp_security_protocol",
            value.smtp_security_protocol !== undefined ? value.smtp_security : ""
        );
        formData.append(
            "smtp_password",
            value.smtp_password !== undefined ? value.smtp_password : ""
        );
        formData.append(
            "smtp_port",
            value.smtp_port !== undefined ? value.smtp_port : ""
        );
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                    if (res.data.message) {
                        setCustomActiveTab("2");
                    }
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 7000);
            });
    };

    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };
    const getCountry = (e) => {
        setPhone(e);
    };
    const getCountry2 = (e) => {
        setPhone2(e);
    };
    // Calendar /Set Calendar
    const optionCalendar = [{ label: "Personal", value: "personal" }];
    function handleSelectCalendar(selectedCalendar) {
        setSelectedCalendar(selectedCalendar.value);
    }

    // Language /Set Language
    const optionLanguage = [
        { label: "English", value: "english" },
        { label: "Spanish", value: "spanish" },
        { label: "Latam Spanish", value: "latam_spanish" },
        { label: "Chilean Spanish", value: "chilean_spanish" },
        { label: "French", value: "french" },
    ];
    function handleSelectLanguage(selectedLanguage) {
        setSelectedLanguage(selectedLanguage.value);
    }

    const getUserRoles = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/roles`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllRoles(res.data.roles);
            });
    };

    // Role /Set Role
    let optionRole = [];
    if (allRoles !== undefined) {
        allRoles.map((item) => {
            item.id !== 1
                ? optionRole.push({
                    value: item.id,
                    label: item.name,
                })
                : "";
        });
    }
    function handleSelectRole(selectedRole) {
        setSelectedRole(selectedRole.value);
    }

    const getPassword = () => {
        var generator = require("generate-password");
        setPassword(
            generator.generate({
                length: 10,
                numbers: true,
            })
        );
    };

    const getEmailConfiguration = (e) => {
        setCheckConfiguration(e.target.value);
    };

    useEffect(() => {
        getUserRoles();
        getPassword();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>New User | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                newUserSubmit(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{props.t("New User")}</h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-none d-md-block">
                                            <Link
                                                type="button"
                                                to="/company"
                                                className="btn btn-primary"
                                            >
                                                {props.t("Back")}
                                            </Link>
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary ms-2" type="submit">
                                                    {props.t("Submit")}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Name")}
                                                    type="text"
                                                    name="name"
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Lastname")}
                                                    type="text"
                                                    name="surname"
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Username")}
                                                    type="text"
                                                    name="username"
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Ced/Ruc")}
                                                    type="number"
                                                    name="tin"
                                                    onKeyPress={(e) => {
                                                        if (e.target.value.length == 13) e.preventDefault();
                                                    }}
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Email")}
                                                    type="email"
                                                    name="email"
                                                    required="required"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="company_img">
                                                <label>{props.t("Employees Photo")}</label>
                                                <input
                                                    className="hidden d-none"
                                                    onChange={(e) => setComImg(e)}
                                                    type="file"
                                                    id="comImg"
                                                />
                                                <label className="company_img-box" htmlFor="comImg">
                                                    <img src={imgPreview} />
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("General")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Access")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "3",
                                                })} disabled`}
                                                onClick={() => {
                                                    toggleCustom("3");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("History")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "4",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("4");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Email Configuration")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="p-3">
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col lg="6">
                                                    <AvField
                                                        className="mb-3"
                                                        placeholder={props.t("Enter phone number")}
                                                        label={props.t("Phone 1")}
                                                        type="number"
                                                        name="phone_1"
                                                        maxLength={10}
                                                        validate={{
                                                            maxLength: {
                                                                value: 10,
                                                                errorMessage:
                                                                    props.t("The number cannot be more than 10 digits."),
                                                            },
                                                        }}
                                                    />
                                                    <AvField
                                                        className="mb-3"
                                                        placeholder={props.t("Enter phone number")}
                                                        label={props.t("Phone 2")}
                                                        type="number"
                                                        name="phone_2"
                                                        maxLength={10}
                                                        validate={{
                                                            maxLength: {
                                                                value: 10,
                                                                errorMessage:
                                                                    props.t("The number cannot be more than 10 digits."),
                                                            },
                                                        }}
                                                    />
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Position")}
                                                            type="text"
                                                            name="position"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Address")}
                                                            type="text"
                                                            name="address"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("State/Province")}
                                                            type="text"
                                                            name="state"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="country"
                                                            label={props.t("Country")}
                                                            defaultValue="Ecuador"
                                                        >
                                                            {countryList().data.map((item) => (
                                                                <option value={item.label} key={item.value}>
                                                                    {item.label}
                                                                </option>
                                                            ))}
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <label>{props.t("Calendar")}</label>
                                                        <Select
                                                            options={optionCalendar}
                                                            onChange={handleSelectCalendar}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("City/Town")}
                                                            type="text"
                                                            name="city"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Zip/Postal Code")}
                                                            type="text"
                                                            name="pincode"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label>{props.t("Language")}</label>
                                                        <Select
                                                            options={optionLanguage}
                                                            onChange={handleSelectLanguage}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("User")}
                                                            type="text"
                                                            name="pincode"
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="mb-3 required">
                                                        <label>{props.t("Role")}</label>
                                                        <Select
                                                            options={optionRole}
                                                            onChange={handleSelectRole}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvCheckboxGroup name="has_access">
                                                            <AvCheckbox
                                                                label={props.t("Allow access")}
                                                                value="1"
                                                            />
                                                        </AvCheckboxGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Password")}
                                                            type="text"
                                                            name="password"
                                                            value={password}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={getPassword}
                                                            className="btn btn-secondary mt-2 border"
                                                        >
                                                            {props.t("Generate A New Password")}
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <label>{props.t("History")}</label>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <label className="mb-3">
                                                {props.t(
                                                    "Authorize Billing System if you use Gmail or enter the SMTP settings of your mail server:"
                                                )}
                                            </label>
                                            <AvRadioGroup
                                                name="use_email_configuartion"
                                                value="gmail"
                                                className="radiobutton"
                                                onChange={(e) => getEmailConfiguration(e)}
                                            >
                                                <AvRadio label={props.t("Gmail")} value="gmail" />
                                                <AvRadio label={props.t("SMTP")} value="smtp" />
                                            </AvRadioGroup>
                                            <Row>
                                                {checkConfiguration === "gmail" ? (
                                                    <>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <AvField
                                                                    label={props.t("Sender Name")}
                                                                    type="text"
                                                                    name="gmail_sender_name"
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    label={props.t("Sender Name")}
                                                                    type="text"
                                                                    name="gmail_email_address"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <AvField
                                                                    label={props.t("Sender Name")}
                                                                    type="text"
                                                                    name="smtp_sender_name"
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    label={props.t("Email Address")}
                                                                    type="text"
                                                                    name="smtp_email_address"
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    label={props.t("SMTP Server")}
                                                                    type="text"
                                                                    name="smtp_server"
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    label={props.t("Security Protocol")}
                                                                    type="text"
                                                                    name="smtp_security_protocol"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <AvField
                                                                    label={props.t("Password")}
                                                                    type="password"
                                                                    name="smtp_password"
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    label={props.t("SMTP Port")}
                                                                    type="text"
                                                                    name="smtp_port"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <label className="mb-3">
                                                                {props.t(
                                                                    "These are the configuration settings for the following email service providers:"
                                                                )}
                                                            </label>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <label>{props.t("HOTMAIL")}</label>
                                                                    <p>
                                                                        SMTP Server: smtp.live.com <br /> Security
                                                                        Protocol (any option is valid):
                                                                        <br /> STARTTLS - SMTP Ports: 25 and 587{" "}
                                                                        <br /> TLS - SMTP Ports: 25, 465 and 587
                                                                    </p>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <label>{props.t("YAHOO")}</label>
                                                                    <p>
                                                                        SMTP Server: smtp.mail.yahoo.com <br />{" "}
                                                                        Security Protocol (any option is valid):
                                                                        <br /> STARTTLS - SMTP Ports: 25 and 587{" "}
                                                                        <br /> TLS - SMTP Ports: 25, 465 and 587{" "}
                                                                        <br /> SSL - SMTP Ports: 465
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                            <label className="mt-2">
                                                                {props.t(`Additional Information: if the email
                                configuration you have inserted isn&apos;t valid
                                then your emails will still be sent using
                                info@billingsystem.com. When the receiver
                                responds to your email, the reply will go to
                                your account.`)}
                                                            </label>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(NewUser);
NewUser.propTypes = {
    t: PropTypes.any,
};
