import React from 'react'
import {
  AvForm, AvField
} from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";


const AmountFilter = (props) => {
  let selectedFrom = localStorage.getItem("amount") ?
    localStorage.getItem("amount").split('-')[0] : "0";
  let selectedTo = localStorage.getItem("amount") ?
    localStorage.getItem("amount").split('-')[1] : "";
  const getSelectedAmount = (e) => {
    if (e.name === "amount_from") {
      selectedFrom = e.value;
    } else if (e.name === "amount_to") {
      selectedTo = e.value;
    }
  };

  return (
    <div>
      {props.amount_filter ? (
        <div className="filter-4">
          <AvForm
            onValidSubmit={(e) => props.getSearchedList(`${selectedFrom}-${selectedTo}`, "amount")}
          >
            <div className="d-flex justify-content-between mb-3">
              <AvField
                onChange={(e) => getSelectedAmount(e.target)}
                className="form-control"
                type="number"
                label={props.t("From")}
                name="amount_from"
                defaultValue={localStorage.getItem("amount") ?
                  localStorage.getItem("amount").split('-')[0] : ""
                }
              />
              <AvField
                onChange={(e) => getSelectedAmount(e.target)}
                className="form-control"
                type="number"
                label={props.t("To")}
                name="amount_to"
                defaultValue={localStorage.getItem("amount") ?
                  localStorage.getItem("amount").split('-')[1] : ""
                }
              />
            </div>

            <button className="btn btn-primary" type="submit">
              <i className="fa fa-search me-2"></i>
              {props.t("Search")}
            </button>
            <button
              className="btn btn-secondary float-end text-dark"
              type="button"
              onClick={(e) => props.getSearchedList("", "amount")}
            >
              <i className="fa fa-undo"></i>
            </button>
          </AvForm>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default withTranslation()(AmountFilter);
AmountFilter.propTypes = {
  t: PropTypes.any,
};