/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import RateModal from "./rate-modal";
import { Alert, Col, Row, Table } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser } from "../../../helpers/Utils";
import SingleList from "./single-list";
import Loader from "../../Loader";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function ProductPurchasePrice(props) {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [error, setError] = useState("");
    const [allRates, setAllRates] = useState([]);
    const [loader, setLoader] = useState(<Loader />);

    const getAllRates = () => {
        if (props.type == "service") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/service-special-prices?type=purchase_price&service_id=${props.product.id
                    }`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setAllRates(res.data.service_special_prices);
                        setError("");
                    } else {
                        setError(res.data.message);
                    }
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/supplier-special-prices?type=purchase_price&product_id=${props.product.id
                    }`,
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setLoader("");
                    if (res.data.status) {
                        setAllRates(res.data.supplier_special_prices);
                        setError("");
                    } else {
                        setError(res.data.message);
                    }
                });
        }
    };

    useEffect(() => {
        getAllRates();
    }, [props.product.id]);

    return (
        <>
            {isOpenModal ? (
                <RateModal
                    setIsOpenModal={setIsOpenModal}
                    getAllRates={getAllRates}
                    product={props.product}
                    type={props.type}
                />
            ) : null}
            <div className="page-title-box pb-0">
                <Row className="align-items-center">
                    <Col md={6}>
                        <h6 className="page-title">{props.t("Special Purchase Price")}</h6>
                    </Col>
                    <Col md={6}>
                        <div className="float-end d-flex">
                            <button
                                type="button"
                                onClick={() => {
                                    setIsOpenModal(true);
                                }}
                                className="btn btn-primary"
                                data-toggle="modal"
                                data-target=".bs-example-modal-lg"
                            >
                                {props.t("New")}
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="table-responsive">
                <Table striped className="align-middle">
                    <thead>
                        <tr>
                            <th>{props.t("Supplier")}</th>
                            <th>{props.t("Special Purchase Price")}</th>
                            <th>{props.t("Discount (%)")}</th>
                            <th>{props.t("Purchase Margin (%)")}</th>
                            <th>{props.t("Sales Margin (%)")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allRates &&
                            allRates.map((item, i) => (
                                <SingleList
                                    data={item}
                                    key={i}
                                    product={props.product}
                                    type={props.type}
                                />
                            ))}
                    </tbody>
                </Table>
                {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                {loader}
            </div>
        </>
    );
}
export default withTranslation()(ProductPurchasePrice);
ProductPurchasePrice.propTypes = {
    t: PropTypes.any,
};
