import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";
const axios = require("axios").default;
import { Row, Col, Alert, Card } from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { userForgetPassword } from "../../store/actions";
import logoSm from "../../assets/images/logo-sm.png";
import loginbg from "../../assets/images/login-bg.jpg";
import { withTranslation } from "react-i18next";

const ResetPasswordPage = (props) =>
//   {
//   match: {
//     params: { slug },
//   },
// }
{
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const userToken = params.get("token");
    const companyName = params.get("company_name");
    const [passField1, setPassField1] = useState(false);
    const [passField2, setPassField2] = useState(false);

    const toggle = () => {
        setPassField1(!passField1);
    };

    const toggle2 = () => {
        setPassField2(!passField2);
    };

    // handleValidSubmit
    const handleValidSubmit = (event, values) => {
        if (password !== confirmPassword) {
            setError("Password and confirm password must be same");
        } else {
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_APIKEY}/reset-password`, {
                    token: userToken,
                    email: values.email,
                    password: values.password,
                    company_name: companyName,
                })
                .then(function (res) {
                    setLoading(false);
                    if (res.data.status) {
                        setError("");
                        setSuccess(res.data.message);
                    } else {
                        setError(res.data.message);
                        setSuccess("");
                    }
                });
        }
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Reset Password | Clouget</title>
            </MetaTags>
            <div
                className="accountbg"
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url(${loginbg})`,
                }}
            ></div>
            <div className="wrapper-page account-page-full">
                <Card className="shadow-none">
                    <div className="card-block">
                        <div className="account-box">
                            <div className="card-box shadow-none p-5">
                                <div className="text-center mt-4">
                                    <a className="logo logo-admin">
                                        <img src={logoSm} height="45" alt="logo" />
                                    </a>
                                </div>
                                <h4 className="text-center mt-5 font-size-18">
                                    {props.t("Forget Password")}
                                </h4>

                                <AvForm
                                    className="form-horizontal mt-4"
                                    onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                >
                                    <div className="mt-5">
                                        {success ? (
                                            <Alert color="success">{props.t(success)}</Alert>
                                        ) : null}

                                        {error ? (
                                            <Alert color="danger">{props.t(error)}</Alert>
                                        ) : null}
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="email"
                                            label={props.t("Email")}
                                            className="form-control"
                                            placeholder={props.t("Enter email")}
                                            type="email"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="password"
                                            label={props.t("Password")}
                                            className="form-control"
                                            placeholder={props.t("Enter new Password")}
                                            type={passField1 ? "text" : "password"}
                                            required
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {passField1 ? (
                                            <span
                                                className="float-end"
                                                style={{ marginTop: "-27px", marginRight: "10px" }}
                                            >
                                                <i
                                                    className="fa fa-eye-slash"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => toggle()}
                                                ></i>
                                            </span>
                                        ) : (
                                            <span
                                                className="float-end"
                                                style={{ marginTop: "-27px", marginRight: "10px" }}
                                            >
                                                <i
                                                    className="fa fa-eye"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => toggle()}
                                                ></i>
                                            </span>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="confirm_password"
                                            label={props.t("Confirm Password")}
                                            className="form-control"
                                            placeholder={props.t("Enter Confirm Password")}
                                            type={passField2 ? "text" : "password"}
                                            required
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        {passField2 ? (
                                            <span
                                                className="float-end"
                                                style={{ marginTop: "-27px", marginRight: "10px" }}
                                            >
                                                <i
                                                    className="fa fa-eye-slash"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => toggle2()}
                                                ></i>
                                            </span>
                                        ) : (
                                            <span
                                                className="float-end"
                                                style={{ marginTop: "-27px", marginRight: "10px" }}
                                            >
                                                <i
                                                    className="fa fa-eye"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => toggle2()}
                                                ></i>
                                            </span>
                                        )}
                                    </div>
                                    <Row className="mb-3">
                                        <Col className="text-end">
                                            {loading ? (
                                                <button
                                                    className="btn btn-primary  disabled"
                                                    type="button"
                                                    disabled
                                                >
                                                    {props.t("Loading")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-primary " type="submit">
                                                    {props.t("Reset")}
                                                </button>
                                            )}
                                        </Col>
                                    </Row>
                                </AvForm>
                                <div className="mt-5 text-center">
                                    <p>
                                        {props.t("Remember It ?")}{" "}
                                        <Link to="/login" className="fw-medium text-primary">
                                            {props.t("Sign In here")}
                                        </Link>
                                    </p>
                                    <p>© {new Date().getFullYear()} Clouget.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </React.Fragment>
    );
};

ResetPasswordPage.propTypes = {
    forgetError: PropTypes.any,
    forgetSuccessMsg: PropTypes.any,
    history: PropTypes.object,
    userForgetPassword: PropTypes.func,
    t: PropTypes.any,
};

const mapStatetoProps = (state) => {
    const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
    return { forgetError, forgetSuccessMsg };
};

export default withRouter(
    connect(mapStatetoProps, { userForgetPassword })(
        withTranslation()(ResetPasswordPage)
    )
);
