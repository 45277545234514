import React, { useState, useEffect } from "react";
import { Modal, Alert, Input } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function StateModal(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [state, setState] = useState("");
    const [simple_color, setsimple_color] = useState(0);
    const [color, setcolor] = useState("");

    const onDrag = (c1) => {
        setcolor(c1);
    };

    const getSingleState = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/custom_states/${props.stateId}?_method=PUT`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setState(res.data.custom_state);
                    setcolor(res.data.custom_state.color);
                    setError("");
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    props.setIsOpenModal(false);
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const submitState = (e, value) => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/custom_states/${props.stateId}?_method=PUT`,
                {
                    type_id: props.typeId,
                    name: value.name,
                    description: value.description,
                    color: color,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    props.setState(res.data.custom_state);
                    props.setIsOpenModal(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 2500);
            });
    };

    useEffect(() => {
        if (props.stateId !== undefined) {
            getSingleState();
        }
    }, []);

    return (
        <Modal size="md" isOpen={props.isOpenModal} centered={true}>
            <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                    submitState(e, v);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {props.t("New State")}
                    </h5>
                    <button
                        type="button"
                        className="btn btn-primary text-md btn-sm"
                        onClick={() => props.setIsOpenModal(false)}
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {success ? (
                        <div className="mb-3">
                            <Alert color="success">{props.t(success)}</Alert>
                        </div>
                    ) : null}

                    {error ? (
                        <div className="mb-3">
                            <Alert color="danger">{props.t(error)}</Alert>
                        </div>
                    ) : null}

                    <div className="mb-3">
                        <AvField
                            label={props.t("Name")}
                            type="text"
                            name="name"
                            value={props.t(state.name)}
                        />
                    </div>
                    <div className="mb-3">
                        <AvField
                            label={props.t("Description")}
                            type="text"
                            name="description"
                            value={state.description}
                        />
                    </div>
                    <div className="mb-3">
                        <label>{props.t("Corporate Color")}r</label>
                        <Input
                            type="text"
                            name="corporate_color"
                            className="colorpicker-default"
                            value={color}
                            onClick={() => {
                                setsimple_color(!simple_color);
                            }}
                            readOnly
                        />
                        {simple_color ? (
                            <ColorPicker
                                saturationHeight={100}
                                saturationWidth={100}
                                value={color}
                                onDrag={onDrag}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="text-end">
                        {loading ? (
                            <button
                                className="btn btn-primary disabled"
                                type="button"
                                disabled
                            >
                                {props.t("Loading")}
                            </button>
                        ) : (
                            <button className="btn btn-primary" type="submit">
                                {props.t("Submit")}
                            </button>
                        )}
                        <button
                            className="btn btn-primary ms-2"
                            type="button"
                            onClick={() => props.setIsOpenModal(false)}
                        >
                            {props.t("Cancel")}
                        </button>
                    </div>
                </div>
            </AvForm>
        </Modal>
    );
}
export default withTranslation()(StateModal);
StateModal.propTypes = {
    t: PropTypes.any,
};
