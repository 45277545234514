/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, Row, Col, Table } from "reactstrap";
import MultiType from "../AllCharts/chartjs/barchart";
import { getCurrentUser } from "../../helpers/Utils";
const axios = require("axios").default;
import Loader from "../../components/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
let taxes = "";
let fromDate = "";
let toDate = "";
const TaxSummary = (props) => {
  const [taxSummary, setTaxSummary] = useState([]);
  //   const [salesInvoicing, setSalesInvoicing] = useState([]);
  //   const [purchaseInvoicing, setPurchaseInvoicing] = useState([]);
  //   const [expenseDistribution, setExpenseDistribution] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedType, SetSelectedType] = useState("");
  const [selectDate, setSelectDate] = useState(new Date());
  const [taxSummaryHistory, setTaxSummaryHistory] = useState([]);
  const [dataTax, setDataTax] = useState([]);
  const [dataIncomeTax, setDataIncomeTax] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const startYear = 2017;
  const endYear = new Date().getFullYear();
  const yearOptions = [];
  for (let year = endYear; year >= startYear; year--) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  const overviewGraph = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/tax-summary?type=taxSummary`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setTaxSummary(res.data.data.tax_Summary);
        }
        setLoader(false);
      });
  };
  function handleSelectedType(e) {
    SetSelectedType(e);
  }
  const getHistory = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/tax-summary?type=taxes`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setTaxSummaryHistory(res.data.data);
        }
      });
  };
  const getExport = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/tax-summary?type=taxes&export=download`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          window.location.href = res.data.url;
        }
      });
  };
  const getTaxes = () => {
    setDataTax([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/cosumption-taxes`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDataTax(res.data.taxes);
        }
      });
  };
  const getIncomeTax = () => {
    setDataIncomeTax([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/income-taxes`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDataIncomeTax(res.data.taxes);
          res.data.taxes?.map((item) => {
            if (item.by_default === "1") {
              setDefaultIncomeTax(item.tax);
            }
          });
        }
      });
  };
  const searchData = () => {
    setTaxSummary([]);
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/tax-summary?type=taxSummary&name=${taxes}&startDate=${fromDate}&endDate=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setTaxSummary(res.data.data.tax_Summary);
        }
        setLoader(false);
      });
    setTaxSummaryHistory([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/tax-summary?name=${taxes}&startDate=${fromDate}&endDate=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setTaxSummaryHistory(res.data.data);
        }
      });
  };
  const getTax = (e) => {
    if (e.target.name == "taxes") {
      taxes = e.target.value;
    }
    searchData();
  };
  const getDate = (e) => {
    if (e.target.name == "start_date") {
      fromDate = e.target.value;
      toDate = e.target.value;
      searchData();
    } else if (e.target.name == "from_date") {
      fromDate = e.target.value;
    } else if (e.target.name == "to_date") {
      toDate = e.target.value;
      searchData();
    } else if (e.target.name == "annual") {
      if (e.target.value == "all_date") {
        fromDate = "";
        toDate = "";
        searchData();
      }
    } else if (e.target.name == "year") {
      fromDate = `01-01-${e.target.value}`;
      toDate = `31-12-${e.target.value}`;
      searchData();
    } else if (e.target.name == "month") {
      if (e.target.value == "january") {
        fromDate = `01-01-${year}`;
        toDate = `31-01-${year}`;
      } else if (e.target.value == "february") {
        fromDate = `01-02-${year}`;
        toDate = `28-02-${year}`;
      } else if (e.target.value == "march") {
        fromDate = `01-03-${year}`;
        toDate = `31-03-${year}`;
      } else if (e.target.value == "april") {
        fromDate = `01-04-${year}`;
        toDate = `30-04-${year}`;
      } else if (e.target.value == "may") {
        fromDate = `01-05-${year}`;
        toDate = `31-05-${year}`;
      } else if (e.target.value == "june") {
        fromDate = `01-06-${year}`;
        toDate = `30-06-${year}`;
      } else if (e.target.value == "july") {
        fromDate = `01-07-${year}`;
        toDate = `31-07-${year}`;
      } else if (e.target.value == "august") {
        fromDate = `01-08-${year}`;
        toDate = `31-08-${year}`;
      } else if (e.target.value == "september") {
        fromDate = `01-09-${year}`;
        toDate = `30-09-${year}`;
      } else if (e.target.value == "october") {
        fromDate = `01-10-${year}`;
        toDate = `31-10-${year}`;
      } else if (e.target.value == "november") {
        fromDate = `01-11-${year}`;
        toDate = `30-11-${year}`;
      } else if (e.target.value == "december") {
        fromDate = `01-12-${year}`;
        toDate = `31-12-${year}`;
      }
      searchData();
    } else if (e.target.name == "quarter") {
      if (e.target.value == "first_quarter") {
        fromDate = `01-01-${year}`;
        toDate = `31-03-${year}`;
      } else if (e.target.value == "second_quarter") {
        fromDate = `01-04-${year}`;
        toDate = `30-06-${year}`;
      } else if (e.target.value == "third_quarter") {
        fromDate = `01-07-${year}`;
        toDate = `30-09-${year}`;
      } else if (e.target.value == "forth_quarter") {
        fromDate = `01-10-${year}`;
        toDate = `31-12-${year}`;
      }
      searchData();
    } else if (e.target.name == "semester") {
      if (e.target.value == "first_semester") {
        fromDate = `01-01-${year}`;
        toDate = `30-06-${year}`;
      } else if (e.target.value == "second_semester") {
        fromDate = `01-07-${year}`;
        toDate = `31-12-${year}`;
      }
      searchData();
    }
  };
  useEffect(() => {
    overviewGraph();
    getHistory();
    getTaxes();
    getIncomeTax();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports - TaxSummary | Clouget</title>
        </MetaTags>
        <Container>
          <div className="page-title-box">
            <AvForm>
              <Row>
                <Col md={2}>
                  <AvField
                    type="select"
                    name="annual"
                    value={selectedType}
                    onChange={(e) => handleSelectedType(e.target.value)}
                  >
                    <option value="year">{props.t("Year")}</option>
                    <option value="semester">{props.t("Semester")}</option>
                    <option value="quarter">{props.t("Quarter")}</option>
                    <option value="month">{props.t("Month")}</option>
                    <option value="day">{props.t("Day")}</option>
                    <option value="between_dates">
                      {props.t("Between dates")}
                    </option>
                    <option value="all_dates">{props.t("All Dates")}</option>
                  </AvField>
                </Col>
                <Col md={2}>
                  <AvField
                    type="select"
                    name="taxes"
                    onChange={(e) => getTax(e)}
                  >
                    {dataTax?.map((item, i) => (
                      <option value="consumption_tax" key={i}>
                        {item.primary_name}
                      </option>
                    ))}
                    {dataIncomeTax?.map((item, i) => (
                      <option value="income_tax" key={i}>
                        {item.name}
                      </option>
                    ))}
                  </AvField>
                </Col>
                <Col md={8}>
                  <div className="float-end d-flex">
                    <button
                      className="btn btn-primary"
                      onClick={() => getExport()}
                    >
                      {props.t("Export")}
                    </button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <div className="mt-2">
                    {selectedType == "semester" ? (
                      <>
                        <AvField
                          type="select"
                          name="semester"
                          onChange={(e) => getDate(e)}
                        >
                          <option value="" disabled>
                            {props.t("Select semester")}
                          </option>
                          <option value="first_semester">
                            {props.t("1st semester")}
                          </option>
                          <option value="second_semester">
                            {props.t("2nd semester")}
                          </option>
                        </AvField>
                      </>
                    ) : selectedType == "quarter" ? (
                      <>
                        <AvField
                          type="select"
                          name="quarter"
                          onChange={(e) => getDate(e)}
                        >
                          <option value="" disabled>
                            {props.t("Select quarter")}
                          </option>
                          <option value="first_quarter">
                            {props.t("1st quarter")}
                          </option>
                          <option value="second_quarter">
                            {props.t("2nd quarter")}
                          </option>
                          <option value="third_quarter">
                            {props.t("3rd quarter")}
                          </option>
                          <option value="forth_quarter">
                            {props.t("4th quarter")}
                          </option>
                        </AvField>
                      </>
                    ) : selectedType == "day" ? (
                      <>
                        <AvField
                          onChange={(e) => getDate(e)}
                          dateFormat="MMMM d, yyyy"
                          type="date"
                          name="start_date"
                          autoComplete="off"
                          className="is-untouched is-pristine av-valid form-control"
                        />
                      </>
                    ) : selectedType == "between_dates" ? (
                      <>
                        <label>{props.t("From")}</label>
                        <AvField
                          onChange={(e) => getDate(e)}
                          dateFormat="MMMM d, yyyy"
                          name="from_date"
                          type="date"
                          autoComplete="off"
                          className="is-untouched is-pristine av-valid form-control"
                        />
                        <label>{props.t("To")}</label>
                        <AvField
                          onChange={(e) => getDate(e)}
                          dateFormat="MMMM d, yyyy"
                          name="to_date"
                          type="date"
                          autoComplete="off"
                          className="is-untouched is-pristine av-valid form-control"
                        />
                      </>
                    ) : selectedType == "month" ? (
                      <>
                        <AvField
                          type="select"
                          name="month"
                          onChange={(e) => getDate(e)}
                        >
                          <option value="" disabled>
                            {props.t("Select month")}
                          </option>
                          <option value="january">January</option>
                          <option value="february">February</option>
                          <option value="march">March</option>
                          <option value="april">April</option>
                          <option value="may">May</option>
                          <option value="june">June</option>
                          <option value="July">July</option>
                          <option value="august">August</option>
                          <option value="september">September</option>
                          <option value="october">October</option>
                          <option value="november">November</option>
                          <option value="december">December</option>
                        </AvField>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <select
                    id="year-select"
                    name="year"
                    className="is-untouched is-pristine av-valid form-control mt-2"
                    onChange={(e) => {
                      getDate(e), setYear(e.target.value);
                    }}
                  >
                    {yearOptions}
                  </select>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
        <Container>
          <div className="page-title-box">
            <h6 className="page-title">{props.t("Tax Summary")} </h6>
          </div>
          <Card>
            <CardBody>
              {loader ? <Loader /> : <MultiType data={taxSummary} />}
            </CardBody>
          </Card>
        </Container>
        <Container>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table striped className="align-middle">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{props.t("Exempt from IVA")}</th>
                      <th>{props.t("Collected")}</th>
                      <th>{props.t("Paid")}</th>
                      <th>{props.t("Total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxSummaryHistory &&
                      taxSummaryHistory.map((item, i) => (
                        <tr key={i} style={{ cursor: "pointer" }}>
                          <td>
                            <br></br>
                            {item.Subtotal}
                            <br></br>
                            {item.Tax}
                          </td>
                          <td>{item.vat}</td>
                          <td>
                            {item.Collected}
                            <br></br>
                            {item.collected}
                            <br></br>
                            {item.ctax}
                          </td>
                          <td>
                            {item.Paid}
                            <br></br>
                            {item.paid}
                            <br></br>
                            {item.ptax}
                          </td>
                          <td>
                            {item.Total}
                            <br></br>
                            {item.total}
                            <br></br>
                            {item.ttax}
                          </td>
                        </tr>
                      ))}
                    {/* {taxSummaryHistory1 &&
                    taxSummaryHistory1.map((item, i) => (
                      <tr key={i}>
                        <td>{item.vat}</td>
                        <td>
                          {item.collected}
                          <br></br>
                          {item.ctax}
                        </td>
                        <td>
                          {item.paid}
                          <br></br>
                          {item.ptax}
                        </td>
                        <td>
                          {item.total}
                          <br></br>
                          {item.ttax}
                        </td>
                      </tr>
                    ))} */}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(TaxSummary);
TaxSummary.propTypes = {
  t: PropTypes.any,
};
