/* eslint-disable react/prop-types */
import React, { useState } from "react";
const axios = require("axios").default;
import { Modal, Table } from "reactstrap";
import uploadImg from "../../assets/images/def-img.svg";
import ServiceModalPP from "../Product/serrvicePopup";
import CreateProductModal from "../Product/createProductModal";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function ExpList(props) {
  const [serviceModal, setServiceModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const getItem = (id, type) => {
    // props.setSelectClientId(id);
    props.selectProduct(id, props.index, type);
    // if (props.setSelectedClient !== undefined) {
    // 	props.setSelectedClient({
    // 		label: name,
    // 		value: id,
    // 	});
    // }
    props.setSelectItemModal(false);
    props.setMainFormDisabled(false);
  };

  return (
    <>
      {productModal ? (
        <CreateProductModal
          productModal={productModal}
          setProductModal={setProductModal}
          getProduct={props.getProduct}
        />
      ) : (
        ""
      )}
      {serviceModal ? (
        <ServiceModalPP
          serviceModal={serviceModal}
          setServiceModal={setServiceModal}
          getServices={props.getServices}
        />
      ) : (
        ""
      )}
      <Modal size="xl" isOpen="true" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {props.t("Select catalog item")}
          </h5>
          <button
            type="button"
            className="cross__btn btn btn-primary waves-effect waves-light"
            // onClick={() => props.setSelectItemModal(false)}
            onClick={() => {
              props.setSelectItemModal(false), props.setMainFormDisabled(false);
            }}
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div className="modal-body table-responsive">
          <div className="d-flex mb-3 float-end">
            <div className="me-3 ">
              <input
                className="form-control"
                type="search"
                placeholder={props.t("Search here")}
                onChange={(e) => {
                  props.getProductData(e.target.value);
                  // props.getServiceData(e.target.value),
                  // props.getAllExpenses(e.target.value);
                }}
              />
            </div>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => setProductModal(true)}
              >
                <i className="fa fa-plus me-2"></i>
                {props.t("New Product")}
              </button>
              <button
                className="btn btn-primary ms-2"
                onClick={() => setServiceModal(true)}
              >
                <i className="fa fa-plus me-2"></i>
                {props.t("New Service")}
              </button>
            </div>
          </div>
          <Table hover className="align-middle">
            <thead>
              <tr>
                <th>{props.t("Image")}</th>
                <th>{props.t("Reference")}</th>
                <th>{props.t("Name")}</th>
                <th>{props.t("Stock")}</th>
                <th>{props.t("Virtual Stock")}</th>
                <th>{props.t("Price of sales")}</th>
                <th>{props.t("Promotional")}</th>
              </tr>
            </thead>
            <tbody>
              {props.data2.map((item, i) => (
                <tr key={i} onClick={() => getItem(item.id, "SER")}>
                  <td role="button" className="py-1">
                    <img
                      height="40"
                      width="40"
                      style={{ objectFit: "cover" }}
                      src={item.image ? item.image : uploadImg}
                    />
                  </td>
                  <td role="button">{item.reference}</td>
                  <td role="button">{item.name}</td>
                  <td role="button">{item.stock}</td>
                  <td role="button">{item.virtual_stock}</td>
                  <td role="button">{item.price}</td>
                  <td role="button">{item.promotional}</td>
                </tr>
              ))}
              {props.data.map((item, i) => (
                <tr key={i} onClick={() => getItem(item.id, "PRO")}>
                  <td role="button" className="py-1">
                    <img
                      height="40"
                      width="40"
                      style={{ objectFit: "cover" }}
                      src={item.image ? item.image : uploadImg}
                    />
                  </td>
                  <td role="button">{item.reference}</td>
                  <td role="button">{item.name}</td>
                  <td role="button">{item.stock}</td>
                  <td role="button">{item.virtual_stock}</td>
                  <td role="button">{item.price}</td>
                  <td role="button">{item.promotional}</td>
                </tr>
              ))}
              {props.dataExp.map((item, i) => (
                <tr key={i} onClick={() => getItem(item.id, "EAI")}>
                  <td role="button" className="py-1">
                    <img
                      height="40"
                      width="40"
                      style={{ objectFit: "cover" }}
                      src={item.image ? item.image : uploadImg}
                    />
                  </td>
                  <td role="button">{item.reference}</td>
                  <td role="button">{item.name}</td>
                  <td role="button">{item.stock}</td>
                  <td role="button">{item.virtual_stock}</td>
                  <td role="button">{item.price}</td>
                  <td role="button">{item.promotional}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal>
    </>
  );
}
export default withTranslation()(ExpList);
ExpList.propTypes = {
  t: PropTypes.any,
};
