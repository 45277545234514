/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Table, Card, CardBody, Row, Col } from "reactstrap";
import LineChart from "../../AllCharts/chartjs/linechart";
import { getCurrentUser } from "../../../helpers/Utils";
import Loader from "../../../components/Loader";
import { AvForm, AvField } from "availity-reactstrap-validation";
const axios = require("axios").default;
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
let isModule = "";
let selectedYear = "";
let dateType = "";
let dateSubType = "";
let selectedMonth = "";

const OfProfit = (props) => {
  //   const [data, setData] = useState([]);

  const [invoiced, setInvoiced] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedType, setSelectedType] = useState("all_dates");
  const [evolutionHistory, setEvolutionHistory] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const startYear = 2017;
  const endYear = new Date().getFullYear();
  const yearOptions = [];

  for (let year = endYear; year >= startYear; year--) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const overviewGraph = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/of-profit?type=graph`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setInvoiced(res.data.data);
        }
        setLoader(false);
      });
  };
  function handleSelectedType(e) {
    setSelectedType(e);
  }
  const getHistory = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/of-profit?type=history`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setEvolutionHistory(res.data.data);
        }
      });
  };
  const getExport = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/of-profit?type=history&export=download`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          window.location.href = res.data.url;
        }
      });
  };
  const searchData = () => {
    setInvoiced([]);
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/of-profit?type=graph&module=${isModule}&year=${selectedYear}&date_type=${dateType}&date_sub_type=${dateSubType}&month=${selectedMonth}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setInvoiced(res.data.data);
        }
        setLoader(false);
      });
    setEvolutionHistory([]);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/reports/of-profit?type=history&module=${isModule}&year=${selectedYear}&date_type=${dateType}&date_sub_type=${dateSubType}&month=${selectedMonth}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setEvolutionHistory(res.data.data);
        }
      });
  };
  const getDate = (e) => {
    if (e.target.name == "date_type") {
      if (e.target.value == "all_dates") {
        dateType = "all_dates";
        dateSubType = "year";
        selectedMonth = "";
        setCurrentMonth(new Date().getMonth() + 1);
        selectedYear = "";
        searchData();
      } else if (e.target.value == "month") {
        dateType = "month";
        dateSubType = "day";
        selectedMonth = new Date().getMonth() + 1;
        setCurrentMonth(new Date().getMonth() + 1);
        selectedYear = "";
        searchData();
      }
    } else if (e.target.name == "year") {
      selectedYear = e.target.value;
      dateSubType = "quarter";
      searchData();
    } else if (e.target.name == "month") {
      setCurrentMonth(e.target.value);
      if (e.target.value == "1") {
        selectedMonth = 1;
      } else if (e.target.value == "2") {
        selectedMonth = 2;
      } else if (e.target.value == "3") {
        selectedMonth = 3;
      } else if (e.target.value == "4") {
        selectedMonth = 4;
      } else if (e.target.value == "5") {
        selectedMonth = 5;
      } else if (e.target.value == "6") {
        selectedMonth = 6;
      } else if (e.target.value == "7") {
        selectedMonth = 7;
      } else if (e.target.value == "8") {
        selectedMonth = 8;
      } else if (e.target.value == "9") {
        selectedMonth = 9;
      } else if (e.target.value == "10") {
        selectedMonth = 10;
      } else if (e.target.value == "11") {
        selectedMonth = 11;
      } else if (e.target.value == "12") {
        selectedMonth = 12;
      }
      searchData();
    } else if (e.target.name == "date_sub_type") {
      dateSubType = e.target.value;
      searchData();
    } else if (e.target.name == "module") {
      isModule = e.target.value;
      searchData();
    }
  };
  useEffect(() => {
    overviewGraph();
    getHistory();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>invoice - ByAgent| Clouget</title>
        </MetaTags>
        <Container>
          <div className="page-title-box">
            <AvForm>
              <Row>
                <Col md={2}>
                  <AvField
                    type="select"
                    name="date_type"
                    value={selectedType}
                    onChange={(e) => {
                      handleSelectedType(e.target.value), getDate(e);
                    }}
                  >
                    <option value="year">{props.t("Year")}</option>
                    <option value="month">{props.t("Month")}</option>
                    <option value="all_dates">{props.t("All Dates")}</option>
                  </AvField>
                </Col>
                <Col md={2}>
                  <AvField
                    type="select"
                    name="date_sub_type"
                    onChange={(e) => getDate(e)}
                  >
                    {selectedType == "year" ? (
                      <>
                        <option value="quarter">{props.t("Quarter")}</option>
                        <option value="month">{props.t("Month")}</option>
                      </>
                    ) : (
                      ""
                    )}
                    {selectedType === "month" ? (
                      <option value="day">{props.t("Day")}</option>
                    ) : (
                      ""
                    )}
                    {selectedType === "all_dates" ? (
                      <>
                        <option value="year">{props.t("Year")}</option>
                        <option value="quarter">{props.t("Quarter")}</option>
                        <option value="month">{props.t("Month")}</option>
                      </>
                    ) : (
                      ""
                    )}
                  </AvField>
                </Col>
                <Col md={2}>
                  <AvField
                    type="select"
                    name="module"
                    onChange={(e) => getDate(e)}
                  >
                    <option value="">{props.t("All")}</option>
                    <option value="Sales">{props.t("Sales")}</option>
                    <option value="Expenses">{props.t("Expenses")}</option>
                    <option value="Profit">{props.t("Profit")}</option>
                  </AvField>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <div className="mt-2">
                    {selectedType == "year" ? (
                      <select
                        id="year-select"
                        name="year"
                        className="is-untouched is-pristine av-valid form-control"
                        onChange={(e) => {
                          getDate(e), setYear(e.target.value);
                        }}
                      >
                        {yearOptions}
                      </select>
                    ) : selectedType == "month" ? (
                      <AvField
                        type="select"
                        name="month"
                        value={currentMonth}
                        onChange={(e) => getDate(e)}
                      >
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </AvField>
                    ) : selectedType == "all_dates" ? (
                      ""
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
        <Container>
          <div className="page-title-box">
            <h6 className="page-title">{props.t("Of Profit")}</h6>
          </div>
          <Card>
            <CardBody>
              {loader ? <Loader /> : <LineChart data={invoiced} />}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div>
                <button className="btn btn-primary" onClick={() => getExport()}>
                  {props.t("Export")}
                </button>
              </div>
              <div className="table-responsive">
                <Table striped className="align-middle">
                  <thead>
                    <tr>
                      <th>{props.t("Period")}</th>
                      <th>{props.t("Sales")}</th>
                      <th>{props.t("Expenses")}</th>
                      <th>{props.t("Profit")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {evolutionHistory?.map((item, i) => (
                      <tr key={i} style={{ cursor: "pointer" }}>
                        <td>{item.period}</td>
                        <td>{item.sales}</td>
                        <td>{item.expense}</td>
                        <td>{item.profit}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {loader}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(OfProfit);
OfProfit.propTypes = {
  t: PropTypes.any,
};
