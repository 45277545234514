/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Table,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Modal,
    Input,
} from "reactstrap";
import classnames from "classnames";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import DatePicker from "react-datepicker";
// import ClientAssets from "../Related/clientAssets";
import "react-datepicker/dist/react-datepicker.css";
import uploadImg from "../../assets/images/def-img.svg";
import ClientAssetsAttachment from "../../components/Attachments/client-assets-attachment";
import SendAttachment from "../SendAttachments/index";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import CreateTrialModal from "../plans/trialModal";
let clientName = "";

const EditAssests = (props) => {
    const { id } = useParams();
    const [userId] = useState(parseInt(id, 10));
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [isEditable, setIsEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [assests, setAssests] = useState("");
    const [maintanceChecked, setMaintanceChecked] = useState(false);
    const [referenceType, setReferenceType] = useState("AST");
    const [clientId, setClientId] = useState("");
    const [data, setData] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [image, setImage] = useState("");
    const [isOpenModals, setIsOpenModals] = useState(false);
    const [document, setDocument] = useState("");
    const [fileDocument, setFileDocument] = useState("");
    const [attachDocument, setAttachDocument] = useState([]);
    //   const [related, setRelated] = useState("");
    const [allRef, setAllRef] = useState([]);
    const [defaultRef, setDefaultRef] = useState("");
    const [refNum, setRefNum] = useState("");
    const [isAttachOpenModal, setIsAttachOpenModal] = useState(false);
    const [attach, setAttach] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [error2, setError2] = useState("");
    const [attachEmail, setAttachEmail] = useState("");
    const [attachEmail2, setAttachEmail2] = useState("");
    const [attachEmail3, setAttachEmail3] = useState("");
    const [emailMessage2, setEmailMessage2] = useState("");
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const getSingleAsset = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets/${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAssests(res.data.client_asset);
                setRefNum(res.data.client_asset.reference_number);
                setClientId(res.data.client_asset.client_id);
                setImgPreview(res.data.client_asset.main_image);
                if (res.data.client_asset.subject_to_maintenance === 1) {
                    setMaintanceChecked(true);
                } else {
                    setMaintanceChecked(false);
                }
            });
    };
    //   const RelatedAll = () => {
    //     axios
    //       .get(
    //         `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
    //           "companyId"
    //         )}/related`,
    //         // )}/client-assets`,
    //         {
    //           headers: {
    //             "content-type": "application/json",
    //             Authorization: `Bearer ${getCurrentUser().token}`,
    //           },
    //         }
    //       )
    //       .then(function (res) {
    //         setRelated(res.data.data);
    //       });
    //   };
    const getReferences = () => {
        setAllRef([]);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/references?type=Client Asset`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                // setLoader("");
                if (res.data.status) {
                    setError("");
                    setAllRef(res.data.reference);
                    res.data.reference?.map((item) => {
                        if (item.by_default === "1") {
                            setDefaultRef(item.prefix);
                        }
                    });
                } else {
                    //   setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getReferences();
        getSingleAsset();
        // RelatedAll();
    }, []);

    const getType = (e) => {
        setReferenceType(e.target.value);
        setRefNum("");
    };

    const updateAssets = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("reference", value.reference);
        formData.append("reference_number", value.reference_number);
        formData.append("name", value.name);
        formData.append("client_id", clientId);
        formData.append("address", value.address !== null ? value.address : "");
        formData.append(
            "identifier",
            value.identifier !== null ? value.identifier : ""
        );
        formData.append("main_image", image);
        formData.append(
            "serial_number",
            value.serial_number !== null ? value.serial_number : ""
        );
        formData.append("brand", value.brand !== null ? value.brand : "");
        formData.append("model", value.model !== null ? value.model : "");
        formData.append("subject_to_maintenance", maintanceChecked ? 1 : 0);
        formData.append("start_of_warranty", e.target.start_of_warranty.value);
        formData.append("end_of_warranty", e.target.end_of_warranty.value);
        formData.append(
            "description",
            value.description !== null ? value.description : ""
        );
        formData.append(
            "private_comments",
            e.target.private_comments.value !== null ? e.target.private_comments.value : ""
        );
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-assets/${userId}?_method=PUT`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setIsEditable(false);
                    setSuccess(res.data.message);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const selectClient = (e, item) => {
        clientName = item;
        setClientId(item.id);
        setIsOpenModal(false);
    };

    const getContactTemplate = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/clients`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setError("");
                    setData(res.data.clients);
                } else {
                    setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getContactTemplate();
    }, []);

    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    const createAttachment = (e, value) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("document", document);
        formData.append("description", e.target.description.value);
        formData.append("type", "attachments");
        formData.append("asset_id", userId);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-asset-attachments`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    AllAttach();
                    setSuccess(res.data.message);
                    setIsOpenModals(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const AllAttach = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-asset-attachments?asset_id=${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAttachDocument(res.data.client_attachments);
            });
    };

    useEffect(() => {
        AllAttach();
    }, []);

    const filePreview = (e) => {
        setDocument(e.target.files[0]);
        setFileDocument(URL.createObjectURL(e.target.files[0]));
    };
    const getSendAttachment = () => {
        if (selectedIds.length < 1) {
            setError2("Please select document");
            setTimeout(() => {
                setError2("");
            }, 4000);
            return false;
        }
        setIsAttachOpenModal(true);
    };
    const getSendAttachment2 = () => {
        const formData = new FormData();
        formData.append("send_to", attachEmail);
        formData.append("cc", attachEmail2);
        formData.append("bcc", attachEmail3);
        formData.append("body", emailMessage2);
        formData.append("ids", selectedIds);
        formData.append("name", selectedName);
        formData.append("type", "supplier_attachments");
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/send-attachments-email`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setSuccess(res.data.message);
                    setError("");
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 3000);
            });
    };

    return (
        <React.Fragment>
            <SendAttachment
                isAttachOpenModal={isAttachOpenModal}
                setIsAttachOpenModal={setIsAttachOpenModal}
                attach={attach}
                setAttach={setAttach}
                setAttachEmail={setAttachEmail}
                setAttachEmail2={setAttachEmail2}
                setAttachEmail3={setAttachEmail3}
                getSendAttachment2={getSendAttachment2}
                attachDocument={attachDocument}
                emailMessage2={emailMessage2}
                setEmailMessage2={setEmailMessage2}
                success={success}
                error={error}
            />
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <div className="page-content">
                <MetaTags>
                    <title>Create Assets | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                updateAssets(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center mt-3">
                                    <Col md={6}>
                                        <h6 className="page-title">{props.t("Update Assests")} </h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-none d-md-block">
                                            <Link
                                                type="button"
                                                to="/assets"
                                                className="btn btn-primary"
                                            >
                                                {props.t("Back")}
                                            </Link>
                                            {isEditable ? (
                                                <>
                                                    {loading ? (
                                                        <button
                                                            className="btn btn-primary  disabled"
                                                            type="button"
                                                            disabled
                                                        >
                                                            {props.t("Loading")}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-primary ms-2"
                                                            type="submit"
                                                        >
                                                            {props.t("Update")}
                                                        </button>
                                                    )}
                                                    <span
                                                        onClick={() => setIsEditable(false)}
                                                        className="btn btn-primary ms-2"
                                                    >
                                                        {props.t("Cancel")}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {getPermission().client_assets.client_assets.edit
                                                        .is_checked === 1 ? (
                                                        <span
                                                            onClick={() => createNew()}
                                                            className="btn btn-primary ms-2"
                                                        >
                                                            {props.t("Edit")}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{success}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <label>{props.t("Reference")}:</label>
                                                <div className="d-flex">
                                                    <AvField
                                                        style={{
                                                            width: "100px",
                                                        }}
                                                        type="select"
                                                        name="reference"
                                                        value={
                                                            assests?.reference !== null
                                                                ? assests?.reference
                                                                : ""
                                                        }
                                                        onChange={(e) => getType(e)}
                                                        disabled={isEditable ? false : true}
                                                    >
                                                        {allRef &&
                                                            allRef.map((item, i) => (
                                                                <option key={i} value={item.prefix}>
                                                                    {item.prefix}
                                                                </option>
                                                            ))}
                                                        <option value="mannual">
                                                            {props.t("Mannual")}
                                                        </option>
                                                    </AvField>
                                                    <div className="w-100 ps-3">
                                                        <AvField
                                                            className="w-100 quantity"
                                                            type="number"
                                                            // className="quantity"
                                                            name="reference_number"
                                                            disabled={isEditable ? false : true}
                                                            //   value={assests && assests.reference_number}
                                                            value={refNum !== null ? refNum : ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    label={props.t("Asset Name")}
                                                    type="text"
                                                    name="name"
                                                    readOnly={isEditable ? false : true}
                                                    value={assests?.name !== null ? assests?.name : ""}
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Identifier")}
                                                    type="text"
                                                    name="identifier"
                                                    readOnly={isEditable ? false : true}
                                                    value={
                                                        assests?.identifier !== null
                                                            ? assests?.identifier
                                                            : ""
                                                    }
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Serial Number")}
                                                    type="text"
                                                    name="serial_number"
                                                    readOnly={isEditable ? false : true}
                                                    value={
                                                        assests?.serial_number !== null
                                                            ? assests?.serial_number
                                                            : ""
                                                    }
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Brand")}
                                                    type="text"
                                                    name="brand"
                                                    readOnly={isEditable ? false : true}
                                                    value={assests?.brand !== null ? assests?.brand : ""}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Model")}
                                                    type="text"
                                                    name="model"
                                                    readOnly={isEditable ? false : true}
                                                    value={assests?.model !== null ? assests?.model : ""}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Client")}
                                                    type="text"
                                                    name="client_id"
                                                    // readOnly={
                                                    //     isEditable
                                                    //         ? false
                                                    //         : true
                                                    // }
                                                    disabled={isEditable ? "" : true}
                                                    value={assests.client_name}
                                                    onClick={() => setIsOpenModal(true)}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    label={props.t("Address")}
                                                    type="text"
                                                    name="address"
                                                    readOnly={isEditable ? false : true}
                                                    value={
                                                        assests?.address !== null ? assests?.address : ""
                                                    }
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Subject to Maintenance")}</label>
                                                <Input
                                                    disabled={isEditable ? "" : true}
                                                    type="checkbox"
                                                    className="ms-2"
                                                    name="subject_to_maintenance"
                                                    onChange={() =>
                                                        setMaintanceChecked(!maintanceChecked)
                                                    }
                                                    checked={maintanceChecked ? "checked" : ""}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Start Of Warranty")}</label>
                                                <AvField
                                                    type="date"
                                                    name="start_of_warranty"
                                                    readOnly={isEditable ? false : true}
                                                    className="form-control"
                                                    value={
                                                        assests?.start_of_warranty !== null
                                                            ? assests?.start_of_warranty
                                                            : ""
                                                    }
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("End Of Warranty")}</label>
                                                <AvField
                                                    type="date"
                                                    name="end_of_warranty"
                                                    readOnly={isEditable ? false : true}
                                                    className="form-control"
                                                    value={
                                                        assests?.end_of_warranty !== null
                                                            ? assests?.end_of_warranty
                                                            : ""
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("General")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Images")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "3",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("3");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Attachments")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames({
                                                    active: customActiveTab === "4",
                                                })}`}
                                                onClick={() => {
                                                    toggleCustom("4");
                                                }}
                                            >
                                                <span className="d-none d-sm-block">
                                                    {props.t("Related")}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="p-3">
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Description")}
                                                            type="textarea"
                                                            name="description"
                                                            readOnly={isEditable ? false : true}
                                                            value={
                                                                assests?.description !== null
                                                                    ? assests?.description
                                                                    : ""
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Private Comments")}
                                                            type="textarea"
                                                            name="private_comments"
                                                            readOnly={isEditable ? false : true}
                                                            value={
                                                                assests?.private_comments !== null
                                                                    ? assests?.private_comments
                                                                    : ""
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <AvField
                                                            label={props.t("Created from")}
                                                            type="text"
                                                            name="created_from"
                                                            value="Web"
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col lg="3">
                                                    <div className="form-inline company_img img-flex">
                                                        <input
                                                            className="hidden d-none"
                                                            onChange={(e) => setComImg(e)}
                                                            disabled={isEditable ? false : true}
                                                            type="file"
                                                            id="comImg"
                                                            name="main_image"
                                                        />
                                                        <label className="company_img-box" htmlFor="comImg">
                                                            {assests && assests.main_image ? (
                                                                <img src={imgPreview} />
                                                            ) : (
                                                                <img src={imgPreview} />
                                                            )}
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Row>
                                                <Col md={12}>
                                                    <div className="attach_btn select-from-row">
                                                        <div
                                                            className="float-end d-none d-md-block btn btn-primary ms-2"
                                                            onClick={() => setIsOpenModals(true)}
                                                        >
                                                            {props.t("New")}
                                                        </div>
                                                        <div
                                                            className="float-end d-none d-md-block btn btn-primary"
                                                            onClick={() => getSendAttachment()}
                                                        >
                                                            {props.t("Send via Email")}
                                                        </div>
                                                        <div className="table-responsive">
                                                            <Table striped className="align-middle mt-5">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{props.t("Name")}</th>
                                                                        <th>{props.t("Description")}</th>
                                                                        <th>{props.t("Upload Date")}</th>
                                                                        <th>{props.t("Activity")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {attachDocument &&
                                                                        attachDocument.map((item, i) => (
                                                                            <ClientAssetsAttachment
                                                                                Attached={item}
                                                                                key={i}
                                                                                selectedIds={selectedIds}
                                                                                setSelectedIds={setSelectedIds}
                                                                                selectedName={selectedName}
                                                                                setSelectedName={setSelectedName}
                                                                            />
                                                                        ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Table striped className="align-middle mt-5">
                                                {/* <thead>
                          <tr>
                            <th>Reference</th>
                            <th>Client</th>
                            <th>Title</th>
                            <th>Created By</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Activity</th>
                          </tr>
                        </thead> */}
                                                {/* <tbody>
                          {related &&
                            related.map((item, i) => (
                              <tr>
                                <td>
                                  {item.reference}
                                  {item.reference_number}
                                </td>
                                <td>{item.client_name}</td>
                                <td>{item.title}</td>
                                <td>{getCurrentUser().email}</td>
                                <td>{item.status}</td>
                                <td>{}</td>
                                <td>{item.date}</td>
                                <td>{item.amount}</td>
                              </tr>
                            ))}
                        </tbody> */}
                                            </Table>
                                            {/* <ClientAssets /> */}
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                    <Modal size="lg" isOpen={isOpenModal} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("Clients")}
                            </h5>
                        </div>
                        <div className="modal-body p-0">
                            <Card>
                                <CardBody>
                                    <div className="overflow-auto w-100">
                                        <Table striped className="align-middle">
                                            <thead>
                                                <tr>
                                                    <th>{props.t("Reference")}</th>
                                                    <th>{props.t("Legal Name")}</th>
                                                    <th>{props.t("Name")}</th>
                                                    <th>{props.t("Ced/Ruc")}</th>
                                                    <th>{props.t("Phone")}</th>
                                                    <th>{props.t("Email")}</th>
                                                    <th>{props.t("Address")}</th>
                                                    <th>{props.t("Activity")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data &&
                                                    data.map((item, i) => (
                                                        <tr onClick={(e) => selectClient(e, item)} key={i}>
                                                            <td>{item.reference}</td>
                                                            <td>{item.legal_name}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.tin}</td>
                                                            <td>{item.phone_1}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.address}</td>
                                                            <td>{getCurrentUser().email}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="modal-footer">
                            <div className="text-end">
                                <button
                                    className="btn btn-primary ms-2"
                                    type="button"
                                    onClick={() => setIsOpenModal(false)}
                                >
                                    {props.t("Cancel")}
                                </button>
                            </div>
                        </div>
                    </Modal>
                    <Modal size="lg" isOpen={isOpenModals} centered={true}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myLargeModalLabel">
                                {props.t("New Document")}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => createAttachment(e)}>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Document")}</label>
                                            <input
                                                type="file"
                                                className="ms-3"
                                                name="document"
                                                onChange={filePreview}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label>{props.t("Description")}</label>
                                            <textarea
                                                name="description"
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="modal-footer">
                                    <div className="text-end">
                                        <button className="btn btn-primary" type="submit">
                                            {props.t("Submit")}
                                        </button>
                                        <button
                                            className="btn btn-primary ms-2"
                                            type="button"
                                            onClick={() => setIsOpenModals(false)}
                                        >
                                            {props.t("Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(EditAssests);
EditAssests.propTypes = {
    t: PropTypes.any,
};
