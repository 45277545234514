import React, { useState, useMemo, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
    AvForm,
    AvField,
    AvRadioGroup,
    AvRadio,
    AvCheckboxGroup,
    AvCheckbox,
} from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Table,
} from "reactstrap";
import uploadImg from "../../assets/images/def-img.svg";
import classnames from "classnames";
import countryList from "react-select-country-list";
import Select from "react-select";
import {
    getCurrentUser,
    getPermission,
    setProfileImg,
} from "../../helpers/Utils";
const axios = require("axios").default;
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useParams, useHistory, Link } from "react-router-dom";
import CreateTrialModal from "../plans/trialModal";

const SingleUser = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const [userId] = useState(parseInt(id, 10));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [customActiveTab, setCustomActiveTab] = useState("1");
    // const [selectedCountry, setselectedCountry] = useState('')
    const [selectedCalendar, setSelectedCalendar] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [image, setImage] = useState("");
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [allRoles, setAllRoles] = useState([]);
    const [password, setPassword] = useState("");
    const [checkConfiguration, setCheckConfiguration] = useState("gmail");
    const [user, setUser] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [phone, setPhone] = useState("");
    const [phone2, setPhone2] = useState("");
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const [recent, setRecent] = useState([]);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };
    const getSingleUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users/${userId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setUser(res.data.user);
                setPhone(res.data.user.phone_1);
                setPhone2(res.data.user.phone_2);
                setImgPreview(res.data.user.image);
                if (getCurrentUser().id === res.data.user.id) {
                    setProfileImg(res.data.user.image);
                    //   window.location.reload();
                }
                setCheckConfiguration(res.data.user.use_email_configuartion);
            });
    };

    const newUserSubmit = (e, value) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", value.name);
        formData.append("surname", e.target.surname.value);
        formData.append("username", value.username !== null ? value.username : "");
        formData.append("email", value.email);
        formData.append("phone_1", phone !== null ? phone : "");
        formData.append("phone_2", phone2);
        formData.append("role", value.role);
        formData.append("tin", e.target.tin.value);
        formData.append("password", value.password);
        formData.append("image", image);
        formData.append("position", e.target.position.value);
        formData.append("calendar", selectedCalendar);
        formData.append("address", e.target.address.value);
        formData.append("city", e.target.city.value);
        formData.append("state", e.target.state.value);
        // formData.append("country", selectedCountry)
        formData.append("pincode", e.target.pincode.value);
        formData.append("language", value.language);
        formData.append("has_access", value.has_access);
        formData.append("use_email_configuartion", value.use_email_configuartion);
        formData.append("gmail_sender_name", value.gmail_sender_name);
        formData.append("gmail_email_address", value.gmail_email_address);
        formData.append("smtp_sender_name", value.smtp_sender_name);
        formData.append("smtp_email_address", value.smtp_email_address);
        formData.append("smtp_server", value.smtp_server);
        formData.append("smtp_security_protocol", value.smtp_security_protocol);
        formData.append("smtp_password", value.smtp_password);
        formData.append("smtp_port", value.smtp_port);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users/${userId}?_method=PUT`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    window.location.reload();
                    setIsEditable(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };
    const getCountry = (e) => {
        setPhone(e);
    };
    const getCountry2 = (e) => {
        setPhone2(e);
    };
    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    // Country/Set Country
    const optionCountries = useMemo(() => countryList().getData(), []);

    // Calendar /Set Calendar
    const optionCalendar = [{ label: "Personal", value: "personal" }];
    function handleSelectCalendar(selectedCalendar) {
        setSelectedCalendar(selectedCalendar.value);
    }

    // Language /Set Language
    const optionLanguage = [
        { label: "English", value: "english" },
        { label: "Spanish", value: "spanish" },
        { label: "Latam Spanish", value: "latam_spanish" },
        { label: "Chilean Spanish", value: "chilean_spanish" },
        { label: "French", value: "french" },
    ];
    function handleSelectLanguage(selectedLanguage) {
        setSelectedLanguage(selectedLanguage.value);
    }

    const getUserRoles = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/roles`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setAllRoles(res.data.roles);
            });
    };

    const getPassword = () => {
        var generator = require("generate-password");
        setPassword(
            generator.generate({
                length: 10,
                numbers: true,
            })
        );
    };

    const getEmailConfiguration = (e) => {
        setCheckConfiguration(e.target.value);
    };
    const getLatestTransations = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/dashboard-counts?type=recent&order=created_at,DESC&user=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setRecent(res.data.data);
                }
            });
    };
    const getClassName = ($status) => {
        var status_class = "";
        switch ($status) {
            case "pending":
                status_class = "badge bg-warning";
                break;
            case "refused":
                status_class = "badge bg-danger";
                break;
            case "resolved":
                status_class = "badge bg-success";
                break;
            case "paid":
                status_class = "badge bg-success";
                break;
            case "closed":
                status_class = "badge bg-success";
                break;
            default:
                status_class = "badge bg-warning";
        }
        return status_class;
    };
    const getEntryType = ($reference, $link = "no") => {
        if ($reference == "SE" || $reference == "se") {
            if ($link == "no") {
                return "Sales Estimate";
            }
            return "/sales/";
        }
        if ($reference == "SO" || $reference == "so") {
            if ($link == "no") {
                return "Sales Order";
            }
            return "/sales/";
        }
        if ($reference == "SDN" || $reference == "sdn") {
            if ($link == "no") {
                return "Sales Delivery Note";
            }
            return "/sales/";
        }
        if ($reference == "WE" || $reference == "we") {
            if ($link == "no") {
                return "Work Estimates";
            }
            return "/technical-service/";
        }
        if ($reference == "WO" || $reference == "wo") {
            if ($link == "no") {
                return "Work Order";
            }
            return "/technical-service/";
        }

        if ($reference == "WDN" || $reference == "wdn") {
            if ($link == "no") {
                return "Work Delivery Note";
            }
            return "/technical-service/";
        }

        if ($reference == "INV" || $reference == "inv") {
            if ($link == "no") {
                return "Normal Invoice";
            }
            return "/invoicing-service/";
        }

        if ($reference == "RET" || $reference == "ret" || $reference == "RCT") {
            if ($link == "no") {
                return "Refund Invoice";
            }
            return "/invoicing-service/";
        }

        if ($reference == "PDN" || $reference == "pdn") {
            if ($link == "no") {
                return "Purchase Delivery Note";
            }
            return "/purchase-delivery-Notes/";
        }

        if ($reference == "PO" || $reference == "po") {
            if ($link == "no") {
                return "Purchase Order";
            }
            return "/purchase-order/";
        }

        if ($reference == "PINV" || $reference == "pinv") {
            if ($link == "no") {
                return "Purchase Invoice";
            }
            return "/purchase-invoice/";
        }
    };
    useEffect(() => {
        getUserRoles();
        getSingleUser();
        getLatestTransations();
    }, []);

    return (
        <>
            <React.Fragment>
                <CreateTrialModal
                    isTrialModal={isTrialModal}
                    setIsTrialModal={setIsTrialModal}
                    nonActive={nonActive}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>New User | Clouget</title>
                    </MetaTags>
                    <Container fluid>
                        <div className="create_company">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    newUserSubmit(e, v);
                                }}
                            >
                                <div className="page-title-box">
                                    <Row className="align-items-center">
                                        <Col md={6}>
                                            <h6 className="page-title">{props.t("New User")}</h6>
                                        </Col>
                                        <Col md={6}>
                                            <div className="float-end d-flex">
                                                <Link
                                                    type="button"
                                                    to="/company"
                                                    className="btn btn-primary"
                                                >
                                                    {props.t("Back")}
                                                </Link>
                                                {getPermission().users.users.edit.is_checked === 1 ? (
                                                    <>
                                                        {isEditable ? (
                                                            <>
                                                                {loading ? (
                                                                    <button
                                                                        className="btn btn-primary ms-2 disabled"
                                                                        type="button"
                                                                        disabled
                                                                    >
                                                                        {props.t("Loading")}
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="btn btn-primary ms-2"
                                                                        type="submit"
                                                                    >
                                                                        {props.t("Update")}
                                                                    </button>
                                                                )}
                                                                <span
                                                                    onClick={() => setIsEditable(false)}
                                                                    className="btn btn-primary  ms-2"
                                                                >
                                                                    {props.t("Cancel")}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span
                                                                onClick={() => createNew()}
                                                                className="btn btn-primary ms-2"
                                                            >
                                                                {props.t("Edit")}
                                                            </span>
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                                {error ? <Alert color="danger">{props.t(error)}</Alert> : null}

                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3 required">
                                                    <AvField
                                                        readOnly={isEditable ? "" : true}
                                                        label={props.t("Name")}
                                                        type="text"
                                                        name="name"
                                                        value={user.name}
                                                        required="required"
                                                    />
                                                </div>
                                                <div className="mb-3 required">
                                                    <AvField
                                                        readOnly={isEditable ? "" : true}
                                                        label={props.t("Lastname")}
                                                        type="text"
                                                        name="surname"
                                                        value={user.surname}
                                                        required="required"
                                                    />
                                                </div>
                                                <div className="mb-3 required">
                                                    <AvField
                                                        readOnly={isEditable ? "" : true}
                                                        label={props.t("Username")}
                                                        type="text"
                                                        name="username"
                                                        value={user.username}
                                                        required="required"
                                                    />
                                                </div>
                                                <div className="mb-3 required">
                                                    <AvField
                                                        readOnly={isEditable ? "" : true}
                                                        label={props.t("Ced/Ruc")}
                                                        type="number"
                                                        onKeyPress={(e) => {
                                                            if (e.target.value.length == 13)
                                                                e.preventDefault();
                                                        }}
                                                        value={user.tin}
                                                        name="tin"
                                                        required="required"
                                                    />
                                                </div>
                                                <div className="mb-3 required">
                                                    <AvField
                                                        readOnly={isEditable ? "" : true}
                                                        label={props.t("Email")}
                                                        type="email"
                                                        name="email"
                                                        value={user.email}
                                                        required="required"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="company_img">
                                                    <label>{props.t("Employees Photo")}</label>
                                                    <input
                                                        className="hidden d-none"
                                                        onChange={(e) => setComImg(e)}
                                                        type="file"
                                                        id="comImg"
                                                    />
                                                    <label
                                                        className="company_img-box"
                                                        //   htmlFor="comImg"
                                                        htmlFor={isEditable ? "comImg" : ""}
                                                    >
                                                        <img src={imgPreview} />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: customActiveTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom("1");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("General")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: customActiveTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom("2");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("Access")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: customActiveTab === "3",
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom("3");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("History")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={`${classnames({
                                                        active: customActiveTab === "4",
                                                    })}`}
                                                    onClick={() => {
                                                        toggleCustom("4");
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t("Email Configuration")}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={customActiveTab} className="p-3">
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col lg="6">
                                                        <AvField
                                                            className="mb-3"
                                                            placeholder={props.t("Enter phone number")}
                                                            label={props.t("Phone 1")}
                                                            value={user.phone_1 !== null ? user.phone_1 : ""}
                                                            disabled={isEditable ? false : true}
                                                            type="text"
                                                            name="phone_1"
                                                            validate={{
                                                                maxLength: {
                                                                    value: 10,
                                                                    errorMessage:
                                                                        props.t("The number cannot be more than 10 digits."),
                                                                },
                                                            }}
                                                        />
                                                        <AvField
                                                            className="mb-3"
                                                            placeholder={props.t("Enter phone number")}
                                                            label={props.t("Phone 2")}
                                                            value={user.phone_2 !== null ? user.phone_2 : ""}
                                                            disabled={isEditable ? false : true}
                                                            type="text"
                                                            name="phone_2"
                                                            validate={{
                                                                maxLength: {
                                                                    value: 10,
                                                                    errorMessage:
                                                                        props.t("The number cannot be more than 10 digits."),
                                                                },
                                                            }}
                                                        />
                                                        <div className="mb-3">
                                                            <AvField
                                                                readOnly={isEditable ? "" : true}
                                                                label={props.t("Position")}
                                                                type="text"
                                                                name="position"
                                                                value={user.position}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <AvField
                                                                readOnly={isEditable ? "" : true}
                                                                label={props.t("Address")}
                                                                type="text"
                                                                name="address"
                                                                value={user.address}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <AvField
                                                                readOnly={isEditable ? "" : true}
                                                                label={props.t("State/Province")}
                                                                type="text"
                                                                name="state"
                                                                value={user.state}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <AvField
                                                                type="select"
                                                                name="country"
                                                                value={user.country}
                                                                label={props.t("Country")}
                                                                disabled={isEditable ? "" : true}
                                                            >
                                                                {optionCountries &&
                                                                    optionCountries.map((item) => (
                                                                        <option value={item.label} key={item.value}>
                                                                            {item.label}
                                                                        </option>
                                                                    ))}
                                                            </AvField>
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <label>{props.t("Calendar")}</label>
                                                            <Select
                                                                options={optionCalendar}
                                                                onChange={handleSelectCalendar}
                                                                isDisabled={isEditable ? "" : true}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <AvField
                                                                readOnly={isEditable ? "" : true}
                                                                label={props.t("City/Town")}
                                                                type="text"
                                                                name="city"
                                                                value={user.city}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <AvField
                                                                readOnly={isEditable ? "" : true}
                                                                label={props.t("Zip/Postal Code")}
                                                                type="text"
                                                                name="pincode"
                                                                value={user.pincode}
                                                            />
                                                        </div>
                                                        {/* <div className="mb-3">
                              <label>{props.t("Language")}</label>
                              <Select
                                onChange={handleSelectLanguage}
                                options={optionLanguage}
                                isDisabled={isEditable ? "" : true}
                              />
                            </div> */}
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                readOnly={isEditable ? "" : true}
                                                                label={props.t("User")}
                                                                type="text"
                                                                name="pincode"
                                                                value={user.pincode}
                                                            />
                                                        </div>
                                                        <div className="mb-3 required">
                                                            <AvField
                                                                type="select"
                                                                name="role"
                                                                label={props.t("Role")}
                                                                value={user && user.role}
                                                                disabled={
                                                                    isEditable
                                                                        ? user?.role === 1
                                                                            ? true
                                                                            : ""
                                                                        : true
                                                                }
                                                                required="required"
                                                            >
                                                                {allRoles &&
                                                                    allRoles.map((item, i) => (
                                                                        <option
                                                                            hidden={item.id === 1 ? true : false}
                                                                            key={i}
                                                                            value={item.id}
                                                                        >
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                            </AvField>
                                                        </div>
                                                        <div className="mb-3">
                                                            <AvCheckboxGroup
                                                                name="has_access"
                                                                value={user.has_access}
                                                            >
                                                                <AvCheckbox
                                                                    label={props.t("Allow access")}
                                                                    value="1"
                                                                    disabled={isEditable ? "" : true}
                                                                />
                                                            </AvCheckboxGroup>
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                readOnly={isEditable ? "" : true}
                                                                label={props.t("Password")}
                                                                type="text"
                                                                name="password"
                                                                value={password}
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={getPassword}
                                                                className="btn btn-secondary mt-2 border"
                                                                disabled={isEditable ? "" : true}
                                                            >
                                                                {props.t("Generate A New Password")}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <Table striped className="align-middle mt-5">
                                                    <thead>
                                                        <tr>
                                                            <th>{props.t("Reference")}</th>
                                                            <th>{props.t("Client")}</th>
                                                            <th>{props.t("Title")}</th>
                                                            <th>{props.t("Created by")}</th>
                                                            <th>{props.t("Status")}</th>
                                                            <th>{props.t("Type")}</th>
                                                            <th>{props.t("Date")}</th>
                                                            <th>{props.t("Amount")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {recent &&
                                                            recent.map((item, i) => (
                                                                <tr
                                                                    style={{ cursor: "pointer" }}
                                                                    key={i}
                                                                    className="transaction_row"
                                                                    onClick={() =>
                                                                        history.push(
                                                                            getEntryType(item.reference, "yes") +
                                                                            item.id
                                                                        )
                                                                    }
                                                                >
                                                                    <th scope="row">
                                                                        {item.reference + item.reference_number}
                                                                    </th>
                                                                    <td>
                                                                        {item.client_name
                                                                            ? item.client_name
                                                                            : item.supplier_name}
                                                                    </td>
                                                                    <td>{item.title}</td>
                                                                    <td>{item.created_by_name}</td>
                                                                    <td>
                                                                        <span className={getClassName(item.status)}>
                                                                            {props.t(
                                                                                item.status == "pending"
                                                                                    ? "Pending"
                                                                                    : item.status == "pending invoice"
                                                                                        ? "Pending Invoice"
                                                                                        : item.status == "unpaid"
                                                                                            ? "Unpaid"
                                                                                            : item.status == "paid"
                                                                                                ? "Paid"
                                                                                                : item.status == "closed"
                                                                                                    ? "Closed"
                                                                                                    : item.status == "refused"
                                                                                                        ? "Refused"
                                                                                                        : item.status == " resolved"
                                                                                                            ? "Resolved"
                                                                                                            : item.status == "accepted"
                                                                                                                ? "Accepted"
                                                                                                                : item.status == "in_progress"
                                                                                                                    ? "In Progress"
                                                                                                                    : item.status
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {props.t(getEntryType(item.reference))}
                                                                    </td>
                                                                    <td>{item.date}</td>
                                                                    <td>${item.amount}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </Table>
                                            </TabPane>
                                            <TabPane tabId="4">
                                                <label className="mb-3">
                                                    {props.t(
                                                        "Authorize Billing System if you use Gmail or enter the SMTP settings of your mail server:"
                                                    )}
                                                </label>
                                                <AvRadioGroup
                                                    name="use_email_configuartion"
                                                    value={user.use_email_configuartion}
                                                    className="radiobutton"
                                                    onChange={(e) => getEmailConfiguration(e)}
                                                    disabled={isEditable ? "" : true}
                                                >
                                                    <AvRadio label={props.t("Gmail")} value="gmail" />
                                                    <AvRadio label={props.t("SMTP")} value="smtp" />
                                                </AvRadioGroup>
                                                <Row>
                                                    {checkConfiguration === "gmail" ? (
                                                        <>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        readOnly={isEditable ? "" : true}
                                                                        label={props.t("Sender Name")}
                                                                        type="text"
                                                                        name="gmail_sender_name"
                                                                        value={
                                                                            user.gmail_sender_name == "null"
                                                                                ? ""
                                                                                : user.gmail_sender_name
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        readOnly={isEditable ? "" : true}
                                                                        label={props.t("Email Address")}
                                                                        type="text"
                                                                        name="gmail_email_address"
                                                                        value={
                                                                            user.gmail_email_address == "null"
                                                                                ? ""
                                                                                : user.gmail_email_address
                                                                        }
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        readOnly={isEditable ? "" : true}
                                                                        label={props.t("Sender Name")}
                                                                        type="text"
                                                                        name="smtp_sender_name"
                                                                        value={
                                                                            user.smtp_sender_name == "undefined"
                                                                                ? ""
                                                                                : user.smtp_sender_name
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        readOnly={isEditable ? "" : true}
                                                                        label={props.t("Email Address")}
                                                                        type="text"
                                                                        name="smtp_email_address"
                                                                        value={
                                                                            user.smtp_email_address == "undefined"
                                                                                ? ""
                                                                                : user.smtp_email_address
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        readOnly={isEditable ? "" : true}
                                                                        label={props.t("SMTP Server")}
                                                                        type="text"
                                                                        name="smtp_server"
                                                                        value={
                                                                            user.smtp_server == "undefined"
                                                                                ? ""
                                                                                : user.smtp_server
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        readOnly={isEditable ? "" : true}
                                                                        label={props.t("Security Protocol")}
                                                                        type="text"
                                                                        name="smtp_security_protocol"
                                                                        value={
                                                                            user.smtp_security_protocol == "undefined"
                                                                                ? ""
                                                                                : user.smtp_security_protocol
                                                                        }
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        readOnly={isEditable ? "" : true}
                                                                        label={props.t("Password")}
                                                                        type="password"
                                                                        name="smtp_password"
                                                                        value={user.smtp_password}
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        readOnly={isEditable ? "" : true}
                                                                        label={props.t("SMTP Port")}
                                                                        type="text"
                                                                        name="smtp_port"
                                                                        value={
                                                                            user.smtp_port == "undefined"
                                                                                ? ""
                                                                                : user.smtp_port
                                                                        }
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="12">
                                                                <label className="mb-3">
                                                                    {props.t(
                                                                        "These are the configuration settings for the following email service providers:"
                                                                    )}
                                                                </label>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <label>{props.t("HOTMAIL")}</label>
                                                                        <p>
                                                                            SMTP Server: smtp.live.com <br /> Security
                                                                            Protocol (any option is valid):
                                                                            <br /> STARTTLS - SMTP Ports: 25 and 587{" "}
                                                                            <br /> TLS - SMTP Ports: 25, 465 and 587
                                                                        </p>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <label>YAHOO</label>
                                                                        <p>
                                                                            SMTP Server: smtp.mail.yahoo.com <br />{" "}
                                                                            Security Protocol (any option is valid):
                                                                            <br /> STARTTLS - SMTP Ports: 25 and 587{" "}
                                                                            <br /> TLS - SMTP Ports: 25, 465 and 587{" "}
                                                                            <br /> SSL - SMTP Ports: 465
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                                <label className="mt-2">
                                                                    {props.t(`Additional Information: if the email
                                  configuration you have inserted isn&apos;t
                                  valid then your emails will still be sent
                                  using info@billingsystem.com. When the
                                  receiver responds to your email, the reply
                                  will go to your account.`)}
                                                                </label>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </AvForm>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        </>
    );
};
export default withTranslation()(SingleUser);
SingleUser.propTypes = {
    t: PropTypes.any,
};
