/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import SupplierModal from "../CreateClientModal/newSupplier";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
const SupplierCustomDropdown = (props) => {
  const [open, setOpen] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [selectedName, setSelectedName] = useState("");

  const selectOption = (item) => {
    props.clientDetail(item);
    setSelectedName(item.legal_name);
    props.setMainFormDisabled(false);
  };
  return (
    <>
      {createNew ?
        <SupplierModal
          createNew={createNew}
          setMainFormDisabled={props.setMainFormDisabled}
          selectOption={selectOption}
          getClientData={props.getClientData}
          setCreateNew={setCreateNew}
          setClientSelect={props.setClientSelect}
          setClientAddress={props.setClientAddress}
          setClientFull={props.setClientFull}
          setClientNameData={props.setClientNameData}
          getAssests={props.getAssests}
          name={selectedName}
          setOpen={setOpen}
        />
        :
        ""
      }
      <UncontrolledDropdown className="dropdown-menu-right mr-2" isOpen={open}>
        <DropdownToggle className="w-100 text-left p-0 border-0">
          <div className="position-relative">
            <AvField
              className="w-100"
              type="text"
              name="supplier"
              placeholder={props.t("Select")}
              required="required"
              value={
                props.clientNameData !== undefined
                  ? props.clientNameData
                  : selectedName
              }
              autoComplete="off"
              onFocus={() => setOpen(!open)}
              onBlur={() => setTimeout(() => {
                setOpen(!open)
              }, 500)}
              onChange={(e) => { props.getClientData(e.target.value), setSelectedName(e.target.value) }}
              disabled={
                props.isEditable !== undefined
                  ? props.isEditable
                    ? false
                    : true
                  : false
              }
            />
            {props.clientLoader ? (
              <div className="clientLoader">
                <Spinner size="sm" />
              </div>
            ) : (
              ""
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="w-100 p-0"
          style={{
            transform: "translate3d(0px, 35px, 0px)",
            overflow: "auto",
          }}
        >
          <DropdownItem
            onClick={() => {
              setCreateNew(true), props.setMainFormDisabled(true);
            }}
            className="text-dark px-3"
          >
            {props.t("Add New")}
          </DropdownItem>
          {props.data &&
            props.data.map((item, i) => (
              <DropdownItem
                key={i}
                className="text-dark px-3"
                style={{
                  lineHeight: "15px",
                }}
                onClick={() => { selectOption(item), setOpen(false) }}
              >
                <small className="text-uppercase d-block">
                  {item.reference}
                  {item.reference_number}
                </small>
                {item.legal_name} ({item.name})
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};
export default withTranslation()(SupplierCustomDropdown);
SupplierCustomDropdown.propTypes = {
  t: PropTypes.any,
};
