import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
const axios = require("axios").default;
import { getCurrentUser, getPermission } from "../../../helpers/Utils";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let selectedRoleId = [];

function SingleSupplier(props) {
  const [supplier, setSupplier] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const deleteSupplier = (Id) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/suppliers/${Id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setSupplier(null);
        setdynamic_description(res.data.message);
      });
  };

  useEffect(() => {
    selectedRoleId = [];
  }, []);

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      props.setSelectedIds(selectedRoleId);
    } else {
      selectedRoleId = props.selectedIds;
      var array = selectedRoleId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
      props.setSelectedIds(selectedRoleId);
    }
  };

  if (supplier === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td>
            <input
              onChange={(e) => getSelectedId(supplier.id, e)}
              name="chk"
              value={supplier.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td role="button" className="py-1">
            <Link to={`/suppliers/${supplier.id}`}>
              {supplier.reference}
              {supplier.reference_number}
            </Link>
          </td>
          <td role="button">
            <Link to={`/suppliers/${supplier.id}`}>{supplier.legal_name}</Link>
          </td>
          <td role="button">
            <Link to={`/suppliers/${supplier.id}`}>{supplier.name}</Link>
          </td>
          <td role="button">
            <Link to={`/suppliers/${supplier.id}`}>{supplier.tin}</Link>
          </td>
          <td role="button">
            <Link to={`/suppliers/${supplier.id}`}>{supplier.phone_1}</Link>
          </td>
          <td role="button">
            <Link to={`/suppliers/${supplier.id}`}>{supplier.email}</Link>
          </td>
          <td role="button">
            <Link to={`/suppliers/${supplier.id}`}>
              {getCurrentUser().name}
            </Link>
          </td>
          <td role="button">
            <Link to={`/suppliers/${supplier.id}`}>
              {supplier.activity}
            </Link>
          </td>
          <td className="text-end">
            {getPermission().suppliers.suppliers.delete.is_checked === 1 ? (
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                {props.t("Delete")}
              </Button>
            ) : (
              ""
            )}
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteSupplier(supplier.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("The supplier has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
}
export default withTranslation()(SingleSupplier);
SingleSupplier.propTypes = {
  t: PropTypes.any,
};
