import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    Nav,
    Alert,
    NavItem,
    NavLink,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Input,
} from "reactstrap";
import uploadImg from "../../assets/images/def-img.svg";
import templateOne from "../../assets/images/d1.png";
import ColorPicker from "@vtaits/react-color-picker";
import "@vtaits/react-color-picker/dist/index.css";
import classnames from "classnames";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import del from "../../assets/images/delete.png";
import { useParams, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CreateTrialModal from "../plans/trialModal";

const CreateTemplate = (props) => {
    const { id } = useParams();
    const [templateId] = useState(parseInt(id, 10));
    const [customActiveTab, setCustomActiveTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [image, setImage] = useState("");
    const [imgPreview, setImgPreview] = useState(uploadImg);
    const [simple_color, setsimple_color] = useState(0);
    const [color, setcolor] = useState("");
    const [design, setDesign] = useState("original");
    const [isEditable, setIsEditable] = useState(false);
    const [template, setTemplate] = useState("");
    const [fields, setFields] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [timer, setTimer] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsEditable(true);
        }
    };
    const getSingleTemplate = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/my_templates/${templateId}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setTemplate(res.data.template);
                setImgPreview(res.data.template.watermark);
                setcolor(res.data.template.color);
            });
    };

    const newUserSubmit = (e, value) => {
        setLoading(true);
        const formData = new FormData();

        formData.append("name", value.name);
        formData.append("document_type", value.document_type);
        formData.append("font", value.font);
        formData.append("watermark", image);
        formData.append("color", color);
        formData.append("design", design);
        formData.append("is_default", value.is_default);
        formData.append("hide_company_information", value.hide_company_information);
        formData.append("hide_assets_information", value.hide_assets_information);
        formData.append("show_signature_box", value.show_signature_box);
        // formData.append("metas", 'value.name')

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/my_templates${templateId ? `/${templateId}?_method=PUT` : ""}`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    location.reload();
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 4000);
            });
    };

    const onDrag = (c1) => {
        setcolor(c1);
    };

    const setComImg = (e) => {
        setImage(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const getFields = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/template-fields?template_id=${templateId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setFields(res.data.data);
                setLoading(false);
            });
    };

    const templateTextValue = (e) => {
        setInputValue(e.target.value);
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            axios
                .post(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/update-template-field`,
                    {
                        template_id: templateId,
                        field_id: e.target.name,
                        value: e.target.value,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    console.log("field", res);
                });
        }, 500);
        setTimer(newTimer);
    };

    const templateCheckboxValue = (e) => {
        if (e.target.checked) {
            axios
                .post(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/update-template-field`,
                    {
                        template_id: templateId,
                        field_id: e.target.name,
                        value: "1",
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    console.log("field", res);
                });
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/update-template-field`,
                    {
                        template_id: templateId,
                        field_id: e.target.name,
                        value: "0",
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    console.log("field", res);
                });
        }
    };
    const deleteWaternark = () => {
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/my_templates-logo`,
                { id: templateId },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setDeleteMessage(res.data.message);
                location.reload();
            });
    };
    useEffect(() => {
        getFields();
        getSingleTemplate();
    }, []);

    return (
        <React.Fragment>
            <CreateTrialModal
                isTrialModal={isTrialModal}
                setIsTrialModal={setIsTrialModal}
                nonActive={nonActive}
            />
            <div className="page-content">
                <MetaTags>
                    <title>New Template | Clouget</title>
                </MetaTags>
                <Container fluid>
                    <div className="create_company">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                newUserSubmit(e, v);
                            }}
                        >
                            <div className="page-title-box">
                                <Row className="align-items-center">
                                    <Col md={6}>
                                        <h6 className="page-title">{props.t("New Template")}</h6>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-end d-flex">
                                            <Link
                                                type="button"
                                                to="/company"
                                                className="btn btn-primary"
                                            >
                                                {props.t("Back")}
                                            </Link>
                                            {getPermission().my_templates.my_templates.edit
                                                .is_checked === 1 ? (
                                                isEditable ? (
                                                    <>
                                                        {loading ? (
                                                            <button
                                                                className="btn btn-primary ms-2 disabled"
                                                                type="button"
                                                                disabled
                                                                value={setIsEditable(false)}
                                                            >
                                                                {props.t("Loading")}
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn btn-primary ms-2"
                                                                type="submit"
                                                            >
                                                                {props.t("Update")}
                                                            </button>
                                                        )}
                                                        <span
                                                            onClick={() => setIsEditable(false)}
                                                            className="btn btn-primary  ms-2"
                                                        >
                                                            {props.t("Cancel")}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span
                                                        className="btn btn-primary ms-2"
                                                        onClick={() => createNew()}
                                                    >
                                                        {props.t("Edit")}
                                                    </span>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {success ? <Alert color="success">{props.t(success)}</Alert> : null}

                            {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="mb-3 required">
                                                <AvField
                                                    readOnly={isEditable ? "" : true}
                                                    label={props.t("Name")}
                                                    type="text"
                                                    name="name"
                                                    value={props.t(template.name)}
                                                    required="required"
                                                />
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    type="select"
                                                    name="document_type"
                                                    label={props.t("Document type")}
                                                    readOnly={isEditable ? "" : true}
                                                    value={template.document_type}
                                                >
                                                    <option>{props.t("Select a type...")}</option>
                                                    <option value="Sales Estimate">
                                                        {props.t("Sales Estimate")}
                                                    </option>
                                                    <option value="Sales Order">
                                                        {props.t("Sales Order")}
                                                    </option>
                                                    <option value="Sales Delivery Note">
                                                        {props.t("Sales Delivery Note")}
                                                    </option>
                                                    <option value="Work Estimate">
                                                        {props.t("Work Estimate")}
                                                    </option>
                                                    <option value="Work Order">
                                                        {props.t("Work Order")}
                                                    </option>
                                                    <option value="Work Delivery Note">
                                                        {props.t("Work Delivery Note")}
                                                    </option>
                                                    <option value="Normal Invoice">
                                                        {props.t("Normal Invoice")}
                                                    </option>
                                                    <option value="Refund Invoice">
                                                        {props.t("Refund Invoice")}
                                                    </option>
                                                    <option value="Purchase Order">
                                                        Purchase {props.t("Order")}
                                                    </option>
                                                    <option value="Purchase Delivery Note">
                                                        {props.t("Purchase Delivery Note")}
                                                    </option>
                                                    <option value="Purchase Invoice">
                                                        {props.t("Purchase Invoice")}
                                                    </option>
                                                </AvField>
                                            </div>
                                            <div className="mb-3 required">
                                                <AvField
                                                    type="select"
                                                    name="font"
                                                    label={props.t("Font")}
                                                    readOnly={isEditable ? "" : true}
                                                    value={template.font}
                                                    required="required"
                                                >
                                                    <option>{props.t("Select a type...")}</option>
                                                    <option value="Select a font...">
                                                        {props.t("Select a font...")}
                                                    </option>
                                                    <option value="Arial">Arial</option>
                                                    <option value="Arial Black">Arial Black</option>
                                                    <option value="Bookman">Bookman</option>
                                                    <option value="Comic Sans MS">Comic Sans MS</option>
                                                    <option value="Courier">Courier</option>
                                                    <option value="Courier New">Courier New</option>
                                                    <option value="DejaVu Sans">DejaVu Sans</option>
                                                    <option value="DejaVu Sans Mono">
                                                        DejaVu Sans Mono
                                                    </option>
                                                    <option value="DejaVu Serif">DejaVu Serif</option>
                                                    <option value="Garamond">Garamond</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Helvetica">Helvetica</option>
                                                    <option value="Impact">Impact</option>
                                                    <option value="Lucida Sans Unicode">
                                                        Lucida Sans Unicode
                                                    </option>
                                                    <option value="Palatino">Palatino</option>
                                                    <option value="Roboto">Roboto</option>
                                                    <option value="Tahoma">Tahoma</option>
                                                    <option value="Times">Times</option>
                                                    <option value="Times New Roman">
                                                        Times New Roman
                                                    </option>
                                                    <option value="Trebuchet MS">Trebuchet MS</option>
                                                    <option value="Verdana">Verdana</option>
                                                </AvField>
                                            </div>
                                            <div className="mb-3">
                                                <div className="company_img text-start">
                                                    <label>{props.t("Watermark")}</label>
                                                    <br />
                                                    <input
                                                        className="hidden d-none"
                                                        onChange={(e) => setComImg(e)}
                                                        type="file"
                                                        id="comImg"
                                                        disabled={isEditable ? "" : true}
                                                    />
                                                    <button
                                                        type="button-input"
                                                        className="button-input remove-input barcode-btn"
                                                        onClick={() => deleteWaternark()}
                                                        disabled={isEditable ? "" : true}
                                                    >
                                                        <img src={del} height="20px" />
                                                    </button>
                                                    <div className="d-inline-block">
                                                        <label className="company_img-box" htmlFor="comImg">
                                                            <img
                                                                src={imgPreview}
                                                            // ref={imageRef}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label>{props.t("Corporate Color")}</label>
                                                <Input
                                                    type="text"
                                                    name="corporate_color"
                                                    className="colorpicker-default"
                                                    value={color}
                                                    onClick={() => {
                                                        setsimple_color(!simple_color);
                                                    }}
                                                    readOnly
                                                    disabled={isEditable ? "" : true}
                                                />
                                                {simple_color ? (
                                                    <ColorPicker
                                                        saturationHeight={100}
                                                        saturationWidth={100}
                                                        value={color}
                                                        onDrag={onDrag}
                                                    />
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                {/* <AvCheckboxGroup name="checkboxExample" name="is_default" disabled={isEditable ? '' : true}> */}
                                                {/*   <AvCheckbox label="Set as default" value="1" /> */}
                                                {/* </AvCheckboxGroup> */}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="template_design">
                                                <label>{props.t("Select a design")}</label>
                                                <Row>
                                                    <Col lg="2"></Col>
                                                    <Col lg="8" className="text-center">
                                                        <label className="radio-img">
                                                            <input
                                                                className="d-none"
                                                                type="radio"
                                                                disabled={isEditable ? "" : true}
                                                                name="design"
                                                                onChange={(e) => setDesign(e.target.value)}
                                                                value="original"
                                                                defaultChecked
                                                            />
                                                            <span className="tem_title">
                                                                {props.t("original")}
                                                            </span>
                                                            <img className="mw-100" src={templateOne} />
                                                        </label>
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={`${template.template_preview}?user_id=${getCurrentUser()?.id
                                                                }`}
                                                            className="btn btn-light"
                                                        >
                                                            <i className="far fa-file-pdf"></i>{" "}
                                                            {props.t("View / Print")}
                                                        </a>
                                                    </Col>
                                                    {/* <Col className="text-center"> */}
                                                    {/*   <label className="radio-img"> */}
                                                    {/*     <input className="d-none" type="radio" disabled={isEditable ? '' : true} name="design" onChange={(e) => setDesign(e.target.value)} value="visual" /> */}
                                                    {/*     <span className="tem_title"> */}
                                                    {/*       visual */}
                                                    {/*     </span> */}
                                                    {/*     <img className="mw-100" src={templateTwo} /> */}
                                                    {/*   </label> */}
                                                    {/*   <NavLink to="/" className="btn btn-light"><i className="far fa-file-pdf"></i> View / Print</NavLink> */}
                                                    {/* </Col> */}
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Nav tabs>
                                        {fields.map((field, i) => (
                                            <NavItem key={i}>
                                                <NavLink
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    className={classnames({
                                                        active: customActiveTab === i + 1,
                                                    })}
                                                    onClick={() => {
                                                        toggleCustom(i + 1);
                                                    }}
                                                >
                                                    <span className="d-none d-sm-block">
                                                        {props.t(field.tab_name)}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                    <TabContent activeTab={customActiveTab} className="pt-3">
                                        {fields.map((tabs, i) => (
                                            <TabPane tabId={i + 1} key={i}>
                                                <Row>
                                                    {tabs.tab_data.map((item, i) => (
                                                        <Col md="6" key={i}>
                                                            <div className="row">
                                                                {item.more.map((field) => (
                                                                    <>
                                                                        <div className="col-4">
                                                                            {field.option_name === "heading" ? (
                                                                                <p>{props.t(field.option_value)}</p>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {field.option_name === "show" ? (
                                                                                <input
                                                                                    disabled={isEditable ? "" : true}
                                                                                    type="checkbox"
                                                                                    name={field.id}
                                                                                    className="mt-2"
                                                                                    onChange={(e) =>
                                                                                        templateCheckboxValue(e)
                                                                                    }
                                                                                    defaultChecked={
                                                                                        field.option_value === "1"
                                                                                            ? "checked"
                                                                                            : ""
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {field.option_name === "text" ? (
                                                                                field.type === "footer_pages" ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <AvField
                                                                                        type={
                                                                                            field.type === "legal_note"
                                                                                                ? "textarea"
                                                                                                : "text"
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            templateTextValue(e)
                                                                                        }
                                                                                        name={field.id}
                                                                                        value={props.t(field.option_value)}
                                                                                        readOnly={isEditable ? "" : true}
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </TabPane>
                                        ))}
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </AvForm>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(CreateTemplate);
CreateTemplate.propTypes = {
    t: PropTypes.any,
};
