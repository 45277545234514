/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCurrentUser } from "../../../helpers/Utils";
const axios = require("axios").default;
let selectedRoleId = [];

const ListTicketsAndExpenses = (props) => {
  const [displayPurchaseTickets, setDisplayPurchaseTickets] = useState(
    props.data
  );
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const delUser = (e) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/purchase-tickets/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setDisplayInvoices(null);
        setDisplayPurchaseTickets(null);
        setdynamic_description(res.data.message);
      });
  };
  useEffect(() => {
    selectedRoleId = [];
  }, []);

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      props.setSelectedIds(selectedRoleId);
    } else {
      selectedRoleId = props.selectedIds;
      var array = selectedRoleId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
      props.setSelectedIds(selectedRoleId);
    }
  };
  if (displayPurchaseTickets === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td>
            <input
              onChange={(e) => getSelectedId(displayPurchaseTickets.id, e)}
              name="chk"
              value={displayPurchaseTickets.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td role="button" className="py-1">
            <Link to={`/tickets-and-expenses/${displayPurchaseTickets.id}`}>
              {displayPurchaseTickets.reference}
              {displayPurchaseTickets.reference_number}
            </Link>
          </td>
          <td role="button">
            <Link to={`/tickets-and-expenses/${displayPurchaseTickets.id}`}>
              {displayPurchaseTickets.supplier_name}
            </Link>
          </td>
          <td role="button">
            <Link to={`/tickets-and-expenses/${displayPurchaseTickets.id}`}>
              {displayPurchaseTickets.employee_name}
            </Link>
          </td>
          <td role="button">
            <Link to={`/tickets-and-expenses/${displayPurchaseTickets.id}`}>
              {displayPurchaseTickets.status}
            </Link>
          </td>
          <td role="button">
            <Link to={`/tickets-and-expenses/${displayPurchaseTickets.id}`}>
              {displayPurchaseTickets.date}
            </Link>
          </td>
          <td role="button">
            <Link to={`/tickets-and-expenses/${displayPurchaseTickets.id}`}>
              {displayPurchaseTickets.payment_date}
            </Link>
          </td>
          {/* <td role="button">
            <Link to={`/purchase-tickets/${displayPurchaseTickets.id}`}>
              {displayPurchaseOrder.date}
            </Link>
          </td> */}
          <td role="button">
            <Link to={`/tickets-and-expenses/${displayPurchaseTickets.id}`}>
              {displayPurchaseTickets.amount}
            </Link>
          </td>
          <td>
            <button
              type="button-input"
              className="btn btn-danger"
              onClick={() => setconfirm_alert(true)}
            >
              Delete
            </button>
          </td>
          <td role="button">{/* {displayPurchaseOrder.activity} */}</td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title="Are you sure?"
            confirmBtnText="Yes, delete it!"
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              delUser(displayPurchaseTickets.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("File has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
      </>
    );
};

export default ListTicketsAndExpenses;
