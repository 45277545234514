/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Col, Row, Modal, Spinner, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NewPayment from "../PaymentOption/payment_option";
import AssignUserList from "../AssignUserList";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CreateTrialModal from "../../pages/plans/trialModal";

let selectedRoleId = [];
let selectedRoleType = [];
const AllReceipts = (props) => {
    const [displayReceipts, setDisplayReceipts] = useState(props.data);
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [updateError, setUpdateError] = useState("");
    const [isOpenModals, setIsOpenModals] = useState(false);
    const [paymentDate, setPaymentDate] = useState("");
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [paymentData, setPaymentData] = useState("");
    const [payOption, setPayOption] = useState("");
    const [isOpenModalNew, setIsOpenModalNew] = useState(false);
    const [paidBy, setPaidBy] = useState(false);
    const [assignedModal, setAssignedModal] = useState(false);
    const [assignedUserId, setAssignedUserId] = useState("");
    const [assignedUserName, setAssignedUserName] = useState("");
    const [assignUsers, setAssignUsers] = useState([]);
    const [error, setError] = useState("");
    const [nonActive, setNonActive] = useState(
        JSON.parse(localStorage.getItem("plan_data"))?.is_subscription_active !==
            1 &&
            JSON.parse(localStorage.getItem("plan_data"))?.stripe_subscription_id ==
            null
            ? true
            : false
    );
    const [isTrialModal, setIsTrialModal] = useState(false);
    const createNew = () => {
        if (nonActive) {
            setIsTrialModal(true);
        } else {
            setIsOpenModals(true);
        }
    };
    const delUser = (e) => {
        axios
            .delete(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoice-receipts/${e}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setDisplayReceipts(null);
                setdynamic_description(res.data.message);
            });
    };

    const updateReceipt = (e, value) => {
        let paidBox;
        if (value.paid) {
            paidBox = 1;
        } else {
            paidBox = 0;
        }
        const formData = new FormData();
        formData.append("concept", value.concept);
        formData.append("amount", value.amount);
        formData.append("payment_option", value.payment_option);
        formData.append("bank_account", value.bank_account);
        formData.append("payment_date", paymentDate);
        formData.append("paid", paidBox);
        formData.append("paid_by", assignedUserId);
        formData.append("expiration_date", value.expiration_date);
        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/invoice-receipts/${displayReceipts.id}?_method=PUT`,
                formData,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setDisplayReceipts(res.data.data);
                    setIsOpenModals(false);
                    window.location.reload();
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                }, 4000);
            });
    };

    const NewpaymentShow = () => {
        setPaymentLoader(true);
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/payment-options`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setPaymentData(res.data.PaymentOptions);
                setPaymentLoader(false);
            });
    };

    useEffect(() => {
        NewpaymentShow();
        getAssignUser();
        selectedRoleId = [];
        selectedRoleType = [];
    }, []);

    let optionPayment = [];
    if (paymentData !== undefined) {
        {
            paymentData &&
                paymentData.map((payment) => {
                    optionPayment.push({
                        value: payment.id,
                        label: payment.name,
                    });
                });
        }
    }

    const setAsPaid = (e) => {
        if (e.target.checked) {
            setPaidBy(true);
        } else {
            setPaidBy(false);
        }
    };

    const getAssignedUser = (id, name) => {
        setAssignedUserId(id);
        setAssignedUserName(name);
    };

    const getAssignUser = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/users`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setAssignUsers(res.data.users);
                }
            });
    };
    // const getSelectedId = (e, check, type) => {
    //   if (check.target.checked) {
    //     selectedRoleId.push(e);
    //     selectedRoleType.push(type);
    //     const uniqueID = selectedRoleId.filter((val, id, array) => {
    //       return array.indexOf(val) == id;
    //     });
    //     const uniqueType = selectedRoleType.filter((val, id, array) => {
    //       return array.indexOf(val) == id;
    //     });
    //     selectedRoleId = uniqueID;
    //     selectedRoleType = uniqueType;
    //     props.setSelectedIds(selectedRoleId);
    //     props.setSelectedType(selectedRoleType);
    //   } else {
    //     selectedRoleId = props.selectedIds;
    //     selectedRoleType = props.selectedType;
    //     var array = selectedRoleId;
    //     var array2 = selectedRoleType;
    //     var index = array.indexOf(e);
    //     var index2 = array2.indexOf(type);
    //     if (index !== -1) {
    //       array.splice(index, 1);
    //     }
    //     if (index2 !== -1) {
    //       array2.splice(index2, 1);
    //     }
    //     props.setSelectedIds(selectedRoleId);
    //     props.setSelectedType(selectedRoleType);
    //   }
    // };
    const getSelectedId = (e, check) => {
        if (check.target.checked) {
            selectedRoleId.push(e);
            const uniqueID = selectedRoleId.filter((val, id, array) => {
                return array.indexOf(val) == id;
            });
            selectedRoleId = uniqueID;
            props.setSelectedIds(selectedRoleId);
        } else {
            selectedRoleId = props.selectedIds;
            var array = selectedRoleId;
            var index = array.indexOf(e);
            if (index !== -1) {
                array.splice(index, 1);
            }
            props.setSelectedIds(selectedRoleId);
        }
    };

    if (displayReceipts === null) {
        return "";
    } else
        return (
            <>
                {isOpenModalNew ? (
                    <NewPayment
                        setIsOpenModal={setIsOpenModalNew}
                        NewpaymentShow={NewpaymentShow}
                    />
                ) : null}
                {assignedModal ? (
                    <AssignUserList
                        setAssignedModal={setAssignedModal}
                        getAssignedUser={getAssignedUser}
                        data={assignUsers}
                    />
                ) : null}
                <CreateTrialModal
                    isTrialModal={isTrialModal}
                    setIsTrialModal={setIsTrialModal}
                    nonActive={nonActive}
                />
                <tr style={{ cursor: "pointer" }}>
                    <td>
                        <input
                            onChange={(e) =>
                                getSelectedId(
                                    displayReceipts.id,
                                    e,
                                    displayReceipts.invoice?.type
                                )
                            }
                            name="chk2"
                            value={displayReceipts.id}
                            className="p-0 d-inline-block"
                            type="checkbox"
                        />
                    </td>
                    <td onClick={() => createNew()}>
                        {displayReceipts.invoice?.reference}
                        {displayReceipts.invoice?.reference_number}
                    </td>
                    <td onClick={() => createNew()}>
                        {displayReceipts.invoice?.client_name}
                    </td>
                    <td onClick={() => createNew()}>{displayReceipts.concept}</td>
                    <td onClick={() => createNew()}>{displayReceipts.expiration_date}</td>
                    <td onClick={() => createNew()}>
                        {displayReceipts.payment_date}
                        {/* {displayReceipts.payment_date} */}
                    </td>
                    <td onClick={() => createNew()}>
                        {displayReceipts.payment_option_name}
                        {/* {displayReceipts.payment_option} */}
                    </td>
                    <td onClick={() => createNew()}>{displayReceipts.amount}</td>
                    <td onClick={() => createNew()}>
                        {displayReceipts.paid == "0" ? (
                            <span className="badge bg-warning text-dark">
                                {props.t("Unpaid")}
                            </span>
                        ) : (
                            <span className="badge bg-success">{props.t("Paid")}</span>
                        )}
                    </td>
                    <td>
                        {getPermission().normal_invoice_receipts.normal_invoice_receipts
                            .delete.is_checked === 1 ? (
                            <button
                                type="button-input"
                                className="btn btn-danger"
                                onClick={() => setconfirm_alert(true)}
                            >
                                {props.t("Delete")}
                            </button>
                        ) : (
                            ""
                        )}
                    </td>
                </tr>
                {confirm_alert ? (
                    <SweetAlert
                        title={props.t("Are you sure?")}
                        cancelBtnText={props.t("Cancel")}
                        confirmBtnText={props.t("Yes, delete it!")}
                        warning
                        showCancel

                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            delUser(displayReceipts.id);
                            setconfirm_alert(false);
                            setsuccess_dlg(true);
                            setdynamic_title("Deleted");
                            setdynamic_description("File has been deleted.");
                        }}
                        onCancel={() => setconfirm_alert(false)}
                    ></SweetAlert>
                ) : null}

                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t(dynamic_title)}
                        onConfirm={() => {
                            setsuccess_dlg(false);
                        }}
                    >
                        {props.t(dynamic_description)}
                    </SweetAlert>
                ) : null}
                <Modal size="xl" isOpen={isOpenModals} centered={true}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            {props.t("Edit Receipt")}
                        </h5>
                    </div>
                    <div className="modal-body">
                        {error ? <Alert color="danger">{props.t(error)}</Alert> : ""}
                        <AvForm onValidSubmit={(e, v) => updateReceipt(e, v)}>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <AvField
                                            name="invoice"
                                            label={props.t("Invoice")}
                                            type="text"
                                            className="form-control"
                                            disabled
                                            defaultValue={`${displayReceipts.invoice?.reference}
                                                ${displayReceipts.invoice?.reference_number}`}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <AvField
                                            name="concept"
                                            label={props.t("Concept")}
                                            type="text"
                                            className="form-control"
                                            value={displayReceipts.concept}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <label>{props.t("Expiration Date")}</label>
                                        <AvField
                                            name="expiration_date"
                                            type="date"
                                            value={displayReceipts.expiration_date}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <AvField
                                            name="amount"
                                            label={props.t("Amount ($)")}
                                            type="number"
                                            className="form-control"
                                            value={displayReceipts.amount}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <label>{props.t("Payment Option")}</label>
                                    <div className="d-flex justify-content-between mb-3">
                                        {paymentLoader ? (
                                            <div className="pe-2">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="w-100 pe-2">
                                            <AvField
                                                type="select"
                                                name="payment_option"
                                                value={displayReceipts.payment_option}
                                                isDisabled={paymentLoader ? true : false}
                                            >
                                                <option value="" hidden>
                                                    {props.t("Select")}
                                                </option>
                                                {paymentData &&
                                                    paymentData.map((item, i) => (
                                                        <option value={item.id} key={i}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                            </AvField>
                                        </div>
                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={() => setIsOpenModalNew(true)}
                                            style={{
                                                whiteSpace: "nowrap",
                                            }}
                                            type="button"
                                        >
                                            + {props.t("New")}
                                        </button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    {payOption !== 1 ? (
                                        ""
                                    ) : (
                                        <div className="mb-3">
                                            <AvField
                                                label={props.t("Bank Account")}
                                                type="select"
                                                name="bank_account"
                                                value={displayReceipts.bank_accounts}
                                            >
                                                <option value="no_account">
                                                    {props.t("Without bank account")}
                                                </option>
                                                <option value="credit">{props.t("Credit Card")}</option>
                                                <option value="card">{props.t("Debit Card")}</option>
                                            </AvField>
                                        </div>
                                    )}
                                </Col>
                                <Col md={6}>
                                    <div className="">
                                        <AvField
                                            name="paid"
                                            label={props.t("Paid")}
                                            type="checkbox"
                                            onChange={(e) => setAsPaid(e)}
                                            className=""
                                        />
                                    </div>
                                </Col>
                                <Col md={6}></Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <label>{props.t("Paid by")}</label>
                                        <span
                                            onClick={() => setAssignedModal(true)}
                                            className={`form-control ${paidBy ? "" : "disabled"}`}
                                            style={{
                                                minHeight: "35px",
                                            }}
                                        >
                                            {assignedUserName}
                                        </span>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <label>{props.t("Payment Date")}</label>
                                        <DatePicker
                                            selected={paymentDate}
                                            onChange={(e) => setPaymentDate(e)}
                                            dateFormat="yyyy-MM-dd"
                                            // minDate={new Date()}
                                            disabled={paidBy ? false : true}
                                            name="payment_date"
                                            className="is-untouched is-pristine av-valid form-control"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="modal-footer">
                                <div className="text-end">
                                    <button className="btn btn-primary" type="submit">
                                        {props.t("Submit")}
                                    </button>
                                    <button
                                        className="btn btn-primary ms-2"
                                        type="button"
                                        onClick={() => setIsOpenModals(false)}
                                    >
                                        {props.t("Cancel")}
                                    </button>
                                </div>
                            </div>
                        </AvForm>
                    </div>
                </Modal>
            </>
        );
};
export default withTranslation()(AllReceipts);
AllReceipts.propTypes = {
    t: PropTypes.any,
};
