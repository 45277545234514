import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import { getCurrentUser, getPermission } from "../../../helpers/Utils";

let selectedDates;

const DateFilter = (props) => {
  const [dateFilter, setDateFilter] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const formatDate = (date) => {
    let d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  };

  return (
    <div>
      {props.date_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={(e) =>
              props.getSearchedList(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "date"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) =>
              props.getSearchedList(
                {
                  from: "",
                  to: "",
                },
                "date"
              )
            }
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}{" "}
      {props.dateExp_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            type="button"
            onClick={(e) =>
              props.getSearchedList(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "dateExp"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            type="button"
            onClick={(e) =>
              props.getSearchedList(
                {
                  from: "",
                  to: "",
                },
                "dateExp"
              )
            }
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}{" "}
      {props.datePay_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            type="button"
            onClick={(e) =>
              props.getSearchedList(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "datePay"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            type="button"
            onClick={(e) =>
              props.getSearchedList(
                {
                  from: "",
                  to: "",
                },
                "datePay"
              )
            }
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )}
      {/* {props.date2_filter ? (
        <div className="filter-3">
          <div className="mb-3">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateFilter([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateFilter}
            />
          </div>

          <button
            className="btn btn-primary"
            onClick={(e) =>
              props.getSearchedLeads(
                {
                  from: formatDate(dateFilter[0].startDate),
                  to: formatDate(dateFilter[0].endDate),
                },
                "date2"
              )
            }
          >
            <i className="fa fa-search me-2"></i>
            {props.t("Search")}
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={(e) =>
              props.getSearchedLeads(
                {
                  from: "",
                  to: "",
                },
                "date2"
              )
            }
          >
            <i className="fa fa-undo"></i>
          </button>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default withTranslation()(DateFilter);
DateFilter.propTypes = {
  t: PropTypes.any,
};