import React, { useState, useEffect } from "react";
import { Modal, Alert } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../helpers/Utils";
import Loader from "../../../components/Loader";
import { Col, Row } from "reactstrap";
import Select from "react-select";
// import ProSerList from "../Pro&SerList";
import ProSerList from "../../../components/Pro&SerList/index";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
function RateModal(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [rate, setRate] = useState("");
    const [productLoader, setProductLoader] = useState("");
    const [products, setProducts] = useState([]);
    const [data, setData] = useState([]);
    const [productData, setProductData] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [selectItemModal, setSelectItemModal] = useState(false);
    const [remove, setRemove] = useState(props.i);
    //   const [dataProduct, setDataProduct] = useState([]);
    //   const [dataService, setDataService] = useState([]);
    const getSingleRate = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/rates/${props.rateId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.status) {
                    setRate(res.data.rate);
                    setError("");
                    props.getAllRates();
                } else {
                    setError(res.data.message);
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const submitPrice = (e, value) => {
        setLoading(true);
        setProductData([]);

        axios
            .post(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-special-prices${props.rateId !== undefined ? `/${props.rateId}?_method=PUT` : ""
                }`,
                {
                    client_id: props.client.id,
                    product_id:
                        props.rateId == undefined ? productData.id : productData.product_id,
                    purchase_price: value.purchase_price,
                    sales_price: value.sales_price,
                    purchase_margin: value.purchase_margin,
                    sales_margin: value.sales_margin,
                    discount: value.discount,
                    //   product_type: productData.reference,
                    product_type:
                        props.rateId == undefined
                            ? productData.reference
                            : productData.product_type,

                    special_price: value.special_price,
                    //   name: value.product_name,
                    type: "sale_price",
                },
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.status) {
                    setError("");
                    setSuccess(res.data.message);
                    if (props.getAllRates != undefined) {
                        props.getAllRates();
                    }
                    if (props.setRate != undefined) {
                        props.setRate(res.data.client_special_price);
                    }
                    props.setIsOpenModal(false);
                } else {
                    setError(res.data.message);
                    setSuccess("");
                }
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 5000);
            });
    };

    const getProduct = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/products`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setProducts(res.data.products);
            });
    };

    const getServices = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/services`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.status) {
                    setError("");
                    setData(res.data.products);
                } else {
                    setError(res.data.message);
                }
            });
    };
    useEffect(() => {
        getServices();
    }, []);

    const getSinglePrice = () => {
        axios
            .get(
                `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                    "companyId"
                )}/client-special-prices/${props.rateId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setProductData(res.data.client_special_price);
            });
    };

    let productOptions = [];
    if (products !== undefined) {
        products &&
            products.map((item) => {
                productOptions.push({
                    value: item.id,
                    label: [item.reference, item.reference_number, " ", item.name],
                    type: item.reference,
                });
            });
    }

    if (products !== undefined) {
        data &&
            data.map((item) => {
                productOptions.push({
                    value: item.id,
                    label: [item.reference, item.reference_number, " ", item.name],
                    type: item.reference,
                });
            });
    }

    function selectProduct(e) {
        setProductLoader(<Loader />);
        setProductData([]);
        if (e.type == "PRO") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/products/${e.value}`,
                    {
                        headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setProductData(res.data.product);
                    setProductLoader("");
                    props.setMainFormDisabled(false);
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/services/${e.value}`,
                    {
                        headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    //   setData(res.data.products);
                    setProductData(res.data.service);
                    setProductLoader("");
                    props.setMainFormDisabled(false);
                });
        }
    }

    function selectProduct2(e, i, type) {
        setProductLoader(<Loader />);
        setProductData([]);
        if (type == "PRO") {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/products/${e}`,
                    {
                        headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    setProductData(res.data.product);
                    setProductLoader("");
                    props.setMainFormDisabled(false);
                    defaultSelected = {
                        value: res.data.product.id,
                        label: res.data.product.name,
                    };
                });
        } else {
            axios
                .get(
                    `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
                        "companyId"
                    )}/services/${e}`,
                    {
                        headers: {
                            "content-type": "application/json",
                            Authorization: `Bearer ${getCurrentUser().token}`,
                        },
                    }
                )
                .then(function (res) {
                    //   setData(res.data.products);
                    setProductData(res.data.service);
                    setProductLoader("");
                    props.setMainFormDisabled(false);
                    defaultSelected = {
                        value: res.data.service.id,
                        label: res.data.service.name,
                    };
                });
        }
    }

    useEffect(() => {
        getProduct();
        if (props.rateId !== undefined) {
            getSinglePrice();
        }
    }, []);

    return (
        <>
            {selectItemModal ? (
                <ProSerList
                    selectItemModal={selectItemModal}
                    setSelectItemModal={setSelectItemModal}
                    index={remove}
                    data={products}
                    data2={data}
                    selectProduct={selectProduct2}
                    getProduct={getProduct}
                    getServices={getServices}
                    setMainFormDisabled={props.setMainFormDisabled}
                />
            ) : (
                ""
            )}
            <Modal size="lg" isOpen={props.isOpenModal} centered={true}>
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        submitPrice(e, v);
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                            {props.rateId
                                ? `${props.t("Edit Special Sales Price")}`
                                : `${props.t("New Special Sales Price")}`}
                        </h5>
                        <button
                            type="button"
                            className="btn btn-primary text-md btn-sm"
                            onClick={() => props.setIsOpenModal(false)}
                            aria-hidden="true"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        {success ? (
                            <div className="mb-3">
                                <Alert color="success">{success}</Alert>
                            </div>
                        ) : null}

                        {error ? (
                            <div className="mb-3">
                                <Alert color="danger">{error}</Alert>
                            </div>
                        ) : null}

                        <div className="mb-3">
                            <AvField
                                label={props.t("Client")}
                                type="text"
                                name="name"
                                value={props.client && props.client.name}
                                readOnly
                            />
                        </div>

                        <div className="mb-3">
                            <label>{props.t("Product / Service")}</label>
                            <div className="d-flex">
                                <div className="pe-2 w-100">
                                    {props.rateId !== undefined ? (
                                        <AvField
                                            type="text"
                                            name="name"
                                            value={productData.product_name}
                                            readOnly
                                            disabled
                                        />
                                    ) : (
                                        <div className="position-relative">
                                            <Select
                                                options={productOptions}
                                                onChange={(e) => selectProduct(e)}
                                                value={{
                                                    label: [
                                                        productData.reference,
                                                        productData.reference_number,
                                                        productData.name,
                                                    ],
                                                }}
                                            // selectedValue={defaultSelected}
                                            />
                                            <div className="small_loader">{productLoader}</div>
                                        </div>
                                    )}
                                </div>

                                <div className="pe-2">
                                    {props.rateId == undefined ? (
                                        <button
                                            className="btn btn-light"
                                            //   onClick={() => setSelectClientModal(true)}
                                            onClick={() => {
                                                setSelectItemModal(true),
                                                    props.setMainFormDisabled(true);
                                            }}
                                            // onChange={(e) => selectProduct(e)}
                                            type="button"
                                        >
                                            <i className="fas fa-search" />
                                        </button>
                                    ) : (
                                        <button className="btn btn-light" disabled>
                                            <i className="fas fa-search" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Row className="align-items-center">
                            <Col md={6}>
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Purchase Price ($)")}
                                        type="text"
                                        name="purchase_price"
                                        value={productData.purchase_price}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Purchase Margin (%)")}
                                        type="text"
                                        name="purchase_margin"
                                        value={productData.purchase_margin}
                                    />
                                </div>
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Discount (%)")}
                                        type="text"
                                        name="discount"
                                        value={productData.discount}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Sales Price ($)")}
                                        type="text"
                                        name="sales_price"
                                        value={productData.price}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Sales Margin (%)")}
                                        type="text"
                                        name="sales_margin"
                                        value={productData.sales_margin}
                                    />
                                </div>
                                <div className="mb-3">
                                    <AvField
                                        label={props.t("Special Price ($)")}
                                        type="text"
                                        name="special_price"
                                        value={productData.price}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <div className="text-end">
                            {loading ? (
                                <button
                                    className="btn btn-primary disabled"
                                    type="button"
                                    disabled
                                >
                                    {props.t("Loading")}
                                </button>
                            ) : (
                                <button className="btn btn-primary" type="submit">
                                    {props.rateId ? props.t("Update") : props.t("Submit")}
                                </button>
                            )}
                            <button
                                className="btn btn-primary ms-2"
                                type="button"
                                onClick={() => props.setIsOpenModal(false)}
                            >
                                {props.t("Cancel")}
                            </button>
                        </div>
                    </div>
                </AvForm>
            </Modal>
        </>
    );
}
export default withTranslation()(RateModal);
RateModal.propTypes = {
    t: PropTypes.any,
};
