/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../helpers/Utils";
import { Card, Col, Row, Modal, Table } from "reactstrap";
import countryList from "react-select-country-list";

export default function ClientAddress(props) {
  const [clientAdd, setClientAdd] = useState(props.clients);
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
  const [allAddress, setAllAddress] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const createOtherAddress = (e, value) => {
    const formData = new FormData();
    formData.append("client_id", props.clients.id);
    formData.append("address", value.address);
    formData.append("state", value.state);
    formData.append("city", value.city);
    formData.append("zip_code", value.zip_code);
    formData.append("country", value.country);
    formData.append("type", value.type);
    formData.append("extra_information", value.extra_information);
    formData.append("description", value.description);
    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-addresses`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
          setIsOpenModalAdd(false);
          getSingleClientsAddress();
        } else {
          setError(res.data.message);
          setSuccess("");
        }
        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 4000);
      });
  };
  const getSingleClientsAddress = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-addresses?client_id=${clientAdd.id}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setAllAddress(res.data.client_addresses);
      });
  };

  useEffect(() => {
    getSingleClientsAddress();
  }, []);

  const defaultAddress = () => {
    props.setClientData(clientAdd.address);
    props.setDelAddress(clientAdd.address);
    props.setIsOpenModal(false);
  };

  const selectClient = (e, item) => {
    clientAddress = item;
    if (props.setClientNameData !== undefined) {
      props.setClientNameData(item.name);
    }
    if (props.setClientData !== undefined) {
      props.setClientData(item.address);
    }
    if (props.setDelAddress !== undefined) {
      props.setDelAddress(item.address);
    }
    props.setIsOpenModal(false);
  };

  return (
    <>
      <Modal size="lg" isOpen="true" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Address Selection
          </h5>
          <button
            type="button"
            className="cross__btn btn btn-primary waves-effect waves-light"
            onClick={() => props.setIsOpenModal(false)}
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          <Card>
            <div className="overflow-auto w-100">
              <div className="float-end d-none d-md-block">
                <div
                  className="btn btn-primary"
                  onClick={() => setIsOpenModalAdd(true)}
                >
                  Create New
                </div>
              </div>
              <Table striped className="align-middle">
                <thead>
                  <tr>
                    <th>Address</th>
                    <th>City/Town</th>
                    <th>State</th>
                    <th>Zip/Postal Code</th>
                    <th>Country</th>
                    <th>Extra Info</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr onClick={(e) => defaultAddress(e, clientAdd)}>
                    <td>{clientAdd.address}</td>
                    <td>{clientAdd.city}</td>
                    <td>{clientAdd.state}</td>
                    <td>{clientAdd.zip_code}</td>
                    <td>{clientAdd.country}</td>
                    <td>{clientAdd.extra_information}</td>
                    <td>{clientAdd.description}</td>
                    <td></td>
                  </tr>
                  {allAddress &&
                    allAddress.map((item, i) => (
                      <tr key={i} onClick={(e) => selectClient(e, item)}>
                        <td>{item.address}</td>
                        <td>{item.city}</td>
                        <td>{item.state}</td>
                        <td>{item.zip_code}</td>
                        <td>{item.country}</td>
                        <td>{item.extra_information}</td>
                        <td>{item.description}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </div>
        <div className="modal-footer">
          <div className="text-end">
            <button
              className="btn btn-primary ms-2"
              type="button"
              onClick={() => props.setIsOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal size="lg" isOpen={isOpenModalAdd} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Other Addresses
          </h5>
          <button
            type="button"
            className="cross__btn btn btn-primary waves-effect waves-light"
            onClick={() => setIsOpenModalAdd(false)}
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          <AvForm
            onValidSubmit={(e, v) => {
              createOtherAddress(e, v);
            }}
          >
            <Row>
              <Col lg={6}>
                <AvField
                  className="mb-3"
                  label="Address"
                  type="text"
                  name="address"
                />
                <AvField
                  className="mb-3"
                  label="State/Province"
                  type="text"
                  name="state"
                />
                <div className="mb-3">
                  <AvField type="select" name="country" label="Country">
                    {countryList().data.map((item) => (
                      <option value={item.value} key={item.id}>
                        {item.label}
                      </option>
                    ))}
                  </AvField>
                </div>
                <AvField
                  className="mb-3"
                  label="Type"
                  type="select"
                  name="type"
                >
                  <option value="invoicing">Invoicing</option>
                  <option value="delivery">Delivery/Work</option>
                  <option value="other">Other</option>
                </AvField>
              </Col>
              <Col lg={6}>
                <AvField
                  className="mb-3"
                  label="City/town"
                  type="text"
                  name="city"
                />
                <AvField
                  className="mb-3"
                  label="Zip code"
                  type="text"
                  name="zip_code"
                />
              </Col>
              <Col lg={12}>
                <div className="mb-3 mt-3">
                  <AvField
                    label="Extra Info."
                    type="text"
                    name="extra_information"
                  />
                </div>
                <div className="mb-3 mt-3">
                  <AvField
                    label="Description"
                    type="textarea"
                    name="description"
                  />
                </div>
              </Col>
            </Row>
            <div className="modal-footer">
              <div className="text-end">
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
                <button
                  className="btn btn-primary ms-2"
                  type="button"
                  onClick={() => setIsOpenModalAdd(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </AvForm>
        </div>
      </Modal>
    </>
  );
}
