import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { getCurrentUser, getPermission } from "../../helpers/Utils";
const axios = require("axios").default;
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
let selectedRoleId = [];
const AllAssestCustom = (props) => {
  const [displayAssets, setDisplayAssets] = useState(props.data);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const deleteAsset = (Id) => {
    axios
      .delete(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/client-assets/${Id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setDisplayAssets(null);
        setdynamic_description(res.data.message);
      });
  };
  useEffect(() => {
    selectedRoleId = [];
  }, []);

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      props.setSelectedIds(selectedRoleId);
    } else {
      selectedRoleId = props.selectedIds;
      var array = selectedRoleId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
      props.setSelectedIds(selectedRoleId);
    }
  };
  if (displayAssets === null) {
    return "";
  } else
    return (
      <>
        <tr>
          <td>
            <input
              onChange={(e) => getSelectedId(displayAssets.id, e)}
              name="chk"
              value={displayAssets.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>
            <Link to={`/assets/${displayAssets.id}`}>
              <img src={displayAssets.main_image} height="59px"></img>
            </Link>
          </td>
          <td>
            <Link
              to={`/assets/${displayAssets.id}`}
              style={{ color: "inherit" }}
            >
              {displayAssets.reference}
              {displayAssets.reference_number}
            </Link>
          </td>
          <td>
            <Link
              to={`/assets/${displayAssets.id}`}
              style={{ color: "inherit" }}
            >
              {displayAssets.name}
            </Link>
          </td>
          <td>
            <Link
              to={`/assets/${displayAssets.id}`}
              style={{ color: "inherit" }}
            >
              {displayAssets.identifier}
            </Link>
          </td>
          <td>
            <Link
              to={`/assets/${displayAssets.id}`}
              style={{ color: "inherit" }}
            >
              {displayAssets.serial_number}
            </Link>
          </td>
          <td>
            <Link
              to={`/assets/${displayAssets.id}`}
              style={{ color: "inherit" }}
            >
              {displayAssets.client_name}
            </Link>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            {getPermission().client_assets.client_assets.create.is_checked ===
            1 ? (
              <Button
                color="danger"
                onClick={() => {
                  setconfirm_alert(true);
                }}
              >
                {props.t("Delete")}
              </Button>
            ) : (
              ""
            )}
          </td>
        </tr>
        {confirm_alert ? (
          <SweetAlert
            title={props.t("Are you sure?")}
          cancelBtnText={props.t("Cancel")}
            confirmBtnText={props.t("Yes, delete it!")}
            warning
            showCancel
            
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteAsset(displayAssets.id);
              setconfirm_alert(false);
              setsuccess_dlg(true);
              setdynamic_title("Deleted");
              setdynamic_description("The asset has been deleted.");
            }}
            onCancel={() => setconfirm_alert(false)}
          ></SweetAlert>
        ) : null}
        {success_dlg ? (
          <SweetAlert
            success
            title={props.t(dynamic_title)}
            onConfirm={() => {
              setsuccess_dlg(false);
            }}
          >
            {props.t(dynamic_description)}
          </SweetAlert>
        ) : null}
      </>
    );
};
export default withTranslation()(AllAssestCustom);
AllAssestCustom.propTypes = {
  t: PropTypes.any,
};
