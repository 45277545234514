import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Alert, Spinner } from "reactstrap";
const axios = require("axios").default;
import { AvForm, AvField } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCurrentUser } from "../../helpers/Utils";
import AssignUserList from "../../components/AssignUserList";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const WithdrwaModal = (props) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [assignedUserId, setAssignedUserId] = useState(getCurrentUser().id);
  const [assignedUserName, setAssignedUserName] = useState(
    getCurrentUser().email
  );
  const [paymentData, setPaymentData] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [selectedCates, setSelectedCates] = useState("");
  const [bank, setBank] = useState(false);
  const [count, setCount] = useState("0.00");
  const [assignedModal, setAssignedModal] = useState(false);
  const [assignUsers, setAssignUsers] = useState([]);

  //   const [assignedUserId, setAssignedUserId] = useState(getCurrentUser().id);

  const createDeposit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("concept", e.target.concept.value);
    formData.append("date", e.target.date.value);
    formData.append("type", "withdraw");
    formData.append("client_id", props.id);
    formData.append("amount", -e.target.amount.value);
    // formData.append("paid_by_name", assignedUserName);
    formData.append("paid_by", assignedUserId);
    formData.append("payment_option", selectedCates);

    axios
      .post(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/deposit`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status) {
          setError("");
          setSuccess(res.data.message);
        } else {
          setError(res.data.message);
          setSuccess("");
        }

        setTimeout(() => {
          if (props.isTransaction) {
            props.setIsTransaction(res.data.data);
          } else {
            props.getCreateDeposit();
            props.getBalance();
          }
          props.setIsWithdrawOpenModal(false);
          setError("");
          setSuccess("");
        }, 2000);
      });
  };
  const NewpaymentShow = () => {
    setPaymentLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/payment-options`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setPaymentData(res.data.PaymentOptions);
        setPaymentLoader(false);
      });
  };
  function handleSelectCategoryAll(selectedcate) {
    setSelectedCates(selectedcate);
    if (selectedcate === "1") {
      setBank(true);
    } else {
      setBank(false);
    }
  }
  const getAssignUser = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIKEY}/${localStorage.getItem(
          "companyId"
        )}/users`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAssignUsers(res.data.users);
        }
      });
  };
  const getAssignedUser = (id, name) => {
    setAssignedUserId(id);
    setAssignedUserName(name);
  };
  useEffect(() => {
    getAssignUser();
    NewpaymentShow();
  }, []);
  return (
    <>
      {assignedModal ? (
        <AssignUserList
          setAssignedModal={setAssignedModal}
          getAssignedUser={getAssignedUser}
          data={assignUsers}
        />
      ) : (
        ""
      )}
      <div>
        <Modal size="lg" isOpen={props.isWithdrawOpenModal} centered={true}>
          {props.success ? (
            <Alert color="success">{props.success}</Alert>
          ) : null}
          {props.error ? <Alert color="danger">{props.error}</Alert> : ""}
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              createDeposit(e, v);
            }}
            // onSubmit={(e,value) => createDeposit(e)}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                {props.t("New Withdraw")}
              </h5>
              <button
                type="button"
                className="btn btn-primary text-md btn-sm"
                onClick={() => props.setIsWithdrawOpenModal(false)}
                aria-hidden="true"
              >
                &times;
              </button>
            </div>

            <Row>
              <Col>
                <div className="modal-body">
                  <div>
                    <AvField
                      label={props.t("Concept")}
                      type="text"
                      name="concept"
                    />
                  </div>
                  <div className="mb-3">
                    <label>{props.t("Date")}</label>
                    <DatePicker
                      selected={startDate}
                      onChange={(e) => setStartDate(e)}
                      dateFormat="dd/MM/yyyy"
                      name="date"
                      type="date"
                      autoComplete="off"
                      className="is-untouched is-pristine av-valid form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label>{props.t("Payment Option")} </label>
                    <div className="d-flex justify-content-between">
                      {paymentLoader ? (
                        <div className="pe-2">
                          <Spinner animation="border" role="status"></Spinner>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="w-100 pe-2">
                        <AvField
                          type="select"
                          name="payment_option"
                          onChange={(e) =>
                            handleSelectCategoryAll(e.target.value)
                          }
                          isDisabled={paymentLoader ? true : false}
                        >
                          <option value="" hidden>
                            {props.t("Select")}
                          </option>
                          {paymentData &&
                            paymentData.map((item, i) => (
                              <option
                                value={item.id}
                                key={i}
                                // label={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                        </AvField>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label>{props.t("Amount")}:</label>
                    <input
                      type="number"
                      value={count}
                      name="amount"
                      className="form-control"
                      onChange={() => setCount()}
                    />
                  </div>
                  <div className="mb-3">
                    <label>{props.t("Paid by")}:</label>
                    <span
                      className="form-control"
                      onClick={() => setAssignedModal(true)}
                    >
                      {assignedUserName}
                    </span>
                    {/* </input> */}
                  </div>

                  <div className="text-end">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => props.setIsWithdrawOpenModal(false)}
                    >
                      {props.t("Cancel")}
                    </button>
                    <button className="btn btn-primary ms-2" type="submit">
                      {props.t("Save")}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Modal>
      </div>
    </>
  );
};
export default withTranslation()(WithdrwaModal);
WithdrwaModal.propTypes = {
  t: PropTypes.any,
};
