/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import EditDepositWithrawModal from "./EditDepositWithdraw";

const TransactionList = (props) => {
  const [isTransaction, setIsTransaction] = useState(props.data && props.data);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  let selectedRoleId = [];
  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e);
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id;
      });
      selectedRoleId = uniqueID;
      props.setSelectedIds(selectedRoleId);
    } else {
      selectedRoleId = props.selectedIds;
      var array = selectedRoleId;
      var index = array.indexOf(e);
      if (index !== -1) {
        array.splice(index, 1);
      }
      props.setSelectedIds(selectedRoleId);
    }
  };
  useEffect(() => {
    selectedRoleId = [];
  }, []);
  return (
    <>
      <EditDepositWithrawModal
        setIsOpenEditModal={setIsOpenEditModal}
        isOpenEditModal={isOpenEditModal}
        isTransaction={isTransaction}
      />
      <tr style={{ cursor: "pointer" }}>
        <td>
          <input
            onChange={(e) => getSelectedId(isTransaction.id, e)}
            name="chk"
            value={isTransaction.id}
            className="p-0 d-inline-block"
            type="checkbox"
          />
        </td>
        <td>
          <span type="button" onClick={() => setIsOpenEditModal(true)}>
            {isTransaction.concept}
          </span>
        </td>
        <td>
          <span type="button" onClick={() => setIsOpenEditModal(true)}>
            {isTransaction.payment_option_name}
          </span>
        </td>
        <td>
          <span type="button" onClick={() => setIsOpenEditModal(true)}>
            {isTransaction.paid_by_name}
          </span>
        </td>
        <td>
          <span type="button" onClick={() => setIsOpenEditModal(true)}>
            {isTransaction.amount}
          </span>
        </td>
        <td>
          <span type="button" onClick={() => setIsOpenEditModal(true)}>
            {isTransaction.date}
          </span>
        </td>
      </tr>
    </>
  );
};
export default TransactionList;
